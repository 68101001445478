



























































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import { formatDate, formatDateYYYYMMDD } from '@/utils/helpers'
import { mapGetters } from 'vuex'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    methods: { formatDate },
    computed: {
        ...mapGetters({
            conditionnements: 'conditionnement/conditionnements',
            loading_conditionnement: 'conditionnement/loading',
            error_conditionnement: 'conditionnement/error',
            tournees: 'tournee/tournees',
            loading_tournee: 'tournee/loading',
            error_tournee: 'tournee/error'
        })
    },
    components: {
        ErrorDisplay,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class PopupLivraisonFournitures extends Vue {
    @Prop() showPopup: any

    Ability = Ability
    session_en_cours: any = null
    start_at: any = null
    end_at: any = null
    show_form_type = false
    show_form_tournee = false

    tournee: any = {
        libelle: null,
        start_at: null,
        end_at: null
    }

    type_conditionnement: any = {
        libelle: null,
        poids_unitaire: null,
        charge_maximale: null
    }

    /**
     * resetConditionnementError
     * @description Réinitialise l'erreur du conditionnement
     * @return {void}
     */
    @Watch('show_form_type')
    resetConditionnementError(): void {
        if (this.show_form_type) {
            this.$store.commit('conditionnement/SET_ERROR', null)
        }
    }

    /**
     * resetTourneeError
     * @description Réinitialise l'erreur de la tournée
     * @return {void}
     */
    @Watch('show_form_tournee')
    resetTourneeError(): void {
        if (this.show_form_tournee) {
            this.$store.commit('tournee/SET_ERROR', null)
        }
    }

    /**
     * initRegles
     * @description Initialise les règles
     * @return {void}
     */
    @Watch('showPopup')
    initRegles(): void {
        if (this.showPopup) {
            this.$store.dispatch('conditionnement/getConditionnements', { perPage: 0 })
            this.$store.dispatch('tournee/getTournees', { perPage: 0 })

            this.session_en_cours = this.$store.state.session.sessions.find((s: any) => s.id === this.$store.getters['auth/user_session_id'])
            if (this.session_en_cours.livraison_start_at) {
                this.start_at = formatDateYYYYMMDD(this.session_en_cours.livraison_start_at)
                this.end_at = formatDateYYYYMMDD(this.session_en_cours.livraison_end_at)
            }
        }
    }

    /**
     * close_livraison_fournitures
     * @description Ferme la popup de paramétrage des dates de livraison de fournitures
     * @return {void}
     */
    close_livraison_fournitures(): void {
        this.$emit('close')
    }

    /**
     * save_dates_livraison
     * @description Sauvegarde les dates de livraison de fournitures
     * @return {void}
     */
    save_dates_livraison(): void {
        const payload = {
            livraisons: 1,
            session_id: this.session_en_cours.id,
            livraison_start_at: this.start_at,
            livraison_end_at: this.end_at
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('session/updateSessionPlages', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                this.close_livraison_fournitures()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * addType
     * @description Ouvre le formulaire pour ajouter un type de conditionnement
     * @return {void}
     */
    addType(): void {
        this.show_form_type = true
    }

    /**
     * cancel_add_type
     * @description Annule la création d'un type de conditionnement
     * @return {void}
     */
    cancel_add_type(): void {
        this.type_conditionnement = {
            libelle: null,
            poids_unitaire: null,
            charge_maximale: null
        }
        this.show_form_type = false
    }

    /**
     * save_type
     * @description Enregistre un type de conditionnement
     * @return {void}
     */
    save_type(): void {
        this.$store.dispatch('conditionnement/createConditionnement', {
            name: this.type_conditionnement.libelle,
            poids_vide: this.type_conditionnement.poids_unitaire,
            poids_max: this.type_conditionnement.charge_maximale
        })
            .then(() => {
                this.cancel_add_type()
            })
    }

    /**
     * delete_type
     * @description Supprime un type de conditionnement
     * @param {number} conditionnement_id - Id du conditionnement à supprimer
     * @return {void}
     */
    delete_type(conditionnement_id: number): void {
        this.$store.dispatch('conditionnement/deleteConditionnement', conditionnement_id)
    }

    /**
     * addTournee
     * @description Ouvre le formulaire pour ajouter une tournée
     * @return {void}
     */
    addTournee(): void {
        this.show_form_tournee = true
    }

    /**
     * cancel_add_tournee
     * @description Annule la création d'une tournée
     * @return {void}
     */
    cancel_add_tournee(): void {
        this.tournee = {
            libelle: null,
            start_at: null,
            end_at: null
        }
        this.show_form_tournee = false
    }

    /**
     * save_tournee
     * @description Enregistre une tournée
     * @return {void}
     */
    save_tournee(): void {
        this.$store.dispatch('tournee/createTournee', {
            name: this.tournee.libelle,
            start_at: this.tournee.start_at,
            end_at: this.tournee.end_at
        })
            .then(() => {
                this.cancel_add_tournee()
            })
    }

    /**
     * getCentres
     * @description Retourne la liste des centres associés à une tournée
     * @param {any} tournee - Tournée
     * @return {string} Liste des centres associés à la tournée
     */
    getCentres(tournee: any): string {
        return tournee.centres.map((c: any) => c.name).join(', ')
    }

    /**
     * delete_tournee
     * @description Supprime une tournée
     * @param {number} tournee_id - Id de la tournée à supprimer
     * @return {void}
     */
    delete_tournee(tournee_id: number): void {
        const tournee = this.$store.getters['tournee/tournees'].find((t: any) => t.id === tournee_id)
        if (tournee.centres.length === 0) {
            this.$store.dispatch('tournee/deleteTournee', tournee_id)
        } else {
            this.$bvToast.toast(`La tournée est associée aux centres suivants : ${this.getCentres(tournee)}. Veuillez d'abord supprimer les associations avant de supprimer la tournée.`, {
                toaster: 'b-toaster-top-right',
                variant: 'danger',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 5000
            })
        }
    }
}
