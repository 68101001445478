















































































import { Vue, Component, Prop } from 'vue-property-decorator'
import {BButton, BModal} from 'bootstrap-vue'
import { formatDate } from '@/utils/helpers'
import {EtatTraitementDossier, getEtatTraitementDossier} from '@/types/Candidat'

@Component({
    computed: {
        EtatTraitementDossier() {
            return EtatTraitementDossier
        }
    },
    methods: { getEtatTraitementDossier, formatDate },
    components: {
        BModal,
        BButton
    },
    watch: {
        open: {
            handler: 'onOpenChange',
            immediate: true
        },
        modal: {
            handler: 'onModalChange'
        }
    }
})
export default class PopupShowHistoriqueInscriptions extends Vue {
    @Prop({ type: Boolean, required: true, default: false }) readonly open!: boolean

    modal = false
    history: any[] | boolean = false

    /**
     * onOpenChange
     * Mise à jour de l'état de la modale
     * @return {void}
     */
    onOpenChange(): void {
        this.modal = this.open
    }

    /**
     * onModalChange
     * Mise à jour des variables en fonction de l'état de la modale
     * @return {Promise<void>}
     */
    async onModalChange(): Promise<void> {
        if (this.modal) {
            // Chargement de l'historique des transferts
            const response = await this.$store.dispatch('candidat/getHistoriqueTransfert')
            const array = response.data.data

            for (let i = 0; i < array.length; i++) {
                if (array[i].datas.nbDossiers) {
                    delete array[i].datas.nbDossiers
                }
                if (array[i].datas.dossierConcerne) {
                    array[i].dossierConcerne = array[i].datas.dossierConcerne
                    delete array[i].datas.dossierConcerne
                }

                const keys = Object.keys(array[i].datas)
                keys.forEach((key) => {
                    array[i].datas[key.toString().slice(11)] = array[i].datas[key]
                    delete array[i].datas[key]
                })
            }
            this.history = array.reverse()
        } else {
            this.history = false
            this.$emit('close')
        }
    }
}
