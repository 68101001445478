












































































































































import { mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import AnnexeConventionEditor from '@/components/Conventions/AnnexeConventionEditor.vue'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getEtatConventionFromRowItem } from '@/types/Centre'
import { FontAwesomeIcon }        from '@fortawesome/vue-fontawesome'

@Component({
    computed: {
        ...mapState('centre', ['error', 'loading', 'source_pdf', 'centreSelect']),
        ...mapGetters('conventiontype', ['conventiontypes', 'loading', 'totalRows', 'lastPage']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    components: {
        VuePdfApp,
        AnnexeConventionEditor,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    }
})
export default class GestionConvention extends Vue {
    @Prop() show?: boolean
    @Prop() centre?: any
    // @Prop() centre_id?: any

    Ability = Ability
    retour_serveur = null
    centre_select: any = null
    convention_type_select: any = null
    showMessageRejet = false
    messageRejetAnnexe = ''
    annexeIsRejected = false
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = null
    showConfirmPublier = false
    showConfirmDepublier = false
    showPreview = false
    modeEditionAnnexe = false
    file_name = ''


    convention_document = null
    rib_document = null
    montant_annexe_financiere = null

    getEtatConventionFromRowItem = getEtatConventionFromRowItem


    @Watch('centre')
    loadCentreSelect () {
        this.$store.state.centre.error = null
        this.centre_select = this.$props.centre

        if (this.$store.state.conventiontype.conventiontypes.length !== 0) {
            if (this.centre_select) {
                const index = this.$store.state.conventiontype.conventiontypes.findIndex((e: any) => e.id === this.$props.centre.convention_type_id)
                if (index !== null) {
                    this.convention_type_select = this.$store.state.conventiontype.conventiontypes[index]
                }
                this.getMessageRejetAnnexe()
            }
        } else {
            this.$store.dispatch('conventiontype/getConventionTypes').then(() => {
                if (this.centre_select) {
                    const index = this.$store.state.conventiontype.conventiontypes.findIndex((e: any) => e.id === this.$props.centre.convention_type_id)
                    if (index !== null) {
                        this.convention_type_select = this.$store.state.conventiontype.conventiontypes[index]
                    }
                    this.getMessageRejetAnnexe()
                }
            })
        }
    }

    changeModeEditionAnnexe (infoModeEdition: any) {
        this.modeEditionAnnexe = infoModeEdition.modeEdition
        if (infoModeEdition.annexeSaved === true) {
            this.annexeIsRejected = false
        }
    }

    // Retourne l'éventuel message de rejet de la liste
    getMessageRejetAnnexe () {
        if (this.centre_select.commentaire_rejet) {
            if (this.centre_select.commentaire_rejet.annexe) {
                this.messageRejetAnnexe = this.centre_select.commentaire_rejet.annexe
                this.annexeIsRejected = true
            } else {
                this.messageRejetAnnexe = ''
                this.annexeIsRejected = false
            }
        }
    }

    selectFiles (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            const files: any = (target.files as FileList)
            if (target.id === 'input_file_convention') {
                this.convention_document = files[0]
            } else if (target.id === 'input_file_rib') {
                this.rib_document = files[0]
            }
            // Reset de l'input qui nous sert juste à sélectionner des fichiers sur l'instant pour alimenter la liste.
        }
    }

    // upload de la convention
    uploadConvention () {
        if (this.montant_annexe_financiere && this.convention_document !== null) {
            const payload: any = new FormData()
            payload.set('montant_annexe_financiere', this.montant_annexe_financiere)
            payload.set('convention_document', this.convention_document)
            if (this.rib_document) {
                payload.set('rib_document', this.rib_document)
            }

            payload.set('_method', 'PUT')

            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }
            this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
            this.$store.dispatch('centre/updateCentreFormData', { centre_id: this.centre_select.id, payload: payload })
                .then(async () => {
                    await this.$store.dispatch('centre/getCentre', { centre_id: this.centre_select.id })
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Type de convention enregistré.', succesToaster)
                    this.centre_select = this.$store.state.centre.centreSelect
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    previewRIB () {
        this.source_doc = null
        this.$store.dispatch('centre/getRIBPdf', { centre_id: this.$props.centre.id }).then(() => {
            this.source_doc = base64ToArrayBuffer(this.$store.state.centre.source_pdf)
            this.file_name = 'Convention_' + this.$store.state.centre.code
            this.showPreview = true
        })
    }

    // Enregistre le convention_type_id
    saveTypeConvention () {
        const payload = {
            id: this.centre_select.id,
            convention_type_id: this.convention_type_select.id
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(async () => {
                await this.$store.dispatch('centre/getCentre', { centre_id: this.centre_select.id })
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Type de convention enregistré.', succesToaster)
                this.centre_select = this.$store.state.centre.centreSelect
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Dissocie le convention_type_id du centre
    dissociateTypeConvention () {
        const payload = {
            id: this.centre_select.id,
            convention_type_id: null
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Dissociation en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Type de convention dissocié.', succesToaster)
                this.centre_select = this.$store.state.centre.centreSelect
                this.convention_type_select = null
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Rejette la saisie de l'annexe proposée
    rejeterAnnexe () {
        this.showMessageRejet = true
    }

    // Affiche le message de confirmation de la publication de la convention
    confirmPublier () {
        this.showConfirmPublier = true
    }

    // Publication de la convention pour signature
    publier () {
        const payload = {
            id: this.centre_select.id,
            convention_publish: 1
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Publication terminée.', succesToaster)
                this.centre_select = this.$store.state.centre.centreSelect
                this.reset(true)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Annulation de la publication
    cancelPublier () {
        this.showConfirmPublier = false
    }

    // Afficher la confirmation de dépublication
    confirmDepublier () {
        this.showConfirmDepublier = true
    }

    // Annulation de la publication
    cancelDepublier () {
        this.showConfirmDepublier = false
    }

    // Dépublication de la convention pour signature
    depublier () {
        const payload = {
            id: this.centre_select.id,
            convention_publish: 0
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Dépublication terminée.', succesToaster)
                this.centre_select = this.$store.state.centre.centreSelect
                this.reset(true)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Confirmation du rejet de l'annexe financière
    saveRejetConvention () {
        const payload = {
            id: this.centre_select.id,
            annexe_reject: 1,
            commentaire_rejet: this.messageRejetAnnexe
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Annexe financière mise à jour.', succesToaster)
                this.showMessageRejet = false
                this.annexeIsRejected = true
                this.messageRejetAnnexe = ''
                this.centre_select = this.$store.state.centre.centreSelect
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Annulation du rejet de l'annexe financière
    cancelRejetConvention () {
        this.showMessageRejet = false
        this.messageRejetAnnexe = ''
        this.annexeIsRejected = false
    }

    // reset
    reset (close: boolean) {
        this.$store.state.centre.error = null
        this.messageRejetAnnexe = ''
        this.annexeIsRejected = false
        this.showMessageRejet = false
        this.centre_select = null
        this.convention_type_select = null
        this.source_doc = null
        this.showConfirmPublier = false
        this.showConfirmDepublier = false
        this.showPreview = false

        this.convention_document = null
        this.rib_document = null
        this.montant_annexe_financiere = null

        if (this.$refs.convention_document) {
            (this.$refs.convention_document as HTMLInputElement).value = ''
        }
        if (this.$refs.rib_document) {
            (this.$refs.rib_document as HTMLInputElement).value = ''
        }


        if (close) {
            this.$emit('close', true)
        }
    }

    // Active le preview de la convention
    previewConvention () {
        this.source_doc = null
        this.showPreview = true
        this.$store.dispatch('centre/getConventionPDF', { centre_id: this.$props.centre.id }).then(() => {
            this.source_doc = base64ToArrayBuffer(this.$store.state.centre.source_pdf)
            this.file_name = 'Convention_' + this.$props.centre.code
        })
    }

    // Close preview
    closePreview () {
        this.source_doc = null
        this.showPreview = false
        this.file_name = ''
    }
}
