






























































import { Vue, Component, Watch }    from 'vue-property-decorator'
import { mapGetters, mapState }               from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { checkIcone, formatDate } from '@/utils/helpers'
import AssistantValidationDossierAdministratif from '@/views/Dossiers/AssistantValidationDossierAdministratif.vue'
import GestionOrdreMission from '@/components/OrdresMission/GestionOrdreMission.vue'
import PopupRelaunchOrdreMission from '@/components/DossierAdministratif/PopupRelaunchOrdreMission.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'

import { PosteContext, PosteInterface, PosteType } from '@/types/Poste'
import { Etat, getEtatSpec } from '@/types/DossierAcademique'

@Component({
    computed: {
        ...mapState('dossieradministratif', ['dossiersAdministratifs', 'loading', 'error', 'totalRows', 'currentPage', 'lastPage', 'totalPage', 'meta']),
        ...mapState('session', ['sessions']),
        ...mapState('poste', ['postes']),
        ...mapState('matiere', ['matieres']),
        ...mapState('ordremission', ['ordreMissions', 'ordreMissionSelect', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        PopupRelaunchOrdreMission,
        GestionOrdreMission,
        AssistantValidationDossierAdministratif,
        PopUpInfoIntervenant
    }
})

export default class GestionOrdresMissionRespAdm extends Vue
{
    showModalEditionCandidat?:          boolean = false
    showModalEditionCommentCandidat?:   boolean = false
    formatDate = formatDate
    sessionActive: any = null
    tableLoading = false
    Ability = Ability
    showModalInfoIntervenant = false
    user_id_temp = 0

    // Ensemble des colonnes du tableau de OrdreMissions
    genericfields = [
        { key: 'etatEdit',                              label: '',                      sortable: false,   class: '', type: 'action' },
        { key: 'etat_dossier_administratif',            label: 'État',                  sortable: true,    class: 'text-center', type: 'text' },
        { key: 'user.name', label: 'Identité', sortable: true, sortDirection: 'asc', class: 'text-start ps-4',  type: 'actionText'  },
        { key: 'ordre_mission_poste_id',                label: 'Poste',                 sortable: false,   class: '', type: 'text' },
        { key: 'dossier_academique_matieres',            label: 'Matière(s)',               sortable: true,    class: '', type: 'text' },
        { key: 'etat_contrats_interne',                 label: 'Contrat(s)',            sortable: true,    class: 'text-center', type: 'text' },
        { key: 'etat_ordres_mission',                   label: 'Ordre(s) de mission',   sortable: true,    class: 'text-center', type: 'text' },
        { key: 'relanceOrdreMission',                   label: 'Relance',               sortable: false,   class: 'text-center', type: 'text' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;

    filtres:    any         = []
    dataForTab: Array<any>  = []
    totalOrdresMisson = 0
    filtreJustInitiated = true
    showConfirmRelance1by1 = false
    showOrdreMission = false
    rowSelect: any = null
    dossier_id = 0
    params = []

    /**
     * loadingChangeHandler
     * @description Met à jour l'état de chargement de la liste
     * @return {void}
     */
    @Watch('loading')
    loadingChangeHandler(): void {
        this.tableLoading = this.$store.state.candidat.loading
    }

    /**
     * majInfosTable
     * @description Met à jour les informations de la liste
     * @return {void}
     */
    @Watch('meta')
    majInfosTable(): void {
        // On récupère le nombre total de dossiers filtrés depuis les infos des Metadonnées
        if (this.$store.state.dossieradministratif.meta !== null) {
            if (this.totalOrdresMisson !== this.$store.state.dossieradministratif.meta.total) {
                this.totalOrdresMisson = this.$store.state.dossieradministratif.meta.total
            }
        } else {
            this.totalOrdresMisson = 0
        }
    }

    /**
     * refreshInterface
     * @description Met à jour l'interface au changement de session
     * @return {void}
     */
    @Watch('user_session_id')
    refreshInterface(): void {
        this.load()
    }

    /**
     * formatteDateSignature
     * @description Formatte les dates des signatures pour colonne du tableau
     * @param {Array<any>} contrats - Liste des contrats
     * @return {string} - Retourne la liste des contrats avec les dates de signature
     */
    formatteDateSignature(contrats: Array<any>): string {
        let retour = ''
        if (contrats && contrats.length > 0) {
            for (let i = 0; i < contrats.length; i++) {
                if (i > 0) {
                    retour += ', '
                }
                retour += 'Contrat #' + contrats[i].id

                if (contrats[i].relaunched_at) {
                    retour += ' relancé le ' + formatDate(contrats[i].relaunched_at)
                } else {
                    retour += ' non relancé'
                }
            }
        }

        return retour
    }


    /** Affichage de l icone enveloppe  */
    /**
     * relanceMail
     * @description Affichage de l'icone enveloppe pour relancer les mails
     * @param {any} result - Données du dossier
     * @return {Array<any>} - Icone enveloppe
     */
    relanceMail(result: any): Array<any> {
        let retour: any = []
        if (result.contrats && result.contrats.length > 0) {
            result.contrats.forEach((element: { etat: Etat }) => {
                if ((element.etat === Etat.ETAT_VALIDE || element.etat === Etat.ETAT_DEPOSE) && this.$store.getters['auth/can'](Ability.RH_DOSADM_MANAGE) && result.ordres_mission.length === 0) {
                    retour = [{
                        name:'envelope',
                        class: 'text-info',
                        dossier_id: result.id,
                        title: result.contrats ? this.formatteDateSignature(result.contrats) : 'non relancé',
                        result: result,
                        button: true
                    }]
                }
            })
        }

        return retour
    }

    /**
     * setDataForGenericTab
     * @description Formatte les données pour le tableau
     * @param {any} poData - Données du dossier
     * @param {boolean} isLoadMore - Chargement de plus de données
     * @return {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            const can = this.$store.getters['auth/can'](Ability.RH_DOSADM_MANAGE)
            const icone = checkIcone(Ability.RH_DOSADM_MANAGE, can)

            for (const result of poData) {
                // Puce etat dossier
                const puce_etat_dossier = []
                if (result.etat !== Etat.ETAT_INVISIBLE) {
                    puce_etat_dossier.push({ name:'circle', class: 'text-' + getEtatSpec(result.etat).color, title: getEtatSpec(result.etat).libelle })
                } else {
                    puce_etat_dossier.push({ name:'circle', class: 'text-tertiary', title: 'Dossier non initialisé' })
                }

                // Postes
                const postes: string = [ ...new Set(result?.dossier_academique?.poste_affectations?.map((p: any) => p.name)) ].join(', ') || '-'

                // Matières
                const matieres: string = [ ...new Set(result?.dossier_academique?.matieres?.map((m: any) => m.name)) ].join(', ') || '-'

                // Puce contrat
                let puce_contrats = []
                if (result.contrats.length === 0) {
                    puce_contrats = []
                    puce_contrats.push({ name:'times-circle', class: 'text-secondary', title: 'Pas de contrat' })
                } else {
                    for (const c in result.contrats) {
                        switch (result.contrats[c].etat) {
                            case Etat.ETAT_INVISIBLE:
                                puce_contrats.push({ name:'file-alt', class: 'text-tertiary', title: 'Contrat non publié', result: result })
                                break
                            case Etat.ETAT_VIERGE:
                                puce_contrats.push({ name:'file-alt', class: 'text-secondary', title: 'Contrat publié', result: result })
                                break
                            case Etat.ETAT_DEPOSE:
                                puce_contrats.push({ name:'file-alt', class: 'text-info', title: 'Contrat signé par l\'intervenant', result: result })
                                break
                            case Etat.ETAT_VALIDE:
                                puce_contrats.push({ name:'file-alt', class: 'text-success', title: 'Contrat signé', result: result })
                                break
                        }
                    }
                }

                // Puce Ordre de missions
                const puce_ordre_mission = []
                if (result.ordres_mission.length === 0) {
                    puce_ordre_mission.push({ name:'times-circle', class: 'text-secondary', title: 'Pas d\'ordre de mission' })
                } else {
                    for (const o in result.ordres_mission) {
                        puce_ordre_mission.push({ name:'file-alt', class: 'text-' + getEtatSpec(result.ordres_mission[o].etat).color, dossier_id: result.id, ordremission_id: result.ordres_mission[o].id, title: result.ordres_mission.length > 1 ? 'ORDRE DE MISSION #' + result.ordres_mission[o].id + ' - CONTRAT #' + result.ordres_mission[o].contrat_type_dossier_administratif_id + ' - ' + getEtatSpec(result.ordres_mission[o].etat).libelle : getEtatSpec(result.ordres_mission[o].etat).libelle })
                    }
                }

                // Puce Relance
                const puce_relance = this.relanceMail(result)

                const line = [
                    { label: icone.label,      item: result.id, type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: puce_etat_dossier, type:'icons', typeAction: null, class: 'text-center' },
                    { label: '', item: result.user.id, type: 'actionText', typeAction: 'infoIntervenant', class: 'text-info item_action', text: result.user.name + ' ' + result.user.first_name },
                    { label: '', item: postes, type: 'text', typeAction: null, class: '' },
                    { label: '', item: matieres, type: 'text', typeAction: null, class: '' },
                    { label: '', item: puce_contrats, type:'icons', typeAction: null, class: 'text-center' },
                    { label: '', item: puce_ordre_mission, type:'icons', typeAction: result.ordres_mission.length === 0 ? null : 'ordres_mission', class: 'text-center' },
                    { label: '', item: puce_relance && puce_relance.length !== 0 ? puce_relance : null, type: puce_relance && puce_relance.length !== 0 ? 'icons' : null, typeAction: 'relance', class: 'text-center' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * setFiltersForGenericTab
     * @description Ajout des filtres diponibles pour le tableau
     * @return {void}
     */
    setFiltersForGenericTab(): void {
        const options_matieres = []
        for (const m in this.$store.state.matiere.matieres) {
            options_matieres.push({ index: this.$store.state.matiere.matieres[m].id, name: this.$store.state.matiere.matieres[m].name })
        }

        const options_postes = []
        for (const p in this.$store.state.poste.postes.filter((p: PosteInterface) => (p.context & PosteContext.CONTEXT_AFFECTATION) === PosteContext.CONTEXT_AFFECTATION)) {
            const poste_temp = this.$store.state.poste.postes.filter((p: PosteInterface) => (p.context & PosteContext.CONTEXT_AFFECTATION) === PosteContext.CONTEXT_AFFECTATION)[p]
            options_postes.push({ index: poste_temp.id, name: poste_temp.name })
        }

        const etats_contrats = []
        etats_contrats.push({ index: null, name: 'Pas de contrats' })
        etats_contrats.push({ index: Etat.ETAT_INVISIBLE, name: 'Contrat en cours de création' })
        etats_contrats.push({ index: Etat.ETAT_VIERGE, name: 'Contrat publié' })
        etats_contrats.push({ index: Etat.ETAT_DEPOSE, name: 'Contrat signé par l\'intervenant' })
        etats_contrats.push({ index: Etat.ETAT_VALIDE, name: 'Contrat signé' })

        const etats_ordre_missions = []
        etats_ordre_missions.push({ index: null, name: 'Pas de d\'ordre de mission' })
        etats_ordre_missions.push({ index: Etat.ETAT_DEPOSE, name: 'Ordre de mission déposé' })
        etats_ordre_missions.push({ index: Etat.ETAT_VALIDE, name: 'Ordre de mission accepté' })
        etats_ordre_missions.push({ index: Etat.ETAT_REJETE, name: 'Ordre de mission refusé' })

        this.filtres =
        [
            { libelle: 'Nom', defautOptionlibelle: 'Rechercher un', model: 'user.name', value: '', datas: '', loading:false, options: { type: 'form', fieldsKey: 'user.name' } },
            { libelle: 'poste', defautOptionlibelle: 'Rechercher un', model: 'ordre_mission_poste_id', value: '', index: 'ordre_mission_poste_id', datas: options_postes, loading: false, options: { type: 'deroulant', fieldsKey: 'ordre_mission_poste_id' } },
            { libelle: 'matière', defautOptionlibelle: 'Rechercher une', model: 'dossier_academique_matieres', value: '', index: 'dossier_academique_matieres', datas: options_matieres, loading: false, options: { type: 'deroulant', fieldsKey: 'dossier_academique_matieres' } },
            { libelle: 'état de contrat', defautOptionlibelle: 'Rechercher un', model: 'etat_contrats_interne', value: '', index: 'etat_contrats_interne', datas: etats_contrats, loading: false, options: { type: 'deroulant', fieldsKey: 'etat_contrats_interne' } },
            { libelle: 'état ordre de mission', defautOptionlibelle: 'Rechercher un', model: 'etat_ordres_mission', value: '', index: 'etat_ordres_mission', datas: etats_ordre_missions, loading: false, options: { type: 'deroulant', fieldsKey: 'etat_ordres_mission' } }
        ]
    }

    /**
     * handleTableEvent
     * @description Gestion des actions sur le tableau
     * @param {any} paParams - Paramètres de l'action [params[0] => l'action, params[1] => l'id de l'item]
     * @return {void}
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const dossiersAdministratifs = this.$store.state.dossieradministratif.dossiersAdministratifs
            let selectedDossier = null
            switch (paParams[0]) {
                case 'edit':
                    // Récupération du candidat by ID
                    selectedDossier = dossiersAdministratifs.filter((dossier: any) => dossier.id === paParams[1])[0]
                    if (selectedDossier) {
                        this.dossier_id = selectedDossier.id
                        this.$store.commit('dossieradministratif/SET_VIEW_DOSSIER', true)
                    }
                    break
                case 'ordres_mission':
                    selectedDossier = dossiersAdministratifs.filter((dossier: any) => dossier.id === paParams[1][paParams[2]].dossier_id)[0]
                    if (selectedDossier) {
                        this.dossier_id = selectedDossier.id
                        this.showEditOrdreMission(selectedDossier.id, paParams[1][paParams[2]].ordremission_id)
                    }
                    break
                case 'relance':
                    selectedDossier = dossiersAdministratifs.filter((dossier: any) => dossier.id === paParams[1][0].dossier_id)[0]
                    this.rowSelect = selectedDossier
                    if (selectedDossier) {
                        this.dossier_id = selectedDossier.id
                        this.openRelance1By1()
                    }
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    /**
     * infoIntervenantHandler
     * @description Affichage de la popup d'information de l'intervenant
     * @param {number} user_id - ID de l'intervenant
     * @return {void}
     */
    infoIntervenantHandler(user_id: number): void {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    /**
     * loadOrdreMissionsIfNotExists
     * @description Récupération des 100 premiers ordres de missions s'ils ne sont pas déja présents dans le store
     * @return {Promise<any>} - Résultat de la requête
     */
    loadOrdreMissionsIfNotExists(): Promise<any> {
        return new Promise((resolve) => {
            this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_PEDAGOGIQUE, context: PosteContext.CONTEXT_AFFECTATION })
                .then(() => {
                    this.$store.dispatch('matiere/getMatieres')
                        .then(() => {
                            this.$store.dispatch('dossieradministratif/getDossiersAdministratifs', { sort:'user.name', direction:'asc' })
                                .then((result) => {
                                    if (this.$store.state.dossieradministratif.meta !== null) {
                                        if (this.totalOrdresMisson !== this.$store.state.dossieradministratif.meta.total) {
                                            this.totalOrdresMisson = this.$store.state.dossieradministratif.meta.total
                                        }
                                    } else {
                                        this.totalOrdresMisson = 0
                                    }
                                    resolve(result)
                                })
                        })
                })
                .catch((error) => {
                    console.log('error : ' + error)
                })
        })
    }

    /**
     * initDatas
     * @description Initialisation des données OrdreMissions affichées dans le tableau ainsi que les compteurs
     * @param {any} poOrdres - Données des ordres de missions
     * @return {void}
     */
    initDatas(poOrdres: any): void {
        if (poOrdres && poOrdres.data && poOrdres.data.data) {
            this.setDataForGenericTab(poOrdres.data.data)
        }
    }

    /**
     * loadHandler
     * @description Appel d'une nouvelle page de OrdreMissions lors du scroll
     * @param {any} params - Paramètres de la requête
     * @return {void}
     */
    loadHandler(params: any): void {
        this.$store.dispatch('dossieradministratif/getMoreDossiersAdministratifs', params)
            .then(() => {
                this.setDataForGenericTab(this.$store.state.dossieradministratif.dossiersAdministratifs)
            })
    }

    /**
     * filtreSortHandler
     * @description Appel des datas avec un sort en paramètres
     * @param {any} params - Payload de la requête
     * @return {void}
     */
    filtreSortHandler(params: any): void {
        if (this.filtreJustInitiated) {
            this.filtreJustInitiated = false
        } else {
            this.$store.state.examinateur.listeExaminateurs = []
            this.$store.dispatch('dossieradministratif/getDossiersAdministratifs', params)
                .then(() => {
                    this.setDataForGenericTab(this.$store.state.dossieradministratif.dossiersAdministratifs)
                })
        }
    }

    /**
     * showEditOrdreMission
     * @description Affichage de la popup de modification de l'ordre de mission
     * @param {number} dossier_id - ID du dossier
     * @param {number} ordremission_id - ID de l'ordre de mission
     * @return {void}
     */
    showEditOrdreMission(dossier_id: number, ordremission_id: number): void {
        this.$store.dispatch('ordremission/getOrdreMissions', { 'filter-dossier_administratif_id': dossier_id })
            .then(() => {
                this.$store.dispatch('ordremission/getOrdreMission', { ordremission_id: ordremission_id })
                    .then(() => {
                        this.$store.state.ordremission.error = null
                        this.showOrdreMission = true
                    })
            })
    }

    /**
     * closeOrdreMission
     * @description Fermeture de la popup de modification de l'ordre de mission
     * @return {void}
     */
    closeOrdreMission(): void {
        this.showOrdreMission = false
        this.setDataForGenericTab(this.$store.state.dossieradministratif.dossiersAdministratifs)
    }

    /**
     * reloadAfterOrdreMission
     * @description Fermeture de la popup de modification de l'ordre de mission et rechargement du tableau
     * @return {void}
     */
    reloadAfterOrdreMission(): void {
        this.showOrdreMission = false
        this.load()
    }

    /**
     * relance1By1
     * @description Relance pour la demande au cas par cas
     * @return {void}
     */
    relance1By1(): void {
        const params = { mission_relaunched: 1 }

        this.$store.dispatch('dossieradministratif/updateDossierAdministratif', { dossier_id: this.dossier_id, payload: params })
            .then(() => {
                this.showConfirmRelance1by1 = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
            })
    }

    /**
     * openRelance1By1
     * @description Ouverture de la popup de relance au cas par cas
     * @return {void}
     */
    openRelance1By1(): void {
        this.showConfirmRelance1by1 = true
    }

    /**
     * closeRelance1by1
     * @description Fermeture de la popup de relance au cas par cas
     * @return {void}
     */
    closeRelance1by1(): void {
        this.showConfirmRelance1by1 = false
    }

    /**
     * load
     * @description Chargement des données à afficher
     * @return {void}
     */
    load(): void {
        // Chargement des ordres de missions
        this.loadOrdreMissionsIfNotExists()
            .then((result) => {
                this.initDatas(result)
                this.setFiltersForGenericTab()
            })
        this.filtres = []
    }

    /**
     * mounted
     * @description Action au chargement du composant
     * @return {void}
     */
    mounted(): void {
        if (!(this.$store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1')) {
            this.$router.go(-1)
        }

        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
