






































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'
import store from '@/store/index'

@Component({
    computed: {
        ...mapState('dossieradministratif', ['dossiersSelections', 'error', 'countError', 'dossiersOpen', 'dossiersAdministratifsOuvertParEtat'])
    }
})
export default class PopupRelaunchContract extends Vue {
    @Prop() mode?: string;
    @Prop() params: any;
    // DATAS
    name_intervenant = ''
    first_name = ''
    email = ''
    session_id = ''
    showError = false
    retour_serveur = null
    envoiEnCours = false
    modalSignature = false

    // METHODS

    // Ouverture en masse de dossier en fonction de la liste filtrée
    relaunchContract () {
        // console.log('-------------------------------test relaunchContract------------------------------------')
        this.envoiEnCours = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Envoi en cours ...', infosToaster)

        const params_loc = this.$props.params
        if (this.$props.mode === 'sps') {
            params_loc.section = 'sps'
        } else {
            params_loc.section = 'sol'
        }

        this.$store.dispatch('dossieradministratif/relaunchContratsDossiersAdministratifs', params_loc)
            .then(() => {
                this.retour_serveur = this.$store.state.dossieradministratif.dossiersOpen
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(this.retour_serveur + ' demande(s) de signature relancé(s) avec succès !', succesToaster)
                this.envoiEnCours = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Close la popup
    closeModal () {
        this.retour_serveur = null
        store.dispatch('dossieradministratif/getDossiersAdministratifs', this.$props.params)
        this.modalSignature = false
    }

    showModalRelancerSignature() {
        this.modalSignature = true
    }
}
