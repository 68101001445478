


























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        FontAwesomeIcon
    },
    methods: {
        ...mapMutations('dossieracademique', ['UPDATE_DATAS_DERNIER_DIPLOME', 'UPDATE_DATAS_DERNIER_DIPLOME_AUTRE']),
        currentYear() {
            return new Date().getFullYear()
        }
    }
})
export default class SelectDiplome extends Vue {
    @Prop() selectedValues?: []
    @Prop() selectedValue?: any
    @Prop() other?: string
    @Prop() readOnly?: boolean
    @Prop({default: 'CCMP'}) mode_formulaire?: string

    diplome_annee = ''
    diplome_libelle = ''

    options = [
        { id: 'Doctorat', value: 'Doctorat' },
        { id: 'Agrégation', value: 'Agrégation' },
        { id: 'Master', value: 'Master' },
        { id: 'Licence', value: 'Licence' },
        { id: 'Diplôme d\'ingénieur', value: 'Diplôme d\'ingénieur' },
        { id: 'Autre', value: 'Autre' }
    ]

    diplomes: Array<any> = []

    ajouterDiplome () {
        if(this.diplome_annee.trim() !== '' &&  this.diplome_libelle.trim() !== '') {
            let currentId = 0
            for(let i = 0; i < this.diplomes.length; i++) {
                if(this.diplomes[i].id > currentId || this.diplomes[i].id === currentId) {
                    currentId = this.diplomes[i].id + 1
                }
            }
            this.diplomes.push({
                id: currentId,
                annee: this.diplome_annee,
                libelle: this.diplome_libelle
            })
            this.diplome_annee = ''
            this.diplome_libelle = ''
        }
        this.updateDatasPro()
    }

    deleteDiplome (diplome_id: number) {
        const index = this.diplomes.findIndex((d:any) => (d.id === diplome_id))
        this.diplomes.splice(index, 1)
        this.updateDatasPro()
    }

    // Event change sur le select
    updateDatasPro (e?: Event) {
        if(this.mode_formulaire === 'CCS') {
            this.$store.commit('dossieracademique/UPDATE_DIPLOMES', this.diplomes)
        } else {
            if (e?.target !== null) {
                const target = e?.target as HTMLTextAreaElement
                if (target.name === 'diplome') {
                    this.$store.commit('dossieracademique/UPDATE_DATAS_DERNIER_DIPLOME', target.value)
                }
                if (target.name === 'diplome_other') {
                    this.$store.commit('dossieracademique/UPDATE_DATAS_DERNIER_DIPLOME_AUTRE', target.value)
                }
            }
        }
    }

    mounted () {
        if(this.selectedValues) {
            this.diplomes = [...this.selectedValues]
        }
    }
}
