

























































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { PosteInterface, PosteContext, PosteType } from '@/types/Poste'
import { Etat, getEtatSpec, getEtatForFilterSelect } from '@/types/DossierAdministratif'
import AssistantValidationDossierAdministratif from '@/views/Dossiers/AssistantValidationDossierAdministratif.vue'
import { DocumentTypeInterface, Dossier_type } from '@/types/DocumentType'
import PopupAddDossierMulti from '@/components/DossierAcademique/PopupAddDossierMulti.vue'
import PopupRelaunchDossier from '@/components/DossierAdministratif/PopupRelaunchDossier.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer, checkIcone, formatDate } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'


@Component({
    components: {
        ExaGenericTable,
        AssistantValidationDossierAdministratif,
        PopupAddDossierMulti,
        PopupRelaunchDossier,
        PopUpInfoIntervenant,
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('dossieradministratif', ['dossiersAdministratifs', 'error', 'totalRows', 'currentPage', 'lastPage', 'totalPage']),
        ...mapGetters('session', ['sessions']),
        ...mapGetters('documenttype', ['documenttypes']),
        ...mapState('document', ['data', 'error']),
        ...mapState('poste', ['postes']),
        ...mapState('matiere', ['matieres']),
        ...mapState('ville', ['villes']),
        ...mapState('centre', ['centres']),
        ...mapState('dossieradministratif', ['meta', 'loading']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    methods: {
        ...mapMutations('dossieradministratif', ['SET_VIEW_DOSSIER']),
        ...mapActions('dossieradministratif', ['getDossiersAdministratifs', 'updateDossierAdministratif', 'getDossierAdministratif']),
        ...mapActions('documenttype', ['getDocumenttypes'])
    }
})
export default class DossiersAdministratifsRespAdm extends Vue {
    @Prop() mode?: string;

    Ability = Ability
    // DATAS
    genericfields: Array<any> = []
    filtres: any = []
    dataForTab: any = []
    documents_type: Array<any> = []
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''
    filter = ''
    dossier_id = 0
    params: any = []
    showConfirmRelance = false
    rowSelect: any = null
    showDocument = false
    titre_pj = ''
    Etat = Etat
    showConfirmOpenDossierAdmin = false
    showMessageRejet = false
    messageRejet = ''
    enumEtats: Array<any> = []
    messageRelance = ''

    firstload = true

    showModalInfoIntervenant = false
    user_id_temp = 0

    @Watch('dossiersAdministratifs')
    refreshTable () {
        this.setDataForGenericTab(this.$store.state.dossieradministratif.dossiersAdministratifs, false)
    }

    @Watch('documenttypes')
    filterDocumentsTypes () {
        // Filtre les documents types selon le type de dossier
        this.documents_type = this.$store.state.documenttype.documenttypes.filter((dt: DocumentTypeInterface) => dt.dossier_type === Dossier_type.DOSSIER_ADMINISTRATIF)
        this.addGenericFields(this.documents_type)
    }

    @Watch('postes')
    buildFiltre () {
        const matieres = this.$store.getters['matiere/matieres']
            .map((m: any) => { return { index: m.id, name: m.name } })

        const postes = [
            { index: null, name: 'Aucun poste' },
            ...this.$store.getters['poste/postes']
                .map((p: PosteInterface) => { return { index: p.name, name: p.name } })
        ]

        const etats = this.getEnumEtats()
            .map((e: any) => { return { index: e.id, name: e.name } })

        const relances = [
            { index: 0, name: 'Non' },
            { index: 1, name: 'Oui' }
        ]

        this.filtres = [
            {
                libelle: 'Nom',
                defautOptionlibelle: 'Rechercher un',
                model: 'user.name',
                value: '',
                datas: '',
                loading: this.$store.getters['dossieracademique/loading'],
                options: { type: 'form', fieldsKey: 'user.name' } // 'form' , 'deroulant'
            },
            { libelle: 'Poste', defautOptionlibelle: 'Rechercher un', model: 'dossier_academique_poste_affectations_name', value: '', index: 'dossier_academique_poste_affectations_name',  datas: postes, loading: this.$store.getters['dossieracademique/loading'], options: { type: 'deroulant', fieldsKey: 'dossier_academique_poste_affectations_name' } },
            { libelle: 'Matière', defautOptionlibelle: 'Rechercher une', model: 'dossier_academique_matieres', value: '', index: 'dossier_academique_matieres',  datas: matieres, loading: this.$store.getters['dossieracademique/loading'], options: { type: 'deroulant', fieldsKey: 'dossier_academique_matieres' } },
            { libelle: 'État', defautOptionlibelle: 'Rechercher un', model: 'etat', value: '', index: 'etat',  datas: etats, loading: this.$store.getters['dossieracademique/loading'], options: { type: 'deroulant', fieldsKey: 'etat' } },
            { libelle: 'Relance', defautOptionlibelle: 'Rechercher une', model: 'relance', value: '', index: 'relance',  datas: relances, loading: this.$store.getters['dossieracademique/loading'], options: { type: 'deroulant', fieldsKey: 'relance' } }
        ]

        this.loadDatas()
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    // METHODS

    // Ajoute les champs dynamiques sur le tableau
    addGenericFields (documents_type: any) {
        this.genericfields = []
        this.genericfields = [
            { key: 'edit',         label: '',       sortable: false, class: '', type: 'action' },
            { key: 'etat',         label: 'État',   sortable: true, class: 'text-center', type: 'text' },
            { key: 'user.name', label: 'Identité', sortable: true, sortDirection: 'asc', class: 'text-start ps-4',  type: 'actionText'  }
        ]

        this.genericfields.push({ key: 'dossier_academique_poste_affectations_name', label: 'Poste', sortable: false, class: '', type: 'text' })
        this.genericfields.push({ key: 'dossier_academique_matieres',            label: 'Matières', sortable: false, class: '', type: 'text' })

        if (documents_type !== undefined) {
            for (const dt in this.documents_type) {
                this.genericfields.push({ key: 'etat_doc_' + this.documents_type[dt].id, label: this.documents_type[dt].name, sortable: false, class: 'text-center', type: 'text' })
            }

            this.genericfields.push({ key: 'relance', label: 'Relance', sortable: false, class: '', type: 'text' })
        }
    }

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const can = this.$store.getters['auth/can'](Ability.RH_DOSADM_MANAGE)
                const icone = checkIcone(Ability.RH_DOSADM_MANAGE, can)
                const puce_etat = []

                if (result) {
                    switch (true) {
                        case result.etat !== Etat.ETAT_INVISIBLE:
                            puce_etat.push({ name:'circle', class: 'text-' + getEtatSpec(result.etat).color, title: getEtatSpec(result.etat).libelle })
                            break
                        case (result.validated_at === null || result.etat === Etat.ETAT_INVISIBLE):
                            puce_etat.push({ name:'circle', class: 'text-tertiary', title: 'Non initialisé' })
                            break
                    }
                }

                const line: any = [
                    { label: icone.label, item: result.id, type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: puce_etat,  type: 'icons', typeAction: null, class: 'text-center' }
                ]

                // Poste du dossier académique
                const poste_sps: string = [ ...new Set(result?.dossier_academique?.poste_affectations?.map((p: PosteInterface) => p.name)) ].join(', ') || '-'

                // Matières du dossier académique
                const matieres: string = [ ...new Set(result?.dossier_academique?.matieres?.map((m: any) => m.name)) ].join(', ') || '-'

                line.push({ label: '', item: result.user.id, type: 'actionText', typeAction: 'infoIntervenant', class: 'text-info item_action', text: result.user.name + ' ' + result.user.first_name })
                line.push({ label: '', item: poste_sps, type: 'text', typeAction: null, class: 'text-start' })
                line.push({ label: '', item:matieres, type: 'text', typeAction: null, class: 'text-start' })

                // Etat des différents documents attendus sur le dossier
                for (const dt in this.documents_type) {
                    if (result.documents) {
                        const doc_temps = result.documents.find((t: any) => t.type === this.documents_type[dt].id.toString())
                        if (doc_temps) {
                            line.push({ label: getEtatSpec(doc_temps.etat).libelle, item: { id: result.id, props: doc_temps },  type: 'action', typeAction: 'consult', class: 'text-center text-' + getEtatSpec(doc_temps.etat).color, icon: 'file-alt', disabled: false })
                        } else {
                            line.push({ label: 'Pas de document', item: { id: result.id, props: doc_temps },  type: 'action', typeAction: null, class: 'text-center text-tertiary', icon: 'file-alt', disabled: false })
                        }
                    }
                }

                const puce_relance: any = []
                if (result.etat !== Etat.ETAT_INVISIBLE && result.etat !== Etat.ETAT_VALIDE && this.$store.getters['auth/can'](Ability.RH_DOSADM_MANAGE)) {
                    puce_relance.push({ name:'envelope', class: 'text-primary', title: result.relaunched_at ? 'Dernière relance effectuée le : ' + formatDate(result.relaunched_at) : '-' })
                }
                line.push({ label: puce_relance.length !== 0 ? puce_relance[0].title : '', item: result.id,  type: 'action', typeAction: (puce_relance.length !== 0 ? 'relance' : null), class: 'text-center ' + (puce_relance.length !== 0 ? puce_relance[0].class : 'text-light'), icon: puce_relance.length !== 0 ? puce_relance[0].name : 'envelope', disabled: false })
                this.dataForTab.push(line)
            }
        }
    }

    // création de la collection complète en fonction d'une demande de filtrage ou de tri
    filtreSortHandler (params: any) {
        if (!this.firstload) {
            this.params = params
            this.params.section = 'sps'

            this.$store.commit('dossieradministratif/SET_LOADING', true)
            this.$store.dispatch('dossieradministratif/getDossiersAdministratifs', this.params)
        }
    }

    // ajout des nouvelles entrées de liste lors d'un scroll
    loadHandler (params: any) {
        this.$store.commit('dossieradministratif/SET_LOADING', true)
        this.params = params
        this.params.section = 'sps'
        this.$store.dispatch('dossieradministratif/getMoreDossiersAdministratifs', this.params)
    }

    // Events
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const dossiers_sps: Array<any> = this.$store.state.dossieradministratif.dossiersAdministratifs
            let selectedDossier: any = null

            switch (paParams[0]) {
                case 'edit':
                    selectedDossier = dossiers_sps.filter((dossier: any) => dossier.id === paParams[1])[0]
                    if (selectedDossier) {
                        if (selectedDossier.etat !== Etat.ETAT_INVISIBLE) {
                            this.dossier_id = selectedDossier.id
                            this.$store.commit('dossieradministratif/SET_VIEW_DOSSIER', true)
                        } else {
                            this.rowSelect = selectedDossier
                            this.showConfirmOpenDossierAdmin = true
                        }
                    }
                    break
                case 'relance':
                    selectedDossier = dossiers_sps.filter((dossier: any) => dossier.id === paParams[1])[0]
                    if (selectedDossier) {
                        this.rowSelect = selectedDossier
                        this.showConfirmRelance = true
                    }
                    break
                case 'consult':
                    selectedDossier = dossiers_sps.filter((dossier: any) => dossier.id === paParams[1].id)[0]
                    if (selectedDossier) {
                        this.consultationPJ(selectedDossier, paParams[1].props)
                    }
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break
            }
        }
    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    // Récupère une énum des états de poste possible
    getEnumEtats () {
        this.enumEtats = []
        for (const etatName in Etat) {
            if (isNaN(Number(etatName))) {
                this.enumEtats.push({ id: getEtatForFilterSelect(etatName).index, name: getEtatForFilterSelect(etatName).libelle })
            }
        }
        return this.enumEtats
    }

    // ajout des nouvelles entrées de liste lors d'un scroll
    loadDatas () {
        this.params = {}
        this.params.section = 'sps'
        this.params.page = 1
        Vue.set(this.params, 'sort', 'user.name')
        Vue.set(this.params, 'direction', 'asc')

        this.$store.dispatch('dossieradministratif/getDossiersAdministratifs', this.params).then(() => {
            this.firstload = false
        })
    }

    // Valide la demande de relance
    confirmRelance () {
        this.$store.state.dossieradministratif.error = null
        const datas = {
            relaunch: 1,
            message: this.messageRelance
        }

        this.$store.dispatch('dossieradministratif/updateDossierAdministratif', { dossier_id: this.rowSelect.id, payload: datas })
            .then(() => {
                if (!this.$store.state.dossieradministratif.error) {
                    this.showConfirmRelance = false
                    this.messageRelance = ''
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Courriel envoyé !', succesToaster)
                }
            })
    }

    // Close la popup de confirmation de relance
    closeConfirmRelance () {
        this.rowSelect = null
        this.showConfirmRelance = false
        this.messageRelance = ''
    }

    // Consulte une PJ au cas par cas
    consultationPJ (params: any, doc_temps: any) {
        let doc_id = 0
        this.showMessageRejet = false
        this.messageRejet = ''
        this.rowSelect = params
        // Charge le document que l'on veut consulter
        for (const d in params.documents) {
            if (params.documents[d].type === doc_temps.type) {
                doc_id = params.documents[d].id
                this.titre_pj = params.documents[d].name
                this.rowSelect.doc_id = doc_id
                this.rowSelect.doc_select = params.documents[d]
            }
        }

        if (doc_id !== 0) {
            this.$store.dispatch('document/getDocumentAdm', { dossier_id: params.id, document_id: doc_id }).then(() => {
                this.showDocument = true
                this.source_doc = base64ToArrayBuffer(this.$store.state.document.documentSelect.data)
            })
        }
    }

    // Ferme la popup de consultation d'une PJ
    closeConsultPJ () {
        this.rowSelect = null
        this.showDocument = false
    }

    // MAJ de l'état de la PJ
    updateEtatPJ (etat: Etat) {
        let params
        if (etat === Etat.ETAT_REJETE) {
            params = { etat: etat, commentaire_rejet_cle: 'pj_' + this.rowSelect.doc_id, commentaire_rejet_message: this.messageRejet }
        } else {
            params = { etat: etat }
        }

        this.$store.dispatch('document/updateDocumentAdm', { dossier_id: this.rowSelect.id, document_id: this.rowSelect.doc_id, payload: params })
            .then((response) => {
                if (!this.$store.state.document.error) {
                    // Maj du document dans les données du tableau
                    const dossier_temp = this.$store.state.dossieradministratif.dossiersAdministratifs.find((d: any) => d.id === this.rowSelect.id)
                    if (dossier_temp) {
                        const index_doc_temp = dossier_temp.documents.findIndex((doc: any) => doc.id === this.rowSelect.doc_id)
                        Vue.set(dossier_temp.documents, index_doc_temp, response.data.data)
                        Vue.set(dossier_temp, 'etat_doc_' + response.data.data.type, response.data.data.etat)

                        if (etat === Etat.ETAT_REJETE) {
                            Vue.set(dossier_temp, 'etat', Etat.ETAT_REJETE)
                        } else {
                            const pj_no_valid = dossier_temp.documents.filter((d: any) => d.etat !== Etat.ETAT_VALIDE)

                            if ((pj_no_valid.length - 1) === 0 && dossier_temp.etat_form_perso === Etat.ETAT_VALIDE && dossier_temp.etat_form_situation === Etat.ETAT_VALIDE) {
                                Vue.set(dossier_temp, 'etat', Etat.ETAT_VALIDE)
                            } else {
                                Vue.set(dossier_temp, 'etat', Etat.ETAT_DEPOSE)
                            }
                        }

                        const index_dossier = this.$store.state.dossieradministratif.dossiersAdministratifs.findIndex((d: any) => d.id === this.rowSelect.id)
                        Vue.set(this.$store.state.dossieradministratif.dossiersAdministratifs, index_dossier, dossier_temp)
                    }
                    this.showDocument = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Pièce jointe mise à jour !', succesToaster)
                }
            })
    }


    // Ferme la popup de confirmation d'ouverture d'un dossier admin
    closeConfirmOpenAdmin () {
        this.rowSelect = null
        this.showConfirmOpenDossierAdmin = false
    }

    // Confirmation de l'ouverture d'un dossier administratif
    confirmOpenDossierAdmin () {
        const datas = {
            etat: Etat.ETAT_VIERGE
        }

        this.$store.dispatch('dossieradministratif/updateDossierAdministratif', { dossier_id: this.rowSelect.id, payload: datas })
            .then((response) => {
                if (!this.$store.state.dossieradministratif.error) {
                    // Maj du document dans les données du tableau
                    const dossier_temp = this.$store.state.dossieradministratif.dossiersAdministratifs.find((d: any) => d.id === this.rowSelect.id)
                    if (dossier_temp) {
                        Vue.set(dossier_temp, 'etat', response.data.data.etat)
                        const index_dossier = this.$store.state.dossieradministratif.dossiersAdministratifs.findIndex((d: any) => d.id === this.rowSelect.id)
                        Vue.set(this.$store.state.dossieradministratif.dossiersAdministratifs, index_dossier, dossier_temp)
                    }
                    this.showConfirmOpenDossierAdmin = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Dossier administratif publié !', succesToaster)
                }
            })
    }

    // Ouvre une zone de saisie d'un message de rejet
    addMessageRejet () {
        this.showMessageRejet = true
    }

    // Ferme la zone de message de rejet
    cancelMessageRejet () {
        this.showMessageRejet = false
    }

    load () {
        this.$store.dispatch('documenttype/getDocumenttypes').then(() => {
            this.$store.dispatch('matiere/getMatieres').then(async () => {
                const postes = await this.$store.dispatch('poste/getPostes', { context: PosteContext.CONTEXT_AFFECTATION })
                this.$store.commit(
                    'poste/SET_POSTES',
                    postes?.data?.data?.filter((p: any) => p.type === PosteType.TYPE_PEDAGOGIQUE || p.type === PosteType.TYPE_LOGISTIQUE) || []
                )
            })
        })
    }

    mounted () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
