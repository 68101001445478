











































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import { DocumentInterface } from '@/types/Document'
import { Etat } from '@/types/DossierAcademique'
import { DocumentTypeInterface, Dossier_type } from '@/types/DocumentType'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import { Ability } from '@/types/Ability'

@Component({
    components: {
        ItemIntervenant,
        VuePdfApp
    },
    methods: {
        ...mapActions('documenttype', ['getDocumenttypes'])
    },
    computed: {
        ...mapState('document', ['errorDocument']),
        ...mapState('documenttype', ['documenttypes']),
        ...mapGetters('documenttype', ['documenttypes']),
        ...mapGetters('dossieradministratif', ['intervenantFonctionnaire']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class FormDocument extends Vue {
    @Prop() dossierAdministratifSelect?: any;
    @Prop() dossier_id?: number;
    @Prop() readOnly?: boolean;
    @Prop() mode?: string;
    @Prop() visible?: boolean;

    Ability = Ability

    showDocument = false
    errors = ''
    showError = false
    showSuccess = false
    success = ''
    zoneViewerClass = 'zoneViewer'
    document: any = {}
    etat_rejete = Etat.ETAT_REJETE
    etat_valide = Etat.ETAT_VALIDE
    dossier_type_administratif = Dossier_type.DOSSIER_ADMINISTRATIF
    documents_type = []
    showMessageRejet = false
    messageRejet = ''
    editResponsable = false
    Etat = Etat
    isFonctionnaire = false
    isASP = false

    oldDatasSituation: any = {}

    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''

    @Watch('errorDocument')
    getErrors () {
        if (this.$store.state.document.errorDocument !== '') {
            this.errors = this.$store.state.document.errorDocument
            this.showError = true
        } else {
            this.errors = ''
            this.showError = false
        }
    }

    @Watch('dossierAdministratifSelect')
    loadElements () {
        // Chargement des documents liés au dossier
        this.$store.commit('document/SET_DOCUMENTS', this.dossierAdministratifSelect.documents)

        // Check la fonction de l'intervenant
        this.isFonctionnaire = this.$store.getters['dossieradministratif/intervenantFonctionnaire']
        this.isASP = this.$store.getters['dossieradministratif/intervenantASP']

        // Si l'intervenant n'est pas fonctionnaire ou ASP, on enlève l'autorisation de cumul
        if (!this.isFonctionnaire && !this.isASP) {
            const i = this.documents_type.map((item: any) => item.name).indexOf('Autorisation de cumul')
            if (i !== -1) {
                this.documents_type.splice(i, 1)
            }
        } else {
            this.documents_type = this.$store.state.documenttype.documenttypes.filter((dt: DocumentTypeInterface) => dt.dossier_type === Dossier_type.DOSSIER_ADMINISTRATIF)
        }
    }

    @Watch('documenttypes')
    filterDocumentsTypes () {
        // Filtre les documents types selon le type de dossier
        this.documents_type = this.$store.state.documenttype.documenttypes.filter((dt: DocumentTypeInterface) => dt.dossier_type === Dossier_type.DOSSIER_ADMINISTRATIF)
    }

    // Visualisation d'un document
    viewDocument (doc: DocumentInterface) {
        this.source_doc = ''
        this.document = doc
        this.zoneViewerClass = (doc.mime_type === 'image/jpeg' ? 'zoneViewerImage' : 'zoneViewer')
        this.$store.dispatch('document/getDocumentAdm', { dossier_id: this.dossierAdministratifSelect.id, document_id: doc.id }).then(() => {
            this.showDocument = true
            this.source_doc = base64ToArrayBuffer(this.$store.state.document.documentSelect.data)
        })
        this.$emit('showDocument', true)
    }

    // Fermer la visualisation d'un document
    backDocument () {
        this.source_doc = ''
        this.showDocument = false
        this.$emit('showDocument', false)
    }

    // Update etat pj
    updateEtatPJ () {
        const params = { etat: Etat.ETAT_VALIDE }
        this.$store.dispatch('document/updateDocumentAdm', { dossier_id: this.dossierAdministratifSelect.id, document_id: this.document.id, payload: params }).then(() => {
            for (const d in this.$store.getters['document/documents']) {
                if (this.$store.getters['document/documents'][d].id === this.document.id) {
                    this.$store.getters['document/documents'][d].etat = this.etat_valide
                }
            }
            this.showDocument = false
            this.showMessageRejet = false
        })
    }

    // Rejeté une pièce jointe
    rejetePJ () {
        const params = { etat: Etat.ETAT_REJETE, commentaire_rejet_cle: 'pj_' + this.document.id, commentaire_rejet_message: this.messageRejet }
        this.$store.dispatch('document/updateDocumentAdm', { dossier_id: this.dossierAdministratifSelect.id, document_id: this.document.id, payload: params }).then(() => {
            for (const d in this.$store.getters['document/documents']) {
                if (this.$store.getters['document/documents'][d].id === this.document.id) {
                    this.$store.getters['document/documents'][d].etat = this.etat_rejete
                }
            }
            this.showDocument = false
            this.showMessageRejet = false
        })
    }

    // Ouvre une zone de saisie d'un message de rejet
    addMessageRejet (document: any) {
        this.document = document
        this.showMessageRejet = true
    }

    // Ferme la zone de message de rejet
    cancelMessageRejet () {
        this.showMessageRejet = false
    }

    // Activation de l'édition pour le responsable
    activeEdition (actif: boolean) {
        this.editResponsable = actif
        this.$emit('edition_form_docs', actif)
    }

    mounted () {
        this.$store.dispatch('documenttype/getDocumenttypes')
    }
}
