


































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import PopupAddDossier from '@/components/DossierAcademique/PopupAddDossier.vue'
import { PosteInterface, PosteType } from '@/types/Poste'
import { Etat, getEtatForFilterSelect, getDecisionSpec, Decision } from '@/types/DossierAcademique'
import AssistantValidationDossier from '@/views/Dossiers/AssistantValidationDossier.vue'
import { checkIcone, formatDate } from '@/utils/helpers'
import CommentaireIntervenant from '@/components/SelectionRH/CommentaireIntervenant.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import _ from 'lodash'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'

@Component({
    components: {
        AssistantValidationDossier,
        PopupAddDossier,
        CommentaireIntervenant,
        ExaGenericTable,
        ErrorDisplay,
        PopUpInfoIntervenant,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('dossieracademique', ['meta']),
        ...mapState('poste', ['postes']),
        ...mapGetters('dossieracademique', ['viewDossier','dossiersAcademiques', 'links', 'loading', 'error', 'totalRows', 'currentPage', 'lastPage', 'totalPage', 'sortby']),
        ...mapGetters('session', ['sessions'])
    },
    methods: {
        ...mapMutations('dossieracademique', ['SET_VIEW_DOSSIER']),
        ...mapActions('dossieracademique', ['getDossiersAcademiques', 'updateDossierAcademique'])
    }
})

export default class DossiersAcademiquesRespSps extends Vue {
    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    dossierId = 0
    params = []
    enumEtats: Array<any> = []
    totalDossiers = 0
    datas = []
    userSelect: any = {}
    showCommentaireIntervenant = false
    showModalInfoIntervenant = false
    userIdTemp = 0
    formatDate = formatDate
    genericfields: Array<any> = []
    dataForTab: any = []
    filtres: any = []
    ability = Ability
    showModalMessageDelete = false
    deleteEnCours = false
    dossierAcademiqueSelect: any = null
    codeClient = 'CCMP'
    popupAddDossierIsOpen = false

    @Watch('meta')
    majInfosTable(): void {
        // On récupère le nombre total de dossiers filtrés depuis les infos des métadonnées
        const meta = this.$store.getters['dossieracademique/meta']
        if (meta !== null && this.totalDossiers !== meta.total) {
            this.totalDossiers = meta.total
        } else {
            this.totalDossiers = 0
        }
    }

    @Watch('dossiersAcademiques', { deep: true })
    wdossiersAcademiques(): void {
        this.setDataForGenericTab(this.$store.getters['dossieracademique/dossiersAcademiques'])
    }

    popupAddDossierOpen(isOpen: boolean): void {
        this.popupAddDossierIsOpen = isOpen
        this.$store.commit('dossieracademique/SET_ERROR', null)
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        const matieres = this.$store.getters['matiere/matieres']
            .map((m: any) => {
                return { index: m.id, name: m.name }
            })

        const etatSouhait = this.getEnumEtats()

        const postes = [
            { index: 0, name: 'Aucun poste' },
            ...this.$store.getters['poste/postes']
                .map((p: PosteInterface) => {
                    return { index: p.id, name: p.name }
                })
        ]

        const middle = Math.floor(Object.keys(Decision).length / 2)
        const decisions = Object.keys(Decision).slice(0, middle)
            .map((decision: any) => {
                const decisionSpec = getDecisionSpec(parseInt(decision.toString()))
                return { index: decisionSpec.index, name: decisionSpec.libelle }
            })

        this.filtres = [
            {
                libelle: 'État',
                defautOptionlibelle: 'Tous les',
                model: 'etat',
                value: '',
                index: 'id',
                datas: etatSouhait,
                loading: '',
                options: { type: 'deroulant', fieldsKey: 'etat' }
            },
            {
                libelle: 'Nom',
                defautOptionlibelle: 'Rechercher un',
                model: 'user.name',
                value: '',
                datas: '',
                loading: this.$store.state.dossieracademique.loading,
                options: { type: 'form', fieldsKey: 'user.name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Session',
                defautOptionlibelle: 'Rechercher une',
                model: 'session.name',
                value: '',
                datas: '',
                loading: this.$store.state.dossieracademique.loading,
                options: { type: 'form', fieldsKey: 'session.name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Poste session N-1',
                defautOptionlibelle: 'Rechercher un',
                model: 'poste_session_precedente',
                value: '',
                index: '',
                datas: postes,
                loading: this.$store.state.dossieracademique.loading,
                options: { type: 'deroulant', fieldsKey: 'poste_session_precedente' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Matières',
                defautOptionlibelle: 'Toutes les',
                model: 'matieres.name',
                value: '',
                index: '',
                datas: matieres,
                loading: this.$store.state.matiere.loading,
                options: { type: 'deroulant', fieldsKey: 'matieres.name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Souhait(s) candidature N',
                defautOptionlibelle: 'Tous les',
                model: 'posteSouhaits.name',
                value: '',
                index: 'name',
                datas: '',
                loading: this.$store.state.poste.loading,
                options: { type: 'form', fieldsKey: 'postes_souhaits' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Décision / Poste session N',
                defautOptionlibelle: 'Rechercher une',
                model: 'decision_affectation',
                value: '',
                index: '',
                datas: decisions,
                loading: this.$store.state.dossieracademique.loading,
                options: { type: 'deroulant', fieldsKey: 'decision_affectation' }
            },
            {
                libelle: 'Quotité totale',
                defautOptionlibelle: 'Rechercher une',
                model: 'posteAffectations.temps',
                value: '',
                index: '',
                datas: '',
                loading: '',
                options: { type: 'form', fieldsKey: 'posteAffectations.temps', strict: true }
            }
        ]
    }

    cancelDelete(): void {
        this.showModalMessageDelete = false
        this.dossierAcademiqueSelect = null
    }

    /**
     * Lance la suppression d'un dossier académique
     * @returns {void}
     */
    deleteDossierSuite(): void {
        this.deleteEnCours = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Supression en cours ...', infosToaster)

        this.$store.dispatch('dossieracademique/deleteDossierAcademique', this.dossierAcademiqueSelect.courant.id)
            .then(async () => {
                this.showModalMessageDelete = false
                await this.$store.dispatch('dossieracademique/getDossiersAcademiques', this.params)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.deleteEnCours = false
            })
    }

    /**
     * Récupère une énum des états de poste possible
     * @returns {Array<any>}
     */
    getEnumEtats(): Array<any> {
        this.enumEtats = []
        for (const etatName in Etat) {
            if (isNaN(Number(etatName))) {
                this.enumEtats.push({
                    id: getEtatForFilterSelect(etatName).index,
                    name: getEtatForFilterSelect(etatName).libelle,
                    index: getEtatForFilterSelect(etatName).index
                })
            }
        }
        return this.enumEtats
    }

    /**
     * Création de la collection complète en fonction d'une demande de filtrage ou de tri
     * @param {any} params - Paramètres de filtrage ou de tri
     * @returns {Promise<void>}
     */
    async filtreSortHandler(params: any): Promise<void> {
        if (params.sort === '') {
            params.sort = 'user.name'
        }
        this.params = params
        await this.$store.dispatch('dossieracademique/getDossiersAcademiques', this.params)
    }

    async refresh(): Promise<void> {
        await this.$store.dispatch('dossieracademique/getDossiersAcademiques', this.params)
        this.setDataForGenericTab(this.$store.getters['dossieracademique/dossiersAcademiques'])
    }

    /**
     * Ajout des nouvelles entrées de liste lors d'un scroll
     * @param {any} params - Paramètres de filtrage ou de tri
     * @returns {Promise<void>}
     */
    async loadHandler(params: any): Promise<void> {
        if (params.length === 0) {
            params = {
                page: 1,
                sort: 'user.name',
                direction: 'asc'
            }
        }

        if (params['sort'] === '') {
            params.sort = 'user.name'
        }

        if (params['filter-posteAffectations.temps']) {
            params['filter-posteAffectations.temps'] *= 100
        }

        if (params['filter-poste_session_precedente']) {
            params['poste_session_precedente'] = params['filter-poste_session_precedente']
            delete params['filter-poste_session_precedente']
        }

        this.params = params
        await this.$store.dispatch(`dossieracademique/get${params.page === 1 ? '' : 'More'}DossiersAcademiques`, this.params)
    }

    /**
     * Ouvre le validateur de dossier
     * @param {any} row - Ligne du tableau
     * @returns {void}
     */
    openValidator(row: any): void {
        this.dossierId = row.id
        if (this.dossierId !== 0) {
            this.$store.commit('dossieracademique/SET_VIEW_DOSSIER', true)
        }
    }

    /**
     * Relance email
     * @param {any} row - Ligne du tableau
     * @returns {Promise<void>}
     */
    async relanceEmail(row: any): Promise<void> {
        this.dossierId = row.item.id
        const params = { relaunch: 1 }
        if (this.dossierId !== 0) {
            await this.$store.dispatch('dossieracademique/updateDossierAcademique', {
                dossier_id: this.dossierId,
                payload: params
            })
        }
    }

    /**
     * Ouvre le popup de classement
     * @param {any} row - Ligne du tableau
     * @returns {void}
     */
    openCommentaireIntervenant(row: any): void {
        this.showCommentaireIntervenant = true
        this.userSelect = row.user
    }

    /**
     * Ferme la popup de commentaire intervenant
     * @param {string} retour - Retour du commentaire
     * @returns {void}
     */
    closeCommentaireIntervenant(retour = null): void {
        const findIndex = this.$store.getters['dossieracademique/dossiersAcademiques']
            .findIndex((dossier: { user_id: any }) => dossier.user_id === this.userSelect.id)
        const updateCandidat = _.cloneDeep(this.$store.getters['dossieracademique/dossiersAcademiques'][findIndex])
        Vue.set(this.$store.getters['dossieracademique/dossiersAcademiques'], findIndex, updateCandidat)
        if (retour != null) {
            if (this.userSelect.comment) {
                this.userSelect.comment.body = retour
            } else {
                this.userSelect.comment = {
                    body: retour
                }
            }
        }
        this.showCommentaireIntervenant = false
        this.userSelect = {}
    }

    /**
     * Récupération des events du tableau (params[0] => l'action, params[1] => l'id de l'item)
     * @param {any} paParams - Paramètres de l'action
     * @returns {Promise<void>}
     */
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    this.openValidator(paParams[1])
                    break

                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break

                case 'openComment':
                    this.openCommentaireIntervenant(paParams[1])
                    break

                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break

                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break

                case 'delete':
                    this.dossierAcademiqueSelect = paParams[1]
                    this.showModalMessageDelete = true
                    break
            }
        }
    }

    setDataForGenericTab(poData: any, isLoadMore = false): void {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const key in poData) {
                const result = poData[key]
                if (result) {
                    // Colonne commentaires
                    const can = this.$store.getters['auth/can'](Ability.RH_DOSADM_MANAGE)
                    const icone = checkIcone(Ability.RH_DOSADM_MANAGE, can)

                    const nameIcon = 'comment-alt'
                    const validation = this.getPuceColorValidation(result)
                    const commentColor = result.user?.comment?.body ? 'text-primary' : 'text-tertiary'
                    const name = `${result.user?.name} ${result.user?.first_name}` || '-'
                    const matieres = [
                        ...new Set(result.courant?.matieres
                            ?.map((m: any) => m.name))
                    ].join(', ') || '-'

                    const postesSouhaits = [
                        ...new Set(result.courant?.postes_souhaits
                            ?.map((p: any) => p.name))
                    ].join(', ') || '-'

                    const postesPrecedents = [
                        ...new Set(result.precedent?.postes_affectations
                            ?.map((p: any) => p.name))
                    ].join(', ') || '-'

                    const quotites = result.courant?.postes_affectations
                        ?.filter((p: any) => p.type === PosteType.TYPE_PEDAGOGIQUE)
                        .map((p: any) => {
                            return {
                                poste: p.name,
                                value: p.temps / 100
                            }
                        })

                    const sessionPrecedente = this.$store.getters['session/sessions']
                        ?.find((s: any) => s.id === result?.precedent?.session_id && result?.precedent?.postes_affectations?.length > 0)
                        ?.name || '-'

                    const line = [
                        { label: icone.label, item: result.courant, type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false }, // edit
                        { label: '', item: validation, type: 'icons', typeAction: null, class: 'text-center' }, // etat
                        { label: 'Commentaire', item: result, type: 'action', typeAction: 'openComment', class: commentColor, icon: nameIcon, disabled: !this.$store.getters['auth/can'](Ability.RH_SPS_MANAGE) }, // usercomment
                        { label: '', item: result.user.id, type: 'actionText', typeAction: 'infoIntervenant', class: 'text-info item_action', text: name }, // user.name
                        { label: '', item: sessionPrecedente, type: 'text', typeAction: null, class: '' }, // session
                        { label: '', item: postesPrecedents, type: 'text', typeAction: null, class: '' }, // poste_session_precedente
                        { label: 'Matières', item: matieres, type: 'text', typeAction: null, class: '' }, // matieres.name
                        { label: '', item: postesSouhaits, type: 'text', typeAction: null, class: '' }, // postes_souhaits
                        { label: 'submitted_at', item: getDecisionSpec(result.courant.decision_affectation).libelle, type: 'text', typeAction: null, class: '' } // decision
                    ]

                    if (this.codeClient === 'CCS') {
                        line.push({
                            label: quotites?.map((q: any) => `${q.poste}: ${q.value}`).join(', ') || '-',
                            item: quotites?.map((q: any) => q.value).join(', ') || '-',
                            type: 'text',
                            typeAction: null,
                            class: ''
                        })
                    }

                    if (this.$store.getters['auth/can'](Ability.RH_SPS_MANAGE)) {
                        line.push({ label: 'Supprimer', item: result, type: 'action', typeAction: 'delete', class: 'commons_delete_action_button text-secondary', icon:'trash-alt', disabled: result.etat === Etat.ETAT_VALIDE })
                    }

                    this.dataForTab.push(line)
                }
            }
        }
    }

    infoIntervenantHandler(userId: any): void {
        this.userIdTemp = userId
        this.showModalInfoIntervenant = true
    }

    /**
     * Formattage de date
     * @param {Date} date - Date à formater
     * @returns {any}
     */
    formatteDate(date: Date): any {
        return formatDate(date)
    }

    /**
     * Puce de l'état
     * @param {any} result - Résultat
     * @returns {any}
     */
    getPuceColorValidation(result: any): any {
        let classAffichage = 'text-tertiary'
        let title = ''
        if (result) {
            switch (true) {
                case result.courant.etat === Etat.ETAT_INVISIBLE:
                    classAffichage = 'text-tertiary'
                    title = ''
                    break
                case result.courant.etat === Etat.ETAT_VIERGE:
                    title = 'Publié'
                    classAffichage = 'text-secondary'
                    break
                case result.courant.etat === Etat.ETAT_DEPOSE:
                    title = 'Déposé'
                    classAffichage = 'text-info'
                    break
                case result.courant.etat === Etat.ETAT_VALIDE:
                    title = 'Validé'
                    classAffichage = 'text-success'
                    break
                case result.courant.etat === Etat.ETAT_REJETE:
                    title = 'Rejeté'
                    classAffichage = 'text-danger'
                    break
            }
        }
        return [{ name: 'circle', class: classAffichage, title: title }]
    }

    /**
     * Chargement de l'interface
     * @returns {Promise<void>}
     */
    async load(): Promise<void> {
        await this.$store.dispatch('concour/getConcoursActifs')
        await this.$store.dispatch('matiere/getMatieres')
        await this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_PEDAGOGIQUE })

        this.setDataForGenericTab(this.$store.getters['dossieracademique/dossiersAcademiques'])
        this.setFiltersForGenericTab()
    }

    buildGenericFields(): void {
        this.genericfields = [
            {
                key: 'edit',
                label: '',
                sortable: true,
                sortDirection: 'asc',
                class: 'text-center'
            },
            {
                key: 'etat',
                label: 'État',
                sortable: true,
                class: 'text-center',
                type: 'text'
            },
            {
                key: 'usercomment',
                label: '',
                sortable: false,
                sortDirection: 'asc',
                class: 'text-start ps-4 w-2'
            },
            {
                key: 'user.name',
                label: 'Identité',
                sortable: true,
                sortDirection: 'asc',
                class: 'text-start ps-4',
                type: 'actionText'
            },
            {
                key: 'session',
                label: 'Dernière session active',
                sortable: true,
                sortDirection: 'asc',
                class: 'text-start ps-4'
            },
            {
                key: 'poste_session_precedente',
                label: 'Poste session N-1',
                sortable: false,
                sortDirection: 'asc',
                class: 'text-start ps-4'
            },
            {
                key: 'matieres.name',
                label: 'Matières',
                sortable: false,
                sortDirection: 'asc',
                class: 'text-start'
            },
            {
                key: 'postes_souhaits',
                label: 'Souhait(s) candidature N',
                sortable: false,
                sortDirection: 'asc',
                class: 'text-start'
            },
            {
                key: 'decision_affectation',
                label: 'Décision / Poste session N',
                sortable: true,
                sortDirection: 'asc',
                class: 'text-start'
            }]

        if (this.codeClient === 'CCS') {
            this.genericfields.push({
                key: 'posteAffectations.temps',
                label: 'Quotité totale',
                sortable: false,
                sortDirection: 'asc',
                class: 'text-start'
            })
        }
        this.genericfields.push({ key: 'delete', label: '', sortable: false, class: '', type: 'action' })
    }

    async mounted(): Promise<void> {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.codeClient = this.$store.getters['auth/findParameter']('codeClient')?.value || 'CCMP'
            await this.load()
            this.buildGenericFields()
        }
    }
}
