

































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Ability } from '@/types/Ability'
import { checkIcone, getTimeZones } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome'
import { getListeAcademie } from '@/types/Ville'

@Component({
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('ville', ['loading', 'error', 'villes', 'meta', 'links', 'totalRows']),
        ...mapState('ville', ['villes', 'zones']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id'])
    }
})

export default class Villes extends Vue
{
    Ability = Ability
    getListeAcademie = getListeAcademie

    // Ensemble des colonnes du tableau de epreuveCorrectionResultats

    genericfields = [
        { key: 'etatEdit',      label: '',                  sortable: false, class: '', type: 'action' },
        { key: 'code_import',   label: 'Code import',       sortable: true, class: '', type: 'text' },
        { key: 'name',          label: 'Libellé',           sortable: true, class: '', type: 'text' },
        { key: 'departement',   label: 'Département',       sortable: true, class: '', type: 'text' },
        { key: 'academie',      label: 'Académie',          sortable: true, class: '', type: 'text' },
        { key: 'nb_centres',    label: 'Nombre de centres', sortable: true, class: 'text-center', type: 'text' },
        { key: 'zone.name',     label: 'Zone',              sortable: true, class: 'text-center', type: 'text' },
        { key: 'zone_vacances',  label: 'Zone de vacances',  sortable: true, class: 'text-center', type: 'text' },
        { key: 'timezone',        label: 'Fuseau horaire',    sortable: true, class: '', type: 'text' },
        { key: 'decalage_passation',      label: 'Décalage de l\'horaire de passation',    sortable: true, class: 'text-center', type: 'text' },
        { key: 'delete',        label: '',                  sortable: true, class: 'text-center', type: 'action' }
    ]

    ville: any = null
    villeTemp: any = null

    showModalEditionVille = false
    showModalMessageDelete = false
    showModalZoneVille = false
    dataForTab: Array<any> = []

    showFormulaireZone = false
    showConfirmDeleteZone = false
    zone_select = {
        id: 0,
        name: ''
    }

    load_zone = false
    timezones: any = null

    @Watch('villes')
    refreshDatas () {
        this.setDataForGenericTab(this.$store.state.ville.villes)
    }

    @Watch('user_session_id')
    refreshCity() {
        this.load()
    }

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const can = this.$store.getters['auth/can'](Ability.GC_CENTER_MANAGE)
                const icone = checkIcone(Ability.GC_CENTER_MANAGE, can)
                const icone_delete = { label: 'Supprimer', icon:'trash-alt' }

                const line = [
                    { label: icone.label, item: result,      type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: result.code_import ? result.code_import : '',          type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name,          type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.departement,   type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.academie,      type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.nb_centres,    type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.zone ? result.zone.name : '',          type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.zone_vacances,      type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.timezone,           type: 'text', typeAction: null, class: '' },
                    { label: '', item: (result.decalage_passation !== 0 ? result.decalage_passation / 60 : 0) + ' min.',           type: 'text', typeAction: null, class: 'text-center' }
                ]

                if (this.$store.getters['auth/can'](Ability.GC_CENTER_MANAGE)) {
                    line.push({ label: icone_delete.label, item: result,   type: 'action',  typeAction: 'delete', class: 'text-secondary max_width_col text-center', icon: icone_delete.icon, disabled: false })
                } else {
                    line.push({ label: '', item: null, type: 'text', typeAction: null, class: '' })
                }
                this.dataForTab.push(line)
            }
        }
    }


    // Events
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':
                    this.editVille(paParams[1])
                    break
                case 'delete':
                    this.deleteVille(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    // Applique des filtres
    filtreSortHandler (params: any) {
        this.$store.dispatch('ville/getVilles', params)
    }

    // Ouvre le popup de création d'une ville
    openAddVille () {
        this.villeTemp = {
            id: 0,
            name: '',
            code_import: null,
            zone_id: null,
            zone_vacances: null,
            academie: null,
            departement: null,
            gps: null,
            decalage_passation: 0,
            timezone: ''
        }
        this.showModalEditionVille = true
        this.$store.state.ville.error = null
    }

    // Ouvre le popup d'édition d'une ville
    editVille (row: any) {
        this.ville = row
        this.villeTemp = {
            id: this.ville.id,
            name: this.ville.name,
            code_import: this.ville.code_import,
            zone_id: this.ville.zone_id,
            zone_vacances: this.ville.zone_vacances,
            academie: this.ville.academie,
            departement: this.ville.departement,
            decalage_passation: this.ville.decalage_passation / 60,
            timezone: this.ville.timezone
        }
        this.showModalEditionVille = true
        this.$store.state.ville.error = null
    }

    // Annule la création ou l'édition d'une ville
    cancelEdit () {
        this.ville = null
        this.villeTemp = null
        this.showModalEditionVille = false
        this.$store.state.ville.error = null
    }

    // Valide la création / modification d'une ville
    save () {
        const payload = {
            id: this.villeTemp.id,
            name: this.villeTemp.name,
            zone_id: this.villeTemp.zone_id,
            code_import: this.villeTemp.code_import,
            zone_vacances: this.villeTemp.zone_vacances,
            academie: this.villeTemp.academie,
            departement: this.villeTemp.departement,
            gps: this.villeTemp.gps,
            decalage_passation: this.villeTemp.decalage_passation * 60,
            timezone: this.villeTemp.timezone
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.villeTemp.id === 0) {
            this.$store.dispatch('ville/addVille', payload)
                .then(() => {
                    this.ville = null
                    this.villeTemp = null
                    this.showModalEditionVille = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Création terminée.', succesToaster)
                    this.$store.dispatch('ville/getVilles')
                })
                .catch((error) => {
                    console.log('error :' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('ville/updateVille', payload)
                .then(() => {
                    this.ville = null
                    this.villeTemp = null
                    this.showModalEditionVille = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Modification terminée.', succesToaster)
                })
                .catch((error) => {
                    console.log('error :' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    // Ouvre le popup de confirmation de suppression d'une ville
    deleteVille (row: any) {
        this.ville = row
        this.showModalMessageDelete = true
        this.$store.state.ville.error = null
    }

    // Annule la suppression d'une ville
    cancelDelete () {
        this.ville = null
        this.showModalMessageDelete = false
        this.$store.state.ville.error = null
    }

    // Confirme l'action de suppression d'une ville
    confirmDelete () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Supression en cours ...', infosToaster)
        this.$store.dispatch('ville/deleteVille', this.ville.id)
            .then(() => {
                this.ville = null
                this.villeTemp = null
                this.showModalEditionVille = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée.', succesToaster)
                this.ville = null
                this.showModalMessageDelete = false
            })
            .catch((error) => {
                console.log('error :' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Ouvre la gestion des zones
    open_gestion_zones () {
        this.showModalZoneVille = true
    }

    // Ferme la gestion des zones
    close_gestion_zones () {
        this.$store.commit('ville/SET_ERROR', null)
        this.showFormulaireZone = false
        this.zone_select = {
            id: 0,
            name: ''
        }
        this.showModalZoneVille = false
    }

    // Set les value depuis le formulaire
    updateChamps (e: any) {
        switch (e.target.name) {
            case 'name':
                this.zone_select.name = e.target.value
                break
            default:
                break
        }
    }

    // Enregistre ou met à jour un role de centre
    save_zone () {
        const data: any = {
            name: this.zone_select.name
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.zone_select.id === 0) {
            // Création d'un role
            this.$store.dispatch('ville/addZone', data)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                    this.showFormulaireZone = false
                    this.zone_select = {
                        id:0,
                        name: ''
                    }
                    this.$store.dispatch('ville/getZones')
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            // Modification de l'utilisateur
            this.$store.dispatch('ville/updateZone', { zone_id: this.zone_select.id, payload: data })
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    this.showFormulaireZone = false
                    this.zone_select = {
                        id:0,
                        name: ''
                    }
                    this.$store.dispatch('ville/getZones')
                    this.$store.dispatch('ville/getZones')
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    // Ajoute une zone
    add_zone () {
        this.zone_select = {
            id: 0,
            name: ''
        }
        this.showFormulaireZone   = true
    }

    // Editer une zone
    edit_zone (zone: any) {
        this.load_zone = true
        this.zone_select.id = zone.id
        this.zone_select.name = zone.name
        this.showFormulaireZone = true
        this.load_zone = false
    }

    // Supprimer une zone
    delete_zone (zone: any) {
        this.showFormulaireZone = false
        this.zone_select = zone
        this.showConfirmDeleteZone = true
    }

    // Annule la suppression d'une zone
    cancel_delete_zone () {
        this.zone_select = {
            id: 0,
            name: ''
        }
        this.showConfirmDeleteZone = false
    }

    // Confirmation de la suppression d'un rôle
    confirm_delete_zone () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)

        // Suppression d'un rôle
        this.$store.dispatch('ville/deleteZone', { zone_id: this.zone_select.id })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée !', succesToaster)
                this.$store.dispatch('ville/getZones').then(() => {
                    this.showConfirmDeleteZone = false
                })
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    load () {
        this.$store.dispatch('ville/getVilles').then(() => {
            this.$store.dispatch('ville/getZones')
            this.timezones = getTimeZones()
        })
    }

    beforeMount () {
        this.load()
    }
}
