export interface VilleInterface {
    name: string,
    id: number | 0,
    session_id: number | 0,
    nb_centres: number | 0,
    nb_salles: number | 0,
    ville_id: number | 1,
    zone: number | null,
    timezone: string,
    decalage_passation: number | 0
}

export interface ZoneInterface {
    name: string,
    id: number | 0
}

export interface StateVilles {
    villes: Array<VilleInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    villeSelect: VilleInterface | {},
    zones: Array<ZoneInterface> | []
}

export function getListeAcademie() {
    const listeAcademie = [
        { libelle: 'Aix-Marseille' },
        { libelle: 'Amiens' },
        { libelle: 'Besançon' },
        { libelle: 'Bordeaux' },
        { libelle: 'Clermont-Ferrand' },
        { libelle: 'Corse' },
        { libelle: 'Créteil' },
        { libelle: 'Dijon' },
        { libelle: 'Grenoble' },
        { libelle: 'Guadeloupe' },
        { libelle: 'Guyane' },
        { libelle: 'Lille' },
        { libelle: 'Limoges' },
        { libelle: 'Lyon' },
        { libelle: 'Martinique' },
        { libelle: 'Mayotte' },
        { libelle: 'Montpellier' },
        { libelle: 'Nancy-Metz' },
        { libelle: 'Nantes' },
        { libelle: 'Nice' },
        { libelle: 'Normandie' },
        { libelle: 'Nouvelle-Calédonie' },
        { libelle: 'Orléans-Tours ' },
        { libelle: 'Paris' },
        { libelle: 'Poitiers' },
        { libelle: 'Polynésie française' },
        { libelle: 'Reims' },
        { libelle: 'Rennes' },
        { libelle: 'Réunion' },
        { libelle: 'Strasbourg' },
        { libelle: 'Saint-Pierre-et-Miquelon' },
        { libelle: 'Toulouse' },
        { libelle: 'Versailles' },
        { libelle: 'Wallis-et-Futuna' },
        { libelle: 'Étranger' }
    ]
    return listeAcademie
}
