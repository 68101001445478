



































































































































































import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { Ability } from '@/types/Ability'
import { codeRestrict } from '@/utils/helpers'

import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getTypeListeSpec, TypeListe } from '@/types/Liste'


@Component({
    components: {
        Table,
        ExaGenericTable,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('liste', ['loading', 'error', 'listes', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class Listes extends Vue {
    codeRestrict = codeRestrict
    // DATAS
    params = 'sort=name&direction=asc'
    dataForTab: Array<any> = []
    filtres: any = []


    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'code', label: 'Code', sortable: true, class: 'text-left col-min-width', type: 'text' },
        { key: 'name', label: 'Libellé', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'ordre', label: 'Ordre', sortable: true, sortDirection: 'asc', class: 'text-center ps-4' },
        { key: 'type', label: 'Type', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        { key: 'liste_id', label: 'Liste parente', sortable: true, sortDirection: 'asc', class: 'text-start ps-4' },
        { key: 'candidats_count', label: 'Nbre de candidats', sortable: true, sortDirection: 'asc', class: 'text-center' },
        { key: 'validListe', label: 'Liste validée', sortable: false, class: 'text-center', type: 'action' },
        { key: 'lacenemtAffectation', label: 'Lancement de l\'affectation', sortable: false, class: 'text-center', type: 'action' },
        { key: 'delete', label: '', sortable: false, class: '', type: 'action' }
    ]


    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    liste: any = null
    listeTemp: any = null
    Ability = Ability

    showModalEditionListe = false
    showModalMessageDelete = false
    showModalValideListe = false
    showModalLancementAffectation = false

    getTypeListeSpec = getTypeListeSpec
    TypeListe = TypeListe




    // ---- generic table --------------------------

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const classEtatEdit = 'text-light col-w-etat btn_action_ligne'
                const iconEtatEdit =  this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) ? 'pen' : 'eye'

                const iconValidListe = result.validated_at === null ? 'times' : 'check'
                const titleValidListe = result.validated_at === null ? 'Liste non validée' : 'Liste validée'
                const classValidListe = result.validated_at === null ? 'text-center text-secondary col-w-action bg-transparent' : 'text-center text-success col-w-action bg-transparent'

                const iconAffectListe = result.affected_at === null ? 'times' : 'check'
                const titleAffectListe = result.affected_at === null ? 'Affection non lancée' : 'Affection lancée'
                const classAffectListe = result.affected_at === null ? 'text-center text-secondary col-w-action bg-transparent' : 'text-center text-success col-w-action bg-transparent'

                const line = [
                    { label: '', item: result, icon: iconEtatEdit, type: 'action', typeAction: 'etatEdit', class: classEtatEdit, disabled: false },
                    { label: '', item: result.code, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.ordre, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: getTypeListeSpec(result.type).libelle, type: 'text', typeAction: null, class: 'ps-3' },
                    { label: '', item: result.liste_id !== null ? this.$store.getters['liste/getListeById'](result.liste_id).name : 'Aucune', type: 'text', typeAction: null, class: 'ps-3' },
                    { label: '', item: result.candidats_count, type: 'text', typeAction: null, class: 'text-center pas-3' },
                    { label: titleValidListe, item: result, icon: iconValidListe, type: 'action', typeAction: 'validListe', class: classValidListe, disabled: !this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) },
                    { label: titleAffectListe, item: result, icon: iconAffectListe, type: 'action', typeAction: 'lancementAffectation', class: classAffectListe, disabled: !this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE) }
                ]

                if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)) {
                    const trashLine =  { label: 'Supprimer',   item: result, type: 'action',  typeAction: 'delete',  class: 'text-secondary', icon:'trash-alt', disabled: false }
                    line.push(trashLine)
                }
                this.dataForTab.push(line)
            }
        }
        return this.dataForTab
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        const types_liste = [
            { index: TypeListe.TYPE_PREAFFECTATION, name: getTypeListeSpec(TypeListe.TYPE_PREAFFECTATION).libelle },
            { index: TypeListe.TYPE_VOEUX, name: getTypeListeSpec(TypeListe.TYPE_VOEUX).libelle }
        ]
        this.filtres = [
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'code',
                value: '',
                index: 'code',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'code', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'Libellé',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Type',
                defautOptionlibelle: 'Rechercher un',
                model: 'type',
                value: '',
                index: 'type',
                datas: types_liste,
                loading: false,
                options:  { type: 'deroulant', fieldsKey: 'type' }  // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':

                    break
                case 'openComment':

                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'etatEdit':
                    this.editListe(paParams[1])
                    break
                case 'delete':
                    this.deleteListe(paParams[1])
                    break

                case 'validListe':
                    if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)) {
                        this.valideListe(paParams[1])
                    }
                    break
                case 'lancementAffectation':
                    if (this.$store.getters['auth/can'](Ability.ADM_PAR_MANAGE)) {
                        this.lancementAffectation(paParams[1])
                    }
                    break
                default:
                    break
            }
        }
    }

    // --------------------------------------

    valideListe(data: any) {
        this.liste = data
        this.listeTemp = {
            id: this.liste.id,
            code: this.liste.code,
            name: this.liste.name,
            ordre: this.liste.ordre,
            type: this.liste.type,
            liste_id: this.liste.liste_id,
            validated_at: this.liste.validated_at,
            affected_at: this.liste.affected_at
        }
        this.showModalValideListe = true
    }

    cancelValideListe() {
        this.listeTemp = null
        this.showModalValideListe = false
    }

    valideListeSuite() {
        const payload = {
            id: this.listeTemp.id,
            code: this.listeTemp.code,
            name: this.listeTemp.name,
            ordre: this.listeTemp.ordre,
            type: this.listeTemp.type,
            liste_id: this.listeTemp.liste_id,
            validate: this.listeTemp.validated_at === null ? 1 : 0
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('liste/updateListe', payload)
            .then(() => {
                this.liste = null
                this.listeTemp = null
                this.showModalValideListe = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Liste modifiée avec succès !', succesToaster)
                this.$store.dispatch('liste/getListes', this.params)
                    .then(() => {
                        this.setDataForGenericTab(this.$store.state.liste.listes)
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // ----------
    lancementAffectation(data: any) {
        this.lancementAffectationEncours = false
        this.liste = data
        this.listeTemp = {
            id: this.liste.id,
            code: this.liste.code,
            name: this.liste.name,
            ordre: this.liste.ordre,
            liste_id: this.liste.liste_id,
            type: this.liste.type,
            validated_at: this.liste.validated_at,
            affected_at: this.liste.affected_at
        }
        this.showModalLancementAffectation = true
    }

    cancellancementAffectation() {
        this.listeTemp = null
        this.showModalLancementAffectation = false
    }

    lancementAffectationEncours = false
    lancementAffectationSuite() {
        this.lancementAffectationEncours = true
        const payload = {
            id: this.listeTemp.id,
            code: this.listeTemp.code,
            name: this.listeTemp.name,
            ordre: this.listeTemp.ordre,
            liste_id: this.listeTemp.liste_id,
            type: this.listeTemp.type,
            affect: this.listeTemp.affected_at === null ? 1 : 0
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('liste/updateListe', payload)
            .then(() => {
                this.liste = null
                this.listeTemp = null
                this.showModalLancementAffectation = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Liste modifiée avec succès !', succesToaster)
                this.$store.dispatch('liste/getListes', this.params)
                    .then(() => {
                        this.setDataForGenericTab(this.$store.state.liste.listes)
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.lancementAffectationEncours = false
                this.$bvToast.hide(idInfo)
            })
    }

    // ----------


    openAddListe () {
        this.listeTemp = {
            id: 0,
            code: '',
            name: '',
            ordre: 0,
            liste_id: null,
            type: TypeListe.TYPE_PREAFFECTATION
        }
        this.showModalEditionListe = true
    }

    editListe (data: any) {
        this.liste = data
        this.listeTemp = {
            id: this.liste.id,
            code: this.liste.code,
            name: this.liste.name,
            ordre: this.liste.ordre,
            liste_id: this.liste.liste_id,
            type: this.liste.type
        }
        this.showModalEditionListe = true
    }

    cancelEdit () {
        this.liste = null
        this.listeTemp = null
        this.showModalEditionListe = false
    }

    editSuite () {
        const payload = {
            id: this.listeTemp.id,
            code: this.listeTemp.code,
            name: this.listeTemp.name,
            ordre: this.listeTemp.ordre,
            liste_id: this.listeTemp.liste_id,
            type: this.listeTemp.type
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.listeTemp.id === 0) {
            this.$store.dispatch('liste/addListe', payload)
                .then(() => {
                    this.liste = null
                    this.listeTemp = null
                    this.showModalEditionListe = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Liste ajoutée avec succès !', succesToaster)
                    this.$store.dispatch('liste/getListes', this.params)
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.liste.listes)
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('liste/updateListe', payload)
                .then(() => {
                    this.liste = null
                    this.listeTemp = null
                    this.showModalEditionListe = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Liste modifiée avec succès !', succesToaster)
                    this.$store.dispatch('liste/getListes', this.params)
                        .then(() => {
                            this.setDataForGenericTab(this.$store.state.liste.listes)
                        }).catch((error) => {
                            console.log('ko:' + error)
                        })
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    deleteListe (data: any) {
        this.liste = data
        this.showModalMessageDelete = true
    }

    cancelDelete () {
        this.liste = null
        this.showModalMessageDelete = false
    }

    deleteSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Supression en cours ...', infosToaster)
        this.$store.dispatch('liste/deleteListe', this.liste.id)
            .then(() => {
                this.liste = null
                this.listeTemp = null
                this.showModalEditionListe = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Liste supprimé avec succès !', succesToaster)
                this.liste = null
                this.showModalMessageDelete = false
                this.$store.dispatch('liste/getListes', this.params)
                    .then(() => {
                        this.setDataForGenericTab(this.$store.state.liste.listes)
                    }).catch((error) => {
                        console.log('ko:' + error)
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('liste/getMoreListes', params).then(() => {
                this.setDataForGenericTab(this.$store.state.liste.listes)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    // Appelle une page lors du scroll
    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('liste/getListes', params).then(() => {
                this.setDataForGenericTab(this.$store.state.liste.listes)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    mounted () {
        this.setFiltersForGenericTab()
    }
}
