






















































































































































































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Etat, getEtatSpec } from '@/types/DossierAdministratif'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { BModal } from 'bootstrap-vue'

@Component({
    computed: {
        ...mapGetters('ordremission', ['error', 'ordreMissionSelect', 'save_finish', 'source_pdf', 'error']),
        ...mapState('ordremission', ['error', 'ordreMissionSelect', 'save_finish', 'source_pdf', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    methods: {
        ...mapActions('ordremission', ['getOrdreMissions', 'getDocumentOrdreMission', 'updateOrdreMission']),
        ...mapMutations('dossieradministratif', ['MAJ_ORDRE_MISSION'])
    },
    components: {
        VuePdfApp,
        ErrorDisplay,
        'b-modal': BModal
    }
})
export default class GestionOrdreMission extends Vue {
    @Prop() showPopup?: boolean

    showInvalidePopup = false
    Ability = Ability
    modePreviewDocument = false
    decision_ordremission: any = null
    formData = new FormData()
    libelle_document = ''
    commentaire_refus = ''
    Etat = Etat
    getEtatSpec = getEtatSpec
    source_doc: any = ''
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    /**
     * selectFile
     * @description Sélectionne un fichier et l'ajoute au formData
     * @param {Event} e - L'évènement de sélection du fichier
     * @return {void}
     */
    selectFile(e: Event): void {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]
            this.formData.set('document', file)
        }
    }

    /**
     * showDocument
     * @description Affiche les pièces jointes à l'ordre de mission au format PDF
     * @param {string} document - Le nom du document à afficher
     * @return {void}
     */
    showDocument(document: string): void {
        const document_select = this.$store.state.ordremission.ordreMissionSelect.documents.filter((d: any) => d.name === document)
        if (document_select[0]) {
            // Appel du pdf
            this.$store.dispatch('ordremission/getDocumentOrdreMission', { ordremission_id: this.$store.state.ordremission.ordreMissionSelect.id, document_id: document_select[0].id })
                .then(() => {
                    this.libelle_document = document_select[0].name
                    this.source_doc = ''
                    this.source_doc = base64ToArrayBuffer(this.$store.state.ordremission.source_pdf)
                    this.modePreviewDocument = true
                })
        }
    }

    // Enregistre la décision pour l'ordre de mission
    /**
     * saveDecision
     * @description Enregistre la décision pour l'ordre de mission
     * @return {void}
     */
    saveDecision(): void {
        this.$store.state.ordremission.error = null
        if (this.decision_ordremission === Etat.ETAT_VALIDE) {
            this.formData.set('etat', this.decision_ordremission.toString())
        }

        if (this.decision_ordremission === Etat.ETAT_REJETE || this.decision_ordremission === Etat.ETAT_VIERGE) {
            this.formData.set('etat', this.decision_ordremission.toString())
            this.formData.set('commentaire_rejet', this.commentaire_refus)
        }

        if (this.decision_ordremission !== null) {
            this.formData.set('_method', 'PUT')
            this.$store.dispatch('ordremission/updateOrdreMission', { ordremission_id: this.$store.state.ordremission.ordreMissionSelect.id, payload: this.formData })
                .then((response) => {
                    this.$store.commit('dossieradministratif/MAJ_ORDRE_MISSION', { dossier_id: this.$store.state.ordremission.ordreMissionSelect.dossier_administratif_id, ordre_mission: response.data.data })
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 3000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    this.$emit('reset')

                    this.commentaire_refus = ''
                    this.decision_ordremission = null
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    /**
     * backDocument
     * @description Ferme la preview d'un document
     * @return {void}
     */
    backDocument(): void {
        this.modePreviewDocument = false
    }

    /**
     * closeOrdreMission
     * @description Ferme la modale de l'ordre de mission
     * @return {void}
     */
    closeOrdreMission(): void {
        this.$emit('reset')
        this.commentaire_refus = ''
        this.decision_ordremission = null
    }

    /**
     * onShowInvalidePopupChanged
     * @description Affiche ou cache la popup d'invalidation de l'ordre de mission en réinitialisant les variables
     * @param {boolean} show - Booléen d'affichage de la popup
     * @return {void}
     */
    @Watch('showInvalidePopup')
    onShowInvalidePopupChanged(show: boolean): void {
        this.commentaire_refus = ''
        this.$store.commit('ordremission/SET_ERROR', null)
        if (show) {
            this.$emit('hide')
        } else {
            this.$emit('show')
        }
    }

    /**
     * invalideOrdreMission
     * @description Invalide l'ordre de mission
     * @return {void}
     */
    invalideOrdreMission(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.formData.set('_method', 'PUT')
        this.formData.set('etat', Etat.ETAT_REJETE.toString())
        this.formData.set('commentaire_rejet', this.commentaire_refus)

        this.$store.dispatch('ordremission/updateOrdreMission', { ordremission_id: this.$store.state.ordremission.ordreMissionSelect.id, payload: this.formData })
            .then((response) => {
                this.$store.commit('ordremission/SET_ORDREMISSION', response.data.data)
                this.$store.commit('dossieradministratif/MAJ_ORDRE_MISSION', { dossier_id: this.$store.state.ordremission.ordreMissionSelect.dossier_administratif_id, ordre_mission: response.data.data })
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 3000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                this.showInvalidePopup = false
                this.$emit('reset')
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * deleteOrdreMission
     * @description Supprime l'ordre de mission
     * @return {void}
     */
    deleteOrdreMission(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)

        this.$store.dispatch('ordremission/deleteOrdreMission',this.$store.state.ordremission.ordreMissionSelect.id)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 3000
                }
                this.$bvToast.toast('Suppression terminé !', succesToaster)
                this.$emit('reload')
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}
