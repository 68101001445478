

























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapMutations, mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        FontAwesomeIcon
    },
    computed: {
        ...mapGetters('dossieracademique', ['dossierAcademiqueSelect']),
        ...mapGetters('etablissement', ['etablissements', 'getEtablissementById'])
    },
    methods: {
        ...mapMutations('dossieracademique', ['UPDATE_DATAS_ETABLISSEMENT_ID', 'UPDATE_DATAS_ETABLISSEMENT_NAME', 'UPDATE_DATAS_ETABLISSEMENT_ADRESSE'])
    }
})
export default class SelectEtablissement extends Vue {
    @Prop() selectedValue?: number
    @Prop() readOnly?: boolean
    @Prop() datas_pro?: any

    selected_code = null

    @Watch('selectedValue')
    getEtablissementId () {
        this.selected_code = this.$props.selectedValue
    }

    @Watch('dossierAcademiqueSelect')
    watch () {
        if (this.$store.state.dossieracademique.dossierAcademiqueSelect.datas_pro.establishment_code) {
            this.selected_code = this.$store.state.dossieracademique.dossierAcademiqueSelect.datas_pro.establishment_code
        }
    }

    getAdresse () {
        if (!this.readOnly) {
            this.$store.commit('dossieracademique/UPDATE_DATAS_ETABLISSEMENT_ID', '')
            this.$store.commit('dossieracademique/UPDATE_DATAS_ETABLISSEMENT_CODE', '')
            this.$store.commit('dossieracademique/UPDATE_DATAS_ETABLISSEMENT_NAME', '')
            this.$store.commit('dossieracademique/UPDATE_DATAS_ETABLISSEMENT_ADRESSE', '')

            const etablissementsTemp = this.$store.state.etablissement.etablissements
            const etablissement_select = etablissementsTemp.filter((etab: any) => etab.code === this.selected_code)[0]

            // let etablissement_select = null
            // etablissement_select = this.$store.getters.etablissement.getEtablissementById(JSON.stringify(this.selected_id))
            if (etablissement_select !== undefined) {
                this.$store.commit('dossieracademique/UPDATE_DATAS_ETABLISSEMENT_CODE', this.selected_code)
                this.$store.commit('dossieracademique/UPDATE_DATAS_ETABLISSEMENT_NAME', etablissement_select.code + ' - ' + etablissement_select.name)
                this.$store.commit('dossieracademique/UPDATE_DATAS_ETABLISSEMENT_ADRESSE', etablissement_select.adresse)
            }
        }
    }

    mounted () {
        if (this.$props.selectedValue !== null && this.$props.selectedValue !== undefined) {
            this.selected_code = this.$props.selectedValue
            this.getAdresse()
        }
    }
}
