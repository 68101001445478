

































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import { checkIcone, getFileNameFromHeader } from '@/utils/helpers'
import GestionIntervenants from '@/components/Centre/Intervenants/GestionIntervenants.vue'
import FicheHoraire from '@/components/Centre/Intervenants/FicheHoraire.vue'
import { TypePassation } from '@/types/Epreuve'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'


@Component({
    computed: {
        ...mapGetters('centre', ['loading', 'centres', 'meta', 'links', 'totalRows', 'lastPage', 'totalPage', 'etat_centre_select']),
        ...mapState('centre', ['meta', 'centreSelect', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('gabaritFicheHoraire', ['errorGabaritJournees', 'errorGabaritCategories', 'can_save_gabarit']),
        ...mapState('gabaritFicheHoraire', ['errorGabaritJournees', 'errorGabaritCategories'])
    },
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon,
        GestionIntervenants,
        FicheHoraire,
        ErrorDisplay
    }
})

export default class Intervenants extends Vue
{
    Ability = Ability
    // DATAS
    genericfields: Array<any> = [
        { key: 'edit',         label: '',       sortable: false, class: '', type: 'action' },
        { key: 'name',         label: 'Libellé', sortable: true, class: '', type: 'text' },
        { key: 'ville.name',   label: 'Ville', sortable: true, class: '', type: 'text' },
        { key: 'etat_ecrit',   label: 'Organisation des écrits', sortable: true, class: 'text-center', type: 'text' },
        { key: 'etat_saisie_intervenant',    label: 'Intervenants', sortable: true, class: 'text-center', type: 'text' },
        { key: 'fiche_gabarit_published_at', label: 'Gabarit fiches horaires', sortable: true, class: 'text-center', type: 'text' },
        { key: 'etat_saisie_fiches_horaires', label: 'Fiches horaires', sortable: true, class: 'text-center', type: 'text' },
        { key: 'etat_fiches_intervenants_signed_at', label: 'Signatures', sortable: true, class: 'text-center', type: 'text' },
        { key: 'bilan', label: 'Bilan', sortable: false, class: 'text-center', type: 'text' },
        { key: 'relance_intervenant', label: 'Relance', sortable: false, class: 'text-center', type: 'text' }
    ]

    dataForTab: Array<any> = []

    filtres: any = []
    centreTemp: any = null
    volumes: Array<any> = []
    enumTypeCentre: Array<any> = []

    showGestionIntervenant = false
    collectionDynamique = { cle: 'concours', datas: {} }

    totalCentres = 0

    showPopupSetGabarit = false
    editionActive = false
    showPopupConfirmationPublicationFicheHoraire = false
    showPopupConfirmationPublicationFicheHoraireCentre = false
    centresFilter: Array<any> = []
    showConfirmRelance = false
    showConfirmRelanceGlobale = false
    rowSelect: any = null
    envoiEnCours = false
    error_relaunch = null
    params: any = null
    exportInProgress = false

    // METHODS

    @Watch('centres')
    filterCentres () {
        if (this.$store.state.centre.centres && this.$store.state.centre.centres.length !== 0) {
            this.centresFilter = this.$store.state.centre.centres.filter((c: any) => c.type_passation === TypePassation.TYPE_PASSATION_ECRIT)
            this.setDataForGenericTab(this.centresFilter)
            this.totalCentres = this.centresFilter.length
        }
    }

    @Watch('user_session_id')
    refreshInterface () {
        const params = {}
        Vue.set(params, 'page', 1)
        Vue.set(params, 'sort', 'ville.name')
        Vue.set(params, 'direction', 'asc')
        Vue.set(params, 'filter-submitted', 1)
        this.params = params
        this.$store.dispatch('centre/getCentres', params)
    }

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const can = this.$store.getters['auth/can'](Ability.RH_SOL_MANAGE)
                const icone = checkIcone(Ability.RH_SOL_MANAGE, can)

                const puce_etat_ecrit = []
                if (result.salles_validated_at && result.nb_intervenants_validated_at) {
                    puce_etat_ecrit.push({ name:'circle', class: 'text-success', title: 'L\'organisation des écrits a été validée' })
                } else if (result.salles_submitted_at && result.nb_intervenants_submitted_at) {
                    puce_etat_ecrit.push({ name:'circle', class: 'text-info', title: 'L\'organisation des écrits a été soumise' })
                } else if (result.commentaire_rejet && (result.commentaire_rejet.besoins || result.commentaire_rejet.salles)) {
                    puce_etat_ecrit.push({ name:'circle', class: 'text-danger', title: 'Corrections attendues' })
                } else {
                    puce_etat_ecrit.push({ name:'circle', class: 'text-tertiary', title: 'Non traitée' })
                }

                const puce_etat_saisie_intervenant = []
                if (result.intervenants_validated_at) {
                    puce_etat_saisie_intervenant.push({ name:'circle', class: 'text-success', title: 'La liste des intervenants a été validée' })
                } else if (result.intervenants_submitted_at) {
                    puce_etat_saisie_intervenant.push({ name:'circle', class: 'text-info', title: 'La liste des intervenants a été soumise' })
                } else if (result.commentaire_rejet && result.commentaire_rejet.intervenants) {
                    puce_etat_saisie_intervenant.push({ name:'circle', class: 'text-danger', title: 'Corrections attendues' })
                } else if (result.besoins && result.besoins.length !== 0) {
                    puce_etat_saisie_intervenant.push({ name:'circle', class: 'text-secondary', title: 'En cours de saisie' })
                } else {
                    puce_etat_saisie_intervenant.push({ name:'circle', class: 'text-tertiary', title: 'Saisie non disponible' })
                }


                const puce_etat_saisie_fiches_horaires = []
                if (result.fiches_intervenants_published_at && result.fiches_intervenants_validated_at) {
                    puce_etat_saisie_fiches_horaires.push({ name:'circle', class: 'text-success', title: 'Les signatures des fiches horaires ont été validées' })
                } else if (result.fiches_intervenants_published_at && !result.fiches_intervenants_signed_at && !result.fiches_intervenants_validated_at) {
                    puce_etat_saisie_fiches_horaires.push({ name:'circle', class: 'text-info', title: 'Fiches horaires publiées pour signature au chef d\'établissement' })
                } else if (result.fiches_intervenants_published_at && result.fiches_intervenants_signed_at && !result.fiches_intervenants_validated_at) {
                    puce_etat_saisie_fiches_horaires.push({ name:'circle', class: 'vertClair', title: 'Le chef d\'établissement a signé les fiches horaires' })
                } else if (result.intervenants_validated_at && result.fiche_gabarit_published_at) {
                    puce_etat_saisie_fiches_horaires.push({ name:'circle', class: 'text-secondary', title: 'En cours de saisie' })
                } else {
                    puce_etat_saisie_fiches_horaires.push({ name:'circle', class: 'text-tertiary', title: 'Saisie non disponible' })
                }

                let etat_fiches_intervenants_signed_at = '-'
                if (result.fiches_signees_count) {
                    etat_fiches_intervenants_signed_at = result.fiches_signees_count + ' / ' + result.postes_count
                }

                const puce_bilan = []
                if (result && result.fiche_intervenant_recap_signed_at) {
                    puce_bilan.push({ name:'circle', class: 'text-success', title: 'Le récapitulatif des fiches intervenants est signé' })
                } else if (result && result.fiches_intervenants_validated_at) {
                    puce_bilan.push({ name:'circle', class: 'text-info', title: 'Le récapitulatif des fiches intervenants est validé' })
                } else {
                    puce_bilan.push({ name:'circle', class: 'text-tertiary', title: '' })
                }


                let puce_relance: any = {}
                let disabled = false

                if ((result && result.salles_validated_at === null) || (result && result.intervenants_submitted_at !== null && result.intervenants_validated_at === null) ||
                    (result && result.intervenants_validated_at !== null && result.fiche_gabarit_published_at === null) || (result && result.fiches_intervenants_submitted_at !== null) || !this.$store.getters['auth/can'](Ability.RH_SOL_MANAGE)) {
                    disabled = true
                }
                puce_relance = { icon:'envelope', class: 'text-primary text-center', label: 'Relance', disabled: disabled }

                let puce_publie_fiche_horaire: any = {}
                let disabled_publie_fiche_horaire = false

                /*
                if ((result && result.salles_validated_at === null) || (result && result.intervenants_submitted_at !== null && result.intervenants_validated_at === null) ||
                    (result && result.intervenants_validated_at !== null && result.fiche_gabarit_published_at === null) || (result && result.fiches_intervenants_submitted_at !== null) || !this.$store.getters['auth/can'](Ability.RH_SOL_MANAGE)) {
                    disabled_publie_fiche_horaire = true
                }
                */

                disabled_publie_fiche_horaire = false
                if (!result || (result.intervenants_validated_at === null || !this.$store.getters['auth/can'](Ability.RH_SOL_MANAGE))) {
                    disabled_publie_fiche_horaire = true
                }
                let color_publi_fiche_horaire = 'text-tertiary'
                let label_publi_fiche_horaire = 'Gabarit des fiches horaires non publié'
                if (result.fiche_gabarit_published_at) {
                    color_publi_fiche_horaire = 'text-success'
                    label_publi_fiche_horaire = 'Gabarit des fiches horaires publié'
                }
                puce_publie_fiche_horaire = { icon:'file-alt', class: 'text-primary text-center ' + color_publi_fiche_horaire, label: label_publi_fiche_horaire, disabled:  disabled_publie_fiche_horaire }


                const line: any = [
                    { label: icone.label, item: result,    type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: result.name,        type: 'text', typeAction: null, class: 'text-start' },
                    { label: '', item: result.ville.name,  type: 'text', typeAction: null, class: 'text-start' },
                    { label: '', item: puce_etat_ecrit,    type: 'icons', typeAction: null, class: 'text-center' },
                    { label: '', item: puce_etat_saisie_intervenant,       type: 'icons', typeAction: null, class: 'text-center' },
                    { label: puce_publie_fiche_horaire.label, item: result,  type: 'action',  typeAction: !puce_publie_fiche_horaire.disabled ? 'publieFicheHoraire' : null, class: puce_publie_fiche_horaire.class, icon: puce_publie_fiche_horaire.icon, disabled: puce_publie_fiche_horaire.disabled },
                    { label: '', item: puce_etat_saisie_fiches_horaires,   type: 'icons', typeAction: null, class: 'text-center' },
                    { label: '', item: etat_fiches_intervenants_signed_at, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: puce_bilan,    type: 'icons', typeAction: null, class: 'text-center' },
                    { label: puce_relance.label, item: result,  type: 'action',  typeAction: !puce_relance.disabled ? 'relance' : null, class: puce_relance.class, icon: puce_relance.icon, disabled: puce_relance.disabled }
                ]

                this.dataForTab.push(line)
            }
            this.$store.commit('centre/SET_LOADING', false)
        }
    }

    // Events
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':
                    this.gestionIntervenants(paParams[1])
                    break
                case 'relance':
                    this.confirmRelance(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'publieFicheHoraire':
                    this.ouvrirConfirmationPublicationFicheHorairesCentre(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    // Construction des filtres
    buildFiltre () {
        const options_basiques = [
            { index: 0, name: "Non" },
            { index: 1, name: "Oui" }
        ]

        this.filtres = [
            { libelle: 'libellé', defautOptionlibelle: 'Rechercher un', model: 'name',       value: '', index: 'name',  datas: '', loading: this.$store.state.centre.loading, options: { type: 'form', fieldsKey: 'name', strict: false } },
            { libelle: 'ville', defautOptionlibelle: 'Rechercher une',  model: 'ville.name', value: '', index: 'ville.name',  datas: '', loading: this.$store.state.centre.loading, options: { type: 'form', fieldsKey: 'ville.name', strict: false } },
            { libelle: 'relance', defautOptionlibelle: 'Rechercher une',  model: 'relance_intervenant', value: '', index: 'relance_intervenant',  datas: options_basiques, loading: this.$store.state.centre.loading, options: { type: 'deroulant', fieldsKey: 'relance_intervenant' } }
        ]
    }

    // Ouverture du popup de confirmation de la publication des fiches horaires
    ouvrirConfirmationPublicationFicheHoraires () {
        this.showPopupConfirmationPublicationFicheHoraire = true
    }

    // Fermeture du popup de confirmation de la publication des fiches horaires
    hideConfirmationPublicationFicheHoraires () {
        this.showPopupConfirmationPublicationFicheHoraire = false
    }

    // Ouverture du popup de confirmation de la publication des fiches horaires d'un centre
    ouvrirConfirmationPublicationFicheHorairesCentre (centre: any) {
        this.centreTemp = centre
        this.showPopupConfirmationPublicationFicheHoraireCentre = true
    }

    // Fermeture du popup de confirmation de la publication des fiches horaires
    hideConfirmationPublicationFicheHorairesCentre () {
        this.centreTemp = null
        this.showPopupConfirmationPublicationFicheHoraireCentre = false
    }

    // publication et de-publication des gabarits fiche horaire par centre
    confirmationPublicationFicheHoraireCentre() {
        // console.log('publicationFicheHoraireParCentre -- ' + centre.id)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        const payload = {
            id: this.centreTemp.id,
            fiche_gabarit_publish: this.centreTemp.fiche_gabarit_published_at ? 0 : 1
        }
        if (this.centreTemp.fiche_gabarit_published_at) {
            this.$bvToast.toast('Modification de la publication en cours ...', infosToaster)
        } else {
            this.$bvToast.toast('Publication en cours ...', infosToaster)
        }
        this.$store.dispatch('centre/updateCentre', payload)
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                if (response.data.data.fiche_gabarit_published_at) {
                    this.$bvToast.toast('Fiches horaires publiées pour le centre ' + response.data.data.name + '.', succesToaster)
                } else {
                    this.$bvToast.toast('Annulation de la publication des fiches horaires pour le centre ' + response.data.data.name + '.', succesToaster)
                }

                this.$store.dispatch('centre/getCentres', this.params)
                    .then(() => {
                        this.showPopupConfirmationPublicationFicheHoraireCentre = false
                        this.centreTemp  = null
                    })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Validation de la confirmation de la publication des fiches horaires
    confirmationPublicationFicheHoraire () {
        if (!this.$store.getters['session/sessionSelect']) {
            this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] }).then(() => {
                this.confirmationPublicationFicheHoraireSuite()
            })
        } else {
            this.confirmationPublicationFicheHoraireSuite()
        }
    }

    // Enregistrement de la confirmation de la publication des fiches horaires
    confirmationPublicationFicheHoraireSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Publication en cours ...', infosToaster)
        this.$store.dispatch('gabaritFicheHoraire/publierFichesHoraires')
            .then((response) => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Fiches horaires publiées pour ' + response.data.data.length + ' centre(s).', succesToaster)

                const params = {}
                Vue.set(params, 'page', 1)
                Vue.set(params, 'sort', 'ville.name')
                Vue.set(params, 'direction', 'asc')
                Vue.set(params, 'filter-submitted', 1)
                this.$store.dispatch('centre/getCentres', params).then(() => {
                    this.showPopupConfirmationPublicationFicheHoraire = false
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Ouvre le popup de définition du gabarit de la fiche horaire */
    OpensetGabaritFicheHoraire () {
        this.showPopupSetGabarit = true
    }

    /** Ferme la popup de définition du gabarit de la fiche horaire */
    closeSetGabarit () {
        this.$store.commit('gabaritFicheHoraire/RESET_STATE')
        this.$store.commit('session/SET_ERROR', null)
        this.showPopupSetGabarit = false
    }

    // Appelle une page lors du scroll
    loadHandler (params: any) {
        this.params = params
        this.$store.dispatch('centre/getMoreCentres', params)
    }

    // Applique des filtres et recharge la collection
    filtreSortHandler (params: any) {
        Vue.set(params, 'filter-submitted', 1)
        this.params = params
        this.$store.dispatch('centre/getCentres', params)
    }

    /** Ouvre le popup d'édition des intervenants d'un centre */
    gestionIntervenants (row: any) {
        this.$store.dispatch('centre/getCentre', { centre_id: row.id }).then(() => {
            this.showGestionIntervenant = true
        })
    }

    /** Ferme la popup de gestion des intervenants */
    closeGestionIntervenants () {
        this.$store.state.gabaritFicheHoraire.error = null
        this.showGestionIntervenant = false
    }

    /** Met à jour les classes css quand on passe en édition */
    updateClass (etat: boolean) {
        this.editionActive = etat
    }

    // Enregistrement du gabarit
    saveGabarit () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const payload = {
            gabarit: this.$store.state.gabaritFicheHoraire.gabarit
        }

        this.$store.dispatch('session/setGabaritHoraire', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)

                this.closeSetGabarit()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Relance un centre en particulier pour les conventions et annexe */
    confirmRelance (row: any) {
        this.rowSelect = row
        this.showConfirmRelance = true
    }

    /** Ferme la popup de confirmation de relance */
    closeConfirmRelance () {
        this.envoiEnCours = false
        this.error_relaunch = null
        this.$store.state.centre.error = null
        this.showConfirmRelance =  false
        this.showConfirmRelanceGlobale = false
    }

    /** Confirme la relance par email sur un centre */
    sendRelance () {
        this.envoiEnCours = true
        this.$store.state.centre.error = null
        this.error_relaunch = null

        this.$store.dispatch('centre/relaunchCentre', { centre_id: this.rowSelect.id, type: 'fiche' })
            .then(() => {
                this.showConfirmRelance = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Envoi terminé !', succesToaster)
                this.envoiEnCours = false
            })
            .catch((error) => {
                this.error_relaunch = error
            })
    }

    /** Relance globale sur tous les centres */
    confirmRelanceGlobale () {
        this.showConfirmRelanceGlobale = true
    }

    /** Confirme la relance globale à tous les centres */
    sendRelanceGlobale () {
        this.envoiEnCours = true
        this.$store.state.centre.error = null
        this.error_relaunch = null

        this.$store.dispatch('centre/relaunchCentres', { type: 'fiche' })
            .then(() => {
                this.showConfirmRelanceGlobale = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Envoi terminé !', succesToaster)
                this.envoiEnCours = false
            })
            .catch((error) => {
                this.error_relaunch = error
            })
    }

    /** Retourne le message de relance adapté à l'état du centre */
    getMessageEtatIntervenant (rowSelect: any) {
        let message = ''
        let desti = 'cdc'
        if (!rowSelect.intervenants_submitted_at) {
            message = 'de saisir et soumettre sa liste d\'intervenants.'
            desti = 'cdc'
        } else if (!rowSelect.fiches_intervenants_published_at && rowSelect.fiche_gabarit_published_at) {
            message = 'de saisir et soumettre les fiches horaires de ses intervenants.'
            desti = 'cdc'
        } else if (!rowSelect.fiches_intervenants_signed_at) {
            message = 'de signer les fiches horaires de ses intervenants.'
            desti = 'resp'
        } else if (!rowSelect.fiches_intervenants_submitted_at) {
            message = ''
            desti = 'intervenants'
        }

        return { message: message, destinataire: desti }
    }

    /**
     * @description Exporter la paie
     */
    exportPaie(): void {
        if (this.exportInProgress) {
            return
        }
        this.exportInProgress = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Génération en cours ...', infosToaster)

        this.$store.dispatch('centre/exportCentresRecapitulatif')
            .then((response) => {
                const file_name = getFileNameFromHeader(response.headers) || 'fiche_horaire.xlsx'
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', file_name)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportInProgress = false
            })
    }

    load () {
        if (isEmpty(this.filtres)) {
            const params = {}
            Vue.set(params, 'page', 1)
            Vue.set(params, 'sort', 'ville.name')
            Vue.set(params, 'direction', 'asc')
            Vue.set(params, 'filter-submitted', 1)
            this.$store.dispatch('centre/getCentres', params).then(() => {
                this.buildFiltre()
            })
        }
    }

    beforeMount () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
