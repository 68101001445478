
















import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        FontAwesomeIcon
    },
    methods: {
        ...mapMutations('dossieracademique', ['UPDATE_DATAS_TYPE_ETABLISSEMENT'])
    }
})
export default class SelectTypeEtablissement extends Vue {
    @Prop() selectedValue?: string
    @Prop() readOnly?: boolean

    options = [
        { id: 'Classes préparatoires aux grandes écoles', value: 'Classes préparatoires aux grandes écoles' },
        { id: 'Lycées et autres établissements de secondaire', value: 'Lycées et autres établissements de secondaire' },
        { id: 'Université - Facultés et IUT', value: 'Université - Facultés et IUT' },
        { id: 'École d\'ingénieurs', value: 'École d\'ingénieurs' },
        { id: 'École de commerce, santé, social, fonctionnaires, éducation', value: 'École de commerce, santé, social, fonctionnaires, éducation' },
        { id: 'Autre', value: 'Autre' }
    ]

    // Event change sur le select
    updateDatasPro (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLTextAreaElement
            this.$store.commit('dossieracademique/UPDATE_DATAS_TYPE_ETABLISSEMENT', target.value)
        }
    }
}
