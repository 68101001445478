






































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { DecisionIntervenant, Etat, getDecisionSpec, getEtatForFilterSelect, getEtatSpec } from '@/types/DossierAcademique'
import { EntityType } from '@/types/Poste'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import AssistantValidationDossier from '@/views/Dossiers/AssistantValidationDossier.vue'
import PopupAddDossier from '@/components/DossierAcademique/PopupAddDossier.vue'
import PopupAddDossierMulti from '@/components/DossierAcademique/PopupAddDossierMulti.vue'
import PopupDecision from '@/components/SelectionRH/PopupDecision.vue'
import CommentaireIntervenant from '@/components/SelectionRH/CommentaireIntervenant.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import { checkIcone } from '@/utils/helpers'
import _ from 'lodash'

@Component({
    components: {
        ExaGenericTable,
        AssistantValidationDossier,
        PopupAddDossier,
        PopupAddDossierMulti,
        PopupDecision,
        CommentaireIntervenant,
        PopUpInfoIntervenant,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('matiere', ['matiereSelect']),
        ...mapState('session', ['sessionSelect']),
        ...mapState('dossieracademique', ['dossiersSelections', 'dossiersSelectionsNew', 'dossierSelectionSelect', 'viewDossier', 'maj_ds', 'maj_dsn']),
        ...mapState('epreuve', ['epreuvesCorrections', 'epreuvesCorrectionsPrecedent']),
        ...mapState('poste', ['postes']),
        ...mapState('pv', ['postesPvCourant', 'reconduction_sans_decision', 'nouvelle_candidature_sans_decision', 'besoins', 'pvSelect']),
        ...mapGetters('dossieracademique', ['loading', 'dossiersSelections', 'totalRows', 'totalPage', 'lastPage', 'error']),
        ...mapGetters('epreuve', ['epreuvesCorrections', 'epreuvesCorrectionsPrecedent']),
        ...mapGetters('poste', ['postes'])
    },
    methods: {
        ...mapMutations('dossieracademique', ['SET_DOSSIER_SELECTION_CURRENT', 'SET_VIEW_DOSSIER']),
        ...mapMutations('pv', ['SET_RECONDUCTIONS_SANS_DECISION', 'SET_NOUVELLE_CANDIDATURE_SANS_DECISION']),
        ...mapActions('dossieracademique', ['getDossiersSelections', 'addDossierAcademique', 'getDossierAcademique']),
        ...mapActions('epreuve', ['getEpreuvesCorrections']),
        ...mapActions('poste', ['getPostes']),
        ...mapActions('pv', ['getBesoins']),
        getStringConcours (value) {
            return value?.map((c: any) => c.name).join(', ') || ''
        }
    }
})
export default class GestionCandidatures extends Vue {
    @Prop() session_id?: number
    @Prop() matiere_id?: number
    @Prop() session_id_precedente?: number
    @Prop() filtre?: any

    Ability = Ability

    // DATAS
    showDecision = false
    showDossierIntervenant = false
    showClassement = false
    showCommentaireIntervenant = false
    readOnly = false
    rowSelect = null
    dossier_select: any = {}
    user_select: any = {}
    input_classement = null
    totalReconductions = 0
    totalNouvellesCandidatures = 0
    dossier_id = 0
    assistant_new = false
    params: any = {
        sort: 'user.name',
        direction: 'asc'
    }

    showModalInfoIntervenant = false
    user_id_temp = 0

    showModalCompteur = false

    genericfields: Array<any> = [
        { key: 'edit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'etat', label: 'État', sortable: true,  class: 'text-center', type: 'text' },
        { key: 'usercomment', label: '', sortable: false, class: 'text-center', type: 'action' },
        { key: 'user.name', label: 'Identité', sortable: true, sortDirection: 'asc', class: 'text-start ps-4',  type: 'actionText'  },
        { key: 'precedent.annee_premiere_candidature', label: 'Première candidature', sortable: true, class: 'text-center', type: 'text' },
        { key: 'disponibilite_selection', label: 'Disponibilité', sortable: true,  class: 'text-center', type: 'text' },
        { key: 'postes_souhait_courant', label: 'Souhait candidature N', sortable: false,  class: '', type: 'text' },
        { key: 'postes_selection_precedent', label: 'Poste session N-1', sortable: false,  class: '', type: 'text' },
        { key: 'postes_selection_courant', label: 'Décision et Poste session N', sortable: false,  class: '', type: 'action' }
    ]

    filtres: any = []
    documents_type: Array<any> = []
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    dataForTab: any = []
    enumEtats: Array<any> = []

    openModalCompteur () {
        this.showModalCompteur = true
    }

    closeModalCompteur () {
        this.showModalCompteur = false
    }

    @Watch('pvSelect')
    pvSelectLoaded () {
        const pv = this.$store.getters['pv/pvSelect']
        this.readOnly = !(pv.validated_at === null && pv.submitted_at === null);
    }

    @Watch('dossiersSelectionsAll')
    getEpreuvesCorrectionsAll () {
        if (this.$store.getters['dossieracademique/dossiersSelectionsAll']?.length) {
            for (const d in this.$store.getters['dossieracademique/dossiersSelectionsAll']) {
                this.setEpreuveCorrectionPostesPrecedent(this.$store.getters['dossieracademique/dossiersSelectionsAll'][d])
                this.setEpreuveCorrectionPostesCourant(this.$store.getters['dossieracademique/dossiersSelectionsAll'][d])
            }
        }
    }

    @Watch('dossiersSelections')
    getEpreuvesCorrections () {
        if (this.$store.getters['dossieracademique/dossiersSelections']?.length) {
            for (const d in this.$store.getters['dossieracademique/dossiersSelections']) {
                this.setEpreuveCorrectionPostesPrecedent(this.$store.getters['dossieracademique/dossiersSelections'][d])
                this.setEpreuveCorrectionPostesCourant(this.$store.getters['dossieracademique/dossiersSelections'][d])
            }

            this.$store.commit('pv/SET_RECONDUCTIONS_SANS_DECISION', this.$store.getters['dossieracademique/dossiersSelections'])

            // On récupère le nombre total de reconductions depuis le dossiersSelections
            this.totalReconductions = this.$store.getters['dossieracademique/dossiersSelections'].length
            this.sortFilterHandler(this.params)
        }
    }

    @Watch('maj_ds')
    maj_epreuve_ds () {
        if (this.$store.getters['dossieracademique/dossiersSelections']?.length && this.$store.state.dossieracademique.maj_ds) {
            for (const d in this.$store.getters['dossieracademique/dossiersSelections']) {
                this.setEpreuveCorrectionPostesPrecedent(this.$store.getters['dossieracademique/dossiersSelections'][d])
                this.setEpreuveCorrectionPostesCourant(this.$store.getters['dossieracademique/dossiersSelections'][d])
            }

            this.$store.commit('pv/SET_RECONDUCTIONS_SANS_DECISION', this.$store.getters['dossieracademique/dossiersSelections'])

            // On récupère le nombre total de reconductions depuis le dossiersSelections
            this.totalReconductions = this.$store.getters['dossieracademique/dossiersSelections'].length
        }
    }

    // METHODS
    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const can = this.$store.getters['auth/can'](Ability.RH_SPS_MANAGE)
                const icone = checkIcone(Ability.RH_SPS_MANAGE, can)
                const puce_etat = []

                if (result) {
                    switch (true) {
                        case result.etat !== Etat.ETAT_INVISIBLE:
                            puce_etat.push({ name:'circle', class: 'text-' + getEtatSpec(result.etat).color, title: getEtatSpec(result.etat).libelle })
                            break
                        case (result.validated_at === null || result.etat === Etat.ETAT_INVISIBLE):
                            puce_etat.push({ name:'circle', class: 'text-tertiary', title: 'Non initialisé' })
                            break
                    }
                }

                const commentColor = result?.user?.comment?.body ? 'text-primary text-center' : 'text-tertiary text-center'
                const nameIcon     = 'comment-alt'

                const line: any = [
                    { label: icone.label, item: result.courant ? result.courant.id : 0, type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: puce_etat,  type: 'icons', typeAction: null, class: 'text-center' },
                    { label: 'Commentaire', item: result, type: 'action',  typeAction: 'openComment', class: commentColor, icon: nameIcon, disabled: !this.$store.getters['auth/can'](Ability.RH_SPS_MANAGE) }
                ]

                line.push({ label: '', item: result.user.id, type: 'actionText', typeAction: 'infoIntervenant', class: 'text-info item_action', text: result.user.name + ' ' + result.user.first_name })


                let postes_souhait_courant = ''
                for (const p in result.postes_souhait_courant) {
                    if (Number(p) > 0) {
                        postes_souhait_courant += ', '
                    }
                    if (result.postes_souhait_courant[p].concour) {
                        postes_souhait_courant += result.postes_souhait_courant[p].name + ' sur ' + result.postes_souhait_courant[p].concour.name
                    } else {
                        postes_souhait_courant += result.postes_souhait_courant[p].name
                    }
                }

                let postes_precedent = ''
                const voyelles = ['a', 'e', 'i', 'o', 'u']
                for (const i in result.postes_selection_precedent) {
                    if (Number(i) > 0) {
                        postes_precedent += '<br/>'
                    }
                    if (result.postes_selection_precedent[i].entity_type === EntityType.ET_EPREUVE_CORRECTION) {
                        if (result.postes_selection_precedent[i].options_affectes !== null) {
                            const opts = JSON.parse(result.postes_selection_precedent[i].options_affectes)
                            if (opts.length !== 0) {
                                postes_precedent += result.postes_selection_precedent[i].name + ' - ' + opts[0] + ' sur ' + result.postes_selection_precedent[i].epreuve_name
                            } else {
                                postes_precedent += result.postes_selection_precedent[i].name + ' sur ' + result.postes_selection_precedent[i].epreuve_name
                            }
                        } else {
                            postes_precedent += result.postes_selection_precedent[i].name + ' sur ' + result.postes_selection_precedent[i].epreuve_name
                        }
                    } else if (result.postes_selection_precedent[i].entity_type === EntityType.ET_MATIERE) {
                        if(voyelles.includes(result.postes_selection_precedent[i].matieres[0].name[0].toLowerCase() )){
                            postes_precedent += result.postes_selection_precedent[i].name + ' d`' + result.postes_selection_precedent[i].matieres.map((matiere: any) => matiere.name).join(', ')
                        } else {
                            postes_precedent += result.postes_selection_precedent[i].name + ' de ' + result.postes_selection_precedent[i].matieres.map((matiere: any) => matiere.name).join(', ')
                        }
                    }
                }

                let postes_selection_courant = ''
                for (const i in result.postes_selection_courant) {
                    if (Number(i) > 0) {
                        postes_selection_courant += '<br/>'
                    }
                    if (result.postes_selection_courant[i].entity_type === EntityType.ET_EPREUVE_CORRECTION) {
                        if (result.postes_selection_courant[i].options_affectes !== null) {
                            const opts = JSON.parse(result.postes_selection_courant[i].options_affectes)
                            if (opts.length !== 0) {
                                postes_selection_courant += result.postes_selection_courant[i].name + ' - ' + opts[0] + ' sur ' + result.postes_selection_courant[i].epreuve_name
                            } else {
                                postes_selection_courant += result.postes_selection_courant[i].name + ' sur ' + result.postes_selection_courant[i].epreuve_name
                            }
                        } else {
                            postes_selection_courant += result.postes_selection_courant[i].name + ' sur ' + result.postes_selection_courant[i].epreuve_name
                        }
                    } else if (result.postes_selection_courant[i].entity_type === EntityType.ET_MATIERE) {
                        postes_selection_courant += result.postes_selection_courant[i].name + ' de ' + result.postes_selection_courant[i].matieres.map((matiere: any) => matiere.name).join(', ')
                    }
                }


                // Etat de disponibilité de l'intervenant
                let puce_etat_dispo = [{ name:'circle', class: 'text-light', title: '' }]
                if (result.courant) {
                    switch (result.courant.decision_intervenant_selection) {
                        case DecisionIntervenant.DECISION_INTERVENANT_MASQUE:
                            puce_etat_dispo = [{ name:'circle', class: 'text-light', title: '' }]
                            break
                        case DecisionIntervenant.DECISION_INTERVENANT_ATTENTE:
                            puce_etat_dispo = [{ name:'circle', class: 'text-secondary', title: 'En attente de réponse' }]
                            break
                        case DecisionIntervenant.DECISION_INTERVENANT_OUI:
                            puce_etat_dispo = [{ name:'circle', class: 'text-success', title: 'Disponible' }]
                            break
                        case DecisionIntervenant.DECISION_INTERVENANT_NON:
                            puce_etat_dispo = [{ name:'circle', class: 'text-danger', title: 'Non disponible' }]
                            break
                    }
                }

                const selectionSpec = result.courant ? getDecisionSpec(result.courant.decision_selection) : null
                const selection     = result.postes_selection_courant && result.postes_selection_courant.length > 0 ? this.selectionFormatter(result.postes_selection_courant) : (selectionSpec ? selectionSpec.libelle : '')

                let class_decision = postes_selection_courant !== '' ? 'text-start max_width_column item_actif_clic' : 'text-start max_width_column item_actif_clic_danger'
                if (this.$store.getters['pv/pvSelect']?.submitted_at || !this.$store.getters['auth/can'](Ability.RH_SPS_MANAGE)) {
                    class_decision = 'text-start max_width_column item_no_clic'
                }

                line.push({ label: '', item: result.precedent?.annee_premiere_candidature || '-', type: 'text', typeAction: null, class: 'text-center' })
                line.push({ label: '', item: puce_etat_dispo,  type: 'icons', typeAction: null, class: 'text-center' })
                line.push({ label: '', item: postes_souhait_courant || '-', type: 'text', typeAction: null, class: 'text-start' })
                line.push({ label: '', item: postes_precedent || '-', type: 'html', typeAction: null, class: 'text-start max_width_column' })
                line.push({ label: '', item: result, type:'actionText', typeAction: 'decision_selection', class: class_decision, text: selection !== '' ? selection : '-', disabled: !this.$store.getters['auth/can'](Ability.RH_SPS_MANAGE) })
                this.dataForTab.push(line)
            }
        }
    }

    //  Formattage du rendu des décisions
    selectionFormatter (value: any) {
        let retour = ''
        for (let i = 0; i < value.length; i++) {
            if (i > 0) {
                retour += '<br>'
            }
            if (value[i].entity_type === EntityType.ET_EPREUVE_CORRECTION) {
                if (value[i].options_affectes !== null) {
                    const opts = JSON.parse(value[i].options_affectes)
                    if (opts.length !== 0) {
                        retour += value[i].name + ' - ' + opts[0] + ' sur ' + value[i].epreuve_name
                    } else {
                        retour += value[i].name + ' sur ' + value[i].epreuve_name
                    }
                } else {
                    retour += value[i].name + ' sur ' + value[i].epreuve_name
                }
            } else if (value[i].entity_type === EntityType.ET_MATIERE) {
                const voyelles = ['a', 'e', 'i', 'o', 'u']
                if(voyelles.includes(value[i].matieres[0].name[0].toLowerCase() )){
                    retour += value[i].name + ' d`' + value[i].matieres.map((matiere: any) => matiere.name).join(', ')
                } else {
                    retour += value[i].name + ' de ' + value[i].matieres.map((matiere: any) => matiere.name).join(', ')
                }
            }
        }
        return retour
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        const options_etats = this.getEnumEtats().map((e: any) => {
            return { index: e.id, name: e.name }
        })

        const options_postes = [
            { index: 0, name: 'Sans poste' },
            { index: 1, name: 'Avec poste' }
        ]

        this.filtres = [
            {
                libelle: 'Etat',
                defautOptionlibelle: 'Rechercher un',
                model: 'etat',
                value: '',
                index: 'etat',
                datas: options_etats,
                loading: this.$store.getters['dossieradministratif/loading'],
                options: { type: 'deroulant', fieldsKey: 'etat' }
            },
            {
                libelle: 'Nom',
                defautOptionlibelle: 'Rechercher un',
                model: 'user.name',
                value: '',
                datas: '',
                loading: this.$store.getters['dossieracademique/loading'],
                options: { type: 'form', fieldsKey: 'user.name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Poste précédent',
                defautOptionlibelle: 'Rechercher un',
                model: 'postes_selection_precedent',
                value: '',
                index: 'postes_selection_precedent',
                datas: options_postes,
                loading: this.$store.getters['dossieradministratif/loading'],
                options: { type: 'deroulant', fieldsKey: 'postes_selection_precedent' }
            },
            {
                libelle: 'Poste',
                defautOptionlibelle: 'Rechercher un',
                model: 'postes_selection_courant',
                value: '',
                index: 'postes_selection_courant',
                datas: options_postes,
                loading: this.$store.getters['dossieradministratif/loading'],
                options: { type: 'deroulant', fieldsKey: 'postes_selection_courant' }
            }
        ]
    }

    // Récupère une énum des états de poste possible
    getEnumEtats () {
        this.enumEtats = []
        for (const etatName in Etat) {
            if (isNaN(Number(etatName))) {
                this.enumEtats.push({ id: getEtatForFilterSelect(etatName).index, name: getEtatForFilterSelect(etatName).libelle })
            }
        }
        return this.enumEtats
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    this.dossier_id = paParams[1]
                    this.assistant_new = false
                    this.$store.commit('dossieracademique/SET_VIEW_DOSSIER', true)
                    break
                case 'openComment':
                    this.user_select = paParams[1].user
                    this.showCommentaireIntervenant = true
                    break
                case 'decision_selection':
                    if (!this.$store.getters['pv/pvSelect'].submitted_at) {
                        this.rowSelect = paParams[1]
                        this.showDecision = true
                    }
                    break
                case 'filterHandler':
                case 'sortHandler':
                    this.sortFilterHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler()
                    break
                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break
            }
        }
    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    /**
     * Appel d'une nouvelle page de candidats lors du scroll
     */
    loadHandler () {
        /* Vue.set(params, 'filter-type', TypeReclamation.TYPE_NOTE_ECRIT)
        this.$store.dispatch('reclamation/getMoreReclamations', { type: 'ECRIT', filters : params }) */
    }

    /*
     * Gestion des filtres et du tri
     */
    sortFilterHandler (params: any): void {
        this.params = params
        let dossiers_selections: Array<any> = this.$store.getters['dossieracademique/dossiersSelections']

        if (this.params['filter-etat']) {
            dossiers_selections = dossiers_selections.filter((d: any) => d.etat.toString() === this.params['filter-etat'])
        }

        if (this.params['filter-user.name']) {
            const regex = new RegExp(this.params['filter-user.name'].replace(/%/g, '').toLowerCase() + '.+$', 'i')
            dossiers_selections = dossiers_selections.filter((d: any) => regex.test(d.user.name.toLowerCase()))
        }

        if (this.params['filter-postes_selection_precedent']) {
            dossiers_selections = dossiers_selections.filter((d: any) => d.postes_selection_precedent.length === parseInt(this.params['filter-postes_selection_precedent']))
        }

        if (this.params['filter-postes_selection_courant']) {
            dossiers_selections = dossiers_selections.filter((d: any) => d.postes_selection_courant.length === parseInt(this.params['filter-postes_selection_courant']))
        }

        dossiers_selections = _.orderBy(dossiers_selections, this.params.sort, this.params.direction)
        this.setDataForGenericTab(dossiers_selections)
    }


    // Set les noms d'épreuves corrections pour les postes -- session precedente
    setEpreuveCorrectionPostesPrecedent (dossierSelection: any) {
        if (dossierSelection.postes_selection_precedent.length) {
            const postes_selection_precedents = dossierSelection.postes_selection_precedent
            for (const p in postes_selection_precedents) {
                const epreuve = this.$store.getters['epreuve/epreuvesCorrectionsPrecedent'].find((e: any) => e.id === postes_selection_precedents[p].entity_id)
                dossierSelection.postes_selection_precedent[p].epreuve_name = epreuve !== undefined ? this.getConcoursName(epreuve.concours) + '-' + epreuve.name : ''
            }
        }
        if (dossierSelection.postes_souhait_precedent.length) {
            const postes_souhait_precedent = dossierSelection.postes_souhait_precedent
            for (const p in postes_souhait_precedent) {
                const epreuve = this.$store.getters['epreuve/epreuvesCorrectionsPrecedent'].find((e: any) => e.id === postes_souhait_precedent[p].entity_id)
                dossierSelection.postes_souhait_precedent[p].epreuve_name = epreuve !== undefined ? this.getConcoursName(epreuve.concours) + '-' + epreuve.name : ''
            }
        }
    }

    // Set les noms d'épreuves corrections pour les postes -- session couranre
    setEpreuveCorrectionPostesCourant (dossierSelection: any) {
        if (dossierSelection.postes_selection_courant.length !== 0) {
            const postes_selection_courant = dossierSelection.postes_selection_courant
            for (const p in postes_selection_courant) {
                const epreuve = this.$store.getters['epreuve/epreuvesCorrections'].find((e: any) => e.id === postes_selection_courant[p].entity_id)
                dossierSelection.postes_selection_courant[p].epreuve_name = epreuve !== undefined ? this.getConcoursName(epreuve.concours) + '-' + epreuve.name : ''
            }
        }
        if (dossierSelection.postes_souhait_courant.length !== 0) {
            const postes_souhait_courant = dossierSelection.postes_souhait_courant
            for (const p in postes_souhait_courant) {
                const epreuve = this.$store.getters['epreuve/epreuvesCorrections'].find((e: any) => e.id === postes_souhait_courant[p].entity_id)
                dossierSelection.postes_souhait_courant[p].epreuve_name = epreuve !== undefined ? this.getConcoursName(epreuve.concours) + '-' + epreuve.name : ''
            }
        }
    }

    // set le nom des concours d'une épreuve
    getConcoursName (concour: any) {
        return concour.map((c: any) => c.name).join(', ')
    }

    // Fermeture popup decision
    closeDecision () {
        this.showDecision = false
    }

    // Update le classement d'un dossier
    updateClassement () {
        this.$store.dispatch('dossieracademique/updateDossierAcademique', {
            mode: 'selection',
            new: true,
            dossier_id: this.dossier_select.courant.id,
            payload: {
                set_classement: 1,
                classement: this.input_classement
            }
        })
            .then(() => {
                this.showClassement = false
                this.input_classement = null
            })
    }

    // Ferme la popup de commentaire intervenant
    closeCommentaireIntervenant (retour = null) {
        if (retour != null) {
            if (this.user_select.comment) {
                this.user_select.comment.body = retour
            } else {
                this.user_select.comment = {
                    body: retour
                }
            }
            this.$store.dispatch('dossieracademique/getDossiersSelections', {
                session_id: this.$store.getters['session/sessionSelect'].id,
                matiere_id: this.$store.getters['matiere/matiereSelect'].id
            })
        }
        this.showCommentaireIntervenant = false
        this.user_select = {}
    }

    infoFiltre = ''

    load () {
        this.infoFiltre = ''
        // Chargement épreuves reconductions
        this.setFiltersForGenericTab()
        if (this.$store.getters['dossieracademique/dossiersSelections']?.length) {
            for (const d in this.$store.getters['dossieracademique/dossiersSelections']) {
                this.setEpreuveCorrectionPostesPrecedent(this.$store.getters['dossieracademique/dossiersSelections'][d])
                this.setEpreuveCorrectionPostesCourant(this.$store.getters['dossieracademique/dossiersSelections'][d])
            }

            this.$store.commit('pv/SET_RECONDUCTIONS_SANS_DECISION', this.$store.getters['dossieracademique/dossiersSelections'])
            const dossiers_selections = _.orderBy(this.$store.getters['dossieracademique/dossiersSelections'], 'user.name', 'asc')
            if (this.filtre !== null) {
                const dossiers_selections_filtre: Array<any> = []
                switch(this.filtre.from) {
                    case 'showPosteAssocie':
                        this.infoFiltre = 'Poste : ' + this.filtre.data.name
                        for(let i = 0; i < dossiers_selections.length; i++) {
                            for(let j = 0; j < dossiers_selections[i].postes_affectation_courant.length; j++) {
                                if(dossiers_selections[i].postes_affectation_courant[j].id === this.filtre.data.id) {
                                    dossiers_selections_filtre.push(dossiers_selections[i])
                                }
                            }
                        }
                        this.setDataForGenericTab(dossiers_selections_filtre)
                        this.$emit('resetFiltre')
                        break
                    case 'showFilliereAssocie':
                        this.infoFiltre = `Filière : ${this.filtre.data.map((f: any) => f.name).join(', ')}`

                        for(let i = 0; i < dossiers_selections.length; i++) {
                            for(let j = 0; j < dossiers_selections[i].postes_affectation_courant.length; j++) {
                                for (let k = 0; k < this.filtre.data.length; k++) {
                                    if (dossiers_selections[i].postes_affectation_courant[j].concour && this.filtre.data[k].id === dossiers_selections[i].postes_affectation_courant[j].concour.id) {
                                        dossiers_selections_filtre.push(dossiers_selections[i])
                                    }

                                }
                            }
                        }
                        this.setDataForGenericTab(dossiers_selections_filtre)
                        this.$emit('resetFiltre')
                        break
                    case 'showEpreuveAssocie':
                        this.infoFiltre = 'Epreuve : ' + this.filtre.data.name
                        for(let i = 0; i < dossiers_selections.length; i++) {
                            for(let j = 0; j < dossiers_selections[i].postes_affectation_courant.length; j++) {
                                if(dossiers_selections[i].postes_affectation_courant[j].entity_type === 'App\\Models\\EpreuveCorrection' && dossiers_selections[i].postes_affectation_courant[j].entity_id === this.filtre.data.id) {
                                    dossiers_selections_filtre.push(dossiers_selections[i])
                                }
                            }
                        }
                        this.setDataForGenericTab(dossiers_selections_filtre)
                        this.$emit('resetFiltre')
                        break
                }
            } else {
                this.setDataForGenericTab(dossiers_selections)
            }
        }

        // Chargement épreuves all selections
        if (this.$store.getters['dossieracademique/dossiersSelectionsAll'].length !== 0) {
            for (const d in this.$store.getters['dossieracademique/dossiersSelectionsAll']) {
                this.setEpreuveCorrectionPostesPrecedent(this.$store.getters['dossieracademique/dossiersSelectionsAll'][d])
                this.setEpreuveCorrectionPostesCourant(this.$store.getters['dossieracademique/dossiersSelectionsAll'][d])
            }
        }
    }

    codeClient = 'CCMP'
    created () {
        for(let i = 0; i < this.$store.getters['auth/authUser'].parameters.length; i ++) {
            if(this.$store.getters['auth/authUser'].parameters[i].code === 'codeClient') {
                this.codeClient = this.$store.getters['auth/authUser'].parameters[i].value
            }
        }
        this.dataForTab = []
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
