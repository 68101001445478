



















































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import store from '@/store/index'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { BaremeRemunerationInterface, getTypeRemuneration, TypeRemuneration } from '@/types/BaremeRemuneration'
import { Etat, getEtatSpecContrat } from '@/types/DossierAdministratif'
import { getEtatSpec } from '@/types/DossierAcademique'
import { ContratTypeInterface } from '@/types/ContratType'
import { EntityType, PosteType } from '@/types/Poste'
import { EpreuveInterface } from '@/types/Epreuve'
import { MatiereInterface } from '@/types/Matiere'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import GestionAvenants from '@/components/DossierAdministratif/GestionAvenants.vue'
import ErrorDisplay   from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapGetters('dossieradministratif', ['dossierAdministratifSelect', 'error', 'contrats']),
        ...mapState('dossieradministratif', ['dossierAdministratifSelect', 'contratSelect', 'source_pdf', 'error', 'contrats']),
        ...mapState('contrattype', ['contrattypes']),
        ...mapGetters('contrattype', ['contrattypes']),
        ...mapGetters('baremeremuneration', ['baremesRemuneration']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('avenant', ['source_pdf_avenant', 'avenant_select', 'error_avenant'])
    },
    methods: {
        ...mapActions('contrattype', ['getContratTypes']),
        ...mapActions('baremeremuneration', ['getBaremesRemuneration']),
        ...mapActions('dossieradministratif', ['updateDossierAdministratif', 'getContrat']),
        ...mapMutations('document', ['SET_DOCUMENTS'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp,
        GestionAvenants,
        ErrorDisplay
    },
    beforeMount () {
        store.dispatch('contrattype/getContratTypes').then(() => {
            store.dispatch('baremeremuneration/getBaremesRemuneration')
        })
    }
})
export default class GestionContrats extends Vue {
    @Prop() showContrats?: boolean
    @Prop() row?: any
    @Prop() mode?: string

    Ability = Ability
    typeRemuneration = TypeRemuneration

    @Watch('contrat_type_select')
    setRemunerations () {
        // Filtre les postes affectés a afficher selon le type de contrats
        // \\('Dans watch contrat_type_select')
        // if (this.$store.state.dossieradministratif.contratSelect === null) {
        this.remunerations_filter = {}
        this.remunerations_ids = {}
        this.initRemunerations()
        // }
    }

    @Watch('contratSelect')
    setRemunerationsUpdate () {
        if (this.$store.state.dossieradministratif.contratSelect && this.$store.state.dossieradministratif.contratSelect.bareme_remunerations && (this.modeEdition || this.modeSaisieQuantitesReelle || this.showGestionAvenants)) {
            this.remunerations_filter = {}
            const contrat = this.$store.state.contrattype.contrattypes.filter((ct: ContratTypeInterface) => ct.id === this.$store.state.dossieradministratif.contratSelect.contrat_type_id)
            this.contrat_type_select = contrat[0]
            this.initRemunerations()
        }
    }

    @Watch('error')
    setError () {
        if (this.$store.state.dossieradministratif.error) {
            this.errorInterface = this.$store.state.dossieradministratif.error
        } else {
            this.errorInterface = ''
        }
    }

    @Watch('dossierAdministratifSelect')
    filtrerElements () {
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect) {
            this.contrats_types_filter = []
            this.postes_restants = []
            this.postes_affectations = []
            this.showCreation = true

            // Poste affectation selon type de dossier adm
            if (this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique) {
                for (const p in this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique.poste_affectations) {
                    this.postes_affectations.push(this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique.poste_affectations[p])
                }

                this.context = PosteType.TYPE_PEDAGOGIQUE
            }
            if (this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user) {
                for (const p in this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user.postes) {
                    this.postes_affectations.push(this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user.postes[p])
                }

                this.context = PosteType.TYPE_LOGISTIQUE
            }


            if (this.$store.state.dossieradministratif.dossierAdministratifSelect.contrats && this.$store.state.dossieradministratif.dossierAdministratifSelect.contrats.length !== 0) {
                this.initContratsTypes()
            } else {
                if (this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique) {
                    const poste_ac = this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique.poste_affectations
                    for (const c in poste_ac) {
                        this.postes_restants.push(poste_ac[c])
                    }
                    // this.postes_restants = this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique.poste_affectations
                }
                if (this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user) {
                    const poste_lo = this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user.postes
                    for (const l in poste_lo) {
                        this.postes_restants.push(poste_lo[l])
                    }
                    // this.postes_restants = this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user.postes
                }

                this.filterContratTypes()
            }
        }
    }

    contrat_type_select: any = null
    contrats_types_filter: Array<ContratTypeInterface> = []
    remuneration_select: Record<string, any> = {}
    volume_select: Record<string, any> = {}
    volume_reel_select: Record<string, any> = {}
    quantite_reelle: Record<string, any> = {}
    remunerations_ids: Record<string, any> = {}
    remunerations_filter: Record<string, any> = {}
    remunerations_reelles_filter: Array<any> = []
    postes_affectations: Array<any> = []
    postes_restants: Array<any> = []
    poste_affectations_filter: Array<any> = []
    postes_associes: Array<any> = []
    errorInterface = ''
    Etat = Etat
    getEtatSpec = getEtatSpec
    modeCreation = true
    modeSaisieQuantitesReelle = false
    modeEdition = false
    modePreview = false
    contrat_select = {}
    confirmPublication = false
    confirmDepublication = false
    confirmDeleteContrat = false
    disabledBoutonPublier = false
    countRemunerations = 0
    showCreation = true
    context: any = null
    getEtatSpecContrat = getEtatSpecContrat
    codeClient: any = null
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''
    showGestionAvenants = false
    contrat_for_avenant: any = null
    modePreviewAvenant = false
    confirmDeleteAvenant = false
    confirmPublicationAvenant = false
    confirmDepublicationAvenant = false
    disabledBoutonPublierAvenant = false
    datas_delete: any = null

    btnSaveIsHidden () {
        return this.$store.state.dossieradministratif.postes_affectations_sans_contrat.length === 0;
    }

    saveIsDisabled = true
    @Watch('remunerations_ids', { deep: true })
    checkSaveIsDisabled() {
        this.saveIsDisabled = true
        let countRemuneration = 0
        let countVolumeSelect = 0
        if (this.contrat_type_select) {
            for (const r in this.remunerations_ids) {
                if (this.remunerations_ids[r].length !== 0) {
                    for (const b in this.remunerations_ids[r]) {
                        countRemuneration++
                        if (this.remunerations_ids[r][b] && this.volume_select[r][b]) {
                            countVolumeSelect++
                        }
                    }
                }
            }
        }
        if (countRemuneration > 0 && countRemuneration === countVolumeSelect) {
            this.saveIsDisabled = false
        }
    }


    // Init contrat type
    initContratsTypes () {
        // Charge les contrats type selon les postes affectés
        this.postes_associes = []
        this.postes_restants = this.postes_affectations
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect.contrats && this.$store.state.dossieradministratif.dossierAdministratifSelect.contrats.length !== 0) {
            // filtrer les postes affectations en fonction des contrats deja créés
            const contrats = this.$store.state.dossieradministratif.dossierAdministratifSelect.contrats
            for (const c in contrats) {
                for (const p in this.postes_affectations) {
                    this.postes_affectations[p].associe = false
                    if (contrats[c].bareme_remunerations) {
                        const matchAssociations = contrats[c].bareme_remunerations.filter((b: any) => b.poste_id === this.postes_affectations[p].id && b.entity_id === this.postes_affectations[p].entity_id)
                        if (matchAssociations.length !== 0) {
                            // On classe le poste associé, car on a un barème affecté : liaison poste_id / entity_id
                            this.postes_associes.push(this.postes_affectations[p])
                        }
                    }
                }
            }

            // Init la collection de contrats type
            if (this.postes_associes.length !== this.postes_restants.length) {
                // on check les postes associés dans les postes restants
                for (const pa in this.postes_associes) {
                    const index = this.postes_restants.findIndex((pr: any) => pr.id === this.postes_associes[pa].id && pr.entity_id === this.postes_associes[pa].entity_id)
                    this.postes_restants[index].associe = true
                }

                this.filterContratTypes()
                this.showCreation = true
            } else {
                this.showCreation = false
            }
        } else {
            // On filtre avec tous les postes affectés
            this.filterContratTypes()
            this.showCreation = true
        }
    }

    // Selectionne une remuneration
    selectRemuneration (index: number, bareme_id: number, poste: any) {
        // \\('Dans selectRemuneration')
        if (this.remuneration_select[poste.id + '_' + poste.entity_id][index]) {
            this.remunerations_ids[poste.id + '_' + poste.entity_id][index] = bareme_id
            this.countRemunerations++
        } else {
            this.remunerations_ids[poste.id + '_' + poste.entity_id].splice(index, 1)
            this.countRemunerations--
        }
        this.checkSaveIsDisabled()
    }

    // Ajoute / Update un contrat à l'intervenant
    saveContrat () {
        const remunerations = []
        for (const r in this.remunerations_ids) {
            const splitKey = r.split('_')

            const poste_aff: any = []
            if (this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique) {
                for (const p in this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique.poste_affectations) {
                    const poste_ac = this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique.poste_affectations.filter((p: any) => p.id.toString() === splitKey[0] && p.entity_id.toString() === splitKey[1])
                    for (const c in poste_ac) {
                        poste_aff.push(poste_ac[c])
                    }
                }
            }
            if (this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user) {
                for (const p in this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user.postes) {
                    const poste_lo = this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user.postes.filter((p: any) => p.id.toString() === splitKey[0])
                    for (const l in poste_lo) {
                        poste_aff.push(poste_lo[l])
                    }
                    // poste_aff += this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user.postes.filter((p: any) => p.id.toString() === splitKey[0])
                }
            }

            if (this.remunerations_ids[r].length !== 0) {
                for (const b in this.remunerations_ids[r]) {
                    if (!this.modeSaisieQuantitesReelle) {
                        if (this.remunerations_ids[r][b] && this.volume_select[r][b]) {
                            remunerations.push({ poste_id: splitKey[0], bareme_remuneration_id: this.remunerations_ids[r][b], quantite: this.volume_select[r][b], entity_type: poste_aff[0].entity_type, entity_id: poste_aff[0].entity_id, options: poste_aff[0].options_affectes })
                            this.errorInterface = ''
                        } else {
                            this.errorInterface = 'Merci de renseigner un volume pour chacun des barèmes de rémunération sélectionnés'
                        }
                    } else if (poste_aff && poste_aff.length > 0) {
                        if (this.remunerations_ids[r][b] && this.volume_select[r][b] && this.volume_reel_select[r][b]) {
                            remunerations.push({
                                poste_id: splitKey[0],
                                bareme_remuneration_id: this.remunerations_ids[r][b],
                                quantite: this.volume_select[r][b],
                                quantite_reelle: this.volume_reel_select[r][b],
                                entity_type: poste_aff[0].entity_type,
                                entity_id: poste_aff[0].entity_id,
                                options: poste_aff[0].options_affectes
                            })
                            this.errorInterface = ''
                        } else {
                            this.errorInterface = 'Merci de renseigner un volume de quantité réelle pour chacun des barèmes de rémunération sélectionnés'
                        }
                    }
                }
            }
        }

        if (this.errorInterface === '') {
            let datas: {}
            if (!this.modeSaisieQuantitesReelle) {
                datas = {
                    contrat_type_id: this.contrat_type_select.id,
                    bareme_remunerations: remunerations,
                    etat: Etat.ETAT_INVISIBLE
                }
            } else if (remunerations.length > 0) {
                datas = {
                    reel: 1,
                    contrat_type_id: this.contrat_type_select.id,
                    bareme_remunerations: remunerations
                }
            } else {
                for (let i = 0; i < this.poste_affectations_filter.length; i++) {
                    remunerations.push({
                        poste_id: this.poste_affectations_filter[i].id,
                        bareme_remuneration_id: this.remunerations_ids[this.poste_affectations_filter[i].id + '_' + this.poste_affectations_filter[i].entity_id][i],
                        quantite: this.volume_select[this.poste_affectations_filter[i].id + '_' + this.poste_affectations_filter[i].entity_id][i],
                        quantite_reelle: this.volume_reel_select[this.poste_affectations_filter[i].id + '_' + this.poste_affectations_filter[i].entity_id][i],
                        entity_type: this.poste_affectations_filter[i].entity_type,
                        entity_id: this.poste_affectations_filter[i].entity_id,
                        options: this.poste_affectations_filter[i].options
                    })
                }

                datas = {
                    reel: 1,
                    contrat_type_id: this.contrat_type_select.id,
                    bareme_remunerations: remunerations
                }
            }

            if (this.modeCreation) {
                this.$store.dispatch('dossieradministratif/storeContrat', { dossier_id: this.$store.state.dossieradministratif.dossierAdministratifSelect.id, payload: datas })
                    .then(async () => {
                        await this.$store.dispatch('dossieradministratif/getDossierAdministratif', this.$store.state.dossieradministratif.dossierAdministratifSelect.id)
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                        this.reset(false)
                        this.initContratsTypes()
                    })
                    .catch((error) => {
                        this.$store.state.dossieradministratif.error = error
                    })
            } else if (this.modeEdition || this.modeSaisieQuantitesReelle) {
                this.$store.dispatch('dossieradministratif/updateContrat', {
                    dossier_id: this.$store.state.dossieradministratif.dossierAdministratifSelect.id,
                    contrat_id: this.$store.state.dossieradministratif.contratSelect.id,
                    payload: datas
                })
                    .then(async () => {
                        this.modeCreation = true
                        this.modeSaisieQuantitesReelle = false
                        this.modeEdition = false
                        this.$store.state.dossieradministratif.contratSelect = null
                        this.contrat_type_select = null
                        this.remuneration_select = []
                        this.volume_select = []

                        await this.$store.dispatch('dossieradministratif/getDossierAdministratif', this.$store.state.dossieradministratif.dossierAdministratifSelect.id)
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                        this.reset(false)
                    })
            }
        }
    }

    // reset
    reset (close: boolean) {
        this.back()
        this.$store.state.dossieradministratif.contratSelect = null
        this.contrat_type_select = null
        this.remuneration_select = []
        this.remunerations_ids = []
        this.volume_select = []
        this.volume_reel_select = []
        this.modeCreation = true
        this.modeSaisieQuantitesReelle = false
        this.modeEdition = false
        this.modePreview = false
        this.confirmDeleteContrat = false
        this.confirmPublication = false
        this.confirmDepublication = false
        this.disabledBoutonPublier = false
        this.$store.state.dossieradministratif.error = null
        this.$store.state.avenant.error_avenant = null
        this.showGestionAvenants = false
        this.confirmDeleteAvenant = false
        this.confirmPublicationAvenant = false
        this.confirmDepublicationAvenant = false
        this.disabledBoutonPublierAvenant = false

        if (close) {
            this.$props.row.contrats = this.$store.state.dossieradministratif.dossierAdministratifSelect.contrats
            this.$emit('close', this.$props.row)
        }
    }

    // reset
    annulerEditionSaisie () {
        this.$store.state.dossieradministratif.contratSelect = null
        this.contrat_type_select = null
        this.remuneration_select = []
        this.remunerations_ids = []
        this.volume_select = []
        this.volume_reel_select = []
        this.modeCreation = true
        this.modeSaisieQuantitesReelle = false
        this.modeEdition = false
        this.modePreview = false
        this.confirmDeleteContrat = false
        this.confirmPublication = false
        this.confirmDepublication = false
        this.disabledBoutonPublier = false
        this.confirmPublicationAvenant = false
        this.confirmDepublicationAvenant = false
        this.disabledBoutonPublierAvenant = false
        this.$store.state.dossieradministratif.error = null

        this.initContratsTypes()
        this.initRemunerations()
    }

    // Retourne le libelle du type de bareme de remuneration
    getLibelleTypeRemuneration (bareme_id: number) {
        return getTypeRemuneration(bareme_id)
    }

    // Retourne une chaine des postes d'affectations de l'intervenant
    getStringPostes (postes: Array<any>) {
        let retour = ''
        for (let i = 0; i < postes.length; i++) {
            if (i > 0) {
                retour += ', '
            }
            retour += postes[i].name
            if (postes[i].options_affectes && postes[i].options_affectes !== '[]' && postes[i].options_affectes !== null) {
                const opt = JSON.parse(postes[i].options_affectes)
                retour += ' - ' + opt[0]
            }

            // Recupere le nom de l'épreuve ou de la matiere
            if (postes[i].entity_type === EntityType.ET_EPREUVE_CORRECTION) {
                const epr = this.$store.state.epreuve.epreuvesCorrections.filter((ep: EpreuveInterface) => ep.id === postes[i].entity_id)
                if (epr.length !== 0) {
                    let concs = ''
                    for(let j = 0; j < epr[0].concours.length; j++) {
                        if(j > 0) {
                            concs += ', '
                        }
                        concs += epr[0].concours[j].name
                    }
                    retour += ' sur ' + concs + ' - ' + epr[0].name
                }
            }

            if (postes[i].entity_type === EntityType.ET_MATIERE) {
                const matiere = this.$store.state.matiere.matieres.filter((mat: MatiereInterface) => mat.id === postes[i].entity_id)
                retour += ' de ' + matiere[0].name
            }
        }
        return retour
    }

    // Retourne le poste affectation formaté
    getStringPoste (poste: any) {
        let retour = ''
        retour += poste.name
        if (poste.options_affectes && poste.options_affectes !== '[]' && poste.options_affectes !== null) {
            const opt = JSON.parse(poste.options_affectes)
            retour += ' - ' + opt[0]
        }
        // Recupere le nom de l'épreuve ou de la matiere
        if (poste.entity_type === EntityType.ET_EPREUVE_CORRECTION) {
            const epr = this.$store.state.epreuve.epreuvesCorrections.filter((epr: EpreuveInterface) => epr.id === poste.entity_id)
            if (epr.length !== 0) {
                let concs = ''
                for(let j = 0; j < epr[0].concours.length; j++) {
                    if(j > 0) {
                        concs += ', '
                    }
                    concs += epr[0].concours[j].name
                }
                retour += ' sur ' + concs + ' - ' + epr[0].name
            }
        }

        if (poste.entity_type === EntityType.ET_MATIERE) {
            const matiere = this.$store.state.matiere.matieres.filter((mat: MatiereInterface) => mat.id === poste.entity_id)
            retour += ' de ' + matiere[0].name
        }

        return retour
    }

    // Retourne le nom d'un contrat type
    getName (contrattype_id: number) {
        const contrat = this.$store.state.contrattype.contrattypes.filter((ct: ContratTypeInterface) => ct.id === contrattype_id)
        return contrat[0] ? contrat[0].name : ''
    }

    // Passes un contrat en saisie des quantités réelles réalisées par l'intervenant
    saisieQuantitesContrat (contrat: any) {
        // \\('Dans saisieQuantitesContrat')
        this.$store.state.dossieradministratif.error = null
        this.poste_affectations_filter = []
        this.modeSaisieQuantitesReelle = true
        this.modeCreation = false
        this.modeEdition = false
        this.showCreation = true
        this.$store.dispatch('dossieradministratif/getContrat', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id })
    }

    // Passes un contrat en edition
    editionContrat (contrat: any) {
        // \\('Dans editionContrat')
        this.modeCreation = false
        this.modeSaisieQuantitesReelle = false
        this.modeEdition = true
        this.showCreation = true
        this.$store.state.dossieradministratif.error = null
        this.$store.dispatch('dossieradministratif/getContrat', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id })
    }

    // Passes un avenant en édition
    editionAvenant (contrat: any, avenant: any) {
        this.showGestionAvenants = true
        this.$store.commit('avenant/SET_AVENANT_SELECT', avenant)

        this.$store.dispatch('dossieradministratif/getContrat', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id }).then(() => {
            this.contrat_for_avenant = contrat
        })
    }

    // Aperçu du contrat en pdf
    previewContrat (contrat: any) {
        // console.log('previewContrat')
        this.source_doc = ''
        this.modePreview = true
        this.$store.dispatch('dossieradministratif/getContrat', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id }).then(() => {
            this.$store.dispatch('dossieradministratif/getContratPDF', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id }).then(() => {
                this.source_doc = base64ToArrayBuffer(this.$store.state.dossieradministratif.source_pdf)
            })
        })
    }

    // Aperçu de l'avenant au format PDF
    previewAvenant (contrat: any, avenant: any) {
        // console.log('previewAvenant')
        this.source_doc = ''
        this.modePreviewAvenant = true
        this.contrat_for_avenant = contrat
        this.$store.commit('avenant/SET_AVENANT_SELECT', avenant)
        this.$store.dispatch('avenant/getAvenantPDF', { contrat_id: contrat.id, avenant_id: avenant.id }).then(() => {
            this.source_doc = base64ToArrayBuffer(this.$store.state.avenant.source_pdf_avenant)
        })
    }

    // Ferme le preview pdf
    back () {
        this.modePreview = false
        this.$store.state.dossieradministratif.source_pdf = ''
        this.modePreviewAvenant = false
        this.$store.state.avenant.source_pdf_avenant = ''
    }

    // Show confirmation publication
    showConfirmationPublication () {
        this.confirmPublication = !this.confirmPublication
    }

    // Show confirmation depublication
    showConfirmationDepublication () {
        this.confirmDepublication = !this.confirmDepublication
    }

    // Publier le contrat
    publierContrat (contrat: any, submit: number) {
        const datas = {
            submit: submit
        }

        this.errorInterface = ''

        this.$store.dispatch('dossieradministratif/updateContrat', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id, payload: datas })
            .then(async () => {
                if (this.errorInterface === '') {
                    await this.$store.dispatch('dossieradministratif/getDossierAdministratif', contrat.dossier_administratif_id)
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                    this.reset(false)
                } else {
                    this.confirmPublication = false
                    this.disabledBoutonPublier = true
                }
            })
    }

    // Refresh la liste des contrats aprés enregistrement avenant
    refreshContrats (dossier_administratif_id: any) {
        this.$store.dispatch('dossieradministratif/getDossierAdministratif', dossier_administratif_id).then(() => {
            this.reset(false)
        })
    }

    // Affiche la confirmation de suppression d'un contrat
    showConfirmDeleteContrat (contrat: any) {
        this.confirmDeleteContrat = !this.confirmDeleteContrat
        if (contrat) {
            this.$store.dispatch('dossieradministratif/getContrat', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id })
        }
    }

    // Affiche la confirmation de suppression d'un avenant
    showConfirmDeleteAvenant (contrat: any, avenant: any) {
        this.datas_delete = {
            avenant: avenant,
            contrat: contrat
        }
        this.confirmDeleteAvenant = !this.confirmDeleteAvenant
    }

    // Suppression d'un contrat
    deleteContrat () {
        this.errorInterface = ''
        this.$store.dispatch('dossieradministratif/deleteContrat', { dossier_id: this.$store.state.dossieradministratif.contratSelect.dossier_administratif_id, contrat_id: this.$store.state.dossieradministratif.contratSelect.id }).then(() => {
            this.confirmDeleteContrat = false
            this.$store.dispatch('dossieradministratif/getDossierAdministratif', this.$store.state.dossieradministratif.contratSelect.dossier_administratif_id).then(() => {
                this.reset(false)
                this.initContratsTypes()
            })
        })
    }

    // Suppression d'un avenant
    deleteAvenant () {
        this.errorInterface = ''
        this.$store.dispatch('avenant/deleteAvenant', { contrat_id: this.datas_delete.contrat.id, avenant_id:  this.datas_delete.avenant.id })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                this.refreshContrats(this.datas_delete.contrat.dossier_administratif_id)
            })
    }

    // Filtres les contrats types selon les postes affectations
    filterContratTypes () {
        for (const p in this.postes_restants) {
            const poste_affectation = this.postes_restants[p]
            if (!this.postes_restants[p].associe) {
                for (const c in this.$store.state.contrattype.contrattypes) {
                    const contrattype = this.$store.state.contrattype.contrattypes[c]
                    const contrattypes_filter = contrattype.associations.filter((ass: any) => ass.poste_id === poste_affectation.id)
                    for (const ctf in contrattypes_filter) {
                        if (contrattypes_filter[ctf]) {
                        // Check si le contrat type n'est pas deja dans la collection
                            const isPush = this.contrats_types_filter.filter((ct: any) => ct.id === contrattype.id)
                            if (isPush.length === 0) {
                                this.contrats_types_filter.push(contrattype)
                            }
                        }
                    }
                }
            }
        }
    }

    // Création des listes de rémunérations selon les postes affectations
    initRemunerations () {
        const postes_affectations: any = []
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique) {
            for (const p in this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique.poste_affectations) {
                postes_affectations.push(this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique.poste_affectations[p])
            }
        }
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user) {
            for (const p in this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user.postes) {
                postes_affectations.push(this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user.postes[p])
            }
        }
        if (this.contrat_type_select && this.contrat_type_select.associations && postes_affectations) {
            this.poste_affectations_filter = []
            for (const p in postes_affectations) {
                const poste_affectation = postes_affectations[p]
                const option_poste_affectation: any = this.getOptionsPosteAffectation(poste_affectation)
                const match_contrattype_poste = this.contrat_type_select.associations.filter((ass: any) => ass.poste_id === poste_affectation.id)
                // const match_contrattype_poste = this.contrat_type_select.associations // .filter((ass: any) => ass.poste_id === poste_affectation.id)

                if (match_contrattype_poste.length !== 0) {
                    this.poste_affectations_filter.push(poste_affectation)
                    this.remuneration_select[poste_affectation.id + '_' + poste_affectation.entity_id] = []
                    this.remunerations_ids[poste_affectation.id + '_' + poste_affectation.entity_id] = []
                    this.volume_select[poste_affectation.id + '_' + poste_affectation.entity_id] = []
                    this.volume_reel_select[poste_affectation.id + '_' + poste_affectation.entity_id] = []
                    this.quantite_reelle[poste_affectation.id + '_' + poste_affectation.entity_id] = []
                    if (!this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id]) {
                        this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id] = []
                    }

                    // Charge les barèmes de remunerations selon le poste et les options
                    this.loadBaremesRemunerations(poste_affectation, option_poste_affectation)
                    // Refiltre les baremes selon les épreuves
                    this.filtreBaremeByEpreuve(poste_affectation)
                    // Refiltre les rémunérations selon les rémunérations réellement affectées
                    this.filtreByRemunerationsReelles(poste_affectation)

                    // Ajoute la quantité au champ de saisie
                    for (let i = 0; i < this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].length; i++) {
                        this.volume_select[poste_affectation.id + '_' + poste_affectation.entity_id][i] = parseFloat(poste_affectation.quantite)
                    }
                }
            }


            // Si on est dans le cadre d'une modification, on initialise les données
            if (this.$store.state.dossieradministratif.contratSelect && this.$store.state.dossieradministratif.contratSelect.bareme_remunerations) {
                for (const b in this.$store.state.dossieradministratif.contratSelect.bareme_remunerations) {
                    if (this.remunerations_filter.length !== 0) {
                        // console.log(this.remunerations_filter)
                        if (this.remunerations_filter[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id]) {
                            const index = this.remunerations_filter[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id].findIndex((bar: BaremeRemunerationInterface) => bar.id === this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].id)

                            this.remuneration_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][index] = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].id
                            if (!this.remunerations_ids[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id]) {
                                this.remunerations_ids[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id] = []
                            }
                            this.remunerations_ids[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][index] = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].id
                            if (!this.volume_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id]) {
                                this.volume_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id] = []
                            }
                            this.volume_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][index] = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite

                            if (!this.volume_reel_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id]) {
                                this.volume_reel_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id] = []
                            }
                            if (!this.quantite_reelle[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id]) {
                                this.quantite_reelle[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id] = []
                            }

                            // Init de la quantité réelle
                            if (this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite_reelle  !== null) {
                                this.volume_reel_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][index] = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite_reelle
                                this.quantite_reelle[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][index] = true
                            } else {
                                this.volume_reel_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][index] = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite
                                this.quantite_reelle[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][index] = false
                            }
                        } else if (!this.showGestionAvenants) {
                            // console.log(this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id + ' pas trouvé')
                            let posteFound = false
                            for (let i = 0; i < this.poste_affectations_filter.length; i++) {
                                if (this.poste_affectations_filter[i].id === this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id) {
                                    posteFound = true
                                }
                            }

                            if (posteFound) {
                                for (let j = 0; j < this.$store.state.poste.postes.length; j++) {
                                    if (this.$store.state.poste.postes[j].id === this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id) {
                                        this.poste_affectations_filter.push(this.poste_affectations_filter[0])
                                        // this.poste_affectations_filter[this.poste_affectations_filter.length - 1].associe = this.$store.state.poste.postes[j].associe
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].context = this.$store.state.poste.postes[j].context
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].context_type = this.$store.state.poste.postes[j].context_type
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].entity_id = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].quantite = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].quantite_reelle = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite_reelle
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].entity_type = this.$store.state.poste.postes[j].entity_type
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].id = this.$store.state.poste.postes[j].id
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].name = this.$store.state.poste.postes[j].name
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].name_plural = this.$store.state.poste.postes[j].name_plural
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].options = this.$store.state.poste.postes[j].options
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].options_affectation = this.$store.state.poste.postes[j].options_affectation
                                        // this.poste_affectations_filter[this.poste_affectations_filter.length - 1].options_affectes = this.$store.state.poste.postes[j].options_affectes
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].regroupement = this.$store.state.poste.postes[j].regroupement
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].type = this.$store.state.poste.postes[j].type
                                        this.poste_affectations_filter[this.poste_affectations_filter.length - 1].type_passation = this.$store.state.poste.postes[j].type_passation
                                    }
                                }
                            } else {
                                for (let j = 0; j < this.$store.state.poste.postes.length; j++) {
                                    if (this.$store.state.poste.postes[j].id === this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id) {
                                    // this.poste_affectations_filter.push(this.$store.state.poste.postes[j])
                                        const postTemp = {
                                            context: this.$store.state.poste.postes[j].context,
                                            context_type: this.$store.state.poste.postes[j].context_type,
                                            bareme_remuneration_id: this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].id,
                                            // quantite: this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite,
                                            entity_id: this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id,
                                            entity_type: this.$store.state.poste.postes[j].entity_type,
                                            id: this.$store.state.poste.postes[j].id,
                                            name: this.$store.state.poste.postes[j].name,
                                            name_plural: this.$store.state.poste.postes[j].name_plural,
                                            options: this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].options,
                                            options_affectation: this.$store.state.poste.postes[j].options_affectation,
                                            regroupement: this.$store.state.poste.postes[j].regroupement,
                                            type: this.$store.state.poste.postes[j].type,
                                            type_passation: this.$store.state.poste.postes[j].type_passation
                                        }
                                        this.poste_affectations_filter.push(postTemp)
                                    }
                                }
                            }


                            if (!this.volume_reel_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id]) {
                                this.volume_reel_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id] = []
                            }
                            // this.volume_reel_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][0] = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite_reelle

                            if (!this.quantite_reelle[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id]) {
                                this.quantite_reelle[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id] = []
                            }
                            // this.quantite_reelle[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][0] = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite_reelle

                            if (!this.remunerations_ids[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id]) {
                                this.remunerations_ids[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id] = []
                            }
                            this.remunerations_ids[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][0] = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].id

                            if (!this.volume_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id]) {
                                this.volume_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id] = []
                            }
                            this.volume_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][0] = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite

                            // Init de la quantité réelle
                            if (this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite_reelle !== null) {
                                this.volume_reel_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][0] = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite_reelle
                                this.quantite_reelle[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][0] = true
                            } else {
                                this.volume_reel_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][0] = this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].quantite
                                this.quantite_reelle[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id][0] = false
                            }

                            this.remunerations_filter[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id] = {}
                            this.remunerations_filter[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id] = [this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b]]
                            // this.remuneration_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id] =  {}
                            this.remuneration_select[this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].poste_id + '_' + this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].entity_id] = [this.$store.state.dossieradministratif.contratSelect.bareme_remunerations[b].id]
                        }
                    }
                }

                // on check les postes associés dans les poste_affectations_filter pour conserver les non associés en modification
                for (const pa in this.postes_associes) {
                    const index = this.poste_affectations_filter.findIndex((pr: any) => pr.id === this.postes_associes[pa].id && pr.entity_id === this.postes_associes[pa].entity_id)
                    if (this.poste_affectations_filter[index]) {
                        this.poste_affectations_filter[index].associe = true
                    }
                }
                // Check ce qui est reaffichable en modification : bareme deja coché
                for (const rs in this.remuneration_select) {
                    const splitKey = rs.split('_')
                    let index: any = null
                    if (this.context === PosteType.TYPE_LOGISTIQUE) {
                        index = this.poste_affectations_filter.findIndex((pa: any) => pa.id.toString() === splitKey[0])
                    } else if (this.context === PosteType.TYPE_PEDAGOGIQUE) {
                        index = this.poste_affectations_filter.findIndex((pa: any) => pa.id.toString() === splitKey[0] && pa.entity_id.toString() === splitKey[1])
                    }

                    if (this.remuneration_select[rs].length !== 0 && this.poste_affectations_filter[index]) {
                        this.poste_affectations_filter[index].associe = false
                    }
                }
            }
        }
    }

    // Retourne les options si le poste affectation en a de sélectionné
    getOptionsPosteAffectation (poste_affectation: any) {
        let option_poste_affectation = null
        if (poste_affectation.options_affectes && poste_affectation.options_affectes !== '[]') {
            const json_options = JSON.parse(poste_affectation.options_affectes)
            const tab_options: Array<any> = []
            for (const o in json_options) {
                if(!tab_options.includes(json_options[o])) {
                    tab_options.push(json_options[o])
                }
            }
            option_poste_affectation = JSON.stringify(tab_options)
        }

        return option_poste_affectation
    }

    // Charge les barèmes de remunerations selon le poste et les options
    loadBaremesRemunerations (poste_affectation: any, option_poste_affectation: any) {
        let is_peda = false
        if (poste_affectation.type === PosteType.TYPE_PEDAGOGIQUE) {
            is_peda = true
        }

        const baremes_by_poste: any = []

        // Isole les barèmes par poste
        if (this.contrat_type_select && this.contrat_type_select.associations) {
            for (const a in this.contrat_type_select.associations) {
                for (const b in this.$store.state.baremeremuneration.baremesRemuneration) {
                    const has_postes_id = this.$store.state.baremeremuneration.baremesRemuneration[b].associations.find((ass: any) => ass.poste_id === poste_affectation.id)
                    if (has_postes_id !== undefined) {
                        baremes_by_poste.push(this.$store.state.baremeremuneration.baremesRemuneration[b])
                        // console.log({ id: this.$store.state.baremeremuneration.baremesRemuneration[b].id, name: this.$store.state.baremeremuneration.baremesRemuneration[b].nature + ' ' + this.$store.state.baremeremuneration.baremesRemuneration[b].prestation })
                    }
                }
            }
        }

        // Si le poste est de type PEDAGOGIQUE
        if (is_peda) {
            // Filtre des baremes par entity_type / entity_id
            for (const bp in baremes_by_poste) {
                const has_entity_type_id = baremes_by_poste[bp].associations.find((ass: any) =>
                    (ass.entity_type === poste_affectation.entity_type) &&
                    (ass.entity_id === poste_affectation.entity_id || ass.entity_id === null) &&
                    ((option_poste_affectation !== null && JSON.stringify(ass.options) === option_poste_affectation) || ((option_poste_affectation === null && ass.options === null) || (option_poste_affectation === null && ass.options.length === 0)))
                )

                if (has_entity_type_id !== undefined) {
                    const isPush = this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].filter((br: any) => br.id.toString() === baremes_by_poste[bp].id.toString())
                    if (isPush.length === 0) {
                        this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].push(baremes_by_poste[bp])
                    }
                } else {
                    // Pas de correspondance sur la combinaison entity_type / entity_id
                    // Recherche par matiere si on a une epreuve correction
                    let ep_correction: any = null
                    if (poste_affectation.entity_id !== null && poste_affectation.entity_type === EntityType.ET_EPREUVE_CORRECTION) {
                        ep_correction = this.$store.getters['epreuve/getEpreuveCorrectionById'](poste_affectation.entity_id)
                        if (ep_correction) {
                            const has_entity_id_matiere = baremes_by_poste[bp].associations.find((ass: any) => (ass.entity_type === EntityType.ET_MATIERE) && (ass.entity_id === ep_correction.matiere_id))
                            if (has_entity_id_matiere !== undefined) {
                                const isPush = this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].filter((br: any) => br.id === baremes_by_poste[bp].id)
                                if (isPush.length === 0) {
                                    this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].push(baremes_by_poste[bp])
                                }
                            } else {
                                // Recherche par entity_type matière et entity_id null
                                let has_entity_type_matiere = null
                                has_entity_type_matiere = baremes_by_poste[bp].associations.find((ass: any) => (ass.entity_type === EntityType.ET_MATIERE && ass.entity_id === null && ass.options === null))

                                if (has_entity_type_matiere !== undefined) {
                                    const isPush = this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].filter((br: any) => br.id === baremes_by_poste[bp].id)
                                    if (isPush.length === 0) {
                                        this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].push(baremes_by_poste[bp])
                                    }
                                } else {
                                    // Cherche par épreuve de correction
                                    const has_entity_id_ep = baremes_by_poste[bp].associations.find((ass: any) => (ass.entity_type === EntityType.ET_EPREUVE_CORRECTION) && (ass.entity_id === ep_correction.id))
                                    if (has_entity_id_ep !== undefined) {
                                        const isPush = this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].filter((br: any) => br.id === baremes_by_poste[bp].id)
                                        if (isPush.length === 0) {
                                            this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].push(baremes_by_poste[bp])
                                        }
                                    }
                                }
                            }
                        }
                    } else if (poste_affectation.entity_id !== null && poste_affectation.entity_type === EntityType.ET_MATIERE) {
                        const has_entity_id_matiere = baremes_by_poste[bp].associations.find((ass: any) =>
                            (ass.entity_type === EntityType.ET_MATIERE) && (ass.entity_id === poste_affectation.entity_id || ass.entity_id === null) &&
                            ((option_poste_affectation !== null && JSON.stringify(ass.options) === option_poste_affectation) || ((option_poste_affectation === null && ass.options === null) || (option_poste_affectation === null && ass.options.length === 0)))
                        )

                        if (has_entity_id_matiere !== undefined) {
                            const isPush = this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].filter((br: any) => br.id === baremes_by_poste[bp].id)
                            if (isPush.length === 0) {
                                this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].push(baremes_by_poste[bp])
                            }
                        }
                    }
                }
            }
        } else {
            // Poste logistique
            for (const bp in baremes_by_poste) {
                const isPush = this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].filter((br: any) => br.id === baremes_by_poste[bp].id)
                if (isPush.length === 0) {
                    this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].push(baremes_by_poste[bp])
                }
            }
        }
    }

    // Filtre la collection des baremes selon les epreuves affectées
    filtreBaremeByEpreuve (poste_affectation: any) {
        // \\('Dans filtreBaremeByEpreuve')
        const baremes_filter = []
        for (const b in this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id]) {
            let filter = this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id][b].associations.filter((b: any) => b.entity_type === poste_affectation.entity_type && (b.entity_id === null || b.entity_id === poste_affectation.entity_id))
            if (filter.length === 0 && poste_affectation.entity_type === EntityType.ET_EPREUVE_CORRECTION && this.context === PosteType.TYPE_PEDAGOGIQUE) {
                // On vérifie sur la matiere si un bareme est compatible
                const epreuve = this.$store.state.epreuve.epreuvesCorrections.filter((e: any) => e.id === poste_affectation.entity_id)
                if (epreuve.length !== 0) {
                    filter = this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id][b].associations.filter((b: any) => b.entity_type === EntityType.ET_MATIERE && (b.entity_id === epreuve[0].matiere_id || b.entity_id === null))
                }
            } else if (this.context === PosteType.TYPE_LOGISTIQUE) {
                baremes_filter.push(this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id][b])
            }
            if (filter.length !== 0) {
                baremes_filter.push(this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id][b])
            }
        }
        this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id] = baremes_filter
    }

    filtreByRemunerationsReelles (poste_affectation: any) {
        // \\('Dans filtreBaremeByEpreuveRealisee')

        const remunerations_reelles = []

        for (let i = 0; i < this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id].length; i++) {
            const filter = this.remuneration_select[poste_affectation.id + '_' + poste_affectation.entity_id].indexOf(this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id][i].id)
            if (filter.length !== 0) {
                remunerations_reelles.push(this.remunerations_filter[poste_affectation.id + '_' + poste_affectation.entity_id][i])
            }
        }

        this.remunerations_reelles_filter = remunerations_reelles
    }

    // Ouvre l'éditeur de création d'un avenant au contrat sélectionné
    create_avenant (contrat: any) {
        this.showGestionAvenants = true
        this.$store.dispatch('dossieradministratif/getContrat', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id }).then(() => {
            this.initRemunerations()
            this.$store.commit('avenant/SET_AVENANT_SELECT', null)
            this.contrat_for_avenant = contrat
        })
    }

    // Show confirmation publication de l'avenant
    showConfirmationPublicationAvenant () {
        this.confirmPublicationAvenant = !this.confirmPublicationAvenant
    }

    // Show confirmation depublication
    showConfirmationDepublicationAvenant () {
        this.confirmDepublicationAvenant = !this.confirmDepublicationAvenant
    }

    // Publier l'avenant
    publierAvenant (contrat: any, avenant: any, submit: number) {
        const datas = {
            submit: submit
        }

        this.errorInterface = ''

        this.$store.dispatch('avenant/updateAvenant', { contrat_id: avenant.avenantable_id, avenant_id: avenant.id, payload: datas })
            .then(() => {
                if (this.errorInterface === '') {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    this.confirmPublicationAvenant = false
                    this.refreshContrats(contrat.dossier_administratif_id)
                } else {
                    this.confirmPublicationAvenant = false
                    this.disabledBoutonPublierAvenant = true
                }
            })
    }

    // Check si une création d'avenant est possible
    check_create_possible (contrat: any) {
        if (contrat.avenants && contrat.avenants.length === 0) {
            return true
        } else {
            return !!(contrat.avenants && contrat.avenants.find((a: any) => a.etat === Etat.ETAT_VALIDE));
        }
    }

    // Retourne une classe texte barré si l'avenant est remplacé par un autre
    is_annule(contrat: any, avenant_id: number) {
        if (contrat.avenants.find((a: any) => a.avenant_id === avenant_id)) {
            const avenant_remplace = contrat.avenants.find((a: any) => a.avenant_id === avenant_id)
            // Un avenant est présent pour remplacer l'autre, il est remplaçant si son état est au minimum publié
            return avenant_remplace.etat >= Etat.ETAT_VIERGE;
        } else {
            return false
        }
    }

    // Retourne vrai ou faux si un avenant est considéré remplaçant
    is_remplace (contrat: any, avenant: any) {
        if (avenant.avenant_id !== null) {
            const avenant_remplace = contrat.avenants.find((a: any) => a.avenant_id === avenant.avenant_id)
            return avenant_remplace.etat >= Etat.ETAT_VIERGE;
        } else { return false }
    }

    mounted () {
        this.$store.state.avenant.error_avenant = null
        if (this.$store.state.epreuve.epreuvesCorrections.length === 0) {
            const params: any = {}
            this.$store.dispatch('epreuve/getEpreuvesCorrections', { isPrecedente: false, filters: params })
        }

        if (this.$store.state.matiere.matieres.length === 0) {
            this.$store.dispatch('matiere/getMatieres')
        }

        for (let i = 0; i < this.$store.state.auth.user.parameters.length; i ++) {
            if (this.$store.state.auth.user.parameters[i].code === 'codeClient') {
                this.codeClient = this.$store.state.auth.user.parameters[i].value
            }
        }
    }
}
