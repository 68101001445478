

































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import myUpload from 'vue-image-crop-upload'
import { TheMask } from 'vue-the-mask'
import { Etat } from '@/types/DossierAdministratif'
import { Ability } from '@/types/Ability'
import PhoneInput from '@/components/Tools/PhoneInput.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        myUpload,
        TheMask,
        PhoneInput,
        'font-awesome-icon': FontAwesomeIcon
    },
    methods: {
        ...mapMutations('dossieradministratif', ['UPDATE_DATAS_NAME', 'UPDATE_DATAS_FIRST_NAME', 'UPDATE_DATAS_CIVILITY', 'UPDATE_DATAS_BIRTH_NAME', 'UPDATE_DATAS_BIRTH_DATE', 'UPDATE_DATAS_BIRTH_PLACE', 'UPDATE_DATAS_NUMERO_SECU', 'UPDATE_DATAS_EMAIL', 'UPDATE_DATAS_TELEPHONE', 'UPDATE_DATAS_ADDRESS', 'UPDATE_DATAS_NATIONALITY']),
        ...mapActions('dossieradministratif', ['storeAvatar'])
    },
    computed: {
        ...mapGetters('dossieradministratif', ['avatar', 'loading', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class FormPerso extends Vue {
    @Prop() dossierAdministratifSelect?: any;
    @Prop() readOnly?: boolean;
    @Prop() mode?: string;
    @Prop() visible?: boolean;

    Ability = Ability

    @Watch('visible')
    visibleHandler () {
        if (this.visible) {
            this.activeEdition()
        } else {
            this.cancelEdition()
        }
    }

    @Watch('dossierAdministratifSelect')
    init () {
        this.initDatasPerso()
    }

    @Watch('editResponsable')
    majVueTelInputProps () {
        if (this.$props.dossierAdministratifSelect && this.$props.dossierAdministratifSelect.datas_perso) {
            this.initDatasPerso()
        }
    }

    beforeMount () {
        if (this.$props.dossierAdministratifSelect && this.$props.dossierAdministratifSelect.datas_perso) {
            this.initDatasPerso()
        }
    }

    showCrop = false
    name = ''
    params = {
        name: 'avatar'
    };

    headers = {
        smail: '*_~'
    };

    payload = {
        documenttype_id: 1,
        document: '',
        name: ''
    }

    civility = ''
    name_intervenant = ''
    first_name = ''
    birth_name = ''
    birth_date = ''
    birth_place = ''
    email = ''
    telephone = ''
    phoneNumber = ''
    address = ''
    numero_secu = ''
    nationality = ''
    was_validated = false
    datas_init = false
    Etat = Etat
    showMessageRejet = false
    messageRejet = ''
    editResponsable = false
    isInvalid = ''
    btnValidDisabled = false
    normalizedNumber = ''

    old_datas = {
        civilite: '',
        nom: '',
        prenom: '',
        nom_naissance: '',
        date_naissance: '',
        lieu_naissance: '',
        courriel: '',
        telephone: '',
        adresse: '',
        numero_secu: '',
        nationality: ''
    }

    /** Met à jour la valeur locale du numéro de téléphone saisi retourné par le composant PhoneInput */
    updatePhoneNumberInputData () {
        if ((this.$refs.phoneInput as any)) {
            if ((this.$refs.phoneInput as any).infosPhoneInput) {
                if ((this.$refs.phoneInput as any).infosPhoneInput.isValidatedPhoneNumber) {
                    this.$store.commit('dossieradministratif/UPDATE_DATAS_TELEPHONE', (this.$refs.phoneInput as any).infosPhoneInput.formattedPhoneNumber)
                    this.isInvalid = ''
                    this.telephone = (this.$refs.phoneInput as any).infosPhoneInput.formattedPhoneNumber
                } else {
                    this.telephone = ''
                }
            }
        }
    }

    /** Remet à jour la valeur du numéro de téléphone dans le composant PhoneInput */
    updatePhoneNumberInputModel () {
        if ((this.$refs.phoneInput as any)) {
            (this.$refs.phoneInput as any).inputPhoneNumber = this.phoneNumber
        }
    }

    // Recuperation du champ
    updateChamps (e: any) {
        switch (e.target.name) {
            case 'name':
                this.$store.commit('dossieradministratif/UPDATE_DATAS_NAME', e.target.value.toUpperCase())
                this.name_intervenant = e.target.value.toUpperCase()
                break
            case 'first_name':
                this.$store.commit('dossieradministratif/UPDATE_DATAS_FIRST_NAME', e.target.value)
                this.first_name = e.target.value
                break
            case 'civility':
                this.$store.commit('dossieradministratif/UPDATE_DATAS_CIVILITY', e.target.value)
                this.civility = e.target.value
                break
            case 'birth_name':
                this.$store.commit('dossieradministratif/UPDATE_DATAS_BIRTH_NAME', e.target.value.toUpperCase())
                this.birth_name = e.target.value.toUpperCase()
                break
            case 'birth_date':
                this.$store.commit('dossieradministratif/UPDATE_DATAS_BIRTH_DATE', e.target.value)
                this.birth_date = e.target.value
                break
            case 'birth_place':
                this.$store.commit('dossieradministratif/UPDATE_DATAS_BIRTH_PLACE', e.target.value)
                this.birth_place = e.target.value
                break
            case 'nationality':
                this.$store.commit('dossieradministratif/UPDATE_DATAS_NATIONALITY', e.target.value)
                this.nationality = e.target.value
                break
            case 'email':
                this.$store.commit('dossieradministratif/UPDATE_DATAS_EMAIL', e.target.value)
                this.email = e.target.value
                break
            case 'address':
                this.$store.commit('dossieradministratif/UPDATE_DATAS_ADDRESS', e.target.value)
                this.address = e.target.value
                break
            default:
                break
        }
    }

    // Maj du numero de securite sociale
    updateChampsNumSecu (value: string) {
        this.$store.commit('dossieradministratif/UPDATE_DATAS_NUMERO_SECU', value)
        this.numero_secu = value
    }

    // Maj du numero de telephone
    updateChampsTelephone (e: any) {
        const value = e.target.value
        this.normalizedNumber = String(value).replace(/[^+0-9]/g, '').replace(/^00/, '+').replace(/^0/, '+33')
        let prefix: string

        if (value[0] === '+') {
            prefix = value[0] + '' + value[1] + '' + value[2] + '' + value[3]
        } else {
            prefix = value[0] + '' + value[1]
        }

        this.$store.state.dossieradministratif.error = null
        if ((value.length === 10 && prefix === '06') || (value.length === 10 && prefix === '07') || (value.length === 12 && prefix === '+336') || (value.length === 12 && prefix === '+337')) {
            this.$store.commit('dossieradministratif/UPDATE_DATAS_TELEPHONE', this.normalizedNumber)
            this.telephone = this.normalizedNumber
            this.isInvalid = ''
            this.btnValidDisabled = false
        } else if ((value.length === 10 && prefix !== '06') || (value.length === 10 && prefix !== '07') || (value.length === 12 && prefix !== '+336') || (value.length === 12 && prefix !== '+337')) {
            this.$store.state.dossieradministratif.error = { telephone: 'Le préfixe du numéro de téléphone est incorrect' }
            this.telephone = ''
            this.isInvalid = 'is-invalid'
            this.btnValidDisabled = true
        } else {
            this.telephone = '___'
            this.isInvalid = 'is-invalid'
            this.$store.state.dossieradministratif.error = { telephone: 'Le format du numéro de téléphone est incorrect.' }
        }
    }

    // Init des datas persos pour le validator
    initDatasPerso () {
        this.civility = (this.dossierAdministratifSelect.datas_perso && this.dossierAdministratifSelect.datas_perso.civility ? this.dossierAdministratifSelect.datas_perso.civility : '')
        this.name_intervenant = (this.dossierAdministratifSelect.datas_perso && this.$props.dossierAdministratifSelect.datas_perso.name ? this.$props.dossierAdministratifSelect.datas_perso.name : '')
        this.first_name = (this.dossierAdministratifSelect.datas_perso && this.$props.dossierAdministratifSelect.datas_perso.first_name ? this.$props.dossierAdministratifSelect.datas_perso.first_name : '')
        this.birth_name = (this.dossierAdministratifSelect.datas_perso && this.$props.dossierAdministratifSelect.datas_perso.birth_name ? this.$props.dossierAdministratifSelect.datas_perso.birth_name : '')
        this.birth_date = (this.dossierAdministratifSelect.datas_perso && this.$props.dossierAdministratifSelect.datas_perso.birth_date ? this.$props.dossierAdministratifSelect.datas_perso.birth_date : '')
        this.birth_place = (this.dossierAdministratifSelect.datas_perso && this.$props.dossierAdministratifSelect.datas_perso.birth_place ? this.$props.dossierAdministratifSelect.datas_perso.birth_place : '')
        this.nationality = (this.dossierAdministratifSelect.datas_perso && this.$props.dossierAdministratifSelect.datas_perso.nationality ? this.$props.dossierAdministratifSelect.datas_perso.nationality : '')
        this.numero_secu = (this.dossierAdministratifSelect.datas_perso && this.$props.dossierAdministratifSelect.datas_perso.numero_secu ? this.$props.dossierAdministratifSelect.datas_perso.numero_secu : '')
        this.email = (this.dossierAdministratifSelect.datas_perso && this.$props.dossierAdministratifSelect.datas_perso.email ? this.$props.dossierAdministratifSelect.datas_perso.email : '')

        this.phoneNumber = (this.dossierAdministratifSelect.datas_perso && this.$props.dossierAdministratifSelect.datas_perso.telephone ? this.$props.dossierAdministratifSelect.datas_perso.telephone : '')
        this.updatePhoneNumberInputModel()

        this.address = (this.dossierAdministratifSelect.datas_perso && this.$props.dossierAdministratifSelect.datas_perso.address ? this.$props.dossierAdministratifSelect.datas_perso.address : '')
        this.datas_init = true
    }

    // Sauvegarde des datas perso formulaire
    saveDatasPerso () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.state.dossieradministratif.error = null
        this.$store.dispatch('dossieradministratif/updateDossierAdministratif', { dossier_id: this.$props.dossierAdministratifSelect.id, payload: { datas_perso: JSON.stringify(this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_perso) } })
            .then(() => {
                if (!this.$store.state.dossieradministratif.error) {
                    if (this.$props.mode === 'responsable') {
                        this.editResponsable = false
                    } else {
                        // Fermeture de la popup aprés validation
                        const closeInfosPersos: HTMLElement = document.getElementsByClassName('closeInfosPersos')[0] as HTMLElement
                        closeInfosPersos.click()
                    }

                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                    this.activeEdition()
                    this.cancelEdition()
                    this.$emit('edition_form_perso', false)
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Valide ou rejete le formulaire
    updateEtatForm (etat: Etat) {
        let params
        if (etat === Etat.ETAT_REJETE) {
            params = { etat_form_perso: etat, commentaire_rejet_cle: 'datas_perso', commentaire_rejet_message: this.messageRejet }
        } else {
            params = { etat_form_perso: etat }
        }
        this.$store.dispatch('dossieradministratif/updateDossierAdministratif', { dossier_id: this.$props.dossierAdministratifSelect.id, payload: params }).then(() => {
            this.showMessageRejet = false
        })
    }

    // Validation de remplissage des champs
    submitInfosPerso (e: Event) {
        e.preventDefault()
        this.was_validated = true

        this.updatePhoneNumberInputData()

        if (!this.datas_init) {
            this.initDatasPerso()
        }

        // Check le remplissage des champs
        if (this.civility !== '' && this.name_intervenant !== '' && this.first_name !== '' && this.birth_date !== '' && this.birth_place !== '' && this.nationality !== '' && this.numero_secu !== '' && this.email !== '' && this.address !== '' && this.telephone !== '') {
        // Enregistre les données
            this.saveDatasPerso()
            this.was_validated = false
        } else {
        // reinit les données pour la prochaine tentative de validation
            // this.initDatasPerso()
        }
    }

    // Methodes pour le crop image
    toggleCrop () {
        this.showCrop = !this.showCrop
    }

    cropSuccess (imgDataUrl: string) {
        // console.log('-------- crop success --------')
        // console.log('field: ' + field)
        this.$store.state.dossieradministratif.avatar = imgDataUrl

        this.payload.document = imgDataUrl
        this.payload.documenttype_id = 1
        this.payload.name = 'avatar'

        this.$store.dispatch('dossieradministratif/storeAvatar', { dossier_id: this.$props.dossierAdministratifSelect.id, payload: this.payload })
    }

    cropUploadSuccess () {
        // console.log('-------- upload success --------')
        // console.log(jsonData)
        // console.log('field: ' + field)
    }

    cropUploadFail () {
        // console.log('-------- upload fail --------')
        // console.log(status)
        // console.log('field: ' + field)
    }

    // Ouvre une zone de saisie d'un message de rejet
    addMessageRejet () {
        this.showMessageRejet = true
    }

    // Ferme la zone de message de rejet
    cancelMessageRejet () {
        this.showMessageRejet = false
    }

    // Activation de l'édition pour le responsable
    activeEdition () {
        this.$emit('edition_form_perso', true)
        if (this.mode === 'responsable') {
            this.editResponsable = true
        }
        this.old_datas.civilite = this.dossierAdministratifSelect.datas_perso.civility
        this.old_datas.nom = this.dossierAdministratifSelect.datas_perso.name
        this.old_datas.prenom = this.dossierAdministratifSelect.datas_perso.first_name
        this.old_datas.nom_naissance = this.dossierAdministratifSelect.datas_perso.birth_name
        this.old_datas.date_naissance = this.dossierAdministratifSelect.datas_perso.birth_date
        this.old_datas.lieu_naissance = this.dossierAdministratifSelect.datas_perso.birth_place
        this.old_datas.courriel = this.dossierAdministratifSelect.datas_perso.email
        this.old_datas.telephone = this.dossierAdministratifSelect.datas_perso.telephone
        this.old_datas.adresse = this.dossierAdministratifSelect.datas_perso.address
        this.old_datas.numero_secu = this.dossierAdministratifSelect.datas_perso.numero_secu
        this.old_datas.nationality = this.dossierAdministratifSelect.datas_perso.nationality
    }

    // fermeture de la popup
    closeInfosPersos () {
        this.isInvalid = ''
        this.btnValidDisabled = false
        this.was_validated = false
        this.$store.state.dossieracademique.error = null
        this.initDatasPerso()

        // const closeInfosPersos: HTMLElement = document.getElementsByClassName('closeInfosPersos')[0] as HTMLElement
        // closeInfosPersos.click()
    }

    // Annule l'édition en cours du formulaire
    cancelEdition () {
        // On remet les datas d'init
        this.$store.state.dossieradministratif.error = null
        this.isInvalid = ''
        this.btnValidDisabled = false
        this.$store.commit('dossieradministratif/UPDATE_DATAS_CIVILITY', this.old_datas.civilite)
        this.$store.commit('dossieradministratif/UPDATE_DATAS_NAME', this.old_datas.nom)
        this.$store.commit('dossieradministratif/UPDATE_DATAS_FIRST_NAME', this.old_datas.prenom)
        this.$store.commit('dossieradministratif/UPDATE_DATAS_BIRTH_NAME', this.old_datas.nom_naissance)
        this.$store.commit('dossieradministratif/UPDATE_DATAS_BIRTH_DATE', this.old_datas.date_naissance)
        this.$store.commit('dossieradministratif/UPDATE_DATAS_BIRTH_PLACE', this.old_datas.lieu_naissance)
        this.$store.commit('dossieradministratif/UPDATE_DATAS_EMAIL', this.old_datas.courriel)
        this.$store.commit('dossieradministratif/UPDATE_DATAS_ADDRESS', this.old_datas.adresse)
        this.$store.commit('dossieradministratif/UPDATE_DATAS_TELEPHONE', this.old_datas.telephone)
        this.$store.commit('dossieradministratif/UPDATE_DATAS_NUMERO_SECU', this.old_datas.numero_secu)
        this.$store.commit('dossieradministratif/UPDATE_DATAS_NATIONALITY', this.old_datas.nationality)

        this.dossierAdministratifSelect.datas_perso.civility = this.old_datas.civilite
        this.dossierAdministratifSelect.datas_perso.name = this.old_datas.nom
        this.dossierAdministratifSelect.datas_perso.first_name = this.old_datas.prenom
        this.dossierAdministratifSelect.datas_perso.birth_name = this.old_datas.nom_naissance
        this.dossierAdministratifSelect.datas_perso.birth_date = this.old_datas.date_naissance
        this.dossierAdministratifSelect.datas_perso.birth_place = this.old_datas.lieu_naissance
        this.dossierAdministratifSelect.datas_perso.email = this.old_datas.courriel
        this.dossierAdministratifSelect.datas_perso.telephone = this.old_datas.telephone
        this.dossierAdministratifSelect.datas_perso.address = this.old_datas.adresse
        this.dossierAdministratifSelect.datas_perso.numero_secu = this.old_datas.numero_secu
        this.dossierAdministratifSelect.datas_perso.nationality = this.old_datas.nationality

        this.initDatasPerso()

        this.editResponsable = false
        this.$emit('edition_form_perso', false)
    }
}
