
































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import { Decision } from '@/types/DossierAcademique'

@Component({
    computed: {
        ...mapGetters('session', [
            'sessions',
            'loading'
        ]),
        ...mapState('dossieracademique', ['dossiersSelections', 'error', 'countError', 'dossiersOpen', 'dossiersAcademiquesOuvertParEtat']),
        ...mapState('dossieradministratif', ['dossiersSelections', 'error', 'countError', 'dossiersOpen', 'dossiersAdministratifsOuvertParEtat']),
        ...mapState('session', ['sessionSelect']),
        ...mapState('matiere', ['matiereSelect'])
    },
    methods: {
        ...mapActions('dossieracademique', ['getDossiersAcademiques', 'ouvrirDossierAcademique'])
    }
})
export default class PopupAddDossierMulti extends Vue {
    // DATAS
    name_intervenant = ''
    first_name = ''
    email = ''
    session_id = ''
    showError = false
    retour_serveur = null
    count_decision_aucune = 0
    count_decision_reconduit = 0
    count_decision_suspension = 0
    count_decision_ajourne = 0
    decision_aucune = Decision.DECISION_AUCUNE
    decision_suspendu = Decision.DECISION_SUSPENDU
    decision_reconduit = Decision.DECISION_RETENU
    decision_ajourne = Decision.DECISION_AJOURNE
    decision_selections = []
    envoiEnCours = false

    @Prop() isNewCandidature?: boolean;
    @Prop() context?: string;
    @Prop() prop_session_id?: number;
    @Prop() prop_matiere_id?: number;
    @Prop() params?: any;
    @Prop() mode?: string;

    @Watch('dossiersOpen')
    checkDossierOpen () {
        this.retour_serveur = this.$store.state.dossieracademique.dossiersOpen
    }

    @Watch('dossiersSelections')
    checkDossiersSelections () {
        this.count_decision_aucune = 0
        this.count_decision_reconduit = 0
        this.count_decision_suspension = 0
        this.count_decision_ajourne = 0
        this.dispachByStatut()
        this.getOpen()
    }

    @Watch('params')
    reloadWithParams () {
        this.getOpen()
    }
    // METHODS

    updateChamps (e: any) {
        switch (e.target.name) {
            case 'name':
                this.name_intervenant = e.target.value
                break
            case 'first_name':
                this.first_name = e.target.value
                break
            case 'email':
                this.email = e.target.value
                break
            case 'session_id':
                this.session_id = e.target.value
                break
            default:
                break
        }
    }

    // Ouverture en masse de dossier en fonction de la liste filtrée
    ouvrirDossier (e: Event) {
        this.envoiEnCours = true
        e.preventDefault()
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Publication des dossiers en cours ...', infosToaster)
        if (this.context === 'administratif') {
            this.$store.dispatch('dossieradministratif/ouvrirDossiersAdministratifs', this.$props.params)
                .then(() => {
                    this.retour_serveur = this.$store.state.dossieradministratif.dossiersOpen
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast(this.retour_serveur + ' dossier(s) publié(s) avec succès !', succesToaster)
                    this.$store.dispatch('dossieradministratif/getDossiersAdministratifs', this.params)
                    this.getOpen()
                    this.envoiEnCours = false
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('dossieracademique/ouvrirDossierAcademique', { session_id: this.$store.state.session.sessionSelect.id, matiere_id: this.$store.state.matiere.matiereSelect.id, isNewCandidature: this.isNewCandidature, payload: { decision_selections: this.decision_selections } })
                .then(() => {
                    this.retour_serveur = this.$store.state.dossieracademique.dossiersOpen
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast(this.retour_serveur + ' dossier(s) publié(s) avec succès !', succesToaster)
                    this.$store.dispatch('dossieracademique/getDossiersSelections', { session_id: this.$store.state.session.sessionSelect.id, matiere_id: this.$store.state.matiere.matiereSelect.id })
                    this.getOpen()
                    this.envoiEnCours = false
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    // Close la popup
    closeModal () {
        this.retour_serveur = null
        this.decision_selections = []
    }

    // Dispatch par statut
    dispachByStatut () {
        if (this.context === 'academique') {
            this.count_decision_aucune = 0
            this.count_decision_reconduit = 0
            this.count_decision_ajourne = 0
            this.count_decision_suspension = 0
            for (let i = 0; i < this.$store.state.dossieracademique.dossiersAcademiquesOuvertParEtat.length; i++) {
                switch (this.$store.state.dossieracademique.dossiersAcademiquesOuvertParEtat[i].decision_selection) {
                    case Decision.DECISION_AUCUNE:
                        this.count_decision_aucune = this.$store.state.dossieracademique.dossiersAcademiquesOuvertParEtat[i].count
                        break
                    case Decision.DECISION_RETENU:
                        this.count_decision_reconduit = this.$store.state.dossieracademique.dossiersAcademiquesOuvertParEtat[i].count
                        break
                    case Decision.DECISION_AJOURNE:
                        this.count_decision_ajourne = this.$store.state.dossieracademique.dossiersAcademiquesOuvertParEtat[i].count
                        break
                    case Decision.DECISION_SUSPENDU:
                        this.count_decision_suspension = this.$store.state.dossieracademique.dossiersAcademiquesOuvertParEtat[i].count
                        break
                    default:
                        // this.count_decision_aucune = this.$store.state.dossieracademique.dossiersAcademiquesOuvertParEtat[i].count
                        break
                }
            }
        }
    }

    getOpen () {
        if (this.context === 'academique') {
            this.$store.dispatch('dossieracademique/getDossiersAcademiquesOuvert', { session_id: this.prop_session_id, matiere_id: this.prop_matiere_id, isNewCandidature: this.isNewCandidature }).then(() => {
                this.dispachByStatut()
            })
        } else if (this.context === 'administratif') {
            if (this.$props.mode === 'sps') {
                this.$props.params.section = 'sps'
            } else {
                this.$props.params.section = 'sol'
            }

            this.$store.dispatch('dossieradministratif/getDossiersAdministratifsOuvert', { filter: this.$props.params }).then(() => {
                this.dispachByStatut()
                this.envoiEnCours = false
            })
        }
    }

    mounted () {
        if (this.context === 'academique') {
            this.getOpen()
        }
    }
}

