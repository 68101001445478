



























































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Vue } from 'vue-property-decorator'
import { getError } from '@/utils/helpers'
import AuthService from '@/services/AuthService'
import { Ability } from '@/types/Ability'
import { mapGetters, mapState } from 'vuex'
import store from '@/store'

@Component({
    computed: {
        ...mapState('auth', ['error', 'from_racine']),
        ...mapGetters('auth', ['is_intervenant_centre'])
    },
    beforeRouteEnter(to, from, next) {
        if (from.fullPath === '/') {
            store.commit('auth/SET_FROM_RACINE', true)
        }

        next()
    }
})
export default class Login extends Vue {
    email = ''
    password = ''
    error_text = ''
    code_two_factor = ''
    showModal2factor = false
    errorotp = false
    useRecovery = false
    showMessageCodeRecoUsed = false
    appName = process.env.VUE_APP_NAME

    async login() {
        const payload = {
            email: this.email,
            password: this.password
        }
        this.error_text = ''
        this.$store.commit('auth/SET_ERROR', null)
        try {
            const retour = await AuthService.login(payload)
            if (retour.data.two_factor) {
                this.showModal2factor = true
            } else {
                this.suiteLogin()
            }
        } catch (error) {
            this.error_text = getError(error)
        }
    }

    async twoFactorHandler() {
        let payload: {}
        if (this.useRecovery) {
            payload = {
                recovery_code: this.code_two_factor
            }
        } else {
            payload = {
                code: this.code_two_factor
            }
        }
        try {
            const retour = await AuthService.twoFactorChallenge(payload)
            if (this.useRecovery) {
                this.showModal2factor = false
                this.showMessageCodeRecoUsed = true
                return
            }
            this.suiteLogin()
        } catch (error) {
            this.errorotp = getError(error)
            this.errorotp = true
        }
    }

    closeModal2Factor() {
        this.showModal2factor = false
    }

    usedRecoverySeenHandler() {
        this.showMessageCodeRecoUsed = false
        this.suiteLogin()
    }

    async suiteLogin() {
        const authUser = await this.$store.dispatch('auth/getAuthUser')
        if (authUser) {
            const userId = this.$store.getters['auth/authUser'].id
            if (this.$router) {
                /* Le if controle si un utilisateur a deja visité une page de type dashboard et le positionne direcement dessus */
                if (
                    localStorage &&
                    localStorage['dernierDashboardUtilise' + userId] &&
                    typeof localStorage['dernierDashboardUtilise' + userId] === 'string' &&
                    localStorage['dernierDashboardUtilise' + userId] !== ''
                ) {
                    this.$router.push(localStorage['dernierDashboardUtilise' + userId]).catch(() => {
                        console.log('')
                    })
                }
                /*  controle des droits et redirection en fonction si pas de local Storage */
                // redirection pour les RH
                else if (
                    this.$store.getters['auth/can'](Ability.RH_SPS_VIEW) ||
                    this.$store.getters['auth/can'](Ability.RH_DOSADM_VIEW) ||
                    this.$store.getters['auth/can'](Ability.RH_CONTRAT_VIEW) ||
                    this.$store.getters['auth/can'](Ability.RH_FRAIS_VIEW) ||
                    this.$store.getters['auth/can'](Ability.GC_CENTER_VIEW) ||
                    this.$store.getters['auth/can'](Ability.RH_SOL_VIEW) ||
                    this.$store.getters['auth/can'](Ability.GC_CONVENTION_VIEW)
                ) {
                    this.$router.push('/dashboard').catch(() => {
                        console.log('')
                    })
                }
                // redirection pour Oraux
                else if (
                    this.$store.getters['auth/can'](Ability.ORAL_AFEX_VIEW) ||
                    this.$store.getters['auth/can'](Ability.ORAL_PREPA_VIEW) ||
                    this.$store.getters['auth/can'](Ability.ORAL_PLAN_VIEW) ||
                    this.$store.getters['auth/can'](Ability.CAND_VIEW) ||
                    this.$store.getters['auth/can'](Ability.GC_CENTER_VIEW)
                ) {
                    this.$router.push('/dashboardOraux').catch(() => {
                        console.log('')
                    })
                }
                // redirection concours
                else if (
                    this.$store.getters['auth/can'](Ability.CAND_VIEW) ||
                    this.$store.getters['auth/can'](Ability.DLB_PREPJURY_VIEW) ||
                    this.$store.getters['auth/can'](Ability.SIGN_CONTRAT_VIEW) ||
                    this.$store.getters['auth/can'](Ability.SIGN_PVA_VIEW) ||
                    this.$store.getters['auth/can'](Ability.SIGN_LETTRE_VIEW) ||
                    this.$store.getters['auth/can'](Ability.SIGN_CONVENTION_VIEW) ||
                    this.$store.getters['auth/can'](Ability.SIGN_CONVENTIONANNEXE_VIEW) ||
                    this.$store.getters['auth/can'](Ability.SIGN_FHRECAP_VIEW) ||
                    this.$store.getters['auth/can'](Ability.SIGN_RECLAMATION_VIEW) ||
                    this.$store.getters['auth/can'](Ability.SIGN_AVENANT_VIEW) ||
                    this.$store.getters['auth/can'](Ability.SIGN_AMENAGEMENT_VIEW)
                ) {
                    this.$router.push('/dashboardConcours').catch(() => {
                        console.log('')
                    })
                }
                // dashboard intervenants
                else if (
                    this.$store.getters['auth/can'](Ability.RH_SPS_DOS_OWN_MANAGE) ||
                    this.$store.getters['auth/can'](Ability.RH_ADM_DOS_OWN_MANAGE) ||
                    this.$store.getters['auth/is_intervenant_centre'] ||
                    this.$store.getters['auth/can'](Ability.INTERV_PLANIF_OWN) ||
                    this.$store.getters['auth/can'](Ability.RH_FICHEHORAIRE_OWN_SIGN)
                ) {
                    this.$router.push('/dashboard_i').catch(() => {
                        console.log('')
                    })
                } else {
                    // redirection si pas de resultat precedent
                    this.$router.push('/dashboard').catch(() => {
                        console.log('')
                    })
                }
            }
        } else {
            const error = Error(
                "Impossible de récupérer l'utilisateur après la connexion, vérifiez les paramètres de votre API."
            )
            error.name = "Récupération de l'utilisateur"
            throw error
        }
    }
}
