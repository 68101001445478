





















import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        FontAwesomeIcon
    },
    methods: {
        ...mapMutations('dossieracademique', ['UPDATE_DATAS_FONCTION_ACTUELLE', 'UPDATE_DATAS_FONCTION_ACTUELLE_AUTRE'])
    }
})
export default class SelectFonction extends Vue {
    @Prop() selectedValue?: string
    @Prop() other?: string
    @Prop() readOnly?: boolean

    options = [
        { id: 'Attaché d\'enseignement et de recherche', value: 'Attaché d\'enseignement et de recherche' },
        { id: 'Chargé de recherche', value: 'Chargé de recherche' },
        { id: 'Chercheur', value: 'Chercheur' },
        { id: 'Contractuel du secteur public', value: 'Contractuel du secteur public' },
        { id: 'Directeur de recherche', value: 'Directeur de recherche' },
        { id: 'Enseignant', value: 'Enseignant' },
        { id: 'Enseignant chercheur', value: 'Enseignant chercheur' },
        { id: 'Inspecteur pédagogique.', value: 'Inspecteur pédagogique' },
        { id: 'Ingénieur', value: 'Ingénieur' },
        { id: 'Maître assistant', value: 'Maître assistant' },
        { id: 'Maître auxiliaire', value: 'Maître auxiliaire' },
        { id: 'Maître de conférence', value: 'Maître de conférence' },
        { id: 'Professeur agrégé', value: 'Professeur agrégé' },
        { id: 'Professeur certifié', value: 'Professeur certifié' },
        { id: 'Professeur de chaire supérieure', value: 'Professeur de chaire supérieure' },
        { id: 'Professeur d\'université', value: 'Professeur d\'université' },
        { id: 'Professeur', value: 'Professeur' },
        { id: 'Retraité', value: 'Retraité' },
        { id: 'Salarié Secteur Privé', value: 'Salarié Secteur Privé' },
        { id: 'Autre', value: 'Autre' }
    ]

    // Event change sur le select
    updateDatasPro (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLTextAreaElement
            if (target.name === 'function') {
                this.$store.commit('dossieracademique/UPDATE_DATAS_FONCTION_ACTUELLE', target.value)
            }
            if (target.name === 'function_other') {
                this.$store.commit('dossieracademique/UPDATE_DATAS_FONCTION_ACTUELLE_AUTRE', target.value)
            }
        }
    }
}
