






































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import { Etat } from '@/types/DossierAcademique'
import { PosteContext, PosteInterface } from '@/types/Poste'
import { Ability } from '@/types/Ability'

@Component({
    components: {
        ItemIntervenant
    },
    methods: {
        ...mapActions('matieres', ['getMatieres']),
        ...mapActions('poste', ['getPostes']),
        ...mapMutations('poste', ['SET_POSTES_SELECTED', 'SET_MAJ_LISTE_POSTES'])
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('poste', ['postes_souhaits', 'postes_affectations']),
        ...mapGetters('matiere', ['matieres']),
        ...mapGetters('poste', ['postes', 'listePostes', 'postes_souhaits', 'postes_affectations']),
        // ...mapActions('dossieracademique', ['getConcoursDossierAcademique']),
        ...mapGetters('dossieracademique', ['posteSelect', 'dacaConcours']),
        ...mapState('dossieracademique', ['error'])
    }
})
export default class FormCandidature extends Vue {
    @Prop() dossierAcademiqueSelect?: any;
    @Prop() dossier_id?: number;
    @Prop() readOnly?: boolean;
    @Prop() mode?: string;
    @Prop() visible?: boolean;
    @Prop({default: 'CCMP'}) mode_formulaire?: string

    @Watch('postes_souhaits')
    setListePostes () {
        if (this.$store.state.poste.postes_souhaits.length !== undefined && this.$store.state.poste.postes_souhaits.length !== 0) {
            this.$store.commit('poste/SET_LISTE_POSTES', this.$store.state.poste.postes_souhaits)
        }
    }

    @Watch('visible')
    visibleHandler () {
        this.showModal = this.visible
        if(this.mode !== 'intervenant'){
            if (this.visible) {
                this.activeEdition()
            } else {
                this.cancelEdition()
            }
        }
    }



    filiere_ids: Array<any> = []
    matiere_ids: Array<any> = []
    poste_ids: Array<any> = []

    Ability = Ability
    posteErrors = false
    matiere = ''
    filiere = ''
    etat_vierge = Etat.ETAT_VIERGE
    etat_depose = Etat.ETAT_DEPOSE
    Etat = Etat
    editResponsable = false
    old_datas: any = {
        filiere_id: '',
        matiere_id: '',
        postes_selected: [],
        filiere_ids:  [],
        matiere_ids: [],
        poste_ids: []
    }

    showModal: any = false

    inputHandler(e:any) {
        e.preventDefault()
        e.stopImmediatePropagation()
        e.stopPropagation()
        return
    }

    labelHandler(e: any, from:string, id: number) {
        e.preventDefault()
        e.stopImmediatePropagation()
        e.stopPropagation()
        this.ligneClickHandler(e, from, id)
        return

    }

    ligneClickHandler(e: any, from:string, id: number) {
        //e.preventDefault()
        e.stopImmediatePropagation()
        e.stopPropagation()
        let index = -1
        switch(from) {
            case 'concour':
                index = this.filiere_ids.indexOf(id)
                if(index > -1) {
                    this.filiere_ids.splice(index, 1)
                } else {
                    this.filiere_ids.push(id)
                }
                break
            case 'matiere':
                index = this.matiere_ids.indexOf(id)
                if(index > -1) {
                    this.matiere_ids.splice(index, 1)
                } else {
                    this.matiere_ids.push(id)
                }
                break
            case 'poste':
                index = this.poste_ids.indexOf(id)
                if(index > -1) {
                    this.poste_ids.splice(index, 1)
                } else {
                    this.poste_ids.push(id)
                }
                break
            default: break
        }
    }

    // Selection de la matière
    selectMatiere (any: any) {
        this.$store.state.dossieracademique.posteSelect.matiere_id = any.target.value
    }

    // Selection du concours
    selectConcour (any: any) {
        this.$store.state.dossieracademique.posteSelect.concour_id = (any.target.value === '' ? null : any.target.value)
    }

    // Sélection d'un poste
    selectPoste (index: number, selected: boolean) {
        if (!this.readOnly || this.editResponsable) {
            this.$store.state.poste.listePostes[index].selected = selected
            this.$store.state.poste.listePostes[index].etat = selected ? this.etat_depose : this.etat_vierge

            // Push ou retire selon la selection les postes
            if (this.$store.state.poste.listePostes[index].selected) {
                // push
                this.$store.state.dossieracademique.posteSelect.postes_id.push(this.$store.state.poste.listePostes[index].id)
            } else {
                for (const p in this.$store.state.dossieracademique.posteSelect.postes_id) {
                    if (this.$store.state.dossieracademique.posteSelect.postes_id[p] === this.$store.state.poste.listePostes[index].id) {
                        this.$store.state.dossieracademique.posteSelect.postes_id.splice(p, 1)
                    }
                }
            }
        }
    }

    // Sauvegarde du poste, filière et matière choisi par l'intervenant
    savePoste () {
        /*

        filiere_ids
        matiere_ids
        poste_ids

        */

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$store.state.dossieracademique.error = null

        if (this.mode_formulaire === 'CCMP') {
            if (this.$store.state.dossieracademique.posteSelect.concour_id === 0) {
                this.$store.state.dossieracademique.posteSelect.concour_id = null
            }
            // Check si tous les éléments sont remplis avant d'envoyer
            if (this.$store.state.dossieracademique.posteSelect.matiere_id !== '' && this.$store.state.dossieracademique.posteSelect.postes_id.length !== 0) {
                this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
                this.$store.dispatch('dossieracademique/updateDossierAcademique', {
                    dossier_id: this.$store.state.dossieracademique.dossierAcademiqueSelect.id,
                    payload: {
                        filiere_ids: [ parseInt(this.$store.state.dossieracademique.posteSelect.concour_id) ],
                        matiere_ids: [ parseInt(this.$store.state.dossieracademique.posteSelect.matiere_id) ],
                        poste_ids: this.$store.state.dossieracademique.posteSelect.postes_id.map((p: any) => parseInt(p))
                    }
                })
                    .then(() => {
                        this.posteErrors = false

                        if (this.$props.mode === 'responsable') {
                            this.editResponsable = false
                        } else {
                            this.editResponsable = false
                            // Fermeture de la popup aprés validation
                            const closeSetPoste: HTMLElement = document.getElementsByClassName('closeSetPoste')[0] as HTMLElement
                            closeSetPoste.click()
                        }

                        if (!this.$store.state.dossieracademique.error) {
                            const idSucces = 't_succes_' + Math.random()
                            const succesToaster = {
                                id: idSucces,
                                toaster: 'b-toaster-top-right',
                                variant: 'success',
                                noCloseButton: true,
                                fade: true,
                                autoHideDelay: 5000
                            }
                            this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                            this.activeEdition()
                            this.cancelEdition()
                            this.$emit('edition_form_candidature', false)
                        } else {
                            this.posteErrors = true
                        }
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            } else {
                this.posteErrors = true
            }
        } else if (this.mode_formulaire === 'CCS') {
            if(this.filiere_ids.length > 0 && this.matiere_ids.length > 0 && this.poste_ids.length > 0) {

                this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

                const payload = {
                    filiere_ids: this.filiere_ids,
                    matiere_ids: this.matiere_ids,
                    poste_ids: this.poste_ids

                }
                this.$store.dispatch('dossieracademique/updateDossierAcademique', { dossier_id: this.$store.state.dossieracademique.dossierAcademiqueSelect.id, payload: payload })
                    .then(() => {
                        this.posteErrors = false

                        if (this.$props.mode === 'responsable') {
                            this.editResponsable = false
                        } else {
                            this.editResponsable = false
                            // Fermeture de la popup aprés validation
                            const closeSetPoste: HTMLElement = document.getElementsByClassName('closeSetPoste')[0] as HTMLElement
                            closeSetPoste.click()
                        }

                        if (!this.$store.state.dossieracademique.error) {
                            const idSucces = 't_succes_' + Math.random()
                            const succesToaster = {
                                id: idSucces,
                                toaster: 'b-toaster-top-right',
                                variant: 'success',
                                noCloseButton: true,
                                fade: true,
                                autoHideDelay: 5000
                            }
                            this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                            this.activeEdition()
                            this.cancelEdition()
                            this.$emit('edition_form_candidature', false)
                        } else {
                            this.posteErrors = true
                        }
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            }  else {
                this.posteErrors = true
                this.$bvToast.hide(idInfo)
            }
        }
    }

    // Activation de l'édition pour le responsable
    activeEdition () {
        /*
        filiere_ids
        matiere_ids
        poste_ids
        */

        this.editResponsable = true
        this.$emit('edition_form_candidature', true)
        if (this.mode_formulaire === 'CCS') {
            this.old_datas.filiere_ids = []
            this.old_datas.matiere_ids = []
            this.old_datas.poste_ids = []

            this.old_datas.filiere_ids = [...this.filiere_ids]
            this.old_datas.matiere_ids = [...this.matiere_ids]
            this.old_datas.poste_ids = [...this.poste_ids]
        } else if (this.mode_formulaire === 'CCMP') {
            this.old_datas.filiere_id = this.dossierAcademiqueSelect.concour_id
            this.old_datas.matiere_id = this.dossierAcademiqueSelect.matiere_id
            this.old_datas.postes_selected = this.dossierAcademiqueSelect.postes.filter((p: PosteInterface) => p.context_type === PosteContext.CONTEXT_SOUHAIT)
        }
    }

    // fermeture de la popup
    closePopupCandidature () {
        this.showModal = false
    }

    // Annule l'édition en cours du formulaire
    cancelEdition () {
        /*
        filiere_ids
        matiere_ids
        poste_ids
        */

        // On remet les datas d'init
        if (this.mode_formulaire === 'CCS') {
            this.filiere_ids = []
            this.matiere_ids = []
            this.poste_ids = []
            this.filiere_ids = [...this.old_datas.filiere_ids]
            this.matiere_ids = [...this.old_datas.matiere_ids]
            this.poste_ids = [...this.old_datas.poste_ids]
        } else if (this.mode_formulaire === 'CCMP') {
            this.$store.state.dossieracademique.posteSelect.matiere_id = this.old_datas.matiere_id
            this.$store.state.dossieracademique.posteSelect.concour_id = this.old_datas.filiere_id
            this.$store.state.dossieracademique.posteSelect.postes_id = []
            this.$store.commit('poste/SET_POSTES_SELECTED', this.old_datas.postes_selected)

            // Reparcours les postes
            this.$store.state.poste.listePostes.forEach((e: any) => {
                const findId = this.old_datas.postes_selected.find((d: any) => d.id === e.id)

                if (findId !== undefined) {
                    e.selected = true
                    e.etat = Etat.ETAT_DEPOSE
                    this.$store.state.dossieracademique.posteSelect.postes_id.push(e.id)
                }
            })

            this.$store.commit('poste/SET_MAJ_LISTE_POSTES', this.$store.state.poste.listePostes)
        }
        this.editResponsable = false
        this.$emit('edition_form_candidature', false)
    }

    mounted () {
        this.editResponsable = false
        if (this.mode_formulaire === 'CCS') {
            this.filiere_ids = []
            for(let i = 0; i < this.dossierAcademiqueSelect.concours.length; i++) {
                this.filiere_ids.push(this.dossierAcademiqueSelect.concours[i].id)
            }
            this.matiere_ids = []
            for(let i = 0; i < this.dossierAcademiqueSelect.matieres.length; i++) {
                this.matiere_ids.push(this.dossierAcademiqueSelect.matieres[i].id)
            }
            this.poste_ids = []
            for(let j = 0; j < this.dossierAcademiqueSelect.postes.length; j++) {
                this.poste_ids.push(this.dossierAcademiqueSelect.postes[j].id)
            }
        }
    }
}
