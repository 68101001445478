


















































import { Vue, Component } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'

@Component({
    computed: {
        ...mapState('dossieradministratif', ['dossiersSelections', 'error', 'countError', 'nb_relance', 'dossiersAdministratifsOuvertParEtat']),
        ...mapGetters('poste', ['postes_affectations'])
    },
    methods: {
        ...mapActions('dossieradministratif', ['relaunchOrdreMissionDossiersAdministratifs'])
    }
})
export default class PopupRelaunchOrdreMission extends Vue {
    // DATAS
    name_intervenant = ''
    first_name = ''
    email = ''
    session_id = ''
    showError = false
    retour_serveur = null
    envoiEnCours = false
    selection_postes = []

    // METHODS

    // Ouverture en masse de dossier en fonction de la liste filtrée
    relaunchContract (e: Event) {
        this.envoiEnCours = true
        e.preventDefault()

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Envoi en cours ...', infosToaster)

        this.$store.dispatch('dossieradministratif/relaunchOrdreMissionDossiersAdministratifs', { payload: { postes_id: this.selection_postes } })
            .then(() => {
                this.$store.dispatch('dossieradministratif/getDossiersAdministratifs')
                this.retour_serveur = this.$store.state.dossieradministratif.nb_relance
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Envoi terminé !', succesToaster)
                this.envoiEnCours = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Close la popup
    closeModal () {
        this.retour_serveur = null
        this.selection_postes = []
    }
}
