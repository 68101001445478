











































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapState, mapGetters } from 'vuex'
import { Etat, getEtatSpec } from '@/types/DossierAdministratif'
import { checkIcone, formatDate } from '@/utils/helpers'
import PopupDemandeRemboursement from '@/components/DemandeRemboursement/PopupDemandeRemboursement.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'

@Component({
    components: {
        ExaGenericTable,
        PopupDemandeRemboursement,
        PopUpInfoIntervenant,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('demanderemboursement', ['demandesRemboursement', 'demandeRemboursementSelect', 'loading', 'totalRows', 'currentPage', 'lastPage', 'totalPage', 'error']),
        ...mapState('demanderemboursement', ['demandesRemboursement', 'demandeRemboursementSelect', 'error', 'meta']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    methods: {
        ...mapActions('demanderemboursement', ['getDemandesRemboursement', 'getMoreDemandesRemboursement'])
    }
})
export default class GestionDemandesRemboursementRespAdm extends Vue {
    Ability = Ability
    // DATAS
    fields = [
        {
            key: 'edit',
            label: '',
            sortable: true,
            class: 'text-start'
        },
        {
            key: 'etat',
            label: 'État',
            sortable: true,
            class: 'text-center'
        },
        {
            key: 'ordre_mission.dossier_administratif.user',
            label: 'Demandeur',
            sortable: true,
            class: 'text-start',
            type: 'actionText'
        },
        {
            key: 'ordre_mission_id',
            label: 'Ordre de mission de référence',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start'
        },
        {
            key: 'submitted_at',
            label: 'Date de la demande',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start'
        }
    ]

    filtres: any        = []
    filtreInitialized   = false
    dataForTab: any     = []


    showModalInfoIntervenant = false
    user_id_temp = 0

    @Watch('demandesRemboursement')
    wDemandeRemboursement() {
        this.setDataForGenericTab(this.$store.state.demanderemboursement.demandesRemboursement)
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.$store.dispatch('demanderemboursement/getDemandesRemboursement', this.params)
    }


    buildFiltre () {
        if (!this.filtreInitialized) {
            this.filtres = [
                {
                    libelle: 'Demandeur',
                    defautOptionlibelle: 'Rechercher un',
                    model: 'ordre_mission.dossier_administratif.user.name',
                    value: '',
                    index: 'name',
                    datas: null,
                    loading: false,
                    options: { type: 'form', fieldsKey: 'ordre_mission.dossier_administratif.user' } // 'form' , 'deroulant'
                },
                {
                    libelle: 'Ordre de mission de référence',
                    defautOptionlibelle: 'Rechercher un',
                    model: 'ordre_mission_id',
                    value: '',
                    index: 'id',
                    datas: null,
                    loading: false,
                    options: { type: 'form', fieldsKey: 'ordre_mission_id' } // 'form' , 'deroulant'
                }
            ]

            this.filtreInitialized = true
        }
    }

    @Watch('meta')
    majInfosTable () {
        // On récupère le nombre total de datas filtrés depuis les infos des Metadonnées
        if (this.$store.state.demanderemboursement.meta !== null) {
            if (this.totalDemandesRemboursement !== this.$store.state.demanderemboursement.meta.total) {
                this.totalDemandesRemboursement = this.$store.state.demanderemboursement.meta.total
            }
        } else {
            this.totalDemandesRemboursement = 0
        }
    }

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    params = []
    rowSelect: any = null
    showDemandeRemboursement = false
    Etat = Etat
    getEtatSpec = getEtatSpec
    totalDemandesRemboursement = 0

    // METHODS

    showEditDemandeRemboursement (row: any) {
        this.$store.dispatch('demanderemboursement/getDemandeRemboursement', { demande_remboursement_id: row.id }).then(() => {
            this.$store.state.demanderemboursement.error = null
            this.rowSelect = row
            this.showDemandeRemboursement = true
        })
    }

    // création de la collection complète en fonction d'une demande de filtrage ou de tri
    filtreSortHandler (params: any) {
        this.params = params
        this.$store.dispatch('demanderemboursement/getDemandesRemboursement', this.params)
    }

    // ajout des nouvelles entrées de liste lors d'un scroll
    loadHandler (params: any) {
        this.params = params
        this.$store.dispatch('demanderemboursement/getMoreDemandesRemboursement', this.params)
    }

    // Close la modif de la demande
    closeDemandeRemboursement () {
        this.rowSelect = null
        this.showDemandeRemboursement = false
    }

    s2ab (s: any) {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xff
        }
        return buf
    }

    exportDemandeRemboursement () {
        this.$store.dispatch('demanderemboursement/exportDemandesRemboursements')
            .then((resp) => {
                const blob = new Blob(['\ufeff' + resp.data], { type: 'text/csv;charset=utf-8' })
                const fileName = 'export_demande_remboursement.csv'
                if (navigator.msSaveBlob) {
                    // IE 10+
                    navigator.msSaveBlob(blob, fileName)
                } else {
                    const link = document.createElement('a')
                    link.setAttribute('id', 'link_downloadExport')
                    if (link.download !== undefined) {
                        const url = URL.createObjectURL(blob)
                        link.setAttribute('href', url)
                        link.setAttribute('download', fileName)
                        link.style.visibility = 'hidden'
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    }
                }
            })
    }

    exportDemandeRemboursementxlsx () {
        this.$store.dispatch('demanderemboursement/exportDemandesRemboursements')
            .then((resp) => {
                const blob = new Blob([this.s2ab(atob(resp.data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                const fileName = 'export_demandes_remboursement.xlsx'
                if (navigator.msSaveBlob) {
                    // IE 10+
                    navigator.msSaveBlob(blob, fileName)
                } else {
                    const link = document.createElement('a')
                    if (link.download !== undefined) {
                        // feature detection
                        // Browsers that support HTML5 download attribute
                        const url = URL.createObjectURL(blob)
                        link.setAttribute('id', 'link_downloadExport')
                        link.setAttribute('href', url)
                        link.setAttribute('download', fileName)
                        link.style.visibility = 'hidden'
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                    }
                }
            })
    }


    /**
     *
     *  Data for generic tab
     *
      */

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':
                    this.showEditDemandeRemboursement(paParams[1])
                    break

                case 'openComment':

                    break

                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break

                default:
                    break
            }
        }
    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        const can = this.$store.getters['auth/can'](Ability.RH_FRAIS_MANAGE)
        const icone = checkIcone(Ability.RH_FRAIS_MANAGE, can)
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const validation = this.getPuceColorValidation(result)

                const demandeur = result && result.ordre_mission && result.ordre_mission.dossier_administratif && result.ordre_mission.dossier_administratif.user && result.ordre_mission.dossier_administratif.user.name ? result.ordre_mission.dossier_administratif.user : null
                const demandeurName = result && result.ordre_mission && result.ordre_mission.dossier_administratif && result.ordre_mission.dossier_administratif.user && result.ordre_mission.dossier_administratif.user.name ? result.ordre_mission.dossier_administratif.user.name + ' ' + result.ordre_mission.dossier_administratif.user.first_name : '-'

                const ordreMissionReference = result && result.ordre_mission_id ? result.ordre_mission_id : '-'

                const submitted_at  = result.submitted_at ? this.formatteDate(result.submitted_at) : '-'

                const line = [
                    { label: icone.label,       item: result,           type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: validation[0].title,       item: validation,           type: 'icons',  typeAction: null, class: 'text-center', icon: icone.icon, disabled: false },
                    // { label: '',       item: demandeur,           type: 'text',  typeAction: null, class: 'text-start' },
                    { label: '', item: demandeur.id, type: 'actionText', typeAction: 'infoIntervenant', class: 'text-info item_action', text: demandeurName },
                    { label: '',       item: '#' + ordreMissionReference,           type: 'text',  typeAction: null, class: 'text-start' },
                    { label: '',       item: submitted_at,           type: 'text',  typeAction: null, class: 'text-start' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /** Formattage de date */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /** Puce de l'état */
    getPuceColorValidation(result: any) {
        let class_affichage = 'text-tertiary'
        let title = ''
        if (result) {
            switch (true) {
                case result.etat === Etat.ETAT_INVISIBLE:
                    class_affichage = 'text-tertiary'
                    title = 'Invisible'
                    break
                case result.etat === Etat.ETAT_VIERGE:
                    title   = 'Vierge'
                    class_affichage = 'text-secondary'
                    break
                case result.etat === Etat.ETAT_DEPOSE:
                    title   = 'Déposé'
                    class_affichage = 'text-info'
                    break
                case result.etat === Etat.ETAT_VALIDE:
                    title   = 'Validé'
                    class_affichage = 'text-success'
                    break
                case result.etat === Etat.ETAT_REJETE:
                    title   = 'Rejeté'
                    class_affichage = 'text-danger'
                    break
                default:
                    title   = 'Vierge'
                    class_affichage = 'text-secondary'
                    break
            }
        }
        return [{ name: 'circle', class: class_affichage, title: title }]
    }

    load () {
        this.buildFiltre()
    }

    mounted () {
        if (!(this.$store.getters['auth/findParameter']('ordreMissionDisabled')?.value !== '1')) {
            this.$router.go(-1)
        }

        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
