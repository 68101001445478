






















import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        FontAwesomeIcon
    },
    methods: {
        ...mapMutations('dossieracademique', ['UPDATE_DATAS_AUTRE_CONCOURS_NOM', 'UPDATE_DATAS_AUTRE_CONCOURS_NOM_AUTRE'])
    }
})
export default class SelectConcours extends Vue {
    @Prop() selectedValue?: string
    @Prop() other?: string
    @Prop() readOnly?: boolean
    @Prop() required?: boolean

    options = [
        { id: 'POLYTECHNIQUE', value: 'POLYTECHNIQUE' },
        { id: 'ENS', value: 'ENS' },
        { id: 'CENTRALE-SUPELEC', value: 'CENTRALE-SUPELEC' },
        { id: 'CCINP', value: 'CCINP' },
        { id: 'POLYTECH', value: 'POLYTECH' },
        { id: 'E3A', value: 'E3A' },
        { id: 'BANQUE PT', value: 'BANQUE PT' },
        { id: 'INSA', value: 'INSA' },
        { id: 'CESI', value: 'CESI' },
        { id: 'EPITA - IPSA - ESME', value: 'EPITA - IPSA - ESME' },
        { id: 'BCE', value: 'BCE' },
        { id: 'BEL', value: 'BEL' },
        { id: 'ECRICOME', value: 'ECRICOME' },
        { id: 'G2E', value: 'G2E' },
        { id: 'SCAV', value: 'SCAV' },
        { id: 'Autre', value: 'Autre' }
    ]

    // Event change sur le select
    updateDatasPro (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLTextAreaElement
            if (target.name === 'other_mission_name') {
                this.$store.commit('dossieracademique/UPDATE_DATAS_AUTRE_CONCOURS_NOM', target.value)
            }
            if (target.name === 'other_mission_name_other') {
                this.$store.commit('dossieracademique/UPDATE_DATAS_AUTRE_CONCOURS_NOM_AUTRE', target.value)
            }
        }
    }
}
