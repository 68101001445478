





























































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import PopupRelaunchContract from '@/components/DossierAdministratif/PopupRelaunchContract.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import GestionConvention from '@/components/Conventions/GestionConvention.vue'
import GestionAnnexeConvention from '@/components/Conventions/GestionAnnexeConvention.vue'
import { getEnumTypeCentreForFilterSelect, getEnumTypeCentrePassationForFilterSelect, getTypeCentrePassationSpec, getTypeCentreSpec, getEtatConventionFromRowItem, getEtatAnnexeConventionFromRowItem } from '@/types/Centre'
import { isEmpty } from 'lodash'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'


@Component({
    components: {
        ExaGenericTable,
        PopupRelaunchContract,
        GestionConvention,
        GestionAnnexeConvention,
        ErrorDisplay,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapState('centre', ['centres', 'error', 'loading', 'totalRows', 'totalPage', 'lastPage', 'meta']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('centre', ['centres', 'conventions'])

    },
    methods: {
        ...mapActions('centre', ['getCentres'])
    }
})
export default class ConventionsRespAdm extends Vue {
    // DATAS
    fields: Array<any> = [
        {
            key: 'etat_centre',
            label: 'État',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center'
        },
        { key: 'name', label: 'Centre', sortable: true, sortDirection: 'asc', class: 'text-start' },
        {
            key: 'ville.name',
            label: 'Ville',
            sortable: true,
            class: 'text-start'
        },
        {
            key: 'type',
            label: 'Type',
            sortable: true,
            class: 'text-center'
        },
        {
            key: 'type_passation_centre',
            label: 'Type Passation',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-center'
        },
        {
            key: 'convention',
            label: 'Convention',
            sortable: true,
            class: 'text-center'
        },
        {
            key: 'annexe_convention',
            label: 'Annexe Convention',
            sortable: true,
            class: 'text-center'
        },
        {
            key: 'relanceConvention',
            label: 'Relance',
            class: 'text-center',
            sortable: true
        }
    ]

    filtres: any    = []
    dataForTab: any = []
    Ability = Ability
    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    params: any = []
    showConfirmRelance = false
    showConfirmRelanceGlobale = false
    rowSelect: any = null
    totalConventions = 0
    envoiEnCours = false
    datas: any = []
    openPopupConvention = false
    openPopupAnnexeConvention = false

    @Watch('meta')
    majInfosTable () {
        // On récupère le nombre total de dossiers filtrés depuis les infos des Metadonnées
        if (this.$store.state.centre.meta !== null) {
            if (this.totalConventions !== this.$store.state.centre.totalRows) {
                this.totalConventions = this.$store.state.centre.totalRows
            }
        } else {
            this.totalConventions = 0
        }
    }

    @Watch('user_session_id')
    refreshInterface () {
        const params = {}
        Vue.set(params, 'page', 1)
        Vue.set(params, 'sort', 'ville.name')
        Vue.set(params, 'direction', 'asc')
        Vue.set(params, 'filter-submitted', 1)
        this.$store.dispatch('centre/getConventionsAdm', params)
    }

    @Watch('conventions', { deep: true })
    wCentre() {
        this.setDataForGenericTab(this.$store.state.centre.conventions)
    }

    // METHODS

    buildFiltre () {
        this.filtres = [
            {
                libelle: 'Centre',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null,
                loading: this.$store.state.centre.loading,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Ville',
                defautOptionlibelle: 'Rechercher une',
                model: 'ville.name',
                value: '',
                index: 'name',
                datas: null,
                loading: this.$store.state.centre.loading,
                options: { type: 'form', fieldsKey: 'ville.name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Types',
                defautOptionlibelle: 'Tous les',
                model: 'type',
                value: '-',
                index: 'name',
                datas: getEnumTypeCentreForFilterSelect(),
                loading: this.$store.state.centre.loading,
                options: { type: 'deroulant', fieldsKey: 'type' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Types de passation',
                defautOptionlibelle: 'Tous les',
                model: 'type_passation',
                value: '-',
                index: 'id',
                datas: getEnumTypeCentrePassationForFilterSelect(),
                loading: this.$store.state.centre.loading,
                options: { type: 'deroulant', fieldsKey: 'type_passation' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Etat',
                defautOptionlibelle: 'Tous les',
                model: 'submitted',
                value: '-',
                index: 'name',
                datas: this.getEtatCentre(),
                loading: this.$store.state.centre.loading,
                options: { type: 'deroulant', fieldsKey: 'etat_centre' } // 'form' , 'deroulant'
            }
        ]
    }

    // Appelle une page lors du scroll
    loadHandler (params: any) {
        this.$store.dispatch('centre/getMoreConventionsAdm', params).then(() => {
            this.datas = []
            this.datas = this.$store.state.centre.conventions
        })
    }

    filtreSortHandler (params: any) {
        const this_params_stringify = JSON.stringify(this.params)

        if (this_params_stringify !== JSON.stringify(params)) {
            this.params = params
            Vue.set(this.params, 'filter-submitted', 1)
            this.$store.dispatch('centre/getConventionsAdm', params).then(() => {
                this.setDataForGenericTab(this.$store.state.centre.conventions)
            })
        }
    }

    // Retourne une liste de filtres pour les etats du centre
    getEtatCentre () {
        return [{ index: 0, id: 0, name: 'Centre non publié' }, { index: 1, id: 1, name: 'Centre publié' }]
    }

    // Ouvre le popup de gestion des conventions
    openGestionConvention (row: any) {
        // modification pour permettre de prendre en compte les dernières datas éventuellement envoyée par le CDC du centre
        this.$store.dispatch('centre/getCentre', { centre_id: row.id }).then(() => {
            this.rowSelect = this.$store.state.centre.centreSelect
            this.openPopupConvention = true
        })
    }

    closeGestionConvention (etat: boolean) {
        this.rowSelect = null
        this.openPopupConvention = !etat
    }

    // Ouvre le popup de gestion des annexes de convention
    openGestionAnnexeConvention (row: any) {
        // modification pour permettre de prendre en compte les dernières datas éventuellement envoyée par le CDC du centre
        this.$store.dispatch('centre/getCentre', { centre_id: row.id }).then(() => {
            this.rowSelect = this.$store.state.centre.centreSelect
            this.openPopupAnnexeConvention = true
        })
    }

    closeGestionAnnexeConvention (etat: boolean) {
        this.rowSelect = null
        this.openPopupAnnexeConvention = !etat
    }

    /** Relance un centre en particulier pour les conventions et annexe */
    confirmRelance (row: any) {
        this.rowSelect = row
        this.showConfirmRelance = true
    }

    /** Ferme la popup de confirmation de relance */
    closeConfirmRelance () {
        this.envoiEnCours = false
        this.$store.state.centre.error = null
        this.showConfirmRelance =  false
        this.showConfirmRelanceGlobale = false
    }

    /** Confirme la relance par email sur un centre */
    sendRelance () {
        this.envoiEnCours = true
        this.$store.state.centre.error = null

        this.$store.dispatch('centre/relaunchCentre', { centre_id: this.rowSelect.id, type: 'convention' })
            .then(() => {
                this.showConfirmRelance = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Envoi terminé !', succesToaster)
                this.envoiEnCours = false
            })
    }

    /** Relance globale sur tous les centres */
    confirmRelanceGlobale () {
        this.showConfirmRelanceGlobale = true
    }

    /** Confirme la relance globale à tous les centres */
    sendRelanceGlobale () {
        this.envoiEnCours = true
        this.$store.state.centre.error = null

        this.$store.dispatch('centre/relaunchCentres', { type: 'convention' })
            .then(() => {
                this.showConfirmRelanceGlobale = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Envoi terminé !', succesToaster)
                this.envoiEnCours = false
            })
    }

    /** Retourne le message de relance adapté à l'état du centre */
    getMessageEtatConvention (rowSelect: any) {
        let message = ''
        let desti = 'chef d\'établissement'
        if (!rowSelect.convention_signed_at) {
            message = 'de signer sa convention.'
            desti = 'chef d\'établissement'
        } else if (!rowSelect.annexe_submitted_at) {
            message = 'de remplir son annexe de convention.'
            desti = 'chef de centre'
        } else if (!rowSelect.annexe_signed_at) {
            message = 'de signer son annexe de convention.'
            desti = 'chef d\'établissement'
        }

        return { destinataire: desti, message: message }
    }

    beforeMount () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }

    load() {
        if (isEmpty(this.filtres)) {
            this.buildFiltre()

            const params = {}
            Vue.set(params, 'page', 1)
            Vue.set(params, 'sort', 'ville.name')
            Vue.set(params, 'direction', 'asc')
            Vue.set(params, 'filter-submitted', 1)
            this.$store.dispatch('centre/getConventionsAdm', params).then(() => {
                this.dataForTab = []
                this.setDataForGenericTab(this.$store.state.centre.conventions)
            })
        }
    }

    /**
     *
     *  Functions pour generic table
     *
     *
      */

    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const centre            = result.name ? result.name : '-'
                const ville             = result.ville && result.ville.name ? result.ville.name : '-'
                const typeCentre        = result.type ? getTypeCentreSpec(result.type).libelle : '-'
                const typePassation     = result.type_passation ? getTypeCentrePassationSpec(result.type_passation).libelle : 'Type inconnu'
                const etat_centre       = [{ name:'circle', class:  result.submitted_at ? 'text-info' : 'text-tertiary' }]
                const convention_affichage  = this.formatAffichageConvention(result)
                const annexe_convention     = this.formatAnnexeConvention(result)
                const relanceMail           = this.relanceMailChecker(result)

                const line = [
                    {
                        label: result.submitted_at ? 'Centre publié' : 'Centre non publié',
                        item: etat_centre,
                        type: 'icons',
                        typeAction: null,
                        class: 'text-center'
                    },
                    {
                        label: '',
                        item: centre,
                        type: 'text',
                        typeAction: null,
                        class: ''
                    },
                    {
                        label: '',
                        item: ville,
                        type: 'text',
                        typeAction: null,
                        class: ''
                    },
                    {
                        label: '',
                        item: typeCentre,
                        type: 'text',
                        typeAction: null,
                        class: 'text-center'
                    },
                    {
                        label: '',
                        item: typePassation,
                        type: 'text',
                        typeAction: null,
                        class: 'text-center'
                    },
                    {
                        label: convention_affichage[0].title,
                        item: convention_affichage,
                        type: 'icons',
                        typeAction: result.submitted_at ? 'convention' : null,
                        class: 'text-center'
                    },
                    {
                        label: annexe_convention[0].title,
                        item: annexe_convention,
                        type: 'icons',
                        typeAction: annexe_convention[0].clickable ? 'annexe_convention' : null,
                        class: 'text-center'
                    },
                    {
                        label: relanceMail[0].title,
                        item: relanceMail,
                        type: 'action',
                        icon: relanceMail[0].name,
                        typeAction: relanceMail[0].disabled ?  null : 'relance_mail',
                        class: 'text-center text-info',
                        disabled: relanceMail[0].disabled
                    }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'convention':
                    this.openGestionConvention(paParams[1][0].result)
                    break
                case 'annexe_convention' :
                    this.openGestionAnnexeConvention(paParams[1][0].result)
                    break
                case 'relance_mail':
                    this.confirmRelance(paParams[1][0].result)
                    break

                default:
                    break
            }
        }
    }

    /** prend la ligne result et retourne l'affichage de l'etat de la conviention */
    formatAffichageConvention(result: any) {
        const etat_convention   = getEtatConventionFromRowItem(result)
        const retour  = []
        if (!result.convention_type_id && result.convention_validated_at) {
            retour.push({ name: 'file-pdf', title: 'La convention et son annexe ont été rédigées et signées en dehors du système.', class: ' text-' + etat_convention.color + ' ', result: result  })
        } else if (result.convention_submitted_at && etat_convention)  { // && result.convention_type_id
            retour.push({ name: 'file-alt', title: etat_convention.libelle, class: ' text-' + etat_convention.color + ' ', result: result  })
        } else {
            retour.push({ name: 'times-circle', title: etat_convention.libelle, class: 'text-secondary', result: result })
        }
        return retour
    }

    formatAnnexeConvention(result: { submitted_at: any; convention_published_at: any; convention_type_id: null }) {
        const retour  = []
        const etatAnnexe = getEtatAnnexeConventionFromRowItem(result)
        if (!result.submitted_at) {
            retour.push({ name: 'times-circle', title: "L'annexe de convention ne peut pas être créée car le centre n'a pas encore été publié", class: ' text-secondary ', clickable: false, result: result })
        } else if (!result.convention_published_at) {
            retour.push({ name: 'times-circle', title: "L'annexe de convention ne peut pas être créée car la convention du centre n'a pas encore été publiée", class: ' text-secondary ', clickable: false,  result: result  })
        } else if (!result.convention_type_id) {
            retour.push({ name: 'file-pdf', title: 'La convention et son annexe ont été rédigées et signées en dehors du système.', class: ' text-success ', clickable: false,  result: result  })
        } else {
            retour.push({ name: 'file-alt', title: etatAnnexe.libelle, class: ' text-' + etatAnnexe.color, clickable: true,  result: result  })
        }
        return retour
    }

    relanceMailChecker(result: any) {
        const retour: any  = []
        let disabled = false
        /*
        disabled = !!((result && result.convention_published_at === null) ||
                                      (result && result.annexe_submitted_at !== null && result.annexe_published_at === null) ||
                                      (result.item && result.item.annexe_signed_at !== null) ||
                                      (result && result.fiches_intervenants_submitted_at !== null) ||
                                      !this.$store.getters['auth/can'](Ability.GC_CONVENTION_MANAGE))
                                      */

        const allSigned =  result.annexe_signed_at !== null && result.convention_signed_at !== null

        if (!this.$store.getters['auth/can'](Ability.GC_CONVENTION_MANAGE) ||
        !result ||
        !result.convention_type_id ||
        allSigned ||
        result.convention_published_at === null ||
        (result.annexe_published_at === null && result.annexe_submitted_at !== null)
        ) {
            disabled = true
        }

        const clickable = !!this.$store.getters['auth/can'](Ability.GC_CONVENTION_MANAGE)
        retour.push({ name: 'envelope', title: 'Relancer le centre', class: 'text-primary', result: result, clickable: clickable, disabled: disabled })
        return retour
    }
}
