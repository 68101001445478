



































































































































































import { mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Decision } from '@/types/DossierAcademique'
import { EntityType, PosteContext, PosteType } from '@/types/Poste'
import { EpreuveInterface } from '@/types/Epreuve'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    computed: {
        ...mapGetters('dossieracademique', ['dossierSelectionSelect']),
        ...mapState('dossieracademique', ['dossierSelectionSelect']),
        ...mapState('poste', ['postes']),
        ...mapState('epreuve', ['epreuvesCorrections', 'epreuvesCorrectionsPrecedent']),
        ...mapState('matiere', ['matieres','matiereSelect']),
        ...mapState('session', ['sessionSelect']),
        ...mapState('pv', ['pvSelect'])
    },
    methods: {
        getStringConcours (concours) {
            return concours?.map((concour: any) => concour.name)
                ?.join(', ') || ''
        }
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class PopupDecision extends Vue {
    @Prop() showDecision?: boolean;
    @Prop() new_candidature?: boolean;
    @Prop() row?: any;
    @Prop() mode?: string;

    epreuvesC = []
    decision_select = 0
    commentaire_select = ''
    poste_select = {
        id: 0,
        name: '',
        entity_type: '',
        type_passation: 0,
        options: null
    }

    epreuve_select = {
        id: 0,
        name: '',
        type_passation: 0,
        concours_name: ''
    }

    option_select: Array<any> = []
    postes_epreuves: Array<any> = []
    erreur_selection_poste = ''
    poste_select_id = 0
    epreuve_select_id = 0
    decision_aucune = Decision.DECISION_AUCUNE
    decision_retenu = Decision.DECISION_RETENU
    decision_suspendu = Decision.DECISION_SUSPENDU
    decision_non_reconduit = Decision.DECISION_NON_RECONDUIT
    decision_demission = Decision.DECISION_DEMISSION
    decision_non_retenu = Decision.DECISION_NON_RETENU
    decision_ajourne = Decision.DECISION_AJOURNE
    entity_epreuve_correction = EntityType.ET_EPREUVE_CORRECTION
    entity_matiere = EntityType.ET_MATIERE
    volume_prevu: null | string = null

    postes_epreuves_init: Array<any> = []
    decision_select_init = 0
    volume_max = '0'
    quotite = '0'

    @Watch('epreuvesCorrections')
    setEpreuvesC () {
        this.epreuvesC = this.$store.getters['epreuve/epreuvesCorrections']
            .filter((epreuve: EpreuveInterface) => epreuve.session_id === this.$store.getters['auth/user_session_id'])
            .filter((epreuve: EpreuveInterface) => epreuve.matiere_id === parseInt(this.$route.params.matiere_id))
    }

    @Watch('poste_select')
    filtreEpreuvesByPoste () {
        if (this.poste_select.id !== 0 && this.poste_select.entity_type === EntityType.ET_EPREUVE_CORRECTION) {
            this.epreuvesC = this.$store.getters['epreuve/epreuvesCorrections']
                .filter((epreuve: EpreuveInterface) => epreuve.session_id === this.$store.getters['auth/user_session_id'])
                .filter((epreuve: EpreuveInterface) => epreuve.matiere_id === parseInt(this.$route.params.matiere_id))
                .filter((epreuve: EpreuveInterface) => epreuve.type_passation === this.poste_select.type_passation)
        } else {
            this.epreuvesC = []
        }
    }

    @Watch('showDecision')
    initPopup () {
        if (this.$props.showDecision) {
            this.setDecision(this.$props.row)
        }
    }

    @Watch('poste_select')
    resetSelection () {
        this.epreuve_select = { id: 0, name: '', type_passation: 0, concours_name: '' }
        this.option_select = []
    }

    @Watch('epreuve_select')
    setVolumePrevu() {
        this.volume_prevu = null
        if (this.poste_select && this.poste_select.id !== 0 && this.epreuve_select && this.epreuve_select.id !== 0) {
            const epreuve: any = this.epreuvesC.find((e: any) => e.id === this.epreuve_select.id)
            const nb_candidats = epreuve ? epreuve.estimation_nb_candidats : 0
            const besoins = this.$store.state.pv.besoins
            let besoins_filter: any = []
            if (this.poste_select.entity_type === EntityType.ET_EPREUVE_CORRECTION) {
                besoins_filter = besoins.filter((b: any) => (b.epreuve && b.epreuve.id === this.epreuve_select.id) && (b.poste && b.poste.id === this.poste_select.id))
            } else if (this.poste_select.entity_type === EntityType.ET_MATIERE) {
                besoins_filter = besoins.filter((b: any) => b.poste && b.poste.id === this.poste_select.id)
            }

            const calcul = (nb_candidats / besoins_filter[0].intervenants.besoin).toFixed(2)
            if (isFinite(Number(calcul))) {
                this.volume_prevu = calcul
            } else {
                this.volume_prevu = null
            }
        }
    }

    @Watch('volume_prevu')
    updateVolumeMax() {
        if (this.volume_prevu) {
            this.volume_max = this.volume_prevu
        }
    }

    @Watch('volume_max')
    updateQuotite() {
        const calcul = parseFloat(this.volume_max) / parseFloat(String(this.volume_prevu))
        this.quotite = '1'

        if (isFinite(calcul)) {
            if (calcul <= 0) {
                this.quotite = '0'
            } else if (calcul < 1) {
                this.quotite = calcul.toFixed(3)
            }
        }
    }

    getPosteMatieresString(poste: any) {
        return poste?.matieres
            ?.map((matiere: any) => matiere.name)
            ?.join(', ') || ''
    }

    // Selectionne une option pour le poste
    selectOption (e: any) {
        if(!this.option_select) {
            this.option_select = []
        }
        this.option_select.push(e.target.value)
    }

    // Change le statut d'un dossier
    setDecision (row: any) {
        if (this.$props.mode === 'affectation' && this.$store.state.pv.pvSelect.validated_at !== null) {
            this.$store.commit('dossieracademique/SET_DOSSIER_SELECTION_CURRENT', row)
            this.commentaire_select = (row.courant ? row.courant.commentaire_pv : '')
            this.decision_select = (row.courant ? row.courant.decision_affectation : 0)
            // Set les postes_epreuves si on en a
            if (row.courant && row.courant.decision_affectation === Decision.DECISION_RETENU) {
                for (const p in row.postes_affectation_courant) {
                    const epreuve = this.$store.state.epreuve.epreuvesCorrections.find((e: any) => e.id === row.postes_affectation_courant[p].entity_id)
                    const poste = this.$store.state.poste.postes.find((e: any) => e.id === row.postes_affectation_courant[p].id)

                    this.poste_select = poste !== undefined ? ({ id: poste.id, name: poste.name, entity_type: poste.entity_type, type_passation: poste.type_passation, options: poste.options }) : ({ id: null, name: '', entity_type: null, type_passation: 0, options: null })
                    const concoursName = epreuve?.concours
                        ?.map((concour: any) => concour.name)
                        ?.join(', ') || ''
                    this.epreuve_select = epreuve !== undefined ? ({ id: epreuve.id, name: epreuve.name, type_passation: epreuve.type_passation, concours_name: concoursName }) : ({ id: null, name: '', type_passation: 0, concours_name: '' })
                    this.option_select = JSON.parse(row.postes_affectation_courant[p].options_affectes)
                    this.postes_epreuves.push({ poste: this.poste_select, epreuve: this.epreuve_select, option: JSON.parse(row.postes_affectation_courant[p].options_affectes), quotite: parseInt(row.postes_affectation_courant[p].temps) / 100, volume_max: parseInt(row.postes_affectation_courant[p].quantite) })
                }
            }
            this.epreuve_select = { id: 0, name: '', type_passation: 0, concours_name: '' }
            this.poste_select = { id: 0, name: '', entity_type: '', type_passation: 0, options: null }
            this.option_select = []
        } else if (this.$props.mode === 'selection' && this.$store.state.pv.pvSelect.validated_at === null) {
            this.$store.commit('dossieracademique/SET_DOSSIER_SELECTION_CURRENT', row)
            if (this.$store.state.poste.postes.length !== 0) {
                const postes_filter = this.$store.state.poste.postes.filter((p: any) => p.type === PosteType.TYPE_PEDAGOGIQUE && (p.context & PosteContext.CONTEXT_SELECTION) === PosteContext.CONTEXT_SELECTION)
                if (postes_filter.length === 0) {
                    this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_PEDAGOGIQUE, context: PosteContext.CONTEXT_SELECTION }).then(() => {
                        this.init_postes_epreuves(row)
                    })
                } else {
                    this.$store.state.poste.postes = postes_filter
                    this.init_postes_epreuves(row)
                }
            } else {
                this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_PEDAGOGIQUE, context: PosteContext.CONTEXT_SELECTION }).then(() => {
                    this.init_postes_epreuves(row)
                })
            }
        }
    }

    // Quand les postes sont init, on affiche les postes épreuves
    init_postes_epreuves (row: any) {
        this.commentaire_select = (row.courant ? row.courant.commentaire_pv : '')
        if (this.$props.mode === 'affectation') {
            this.decision_select = (row.courant ? row.courant.decision_affectation : 0)
            // Set les postes_epreuves si on en a
            if (row.courant && row.courant.decision_affectation === Decision.DECISION_RETENU) {
                for (const p in row.postes_affectation_courant) {
                    const epreuve = this.$store.state.epreuve.epreuvesCorrections.find((e: any) => e.id === row.postes_affectation_courant[p].entity_id)
                    const poste = this.$store.state.poste.postes.find((e: any) => e.id.toString() === row.postes_affectation_courant[p].id.toString())
                    this.poste_select = poste !== undefined ? ({ id: poste.id, name: poste.name, entity_type: poste.entity_type, type_passation: poste.type_passation, options: poste.options }) : ({ id: null, name: '', entity_type: null, type_passation: 0, options: null })
                    const concoursName = epreuve?.concours
                        ?.map((concour: any) => concour.name)
                        ?.join(', ') || ''
                    this.epreuve_select = epreuve !== undefined ? ({ id: epreuve.id, name: epreuve.name, type_passation: epreuve.type_passation, concours_name: concoursName }) : ({ id: null, name: '', type_passation: 0, concours_name: '' })
                    this.option_select = JSON.parse(row.postes_affectation_courant[p].options_affectes)
                    this.postes_epreuves.push({ poste: this.poste_select, epreuve: this.epreuve_select, option: JSON.parse(row.postes_affectation_courant[p].options_affectes), quotite: parseInt(row.postes_affectation_courant[p].temps) / 100, volume_max: parseInt(row.postes_affectation_courant[p].quantite) })
                }
            } else {
                this.epreuve_select = { id: 0, name: '', type_passation: 0, concours_name: '' }
                this.poste_select = { id: 0, name: '', entity_type: '', type_passation: 0, options: null }
                this.option_select = []
            }
        } else if (this.$props.mode === 'selection') {
            this.decision_select = (row.courant ? row.courant.decision_selection : 0)
            // Set les postes_epreuves si on en a
            if (row.courant && row.courant.decision_selection === Decision.DECISION_RETENU) {
                for (const p in row.postes_selection_courant) {
                    const epreuve = this.$store.state.epreuve.epreuvesCorrections.find((e: any) => e.id === row.postes_selection_courant[p].entity_id)
                    const poste = this.$store.state.poste.postes.find((e: any) => e.id.toString() === row.postes_selection_courant[p].id.toString())
                    this.poste_select = poste !== undefined ? ({ id: poste.id, name: poste.name, entity_type: poste.entity_type, type_passation: poste.type_passation, options: poste.options }) : ({ id: null, name: '', entity_type: null, type_passation: 0, options: null })
                    const concoursName = epreuve?.concours
                        ?.map((concour: any) => concour.name)
                        ?.join(', ') || ''
                    this.epreuve_select = epreuve !== undefined ? ({ id: epreuve.id, name: epreuve.name, type_passation: epreuve.type_passation, concours_name: concoursName }) : ({ id: null, name: '', type_passation: 0, concours_name: '' })
                    this.option_select = JSON.parse(row.postes_selection_courant[p].options_affectes)
                    this.postes_epreuves.push({ poste: this.poste_select, epreuve: this.epreuve_select, option: JSON.parse(row.postes_selection_courant[p].options_affectes), quotite: parseInt(row.postes_selection_courant[p].temps) / 100, volume_max: parseInt(row.postes_selection_courant[p].quantite) })
                }
            } else {
                this.epreuve_select = { id: 0, name: '', type_passation: 0, concours_name: '' }
                this.poste_select = { id: 0, name: '', entity_type: '', type_passation: 0, options: null }
                this.option_select = []
            }
        }

        this.postes_epreuves_init = [...this.postes_epreuves]
        this.decision_select_init = this.decision_select
    }

    // Ajoute un poste et une épreuve
    addPosteEpreuve () {
        this.erreur_selection_poste = ''
        if (this.$props.mode === 'affectation') {
            this.postes_epreuves.push({ poste: this.poste_select, epreuve: this.epreuve_select, option: this.option_select, quotite: this.quotite, volume_max: this.volume_max })
            this.option_select = []
        } else {
            if ((this.epreuve_select.id !== 0 && this.poste_select.id !== 0) || (this.poste_select.entity_type === EntityType.ET_MATIERE)) {
                if (this.postes_epreuves.some(e => e.poste === this.poste_select && e.epreuve === this.epreuve_select)) {
                    this.erreur_selection_poste = 'Ce poste et cette épreuve ont déjà été sélectionnés.'
                } else {
                    if (this.checkSelectionBesoin(this.poste_select, this.epreuve_select)) {
                        this.postes_epreuves.push({ poste: this.poste_select, epreuve: this.epreuve_select, option: this.option_select, quotite: this.quotite, volume_max: this.volume_max })
                        this.option_select = []
                    } else {
                        this.erreur_selection_poste = 'Vous avez déjà sélectionné tous les postes pour cette épreuve.'
                    }
                }
            } else {
                this.erreur_selection_poste = 'Il manque une épreuve ou un poste dans votre sélection.'
            }
        }
    }

    // Supprimer une affectation poste epreuve
    deletePosteEpreuve (poste_ep: any) {
        const index = this.postes_epreuves.findIndex(e => e.poste === poste_ep.poste && e.epreuve === poste_ep.epreuve)
        this.postes_epreuves.splice(index, 1)
    }

    // Annulation de la prise de décision
    reset () {
        this.epreuve_select = { id: 0, name: '', type_passation: 0, concours_name: '' }
        this.poste_select = { id: 0, name: '', entity_type: '', type_passation: 0, options: null }
        this.quotite = '0'
        this.volume_max = '0'
        this.postes_epreuves = []
        this.decision_select = this.decision_aucune
        this.erreur_selection_poste = ''
        this.$emit('close', true)
    }

    // Check si on peut selectionner l'intervenant sur la combinaison poste /epreuve ou poste / matiere
    checkSelectionBesoin (poste: any, epreuve: any): boolean {
        const besoins = this.$store.state.pv.besoins
        // Isole le besoin concernant le poste et l'épreuve sélectionnés
        let besoins_filter: any = []
        if (poste.entity_type === EntityType.ET_EPREUVE_CORRECTION) {
            besoins_filter = besoins.filter((b: any) => (b.epreuve && b.epreuve.id === epreuve.id) && (b.poste && b.poste.id === poste.id))
        } else if (poste.entity_type === EntityType.ET_MATIERE) {
            besoins_filter = besoins.filter((b: any) => b.poste && b.poste.id === poste.id)
        }

        if (this.$props.mode === 'selection') {
            // Check si les besoins sont complets ou non
            return besoins_filter[0].intervenants.besoin !== besoins_filter[0].intervenants.selection;
        } else if (this.$props.mode === 'affectation') {
            // Check si les besoins sont complets ou non
            return besoins_filter[0].intervenants.besoin !== besoins_filter[0].intervenants.affectation;
        }

        return false
    }

    // Enregistrement d'une décision sur un dossier en selection
    saveDecisionSelection () {
        const dif = this.postes_epreuves.filter((x: any) => !this.postes_epreuves_init.includes(x)).concat(this.postes_epreuves_init.filter((x: any) => !this.postes_epreuves.includes(x)))
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        // Si la décision est aucune et l'état du dossier est null : on demande la création du dossier
        this.$store.commit('dossieracademique/MAJ_DS', false)
        this.$store.commit('dossieracademique/MAJ_DSN', false)

        let datas = {}
        let errors = 0
        const postes_selections = []
        if (this.decision_select === this.decision_retenu && this.postes_epreuves.length !== 0) {
            for (const p in this.postes_epreuves) {
                if (this.postes_epreuves[p].poste.entity_type === EntityType.ET_EPREUVE_CORRECTION) {
                    postes_selections.push({ poste_id: this.postes_epreuves[p].poste.id, entity_id: this.postes_epreuves[p].epreuve.id, entity_type: this.postes_epreuves[p].poste.entity_type, options: this.postes_epreuves[p].option, temps: this.postes_epreuves[p].quotite, quantite: this.postes_epreuves[p].volume_max})
                } else if (this.postes_epreuves[p].poste.entity_type === EntityType.ET_MATIERE) {
                    postes_selections.push({ poste_id: this.postes_epreuves[p].poste.id, entity_id: this.$store.state.matiere.matiereSelect.id, entity_type: this.postes_epreuves[p].poste.entity_type, options: this.postes_epreuves[p].option, temps: this.postes_epreuves[p].quotite, quantite: this.postes_epreuves[p].volume_max})
                }
            }
        } else if (this.decision_select === this.decision_retenu) {
            this.erreur_selection_poste = 'Vous devez choisir au moins un poste.'
            errors++
        }

        if (errors === 0) {
            if (this.$store.state.dossieracademique.dossierSelectionSelect.etat === null) {
                if (postes_selections.length !== 0) {
                    datas = {
                        user_id: this.$store.state.dossieracademique.dossierSelectionSelect.user.id,
                        session_id: this.$props.session_id,
                        decision_selection: this.decision_select,
                        commentaire_pv: this.commentaire_select,
                        matiere_id: this.$props.matiere_id,
                        selections: JSON.stringify(postes_selections)
                    }
                } else {
                    datas = {
                        user_id: this.$store.state.dossieracademique.dossierSelectionSelect.user.id,
                        session_id: this.$props.session_id,
                        decision_selection: this.decision_select,
                        matiere_id: this.$props.matiere_id,
                        commentaire_pv: this.commentaire_select
                    }
                }
                datas = {
                    user_id: this.$store.state.dossieracademique.dossierSelectionSelect.user.id,
                    session_id: this.$props.session_id,
                    decision_selection: this.decision_select,
                    commentaire_pv: this.commentaire_select,
                    matiere_id: this.$props.matiere_id,
                    selections: JSON.stringify(postes_selections)
                }
                this.$store.dispatch('dossieracademique/addDossierAcademique', datas)
                    .then(() => {
                        if (this.$store.state.dossieracademique.countError === 0) {
                            const idSucces = 't_succes_' + Math.random()
                            const succesToaster = {
                                id: idSucces,
                                toaster: 'b-toaster-top-right',
                                variant: 'success',
                                noCloseButton: true,
                                fade: true,
                                autoHideDelay: 5000
                            }
                            this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                            this.$store.dispatch('pv/getBesoins', this.$store.state.pv.pvSelect.id)
                            this.$store.dispatch('dossieracademique/getDossiersSelections', { session_id: this.$store.state.session.sessionSelect.id, matiere_id: this.$store.state.matiere.matiereSelect.id })
                            this.reset()
                        } else {
                            this.erreur_selection_poste = this.$store.state.dossieracademique.error
                        }
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            } else {
                if (postes_selections.length !== 0) {
                    datas = {
                        decision_selection: this.decision_select,
                        commentaire_pv: this.commentaire_select,
                        matiere_id: this.$props.matiere_id,
                        selections: JSON.stringify(postes_selections)
                    }
                } else {
                    datas = {
                        decision_selection: this.decision_select,
                        matiere_id: this.$props.matiere_id,
                        commentaire_pv: this.commentaire_select
                    }
                }
                // Sinon update du dossier académique
                this.$store.dispatch('dossieracademique/updateDossierAcademique', { mode: 'selection', new: this.new_candidature, dossier_id: this.$store.state.dossieracademique.dossierSelectionSelect.courant.id, payload: datas })
                    .then(() => {
                        this.$store.dispatch('pv/getBesoins', this.$store.state.pv.pvSelect.id)
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                        this.reset()
                    }).catch(() => {
                        this.erreur_selection_poste = this.$store.state.dossieracademique.error
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            }
        }
    }

    // Enregistrement d'une décision sur un dossier en affectation
    saveDecisionAffectation () {
        const dif = this.postes_epreuves.filter((x: any) => !this.postes_epreuves_init.includes(x)).concat(this.postes_epreuves_init.filter((x: any) => !this.postes_epreuves.includes(x)))
        // Si la décision est aucune et l'état du dossier est null : on demande la création du dossier
        this.$store.commit('dossieracademique/MAJ_DSALL', false)
        let datas = {}
        let errors = 0
        const postes_affectations = []
        if (this.decision_select === this.decision_retenu && this.postes_epreuves.length !== 0) {
            for (const p in this.postes_epreuves) {
                if (this.postes_epreuves[p].poste.entity_type === EntityType.ET_EPREUVE_CORRECTION) {
                    postes_affectations.push({ poste_id: this.postes_epreuves[p].poste.id, entity_id: this.postes_epreuves[p].epreuve.id, entity_type: this.postes_epreuves[p].poste.entity_type, options: this.postes_epreuves[p].option, temps: this.postes_epreuves[p].quotite, quantite: this.postes_epreuves[p].volume_max})
                } else if (this.postes_epreuves[p].poste.entity_type === EntityType.ET_MATIERE) {
                    postes_affectations.push({ poste_id: this.postes_epreuves[p].poste.id, entity_id: this.$store.state.matiere.matiereSelect.id, entity_type: this.postes_epreuves[p].poste.entity_type, options: this.postes_epreuves[p].option, temps: this.postes_epreuves[p].quotite, quantite: this.postes_epreuves[p].volume_max})
                }
            }
        } else if (this.decision_select === this.decision_retenu) {
            this.erreur_selection_poste = 'Vous devez choisir au moins un poste.'
            errors++
        }

        if (errors === 0) {
            if (postes_affectations.length !== 0) {
                datas = {
                    decision_affectation: this.decision_select,
                    commentaire_pv: this.commentaire_select,
                    matiere_id: this.$props.matiere_id,
                    affectations: JSON.stringify(postes_affectations)
                }
            } else {
                datas = {
                    decision_affectation: this.decision_select,
                    matiere_id: this.$props.matiere_id,
                    commentaire_pv: this.commentaire_select
                }
            }
            // Update du dossier académique
            this.$store.dispatch('dossieracademique/updateDossierAcademique', { mode: 'affectation', dossier_id: this.$store.state.dossieracademique.dossierSelectionSelect.courant.id, payload: datas }).then(() => {
                if( (dif.length > 0 || this.decision_select !== this.decision_select_init)) {
                    this.$store.dispatch('dossieracademique/envoiLettreIntervenant', { dossierId: this.$store.state.dossieracademique.dossierSelectionSelect.courant.id })
                }
                this.$store.dispatch('pv/getBesoins', this.$store.state.pv.pvSelect.id)
                this.reset()
            }).catch(() => {
                this.erreur_selection_poste = this.$store.state.dossieracademique.error
            })
        }
    }

    load_matiere = false

    mounted() {
        this.load_matiere = true
        this.$store.dispatch('matiere/getMatieres').then(() => {
            this.load_matiere = false
        })
    }
}

