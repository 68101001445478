


























import { Vue, Component } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SaisieIntervenants from '@/components/Centre/Intervenants/SaisieIntervenants.vue'
import { Ability } from '@/types/Ability'
import SaisieFicheHoraireIntervenants from '@/components/Centre/Intervenants/SaisieFicheHoraireIntervenants.vue'
import SignatureFichesHoraire from '@/components/Centre/Intervenants/SignatureFichesHoraire.vue'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        SaisieIntervenants,
        SaisieFicheHoraireIntervenants,
        SignatureFichesHoraire
    },
    computed: {
        ...mapState('centre', ['meta', 'error', 'centreSelect', 'loading']),
        ...mapState('session', ['sessionActive', 'sessionSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('organisationecrit', ['besoins_centre', 'besoins_precedent', 'get_total_intervenants_precedent', 'get_total_intervenants']),
        ...mapGetters('intervenant', ['users_by_postes', 'compteur_by_poste'])
    }
})
export default class GestionIntervenants extends Vue {
    Ability = Ability
    editionActive = false
    selected_tab = 'intervenants'

    /** Met à jour les classes css quand on passe en édition */
    updateClass (etat: boolean) {
        this.editionActive = etat
        this.$emit('activeEdition', etat)
    }
}
