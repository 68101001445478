





















import { Vue, Component, Prop } from 'vue-property-decorator'
import ContratsRespAdm from '@/views/Contrats/ContratsRespAdm.vue'
import ContratsOpeLog from '@/views/Contrats/ContratsOpeLog.vue'

@Component({
    components:
    {
        ContratsRespAdm,
        ContratsOpeLog
    },
    computed:
    {

    },
    methods:
    {

    }
})

export default class ContratsGlobal extends Vue
{
    @Prop() session_id?: number
    @Prop() matiere_id?: number
    @Prop() pv_id?: number

    activeSol = false
    activeSps = true
    modeactif = 'sps'

    // Charge les contrats sol
    loadSol ()
    {
        this.$store.state.dossieradministratif.loading = true
        this.$store.state.dossieradministratif.dossiersAdministratifs = []
        if (this.$store.state.dossieradministratif.dossiersAdministratifs.length === 0) {
            this.activeSps = false
            this.modeactif = 'sol'
            this.activeSol = true
        }
    }

    loadSps () {
        this.$store.state.dossieradministratif.loading = true
        this.$store.state.dossieradministratif.dossiersAdministratifs = []
        if (this.$store.state.dossieradministratif.dossiersAdministratifs.length === 0) {
            this.activeSps = true
            this.activeSol = false
            this.modeactif = 'sps'
        }
    }
}
