




















import { Vue, Component, Prop } from 'vue-property-decorator'
import DossiersAdministratifsRespAdm from '@/views/Dossiers/DossiersAdministratifsRespAdm.vue'
import DossiersAdministratifsRespAdmSol from '@/views/Dossiers/DossiersAdministratifsRespAdmSol.vue'

@Component({
    components: {
        DossiersAdministratifsRespAdm,
        DossiersAdministratifsRespAdmSol
    },
    computed: {

    },
    methods: {

    }
})

export default class DossiersAdministratifsGlobal extends Vue {
    @Prop() session_id?: number
    @Prop() matiere_id?: number
    @Prop() pv_id?: number

    activeSol = false
    activeSps = true

    // Charge les dossiers administratifs sol
    loadSol () {
        this.$store.state.dossieradministratif.meta = null
        this.$store.state.dossieradministratif.loading = true
        this.$store.state.dossieradministratif.dossiersAdministratifs = []
        if (this.$store.state.dossieradministratif.dossiersAdministratifs.length === 0) {
            this.activeSol = true
            this.activeSps = false
        }
    }

    loadSps () {
        this.$store.state.dossieradministratif.meta = null
        this.$store.state.dossieradministratif.loading = true
        this.$store.state.dossieradministratif.dossiersAdministratifs = []
        if (this.$store.state.dossieradministratif.dossiersAdministratifs.length === 0) {
            this.activeSps = true
            this.activeSol = false
        }
    }
}
