










































import { Vue, Component }    from 'vue-property-decorator'
import { mapGetters }               from 'vuex'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'
import { Ability }                  from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat            from '@/components/Candidat/PopupEditCandidat.vue'
import PopupEditCommentCandidat     from '@/components/Candidat/PopupEditCommentCandidat.vue'

/**
 * Composant contenant l'ensemble des candidats
*/
@Component({
    computed: {
        ...mapGetters('candidat', ['candidats', 'loading', 'totalRows', 'lastPage', 'totalPage'])
    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        PopupEditCommentCandidat,
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class ExemplesInscriptions extends Vue
{
    showModalEditionCandidat?:          boolean = false
    showModalEditionCommentCandidat?:   boolean = false

    Ability = Ability

    // Ensemble des colonnes du tableau de candidats

    genericfields = [
        { key: 'etatEdit',                  label: '',                      sortable: false,    class: '', type: 'action' },
        { key: 'candidatCommentEdit',       label: '',                      sortable: true,     class: '', type: 'action' },
        { key: 'code',                      label: 'code',                  sortable: true,     class: '', type: 'text' },
        { key: 'identite',                  label: 'Identité',              sortable: true,     class: '', type: 'text' },
        { key: 'formattedDateNaissance',    label: 'Date de naissance',     sortable: true,     class: '', type: 'text' },
        { key: 'email',                     label: 'Courriel',              sortable: true,     class: '', type: 'text' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;

    filtres:    any         = []
    dataForTab: Array<any>  = []


    /**
     * Au montage du composant, on charge les candidats et les concours si cela est nécéssaire
     */
    constructor ()
    {
        super()
        // Chargement des candidats
        this.loadCandidatIfNotExists().then((result) =>
        {
            this.initDatas(result)
            // this.setFiltersForGenericTab()
        })
        this.filtres = []
        // this._serviceB.setActivePageName('test2')
    }


    /*
    mounted()
    {
        this.setDataForGenericTab(this.$store.getters['candidat/candidats'])
    }
    */

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                // TODO Formaliser tout ça
                const line = [
                    { label: 'Editer',      item: result.id,                        type: 'action',  typeAction: 'edit',        class: 'commons_first_action_button', icon:'pen', disabled: false },
                    { label: 'Commentaire', item: result.id,                        type: 'action',  typeAction: 'openComment', class: 'commons_comment_button', icon:'comment-alt', disabled: false },
                    { label: '',            item: result.code,                      type: 'text',    typeAction: null,          class: '' },
                    { label: '',            item: result.identite,                  type: 'text',    typeAction: null,          class: '' },
                    { label: '',            item: result.formattedDateNaissance,    type: 'text',    typeAction: null,          class: '' },
                    { label: '',            item: result.email,                     type: 'text',    typeAction: null,          class: '' }
                ]
                this.dataForTab.push(line)
            }
        }
    }


    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab()
    {
        this.filtres =
        [
            { libelle: 'Code',      defautOptionlibelle: 'Rechercher un',   model: 'code',      value: '', index: 'code',       datas: this.$store.state.etablissement.code,        loading: this.$store.state.etablissement.loading, options: { type: 'form', fieldsKey: 'code', strict: true } },
            { libelle: 'Name',      defautOptionlibelle: 'Rechercher un',   model: 'name',      value: '', index: 'name',       datas: this.$store.state.etablissement.name,        loading: this.$store.state.etablissement.loading, options: { type: 'form', fieldsKey: 'name' } },
            { libelle: 'Académie',  defautOptionlibelle: 'Rechercher une',  model: 'academie',  value: '', index: 'academie',   datas: this.$store.state.etablissement.academie,    loading: this.$store.state.etablissement.loading, options: { type: 'form', fieldsKey: 'academie' } },
            { libelle: 'Adresse',   defautOptionlibelle: 'Rechercher une',  model: 'adresse',   value: '', index: 'adresse',    datas: this.$store.state.etablissement.adresse,     loading: this.$store.state.etablissement.loading, options: { type: 'form', fieldsKey: 'adresse' } },
            { libelle: 'Ville',     defautOptionlibelle: 'Rechercher une',  model: 'ville',     value: '', index: 'ville',      datas: this.$store.state.etablissement.ville,       loading: this.$store.state.etablissement.loading, options: { type: 'form', fieldsKey: 'ville' } },
            { libelle: 'Type',      defautOptionlibelle: 'Rechercher un',   model: 'type',      value: '', index: 'type',       datas: this.$store.state.etablissement.type,        loading: this.$store.state.etablissement.loading, options: { type: 'form', fieldsKey: 'type' } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            const candidats         = this.$store.state.candidat.candidats
            let selectedCandidat    = null

            switch (paParams[0])
            {
                case 'edit':
                    // Récupération du candidat by ID
                    selectedCandidat = candidats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat)
                    {
                        this.editCandidat(selectedCandidat)
                    }
                    break

                case 'openComment':
                    // Récupération du candidat by ID
                    selectedCandidat = candidats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat)
                    {
                        this.openCommentaireCandidat(selectedCandidat)
                    }
                    break

                case 'sortHandler':
                    this.loadHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.loadHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                default:
                    break
            }
        }
    }


    /**
     * Récupération sur le serveur des 100 premiers candidats s'ils ne sont pas déja
     * présents dans le state
     */
    loadCandidatIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            if (this.$store.getters['candidat/candidats'].length === 0)
            {
                this.$store.dispatch('candidat/getCandidats', { sort: 'name', direction: 'asc' }).then((result) =>
                {
                    resolve(result)
                })
            }
            else
            {
                resolve(true)
            }
        })
    }


    /**
     * Initialisation des données candidats affichées dans le tableau ainsi que les compteurs
     */
    initDatas (poCandidats: any)
    {
        if (poCandidats && poCandidats.data && poCandidats.data.data)
        {
            this.setDataForGenericTab(poCandidats.data.data)
        }
    }


    /**
     * Appel d'une nouvelle page de candidats lors du scroll
     */
    loadHandler (params: any)
    {
        this.$store.dispatch('candidat/getMoreCandidats', params)
    }


    /**
     * Edition d'un candidat: enregistrement du candidat en tant que candidat sélectionné
     * et affichage de la modale
     */
    editCandidat (item: any)
    {
        if (item && item.id !== undefined)
        {
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
            this.showModalEditionCandidat = true
        }
    }

    /**
     * Edition des commentaires associés à un candidat
     */
    openCommentaireCandidat (item: any)
    {
        if (item && item.id !== undefined)
        {
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
            this.showModalEditionCommentCandidat = true
        }
    }

    /**
     * Fermeture de la modale d'édition candidat
     */
    reinitShowModalCandidat ()
    {
        this.showModalEditionCandidat = false
    }

    /**
     * Fermeture de la modale d'édition des commentaires candidats
     */
    reinitShowModalCommentCandidat ()
    {
        this.showModalEditionCommentCandidat = false
    }
}
