






































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import store from '@/store/index'
import { ExaSignature } from '@exatech-group/signature'
import '@exatech-group/signature/dist/style.css'
import { DocumentSignableInterface } from '@/types/DocumentSignable'
import { base64ToArrayBuffer, checkIcone, formatDate } from '@/utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getAbilitiesFromEntityType } from '@/types/Document'

@Component({
    components: {
        ExaGenericTable,
        ExaSignature,
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('documentsignable', [
            'loading',
            'error',
            'documentSignableTypes',
            'documentsSignables',
            'documentsSignablesToSign',
            'documentSignableSelect',
            'source_pdf',
            'links',
            'totalRows',
            'lastPage',
            'totalPage',
            'sortby'
        ]),
        ...mapState('documentsignable', [
            'documentSignableTypes',
            'documentsSignables',
            'documentsSignablesToSign',
            'documentSignableSelect',
            'source_pdf',
            'totalRows',
            'meta'
        ]),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        Ability() {
            return Ability
        }
    },
    methods: {
        ...mapActions('documentsignable', [
            'getDocumentsSignables',
            'getDocumentSignablePdf',
            'signDocumentSignable',
            'getEnumTypesDeDocuments'
        ])
    },
    beforeMount() {
        store.dispatch('documentsignable/getEnumTypesDeDocuments')
    }
})
export default class SignatureDocuments extends Vue {
    // DATAS
    fields = [
        { key: 'edit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'signature_context', label: 'État', sortable: false, class: 'text-center', type: 'text' },
        { key: 'type', label: 'Type', sortable: true, class: '', type: 'text' },
        { key: 'updated_at', label: 'Date', sortable: true, class: '', type: 'text' },
        { key: 'name', label: 'Intitulé', sortable: true, class: '', type: 'text' },
        { key: 'validated_at', label: 'Date de signature', sortable: true, class: 'text-start', type: 'text' }
    ]

    filtresEnumType: any
    filtres: any = []

    modeSignature = false
    modeValidation = false
    showAnnulationSignature = false
    showConsultDocument = false
    signature = ''
    showError = false
    message_error = ''
    documentsignableSelectTampon: any
    documentsSignablesListeFullCourante: any
    totalDocumentsSignables = 0

    modeMultiSignature = false
    indiceSignatureRegroupe = 0
    selectionToSign: Array<any> = []
    chargementEncours = false
    showAnnulationRegroupSignature = false
    showSignRegroup = false
    signatureRegroup = ''
    documents: Array<any> = []
    modeRapport = false
    retourSigne: Array<any> = []
    retourProblemes: Array<any> = []

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filterOn = false
    rebuiltFullListeOnly = false
    stickyHeader = true
    dossier_id = 0
    params = []
    param_json: any
    filtre_original: any = []
    filtreCourant_StringValue = ''
    filtreUseFullListe = true
    showModalSignParLotImpossible = false
    backListe = false
    config = {
        toolbar: {
            toolbarViewerRight: {
                presentationMode: false,
                openFile: false,
                viewBookmark: false,
                secondaryToolbarToggle: false
            }
        }
    }

    source_doc: any = ''
    pdf_app: any = null
    file_name = ''

    firstload = true // Permet de préciser qu'on est sur le premier chargement de l'interface pour prendre en compte un éventuel forçage du filtrage sur les documents signés (s'il y a des documents à signer).
    forceDocToSignedFilter = true // Permet de préciser si on force la création du filtre sur un filtrage par documents signés. par défaut à true
    currentFilterContext = '-' // Détermine le contexte de filtre actuellement en cours. Permet de réappliquer le filtre qui était appliqué à la table lorsqu'on revient d'une signature.
    dataForTab: any = []

    @Watch('documentsSignables')
    setRoute() {
        if (
            this.$route.query.mode === 'signature' &&
            this.$route.query.contrat !== null &&
            this.$route.query.entity_type !== null &&
            this.source_doc === ''
        ) {
            this.activationModeSignature(
                parseInt(this.$route.query.contrat.toString()),
                this.$route.query.entity_type.toString()
            )
        } else {
            this.dataForTab = []
            this.setDataForGenericTab(this.$store.state.documentsignable.documentsSignables)
        }
    }

    @Watch('modeSignature')
    checkModeValidation() {
        if (!this.modeSignature) {
            // on sort d'une signature (par validation ou annulation), on revient sur le filtre qui était appliqué à l'origine sur la table.
            this.buildFiltreDetail(this.currentFilterContext)
        }
    }

    @Watch('$route')
    goBack() {
        if (this.$route.query && !this.$route.query.mode && this.modeSignature) {
            this.modeSignature = false
        } else if (
            this.$route.query.mode === 'signature' &&
            this.$route.query.contrat !== null &&
            this.$route.query.entity_type !== null &&
            this.source_doc === ''
        ) {
            this.activationModeSignature(
                parseInt(this.$route.query.contrat.toString()),
                this.$route.query.entity_type.toString()
            )
        }
    }

    @Watch('documentSignableTypes')
    // on attend d'avoir la liste des types avant d'init la table et son filtre
    majBuildFiltre() {
        this.initTable()
    }

    @Watch('user_session_id')
    refreshInterface() {
        this.load()
        this.initTable()
    }

    // METHODS
    initTable() {
        const paramsDocsASigner = { 'filter-signature_context': 0, page: 1, sort: 'updated_at', direction: 'asc' }
        this.$store.commit('documentsignable/SET_LOADING', true)
        this.$store.dispatch('documentsignable/getDocumentsSignables', paramsDocsASigner).then(() => {
            if (this.$store.getters['documentsignable/toSignedDocumentsNumber'] > 0) {
                // si premier chargement d'interface avec au moins un document à signer, alors on force l'affichage du filtre sur les documents à signer.
                this.currentFilterContext = '0'
                this.forceDocToSignedFilter = true
                this.buildFiltre()
                this.firstload = false
            } else {
                // sinon, on construit le filtre qui va faire se relancer la requête de base
                this.currentFilterContext = '-'
                this.forceDocToSignedFilter = false
                this.firstload = false
                this.buildFiltre()
            }
        })
    }

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const ability = getAbilitiesFromEntityType(result.entity_type).manage
                const can = this.$store.getters['auth/can'](ability)
                const icone = checkIcone(ability, can)
                const puce_etat = []

                if (result) {
                    switch (true) {
                        case result.validated_at === null:
                            puce_etat.push({ name: 'circle', class: 'text-info', title: 'Document à signer' })
                            break
                        case result.validated_at !== null:
                            puce_etat.push({ name: 'circle', class: 'text-success', title: 'Document signé' })
                            break
                    }
                }

                const line = [
                    {
                        label: icone.label,
                        item: result,
                        type: 'action',
                        typeAction: 'edit',
                        class: 'commons_first_action_button',
                        icon: icone.icon,
                        disabled: false
                    },
                    { label: '', item: puce_etat, type: 'icons', typeAction: null, class: 'text-center' },
                    { label: '', item: result.type, type: 'text', typeAction: null, class: '' },
                    { label: '', item: formatDate(result.updated_at), type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    {
                        label: '',
                        item: result.validated_at ? formatDate(result.validated_at) : 'Non signé',
                        type: 'text',
                        typeAction: null,
                        class: 'text-start'
                    }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    // Construction des filtres selon contexte
    buildFiltre() {
        if (this.forceDocToSignedFilter) {
            this.buildFiltreDetail(0)
            this.forceDocToSignedFilter = false
        } else {
            this.buildFiltreDetail('-')
        }
    }

    // Construction des filtres selon options
    buildFiltreDetail(valueDocumentsFilter: any) {
        this.currentFilterContext = valueDocumentsFilter

        const options_etat = []
        options_etat.push({ index: 0, name: 'Documents à signer' })
        options_etat.push({ index: 1, name: 'Documents signés' })

        const options_type = []
        for (const t in this.$store.state.documentsignable.documentSignableTypes) {
            options_type.push({
                index: this.$store.state.documentsignable.documentSignableTypes[t].key,
                name: this.$store.state.documentsignable.documentSignableTypes[t].name
            })
        }

        this.filtres = [
            {
                libelle: 'Etat de signature',
                defautOptionlibelle: 'Rechercher un état',
                model: 'signature_context',
                value: valueDocumentsFilter,
                index: 'signature_context',
                datas: options_etat,
                loading: this.$store.getters['documentsignable/loading'],
                options: { type: 'deroulant', fieldsKey: 'signature_context', strict: true }
            },
            {
                libelle: 'Type',
                defautOptionlibelle: 'Rechercher un',
                model: 'entity_type',
                value: '',
                index: 'type',
                datas: options_type,
                loading: this.$store.getters['documentsignable/loading'],
                options: { type: 'deroulant', fieldsKey: 'type', strict: true }
            },
            {
                libelle: 'Intitulé',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null,
                loading: this.$store.getters['documentsignable/loading'],
                options: { type: 'form', fieldsKey: 'name', strict: true }
            }
        ]
    }

    // Récupère la valeur de contexte du filtre en cours
    getParamsFilterContext(params: any) {
        let returnCurrentFilterContext: string
        if (params['filter-signature_context'] !== undefined) {
            returnCurrentFilterContext = params['filter-signature_context']
        } else {
            returnCurrentFilterContext = '-'
        }
        return returnCurrentFilterContext
    }

    // création de la collection complète en fonction d'une demande de filtrage ou de tri
    filtreSortHandler(params: any) {
        if (!this.firstload) {
            this.$store.commit('documentsignable/SET_LOADING', true)
            this.$store.dispatch('documentsignable/getDocumentsSignables', params)
        }
    }

    // ajout des nouvelles entrées de liste lors d'un scroll
    loadHandler(params: any) {
        this.$store.commit('documentsignable/SET_LOADING', true)
        this.params = params
        this.$store.dispatch('documentsignable/getMoreDocumentsSignables', this.params)
    }

    // Events
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const documents: Array<any> = this.$store.state.documentsignable.documentsSignables
            let selecteDocument: any = null

            switch (paParams[0]) {
                case 'edit':
                    selecteDocument = documents.filter(
                        (d: any) => d.id === paParams[1].id && d.entity_type === paParams[1].entity_type
                    )[0]
                    if (selecteDocument) {
                        this.signatureDocument(selecteDocument)
                    }
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                default:
                    break
            }
        }
    }

    checkIsInSelectionToSign(id: number, entity_type: string) {
        if (this.selectionToSign.length > 0) {
            for (let i = 0; i < this.selectionToSign.length; i++) {
                if (this.selectionToSign[i].id === id && this.selectionToSign[i].entity_type === entity_type) {
                    return true
                }
            }
        }
        return false
    }

    getDocumentById(id: number, entity_type: string) {
        for (let i = 0; i < this.$store.state.documentsignable.documentsSignables.length; i++) {
            if (
                id === this.$store.state.documentsignable.documentsSignables[i].id &&
                entity_type === this.$store.state.documentsignable.documentsSignables[i].entity_type
            ) {
                return this.$store.state.documentsignable.documentsSignables[i]
            }
        }
        return null
    }

    // Ajoute un document à la sélection
    addToSelectionToSign(id: number, entity_type: string) {
        if (!this.checkIsInSelectionToSign(id, entity_type)) {
            this.selectionToSign.push(this.getDocumentById(id, entity_type))
        }
    }

    // Retire un document de la sélection
    removeFromSelectionToSign(id: number, entity_type: string) {
        if (this.selectionToSign.length > 0) {
            for (let i = 0; i < this.selectionToSign.length; i++) {
                if (this.selectionToSign[i].id === id && this.selectionToSign[i].entity_type === entity_type) {
                    this.selectionToSign.splice(i, 1)
                }
            }
        }
    }

    moveTo(id: number) {
        if (!this.chargementEncours && !this.showSignRegroup) {
            for (let i = 0; i < this.$store.state.documentsignable.documentsSignables.length; i++) {
                if (id === this.$store.state.documentsignable.documentsSignables[i].id) {
                    this.indiceSignatureRegroupe = i
                    this.loadPdfModeRegroupe()
                }
            }
        }
    }

    moveIndiceRegroupe(direction: string) {
        if (direction === 'back' && this.indiceSignatureRegroupe > 0) {
            this.indiceSignatureRegroupe--
            this.loadPdfModeRegroupe()
        } else if (
            direction === 'forward' &&
            this.indiceSignatureRegroupe <= this.$store.state.documentsignable.documentsSignables.length - 1
        ) {
            this.indiceSignatureRegroupe++
            this.loadPdfModeRegroupe()
        }
    }

    loadPdfModeRegroupe() {
        this.chargementEncours = true
        this.$store.state.documentsignable.source_pdf = ''
        this.source_doc = ''
        this.$store
            .dispatch('documentsignable/getDocumentSignablePdf', {
                entity_type:
                    this.$store.state.documentsignable.documentsSignables[this.indiceSignatureRegroupe].entity_type,
                documentId: this.$store.state.documentsignable.documentsSignables[this.indiceSignatureRegroupe].id
            })
            .then(() => {
                this.source_doc = base64ToArrayBuffer(this.$store.state.documentsignable.source_pdf)
                this.chargementEncours = false
            })
    }

    // Active le mode signature par lot
    btnSignGroupHandler() {
        if (this.modeSignature === false && this.modeMultiSignature === false) {
            this.modeRapport = false
            this.indiceSignatureRegroupe = 0
            this.modeMultiSignature = true
            this.showSignRegroup = false
            this.loadPdfModeRegroupe()
        } else if (this.modeMultiSignature === true) {
            this.initTable()
            this.modeRapport = false
            this.modeMultiSignature = false
        }
    }

    // Ferme la popup de signature par lot impossible
    closeModalSignParLotImpossible() {
        this.showModalSignParLotImpossible = false
    }

    // Annule la signature par lot
    annulationRegroupement() {
        if (this.selectionToSign.length > 0) {
            this.showAnnulationRegroupSignature = true
        } else {
            this.btnSignGroupHandler()
        }
    }

    closeAnnulationRegroupSignature() {
        this.showAnnulationRegroupSignature = false
    }

    confirmAnnulationRegroupement() {
        this.btnSignGroupHandler()
        this.selectionToSign = []
        this.showAnnulationRegroupSignature = false
        // Réinit de la source erreur potentiellement non vide pour éviter qu'elle ne se affiché qu'au prochain chargement de la popup modale.
        this.$store.state.documentsignable.error = null
    }

    showSignRegroupHandler() {
        this.showSignRegroup = true
    }

    // saveSignatureRegroupWithToken (signature: string) {
    saveSignatureRegroup(params: any) {
        this.signatureRegroup = params.signature
        this.documents = []
        for (let i = 0; i < this.selectionToSign.length; i++) {
            this.documents.push({
                entity_type: this.selectionToSign[i].entity_type,
                id: this.selectionToSign[i].id
            })
        }
        const datas = {
            documents: this.documents
        }
        this.$store.dispatch('documentsignable/getTokensSignature', {
            mode: params.mode,
            payload: datas
        })
    }

    // Enregistre la signature si besoin et update le document
    saveSignatureRegroupSuite(tokenSMS: string) {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Signature en cours ...', infosToaster)
        this.$store.state.documentsignable.error = null
        const datas = {
            signature: this.signatureRegroup,
            documents: this.documents,
            code: tokenSMS
        }

        this.$store.dispatch('documentsignable/signDocumentsSignables', {
            payload: datas
        })
            .then((responses) => {
                if (!this.$store.state.documentsignable.error) {
                    this.modeSignature = false
                    this.modeValidation = false

                    // Réinit de la source pdf sinon au prochain chargement de pdf, on verra l'éventuel ancien pdf affiché le temps que le nouveau se charge.
                    this.$store.state.documentsignable.source_pdf = ''

                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast(
                        'Votre signature a bien été enregistrée pour les documents selectionnés.',
                        succesToaster
                    )

                    this.retourSigne = []
                    for (let i = 0; i < responses.data.data.signes.length; i++) {
                        this.retourSigne.push(
                            'le document ' + responses.data.data.signes[i].name + ' a été signé avec succès.'
                        )
                    }
                    this.retourProblemes = []
                    for (let j = 0; j < responses.data.data.problemes.length; j++) {
                        this.retourProblemes.push(
                            'Un problème est survenu lors de la signature du document ' +
                                responses.data.data.problemes[j].ref.name +
                                ' : ' +
                                responses.data.data.problemes[j].message
                        )
                    }
                    this.selectionToSign = []
                    this.modeRapport = true
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Ouvre le mode signature/consultation pour un document
    signatureDocument(document: any) {
        this.file_name = document.name
        this.activationModeSignature(document.id, document.entity_type)
    }

    activationModeSignature(contrat_id: number, entity_type: string) {
        // rajouté entity_type pour le filtrage, car dans le cas des conventions et annexes de convention, l'id seul ne suffit plus à cibler le document à signer
        // Récupération du document sélectionné dans la liste
        this.documentsignableSelectTampon = this.$store.state.documentsignable.documentsSignables.filter(
            (current_doc: DocumentSignableInterface) =>
                current_doc.id === contrat_id && current_doc.entity_type === entity_type
        )
        if (this.documentsignableSelectTampon.length !== 0) {
            this.documentsignableSelectTampon = this.documentsignableSelectTampon[0]

            // MAJ du documentSignableSelect dans le store
            this.$store.state.documentsignable.documentSignableSelect = this.documentsignableSelectTampon
            this.$store.state.documentsignable.source_pdf = ''
            this.source_doc = ''

            this.$store
                .dispatch('documentsignable/getDocumentSignablePdf', {
                    entity_type: this.documentsignableSelectTampon.entity_type,
                    documentId: this.documentsignableSelectTampon.id
                })
                .then(() => {
                    this.source_doc = base64ToArrayBuffer(this.$store.state.documentsignable.source_pdf)

                    if (
                        this.$store.getters['auth/can'](getAbilitiesFromEntityType(entity_type).view) &&
                        !this.$store.getters['auth/can'](getAbilitiesFromEntityType(entity_type).manage)
                    ) {
                        // uniquement le droit de lecture seule
                        this.showConsultDocument = true
                    } else if (this.$store.state.documentsignable.documentSignableSelect.validated_at === null) {
                        if (!this.$route.query.mode) {
                            this.$router.push({
                                path: '/signature_documents',
                                query: {
                                    mode: 'signature',
                                    contrat: this.documentsignableSelectTampon.id,
                                    entity_type: this.documentsignableSelectTampon.entity_type
                                }
                            })
                        }
                        this.modeSignature = true
                    } else {
                        this.showConsultDocument = true
                    }
                })
        } else {
            this.$router.push({ path: '/signature_documents' })
        }
    }

    valideSignatureContratHandler(params: any) { // pour test
        console.log('valideSignatureContratHandler params', params)
        if (!this.$store.state.documentsignable.error) {
            this.initTable()
            this.modeSignature = false
            this.modeValidation = false

            // Réinit de la source pdf sinon au prochain chargement de pdf, on verra l'éventuel ancien pdf affiché le temps que le nouveau se charge.
            this.$store.state.documentsignable.source_pdf = ''

            const idSucces = 't_succes_' + Math.random()
            const succesToaster = {
                id: idSucces,
                toaster: 'b-toaster-top-right',
                variant: 'success',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 5000
            }
            this.$bvToast.toast(
                'Votre signature a bien été enregistrée pour le document suivant : ' +
                                this.$store.state.documentsignable.documentSignableSelect.name,
                succesToaster
            )
            this.initTable()
        }
    }

    // Enregistre la signature sur le document et demande de Token
    saveSignature(params: any) {
        console.log('rentré dans saveSignature')
        this.signature = params.signature

        // Requete demande de token
        this.$store.dispatch('documentsignable/getTokenSignature', {
            model: this.$store.state.documentsignable.documentSignableSelect.entity_type,
            id: this.$store.state.documentsignable.documentSignableSelect.id,
            mode: params.mode
        })
    }

    // Confirme la signature du document
    validationSignatureDocument(tokenSMS: string) {
        if (this.signature !== '') {
            this.$store.state.documentsignable.error = null
            const datas = {
                signature: this.signature,
                code: tokenSMS
            }

            this.$store.dispatch('documentsignable/signDocumentSignable', {
                entity_type: this.$store.state.documentsignable.documentSignableSelect.entity_type,
                documentId: this.$store.state.documentsignable.documentSignableSelect.id,
                payload: datas
            })
                .then(() => {
                    if (!this.$store.state.documentsignable.error) {
                        this.initTable()
                        this.modeSignature = false
                        this.modeValidation = false

                        // Réinit de la source pdf sinon au prochain chargement de pdf, on verra l'éventuel ancien pdf affiché le temps que le nouveau se charge.
                        this.$store.state.documentsignable.source_pdf = ''

                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast(
                            'Votre signature a bien été enregistrée pour le document suivant : ' +
                                this.$store.state.documentsignable.documentSignableSelect.name,
                            succesToaster
                        )
                        this.initTable()
                    }
                })
        }
    }

    // Affiche si des erreurs de signature sont retournées
    showErrorSignature(error: string) {
        if (error !== '') {
            this.$store.state.documentsignable.error = error
        }
    }

    // Annule la signature en cours
    confirmAnnulationSignature() {
        this.signature = ''
        this.modeSignature = false
        this.showAnnulationSignature = false
        this.$router.push({ path: '/signature_documents' })

        // Réinit de la source erreur potentiellement non vide pour éviter qu'elle ne se affiché qu'au prochain chargement du popup modale.
        this.$store.state.documentsignable.error = null
        // Réinit de la source pdf sinon au prochain chargement de pdf, on verra l'éventuel ancien pdf affiché le temps que le nouveau se charge.
        this.$store.state.documentsignable.source_pdf = ''
    }

    // Ferme la consultation d'un document
    closeConsultationDocument() {
        this.showConsultDocument = false
    }

    // Bouton Retour
    back() {
        this.showAnnulationSignature = true
    }

    // Ferme la popup d'annulation de signature
    closeAnnulationSignature() {
        this.showAnnulationSignature = false
    }

    /** Signe le document avec un certificat */
    saveSignatureWithCertificat() {
        this.$store.state.documentsignable.error = null
        const datas = {
            signature: 'certificat',
            code: 'certificat'
        }

        this.$store.dispatch('documentsignable/signDocumentSignable', {
            entity_type: this.$store.state.documentsignable.documentSignableSelect.entity_type,
            documentId: this.$store.state.documentsignable.documentSignableSelect.id,
            payload: datas
        })
            .then(() => {
                if (!this.$store.state.documentsignable.error) {
                    this.initTable()
                    this.modeSignature = false
                    this.modeValidation = false

                    // Réinit de la source pdf sinon au prochain chargement de pdf, on verra l'éventuel ancien pdf affiché le temps que le nouveau se charge.
                    this.$store.state.documentsignable.source_pdf = ''

                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast(
                        'Votre signature a bien été enregistrée pour le document suivant : ' +
                            this.$store.state.documentsignable.documentSignableSelect.name,
                        succesToaster
                    )
                }
            })
    }

    /** Signe les documents avec un certificat */
    saveSignatureWithCertificatRegroup() {
        console.log('signature avec certificat')
        this.documents = []
        for (let i = 0; i < this.selectionToSign.length; i++) {
            this.documents.push({
                entity_type: this.selectionToSign[i].entity_type,
                id: this.selectionToSign[i].id
            })
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Signature en cours ...', infosToaster)
        this.$store.state.documentsignable.error = null
        const datas = {
            signature: 'certificat',
            documents: this.documents,
            code: 'certificat'
        }

        this.$store.dispatch('documentsignable/signDocumentsSignables', {
            payload: datas
        })
            .then((responses) => {
                if (!this.$store.state.documentsignable.error) {
                    this.initTable()
                    this.modeSignature = false
                    this.modeValidation = false

                    // Réinit de la source pdf sinon au prochain chargement de pdf, on verra l'éventuel ancien pdf affiché le temps que le nouveau se charge.
                    this.$store.state.documentsignable.source_pdf = ''

                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast(
                        'Votre signature a bien été enregistrée pour les documents selectionnés.',
                        succesToaster
                    )

                    this.retourSigne = []
                    for (let i = 0; i < responses.data.data.signes.length; i++) {
                        this.retourSigne.push(
                            'le document ' + responses.data.data.signes[i].name + ' a été signé avec succès.'
                        )
                    }
                    this.retourProblemes = []
                    for (let j = 0; j < responses.data.data.problemes.length; j++) {
                        this.retourProblemes.push(
                            'Un problème est survenu lors de la signature du document ' +
                                responses.data.data.problemes[j].ref.name +
                                ' : ' +
                                responses.data.data.problemes[j].message
                        )
                    }
                    this.selectionToSign = []
                    this.modeRapport = true
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    load() {
        if (this.$route.query.mode === 'signature') {
            this.modeSignature = true
        }
    }

    signatureMode = []
    mounted() {
        for(let i = 0; i < this.$store.state.auth.user.parameters.length; i ++) {
            if(this.$store.state.auth.user.parameters[i].code === 'signatureMode') {
                this.signatureMode = this.$store.state.auth.user.parameters[i].value
                // this.codeClient = 'CCMP'
            }
        }
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
