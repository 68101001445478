













































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { PosteContext, PosteInterface } from '@/types/Poste'
import { Ability } from '@/types/Ability'
import PhoneInput from '@/components/Tools/PhoneInput.vue'

@Component({
    computed: {
        ...mapGetters('centre', ['error', 'countError', 'error_conflict', 'loading', 'loading_search']),
        ...mapState('centre', ['error', 'countError', 'error_conflict', 'usersFromSearch', 'centreSelect']),
        ...mapState('poste', ['postes']),
        ...mapGetters('auth', ['is_intervenant_centre']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    components: {
        PhoneInput
    }
})
export default class PopupAddDossierSol extends Vue {
    @Prop() poste_select?: any
    @Prop() contexteLogistique?: string
    @Prop() remaining_time?: number
    @Prop() showCancel?: boolean

    Ability = Ability
    // DATAS
    name_intervenant = ''
    first_name = ''
    email = ''
    telephone = ''
    phoneNumber = ''
    name_search = ''
    poste_id = 0
    showError = false
    showErrorConflict = false
    messageErreurSimple = ''
    lockInput = false
    confirmation = 0
    confirmation_choose_intervenant = 0
    msgSuccess_toast = ''
    centreUserActual: any = {}
    centreUserActual_PosteId = 0
    usersListFromSearch = {}
    showResultats = false
    selectedIntervenant = null
    liste_postes: Array<PosteInterface> = []
    addNewIntervenant = false
    selectIntervenant = false
    tempTravail = '100'
    list_options: Array<string> = []

    // METHODS

    beforeMount () {
        this.initCentreUserActual()
    }

    @Watch('centre_select')
    InitGlobal () {
        this.initCentreUserActual()
    }

    @Watch('contexteLogistique')
    initInterface () {
        this.initCentreUserActual()
    }

    /** Initialise l'intervenant sélectionné ou non */
    get_UserByPoste (poste_id: number, user_id: number) {
        const usersMatch: Array<any> = []
        if (this.$store.state.centre.centreSelect) {
            for (const indexUser in this.$store.state.centre.centreSelect.centreusers) {
                const posteMatch = this.$store.state.centre.centreSelect.centreusers[indexUser].postes.filter((poste: any) => parseInt(poste.id) === poste_id)
                if (posteMatch.length > 0 && this.$store.state.centre.centreSelect.centreusers[indexUser].user_id === user_id) {
                    this.centreUserActual_PosteId = posteMatch[0].id
                    usersMatch.push(this.$store.state.centre.centreSelect.centreusers[indexUser])
                    this.tempTravail = posteMatch[0].temps
                    break
                }
            }
            return usersMatch
        }
    }

    /** Met à jour la valeur locale du numéro de téléphone saisi retourné par le composant PhoneInput */
    updatePhoneNumberInputData () {
        if ((this.$refs.phoneInput as any)) {
            if ((this.$refs.phoneInput as any).infosPhoneInput) {
                if ((this.$refs.phoneInput as any).infosPhoneInput.isValidatedPhoneNumber) {
                    this.telephone = (this.$refs.phoneInput as any).infosPhoneInput.formattedPhoneNumber
                    this.phoneNumber = (this.$refs.phoneInput as any).infosPhoneInput.formattedPhoneNumber
                } else {
                    this.telephone = ''
                }
            }
        }
    }

    /** Initialisation de l'utilisateur */
    initCentreUserActual () {
        if (this.$props.poste_select.user_id !== 0) {
            this.centreUserActual = this.get_UserByPoste(this.$props.poste_select.poste_id, this.$props.poste_select.user_id)
            this.selectedIntervenant = this.$props.poste_select.user_id
        } else {
            this.centreUserActual = {}
            this.selectedIntervenant = null
        }

        this.liste_postes = this.$store.state.poste.postes.filter((p: any) => p.context !== PosteContext.CONTEXT_RESPONSABLE_CENTRE && p.context !== PosteContext.CONTEXT_CHEF_CENTRE && p.context !== PosteContext.CONTEXT_CORRESPONDANT_ORAL)

        // Init des options pour le temps de travail
        this.initOptionsTempsTravail()
    }

    /** Fermeture des formulaires */
    closeHandler () {
        this.name_intervenant = ''
        this.first_name = ''
        this.email = ''
        this.name_search = ''
        this.telephone = ''
        this.poste_id = 0
        this.showError = false
        this.showErrorConflict = false
        this.messageErreurSimple = ''
        this.lockInput = false
        this.confirmation = 0
        this.confirmation_choose_intervenant = 0
        this.showResultats = false
        this.selectedIntervenant = null
        this.addNewIntervenant = false
        this.selectIntervenant = false
    }

    /** Update des champs sur changement de valeur */
    updateChamps (e: any) {
        switch (e.target.name) {
            case 'name':
                this.name_intervenant = e.target.value
                break
            case 'first_name':
                this.first_name = e.target.value
                break
            case 'email':
                this.email = e.target.value
                break
            case 'nameSearch':
                this.name_search = e.target.value
                break
            case 'confirmation':
                this.confirmation = this.confirmation === e.target.value ? 0 : e.target.value
                break
            case 'poste_id':
                this.poste_id = this.poste_id === e.target.value ? 0 : e.target.value
                break
            case 'confirmation_choose_intervenant':
                this.confirmation_choose_intervenant = this.confirmation_choose_intervenant === e.target.value ? 0 : e.target.value
                break
            case 'telephone':
                this.telephone = String(e.target.value).replace(/[^+0-9]/g, '').replace(/^00/, '+').replace(/^0/, '+33')
                break
            default:
                break
        }
    }

    /** Lance la recherche à partir d'un mot cle pour rechercher un intervenant */
    submitRechercherIntervenant (e: Event) {
        e.preventDefault()
        if (this.name_search !== '') {
            const data = {
                centre_id: this.$store.state.centre.centreSelect.id,
                payload: { name: this.name_search + '%' }
            }
            this.selectedIntervenant = null
            this.$store
                .dispatch('centre/getUsersFromSearch', data)
                .then(() => {
                    this.usersListFromSearch = this.$store.state.centre.usersFromSearch.data
                    this.showResultats = true
                })
                .catch((error) => {
                    console.log('ko:' + error)
                })
        }
    }

    /** Création du nouvel intervenant */
    submitDossier (e: any) {
        e.preventDefault()
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.updatePhoneNumberInputData()

        this.msgSuccess_toast = 'Intervenant ajouté.'
        const data: any = {
            email: this.email,
            name: this.name_intervenant,
            first_name: this.first_name,
            telephone: this.telephone,
            centre_id: this.$store.state.centre.centreSelect.id,
            poste_id: this.$props.poste_select.poste_id,
            temps: this.tempTravail,
            confirmation: this.confirmation
        }

        this.$store.dispatch('centre/addDossierUser', data)
            .then(() => {
                this.showError = false
                this.showErrorConflict = false
                this.messageErreurSimple = ''
                this.first_name = ''
                this.name_intervenant = ''
                this.telephone = ''
                this.email = ''
                this.confirmation = 0
                // Fermeture de la popup aprés validation
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(this.msgSuccess_toast, succesToaster)

                this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                    this.$store.dispatch('centre/getCentreUsers', { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                        this.$store.dispatch('centre/getUsersCount', { centre_id: this.$store.state.centre.centreSelect.id })
                        this.initCentreUserActual()
                        this.$emit('reset')
                    })
                })
            })
            .catch(() => {
                if (this.$store.state.centre.error_conflict && this.$store.state.centre.error_conflict.message) {
                    this.showErrorConflict = true
                    this.showError = false
                    this.lockInput = true
                } else {
                    this.showErrorConflict = false
                    this.showError = true
                    this.messageErreurSimple = 'Une erreur est survenue :'
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Confirmation de la sélection de l'intervenant existant */
    submitChoixIntervenant (e: any) {
        e.preventDefault()

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.msgSuccess_toast = 'Intervenant ajouté.'
        const data: any = {
            user_id: this.selectedIntervenant,
            centre_id: this.$store.state.centre.centreSelect.id,
            poste_id: this.$props.poste_select.poste_id,
            temps: this.tempTravail,
            confirmation: this.confirmation
        }

        this.$store.dispatch('centre/addDossierUser', data)
            .then(() => {
                this.showError = false
                this.showErrorConflict = false
                this.messageErreurSimple = ''
                this.first_name = ''
                this.name_intervenant = ''
                this.telephone = ''
                this.email = ''
                this.confirmation = 0
                // Fermeture de la popup aprés validation
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(this.msgSuccess_toast, succesToaster)

                this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                    this.$store.dispatch('centre/getCentreUsers', { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                        this.$store.dispatch('centre/getUsersCount', { centre_id: this.$store.state.centre.centreSelect.id })
                        this.initCentreUserActual()
                        this.$emit('reset')
                    })
                })
            })
            .catch(() => {
                if (this.$store.state.centre.error_conflict && this.$store.state.centre.error_conflict.message) {
                    this.showErrorConflict = true
                    this.showError = false
                    this.lockInput = true
                } else {
                    this.showErrorConflict = false
                    this.showError = true
                    this.messageErreurSimple = 'Une erreur est survenue :'
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Remplace un intervenant sur le poste */
    remplaceIntervenant () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Dissociation en cours ...', infosToaster)

        const data = {
            centre_id: this.$store.state.centre.centreSelect.id,
            centre_user_id: this.centreUserActual[0].id,
            payload: { _method: 'DELETE', poste_id: this.centreUserActual_PosteId }
        }

        this.$store.dispatch('centre/dissociateUserFromCentre', data)
            .then(() => {
                this.showError = false
                this.messageErreurSimple = ''

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Dissociation terminée.', succesToaster)

                this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                    this.$store.dispatch('centre/getCentreUsers', { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                        this.initCentreUserActual()
                    })
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
                this.showError = true
                this.messageErreurSimple = 'Une erreur est survenue :'
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** update le temps de travail d'un intervenant */
    updateTempTravail () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Modification en cours ...', infosToaster)

        const data = {
            centre_id: this.$store.state.centre.centreSelect.id,
            centre_user_id: this.centreUserActual[0].id,
            poste_id: this.centreUserActual_PosteId,
            payload: { temps: this.tempTravail }
        }

        this.$store.dispatch('centre/updateUserFromCentre', data)
            .then(() => {
                this.showError = false
                this.messageErreurSimple = ''

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Modification terminée.', succesToaster)

                this.$store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                    this.$store.dispatch('centre/getCentreUsers', { centre_id: this.$store.state.centre.centreSelect.id }).then(() => {
                        this.initCentreUserActual()
                    })
                })
            })
            .catch((error) => {
                console.log('ko:' + error)
                this.showError = true
                this.messageErreurSimple = 'Une erreur est survenue :'
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Initialise la liste des options pour le temps de travail */
    initOptionsTempsTravail () {
        switch (true) {
            case this.$props.remaining_time === 25:
                this.list_options = ['25', '100']
                this.tempTravail = '25'
                break
            case this.$props.remaining_time === 50:
                this.list_options = ['50', '100']
                this.tempTravail = '50'
                break
            case this.$props.remaining_time === 75:
                this.list_options = ['75', '100']
                this.tempTravail = '75'
                break
            case this.$props.remaining_time >= 100:
                this.list_options = ['25', '50', '75', '100']
                this.tempTravail = '100'
                break
        }
    }
}
