





































import { Watch, Prop, Vue, Component } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapState } from 'vuex'
import { isObject } from '@/utils/helpers'
/**
 * Composant relatif à l'import de candidats et aux dossiers de handicaps
 */
@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapState('candidat', ['liste_dossiers', 'loading', 'loading_import']),
        ...mapState('amenagement', ['import_done'])
    }
})
export default class PopupImportAmenagements extends Vue {
    @Prop() showEdit?: boolean;
    showModal?: boolean = false
    showErrorElement = false
    element_error = null
    fileSingleFromInput: any = null

    isObject = isObject

    /**
     * Affichage ou non de la modale
     */
    @Watch('showEdit')
    showNoShow () {
        this.showModal = this.$props.showEdit
    }

    /**
     * Importation du fichier selectionné
     */
    importDatas ()
    {
        const payload = new FormData()
        payload.set('document', this.fileSingleFromInput)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Importation en cours ...', infosToaster)
        this.$store.dispatch('amenagement/importAmenagements', payload)
            .then(() => {
                if (this.$store.state.amenagement.import_done === 'DONE') {
                    this.$store.dispatch('amenagement/getAmenagements').then(() => {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Importation terminée.', succesToaster)
                        this.closeModal()
                    })
                } else {
                    this.showErrorElement = true
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Fermeture de la modale
     */
    closeModal() {
        this.showErrorElement = false
        this.element_error = null
        this.$emit('reinitShow', null)
    }

    /** Affiche les erreurs d'un fichier */
    showErrorsElement (dossier: any) {
        this.element_error = dossier
        this.showErrorElement = true
    }

    /** Ferme la visualisation des errors */
    closeErrorElements () {
        this.element_error = null
        this.showErrorElement = false
    }

    // Selectionne un fichier
    selectFile (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            this.fileSingleFromInput = (target.files as FileList)[0]
        }
    }
}
