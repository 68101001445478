



















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapMutations, mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        FontAwesomeIcon
    },
    computed: {
        ...mapGetters('etablissement', ['etablissements']),
        ...mapGetters('concour', ['banques'])
    },
    methods: {
        ...mapMutations('dossieracademique', ['UPDATE_DATAS_DERNIER_DIPLOME', 'UPDATE_DATAS_DERNIER_DIPLOME_AUTRE'])
    }
})
export default class SelectEtablissements extends Vue {
    @Prop() selectedValues?: string
    @Prop() other?: string
    @Prop() readOnly?: boolean
    @Prop({default: 'CCMP'}) mode_formulaire?: string

    listeEtablissements: Array<any> = []
    selectedEtablissements: Array<any> = []
    selected_type = null
    selected_academie = null
    selected_etablissement = null
    selected_type_classe = []
    selected_filiere = []

    liste_types_classes: Array<any> = []

    types_etablissement = [
        { id: 'Classes préparatoires aux grandes écoles', value: 'Classes préparatoires aux grandes écoles' },
        { id: 'Lycées et autres établissements de secondaire', value: 'Lycées et autres établissements de secondaire' },
        { id: 'Université - Facultés et IUT', value: 'Université - Facultés et IUT' },
        { id: 'École d\'ingénieurs', value: 'École d\'ingénieurs' },
        { id: 'École de commerce, santé, social, fonctionnaires, éducation', value: 'École de commerce, santé, social, fonctionnaires, éducation' },
        { id: 'Autre', value: 'Autre' }
    ]

    academies = [
        { id: 'Aix-Marseille', value: 'Aix-Marseille' },
        { id: 'Amiens', value: 'Amiens' },
        { id: 'Besançon', value: 'Besançon' },
        { id: 'Bordeaux', value: 'Bordeaux' },
        { id: 'Caen', value: 'Caen' },
        { id: 'Clermont-Ferrand', value: 'Clermont-Ferrand' },
        { id: 'Corse', value: 'Corse' },
        { id: 'Créteil', value: 'Créteil' },
        { id: 'Dijon', value: 'Dijon' },
        { id: 'Grenoble', value: 'Grenoble' },
        { id: 'Guadeloupe', value: 'Guadeloupe' },
        { id: 'Guyane', value: 'Guyane' },
        { id: 'La Réunion', value: 'La Réunion' },
        { id: 'Lille', value: 'Lille' },
        { id: 'Limoges', value: 'Limoges' },
        { id: 'Lyon', value: 'Lyon' },
        { id: 'Martinique', value: 'Martinique' },
        { id: 'Mayotte', value: 'Mayotte' },
        { id: 'Montpellier', value: 'Montpellier' },
        { id: 'Nancy-Metz', value: 'Nancy-Metz' },
        { id: 'Nantes', value: 'Nantes' },
        { id: 'Nice', value: 'Nice' },
        { id: 'Nouvelle Calédonie', value: 'Nouvelle Calédonie' },
        { id: 'Orléans-Tours', value: 'Orléans-Tours' },
        { id: 'Paris', value: 'Paris' },
        { id: 'Poitiers', value: 'Poitiers' },
        { id: 'Polynésie Française', value: 'Polynésie Française' },
        { id: 'Reims', value: 'Reims' },
        { id: 'Rennes', value: 'Rennes' },
        { id: 'Rouen', value: 'Rouen' },
        { id: 'Saint Pierre et Miquelon', value: 'Saint Pierre et Miquelon' },
        { id: 'Strasbourg', value: 'Strasbourg' },
        { id: 'Toulouse', value: 'Toulouse' },
        { id: 'Versailles', value: 'Versailles' },
        { id: 'Wallis et Futuna', value: 'Wallis et Futuna' },
        { id: 'Autre', value: 'Autre' }
    ]

    isNotAddable () {
        return (this.selected_type === null || this.selected_academie === null || this.selected_etablissement === null)
    }

    selectedBanquesToString (bs: Array<any>) {
        if (bs && bs.length) {
            return bs.map((b: any) => b.name).join(', ')
        }
        return '-'
    }


    ajouterEtablissement () {
        const type_temp = this.types_etablissement.find((t: any) => t.id === this.selected_type)
        const academie_temp = this.academies.find((a: any) => a.id === this.selected_academie)
        const etablissement_temp = this.$store.state.etablissement.etablissements.find((m: any) => m.id === this.selected_etablissement)
        const types_classes_temp = []
        if (this.selected_type_classe.length) {
            for(let j = 0; j < this.selected_type_classe.length; j++) {
                if(this.selected_type_classe[j] !== null) {
                    types_classes_temp.push(this.liste_types_classes.find((b: any) => b.id === this.selected_type_classe[j]))
                }
            }
        }
        const filieres_temp = []
        if (this.selected_filiere.length) {
            for(let k = 0; k < this.selected_filiere.length; k++) {
                if(this.selected_filiere[k] !== null) {
                    const filiere_temp = this.$store.getters['concour/banques'].find((b: any) => b.id === this.selected_filiere[k])
                    filieres_temp.push({ id: filiere_temp.id, name: filiere_temp.name })
                }
            }
        }

        if(type_temp && academie_temp && etablissement_temp) {
            let currentId = 0
            for(let i = 0; i < this.selectedEtablissements.length; i++) {
                if(this.selectedEtablissements[i].id > currentId || this.selectedEtablissements[i].id === currentId) {
                    currentId = this.selectedEtablissements[i].id + 1
                }
            }

            const result: any = {
                type: type_temp,
                academie: academie_temp,
                etablissement: etablissement_temp
            }

            if (types_classes_temp && types_classes_temp.length) {
                result.types_classe = types_classes_temp
            }
            if (filieres_temp && filieres_temp.length) {
                result.filieres = filieres_temp
            }

            this.selectedEtablissements.push(result)
        }

        this.selected_type = null
        this.selected_academie = null
        this.selected_etablissement = null
        this.selected_filiere = []
        this.selected_type_classe = []
        this.updateDatasPro()
    }


    deleteEtablissement (index: number) {
        this.selectedEtablissements.splice(index, 1)
        this.updateDatasPro()
    }

    // Event change sur le select
    updateDatasPro (e?: Event) {
        if(this.mode_formulaire === 'CCS') {
            this.$store.commit('dossieracademique/UPDATE_SELECTED_ETABLISSEMENTS',this.selectedEtablissements)
        } else {
            if (e?.target !== null) {
                const target = e?.target as HTMLTextAreaElement
                if (target.name === 'etablissement') {
                    this.$store.commit('dossieracademique/UPDATE_DATAS_DERNIER_DIPLOME', target.value)
                }
                if (target.name === 'etablissement_other') {
                    this.$store.commit('dossieracademique/UPDATE_DATAS_DERNIER_DIPLOME_AUTRE', target.value)
                }
            }
        }
    }

    @Watch('selected_type')
    getTypeEtablissement () {
        if (this.selected_type !== '') {
            this.loadEtablissements()
        }
    }

    @Watch('selected_academie')
    getAcademie () {
        if (this.selected_academie !== '') {
            this.loadEtablissements()
        }
    }

    async loadEtablissements () {
        const academie = this.selected_academie
        const type_etablissement = this.selected_type
        if (academie !== '' && type_etablissement !== '') {
            await this.$store.dispatch('etablissement/getEtablissements', { academie: academie, type: type_etablissement, perPage: 1000 })
        }
    }

    async mounted () {
        if (this.$store.getters['concour/banques'].length === 0) {
            await this.$store.dispatch('concour/getConcours')
        }
        if (this.selectedValues) {
            this.selectedEtablissements = [...this.selectedValues]
        }

        this.liste_types_classes = []
        const banques = this.$store.getters['concour/banques']
        for(let i = 0; i < banques.length; i++ ) {
            this.liste_types_classes.push({id:banques[i].id, name: banques[i].name })
            this.liste_types_classes.push({id:banques[i].id + '*', name: banques[i].name+' *' })
        }
    }
}
