






















































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import store from '@/store/index'
import { PosteContext, PosteType } from '@/types/Poste'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'




@Component({
    components: {
        ErrorDisplay,
        ExaGenericTable,
        FontAwesomeIcon
    },
    computed: {
        ...mapGetters('pv', ['pvs', 'postesPv', 'loading', 'error', 'avancemmentSelectionPct', 'epreuvesCompletesSelection', 'pvSigne']),
        ...mapGetters('poste', ['postes', 'loading', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        intervSelectionManquant() {
            if (this.$store.getters['pv/intervSelectionManquant']) {
                const intervSelectionManquant = this.$store.getters['pv/intervSelectionManquant'].toString().split('.')
                return intervSelectionManquant[1] ? `${intervSelectionManquant[0]}.${intervSelectionManquant[1].substring(0, 2)}` : intervSelectionManquant[0]
            }
            return 0
        }
    }
})
export default class SelectionIntervenants extends Vue {
    // DATAS

    filter = ''
    filterOn = []
    stickyHeader = true
    dossier_id = 0
    params = null
    fields: any = []
    dataForTab: any = []

    filtres = [
        {
            libelle: 'Épreuves',
            model: 'epreuve.name',
            value: '',
            index: 'name',
            datas: null,
            loading: false
        }
    ]

    page = 1
    tabParams = {}
    timeOut = 0

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }


    // Creation des fields (maintenant pour generic fields)
    buildFields () {
        this.fields = []
        this.fields.push({ key: 'matiere_name', label: 'Matière', sortable: false, type: 'text', class: 'text-start ps-4' })
        this.fields.push({ key: 'epreuvecorrections_count', label: 'Nb. épreuves', sortable: false, type: 'text', class: 'text-center' })

        for (let i = 0; i < this.$store.state.pv.postesPv.length; i++) {
            this.fields.push({
                key: this.$store.state.pv.postesPv[i].name,
                label: this.$store.state.pv.postesPv[i].name,
                sortable: false,
                type: 'text',
                class: 'text-center'
            })
        }
        this.fields.push({
            key: 'avancemmentPctSelection',
            label: 'Sélection',
            sortable: false,
            type: 'text',
            class: 'text-center'
        })
        this.fields.push({ key: 'intervManquantSelect', label: 'Intervenants manquants', sortable: false, type: 'text', class: 'text-center' })
        this.fields.push({
            key: 'etatAvancementPv',
            label: 'Procès-verbal',
            sortable: false,
            type: 'text',
            class: 'text-start'
        })
        this.fields.push({ key: 'actionEpreuveSelect', label: '', class: '', type: 'text' })
    }

    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const matiere = result.matiere_name ? result.matiere_name : '-'
                const nbEpreuves = result.epreuvecorrections_count ? result.epreuvecorrections_count : '-'
                const line: any = [

                    { label: 'Matière',             item: matiere,             type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'Nb. Épreuves',             item: nbEpreuves,             type: 'text', typeAction: null, class: 'text-center' }

                ]
                // Les deux let sont là pour calculer la colonne Selection
                let valeurSelectionne   = 0
                let valeurTotale        = 0
                for (let i = 0; i < this.$store.state.pv.postesPv.length; i++) {
                    const prop =  this.$store.state.pv.postesPv[i].name
                    let infos = ''
                    if (result.postes[prop]) {
                        valeurSelectionne   += result.postes[prop].selection
                        valeurTotale        += result.postes[prop].besoin
                        const diff = result.postes[prop].besoin - result.postes[prop].precedent
                        infos =  result.postes[prop].selection + '/' + result.postes[prop].besoin + ' (' + (diff > 0 ? '+' : '') + diff + ')'
                    } else {
                        infos = ''
                    }
                    line.push({ label: '',             item: infos,             type: 'text', typeAction: null, class: 'text-center' })
                }

                // ligne selection
                const pourcentage = valeurSelectionne && valeurTotale ? Math.trunc(valeurSelectionne * 100 / valeurTotale) + '%' : '-'
                line.push({ label: '',             item: pourcentage,             type: 'text', typeAction: null, class: 'text-center' })

                // ligne intervenants manquants
                let intervenantsManquants: any = '-'
                if (valeurSelectionne && valeurTotale) {
                    intervenantsManquants = (valeurTotale - valeurSelectionne).toString().split('.')
                    intervenantsManquants = intervenantsManquants[1] ? `${intervenantsManquants[0]}.${intervenantsManquants[1].substring(0, 2)}` : intervenantsManquants[0]
                }
                line.push({ label: '',             item: intervenantsManquants,             type: 'text', typeAction: null, class: 'text-center' })

                // proces verbal
                const etatProces = () => {
                    if (result.submitted_at && result.validated_at) {
                        return 'Signé'
                    } else if (result.submitted_at) {
                        return 'Terminé'
                    } else {
                        return 'En cours'
                    }
                }
                line.push({ label: '',             item: etatProces(),             type: 'text', typeAction: null, class: 'text-start' })

                // Lien
                line.push({
                    label: 'actionEpreuveSelect',
                    item:
                    [{
                        name: 'arrow-circle-right',
                        class: 'text-info',
                        infos : result
                    }],
                    type: 'icons',
                    typeAction: 'actionEpreuveSelect',
                    class: 'text-center'
                })
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    break
                case 'actionEpreuveSelect':
                    this.actionEpreuveSelect(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    /** ouvre la page selection intervenant  */
    actionEpreuveSelect(paParams1: any) {
        const selection = paParams1[0].infos.id
        const session   = this.$store.getters['auth/user_session_id']
        const matiere   = paParams1[0].infos.matiere_id
        const link      = '/selections/' + selection + '/sessions/' + session + '/matiere/' + matiere
        // <router-link :to="'/selections/' + row.item.id + '/session/' + row.item.session_id + '/matiere/' + row.item.matiere_id">
        this.$router.push(link)
    }

    // Chargement de l'interface
    load () {
        this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_PEDAGOGIQUE, context: PosteContext.CONTEXT_SELECTION }).then(() => {
            store.dispatch('pv/getPvs', { isPrecedente: false, filters: null, postes: this.$store.state.poste.postes }).then(() => {
                this.buildFields()
                this.setDataForGenericTab(this.$store.state.pv.pvs)
            })
        })
    }

    mounted () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}



