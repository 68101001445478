



























import { Vue, Component }   from 'vue-property-decorator'
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome'

import ExemplesInscriptions        from '@/views/Exemples/ExemplesInscriptions.vue'

/**
 * Composant principal de la gestion des candidats
*/
@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ExemplesInscriptions
    }
})
export default class Exemples extends Vue
{
}
