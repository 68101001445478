


















































































































































import { mapGetters, mapMutations, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { EtatDepot } from '@/types/DossierAdministratif'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapGetters('dossieradministratif', ['error', 'errorUpload', 'contrats', 'loading', 'dossierAdministratifSelect', 'source_pdf']),
        ...mapState('dossieradministratif', ['error', 'contrats', 'loading', 'dossierAdministratifSelect', 'contrats_documents', 'set_bulletin', 'source_pdf']),
        ...mapMutations('dossieradministratif', ['SET_DOSSIER_ADMINISTRATIF_CURRENT', 'SET_BULLETINS', 'MAJ_PRESENCE_BULLETINS']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp,
        ErrorDisplay
    }
})
export default class DepotBulletinSalaire extends Vue {
    @Prop() show?: boolean
    @Prop() mode?: string

    Ability = Ability

    contrat_select: any = null
    formData = new FormData()
    activeBtnAjout = false
    file_select = false
    retour_serveur = null
    error_aff = null
    contrats_combo: any = []
    contrats_total = 0
    bulletins_list: any = []
    confirmDeleteBulletin = false
    document_select = null
    modePreview = false
    errorInterface = ''
    fileInputKey = 0
    retourDepotArchive: any = null
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''

    @Watch('contrat_select')
    checkBtnAjout () {
        this.activeBtnAjout = !!(this.contrat_select && this.file_select);
    }

    @Watch('set_bulletin')
    getDocuments () {
        if (this.$store.state.dossieradministratif.set_bulletin) {
            // Filtres les contrats selon leurs états et s'ils ont deja un bulletin associé
            this.bulletins_list = []
            this.contrats_combo = this.$store.state.dossieradministratif.contrats.filter((c: any) => c.validated_at !== null)
            this.contrats_total = this.contrats_combo.length

            // Charge les documents liés aux contrats
            for (const c in this.$store.state.dossieradministratif.contrats) {
                const bulletins = this.$store.state.dossieradministratif.contrats[c].documents.filter((d: any) => d.type === 'bulletin')
                for (const b in bulletins) {
                    this.bulletins_list.push({ contrat: this.$store.state.dossieradministratif.contrats[c], bulletin: bulletins[b] })

                    // Le contrat a un bulletin de salaire, on le sort de la combo de creation
                    const index = this.contrats_combo.map((item: any) => item.id).indexOf(this.$store.state.dossieradministratif.contrats[c].id)
                    this.contrats_combo.splice(index, 1)
                }
            }
        }
    }

    // reset
    reset (close: boolean) {
        this.$store.state.dossieradministratif.error = null
        this.contrat_select = null
        this.activeBtnAjout = false
        this.file_select = false
        this.fileInputKey++ // La modification de la key va faire réséter l'input
        this.modePreview = false
        this.confirmDeleteBulletin = false
        this.document_select = null
        this.errorInterface = ''
        this.$store.commit('dossieradministratif/SET_BULLETINS', false)
        this.error_aff = null
        this.retourDepotArchive = null

        if (close) {
            this.$emit('close', true)
        }
    }

    // Select le bulletin de salaire
    selectFile (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]

            // this.formData.append('collection_name', 'bulletin')
            // this.formData.append('document', file)

            // On est en dépôt d'un seul fichier à la fois donc pas d'append.
            this.formData.set('collection_name', 'bulletin')
            this.formData.set('document', file)

            this.file_select = true

            if (this.$props.mode === 'simple') {
                this.activeBtnAjout = !!(this.contrat_select && this.file_select);
            } else {
                this.activeBtnAjout = true
            }
        }
    }

    // Ajouter un bulletin de salaire
    addBulletinSalaire () {
        this.$store.dispatch('dossieradministratif/uploadDocumentContrat', { dossier_id: this.$store.state.dossieradministratif.dossierAdministratifSelect.id, contrat_id: this.contrat_select.id, payload: this.formData })
            .then((response) => {
                const index = this.contrats_combo.map((item: any) => item.id).indexOf(this.contrat_select.id)
                this.contrats_combo.splice(index, 1)
                this.bulletins_list.push({ contrat: this.contrat_select, bulletin: response.data.data })
                let etat_depot = EtatDepot.DEPOT_EN_COURS
                if (this.$store.state.dossieradministratif.dossierAdministratifSelect.contrats.length === this.bulletins_list.length) {
                    etat_depot = EtatDepot.DEPOT_COMPLET
                }

                this.$store.commit('dossieradministratif/MAJ_PRESENCE_BULLETINS', { dossier_id: this.$store.state.dossieradministratif.dossierAdministratifSelect.id, etat: etat_depot })

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Dépôt terminé !', succesToaster)

                this.activeBtnAjout = false
                if (this.contrats_total < 2) {
                    // Si l'intervenant n'avait qu'un contrat, on referme l'interface, sinon on laisse en visuel l'ensemble des bulletins déjà déposés pour les contrats de l'intervenant
                    this.reset(true)
                }
            })
            .catch((error) => {
                this.error_aff = error
            })
    }

    // Déposer une archive
    deposerArchive () {
        this.error_aff = null
        this.retourDepotArchive = null
        // Push vers le save des bulletins de salaire
        this.$store.dispatch('dossieradministratif/uploadArchive', { payload: this.formData })
            .then((response) => {
                const params = {
                    page:1,
                    sort:'user.name',
                    direction:'asc'
                }
                this.$store.dispatch('dossieradministratif/getDossiersAdministratifs', params)
                this.activeBtnAjout = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Dépôt terminé !', succesToaster)
                this.retourDepotArchive = response.data
            })
            .catch((error) => {
                this.retourDepotArchive = error.response.data
                this.retourDepotArchive.isError = true
            })
    }

    // Message de confirmation de suppression du bulletin
    showConfirmDeleteBulletin (document: any) {
        this.confirmDeleteBulletin = !this.confirmDeleteBulletin
        this.document_select = document
    }

    // Suppression du document
    deleteBulletin (document: any) {
        this.$store.dispatch('dossieradministratif/deleteDocumentContrat', { dossier_id: document.contrat.dossier_administratif_id, contrat_id: document.contrat.id, document_id: document.bulletin.id })
            .then(() => {
                const index = this.bulletins_list.map((item: any) => item.bulletin.id).indexOf(document.bulletin.id)
                this.bulletins_list.splice(index, 1)
                this.confirmDeleteBulletin = false

                let etat_depot = EtatDepot.DEPOT_EN_COURS
                if (this.bulletins_list.length === 0) {
                    etat_depot = EtatDepot.NON_DEPOSER
                }

                this.$store.commit('dossieradministratif/MAJ_PRESENCE_BULLETINS', { dossier_id: this.$store.state.dossieradministratif.dossierAdministratifSelect.id, etat: etat_depot })

                this.contrats_combo.push(document.contrat)
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée !', succesToaster)
            })
            .catch((error) => {
                this.error_aff = error
            })
    }

    mounted () {
        this.retourDepotArchive = null
    }

    // Aperçu du pdf
    previewBulletin (document: any) {
        this.source_doc = ''
        this.$store.dispatch('dossieradministratif/getPDFDocumentContrat', { dossier_id: document.contrat.dossier_administratif_id, contrat_id: document.contrat.id, document_id: document.bulletin.id })
            .then(() => {
                this.source_doc = base64ToArrayBuffer(this.$store.state.dossieradministratif.source_pdf)
                this.modePreview = true
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // Retour
    back () {
        this.modePreview = false
        this.$store.commit('dossieradministratif/SET_PDF_SOURCE', null)
    }
}
