

















































































































































































































import { Vue, Component /* , Watch */ } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { Ability } from '@/types/Ability'
import { codeRestrict, getFileNameFromHeader, isObject } from '@/utils/helpers'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { TypeDossier, getEtatTraitementDossier, EtatTraitementDossier } from '@/types/Candidat'

@Component({
    components: {
        Table,
        ExaGenericTable,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('posteOuvert', ['posteOuverts', 'loading', 'totalRows', 'lastPage', 'totalPage', 'meta', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})

export default class ListePostes extends Vue {
    codeRestrict = codeRestrict
    // DATAS
    params = 'sort=name&direction=asc'
    dataForTab: Array<any> = []
    filtres: any = []

    currentSession: any = null

    genericfields = [
        { key: 'name_ars', label: 'ARS', sortable: true, class: 'text-left col-min-width', type: 'text' },
        { key: 'name', label: 'Poste', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'concour.name', label: 'Spécialité', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'candidat.code', label: 'Code', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'candidat.name', label: 'Nom', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'liste.name', label: 'Liste', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' }
    ]

    getEtatTraitementDossier = getEtatTraitementDossier
    EtatTraitementDossier = EtatTraitementDossier

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    Ability = Ability


    file: any = null
    showModalImportPoste = false
    messagesErreur: Array<string> = []
    importEnCours = false

    showModalValideListePostes = false

    showModalAddPoste = false
    posteTemp: any = null
    listeConcours: Array<any> = []

    liste_dossiers_postes = []

    currentTimeoutID = -1
    currentTimeoutIDArray: Array<any> = []

    showErrorDossier = false
    dossier_error = null

    isObject = isObject
    exportingIsWorking =  false

    // METHODS

    addPoste(): void {
        this.posteTemp = {
            concour_id: 0,
            code_ars:'',
            name_ars:'',
            code:'',
            name:'',
            name_etablissement:'',
            name_service:''
        }
        this.$store.dispatch('concour/getConcours', {
            session_id: this.$store.state.session.sessionSelect.id,
            params: { 'filter-is_banque': 1 }
        })
            .then(() => {
                this.listeConcours = this.$store.getters['concour/banques']
                this.showModalAddPoste = true
            })
    }

    cancelAddPoste(): void {
        this.showModalAddPoste = false
    }

    addPosteSuite(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('posteOuvert/addPosteOuvert',  this.posteTemp)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$store.dispatch('posteOuvert/getPosteOuverts', this.params)
                    .then(() => {
                        this.setDataForGenericTab(this.$store.state.posteOuvert.posteOuverts)
                        this.$bvToast.toast('Poste ajouté avec succès !', succesToaster)
                        this.showModalAddPoste = false
                    })
            })
            .finally(() => this.$bvToast.hide(idInfo))
    }

    exportListePostes(): void {
        this.exportingIsWorking = true
        let fileName = 'export_liste_poste.xlsx'

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('posteOuvert/export')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * cancelValideListePostes
     * Cette fonction permet d'annuler la validation de la liste des postes.
     *
     * @returns {void}
     */
    cancelValideListePostes(): void {
        this.showModalValideListePostes = false
    }

    /**
     * valideListePostes
     * Cette fonction permet de valider la liste des postes en affichant la modal correspondante.
     *
     * @returns {void}
     */
    valideListePostes(): void {
        this.showModalValideListePostes = true
    }

    /**
     * valideListePostesSuite
     * Cette fonction permet de valider ou d'invalider la liste des postes et affiche les toasts correspondants.
     *
     * @returns {void}
     */
    valideListePostesSuite(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const posteOuvertValidate = this.currentSession.poste_ouverts_validated_at !== null ? 0 : 1
        this.$store.dispatch('posteOuvert/posteOuvertUpdateSession', { poste_ouvert_validate: posteOuvertValidate })
            .then((response) => {
                this.currentSession.poste_ouverts_validated_at = response.data.data.poste_ouverts_validated_at === undefined ? null : response.data.data.poste_ouverts_validated_at
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Liste des postes ' + (posteOuvertValidate === 1 ? 'validée' : 'invalidée') + ' avec succès !', succesToaster)
                this.showModalValideListePostes = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * openImportPoste
     * Cette fonction permet d'ouvrir la modal d'importation des postes et charge les listes de dossiers.
     *
     * @returns {void}
     */
    openImportPoste(): void {
        this.showModalImportPoste = true
        this.loadListesDossiers()
    }

    /**
     * showErrorsFichiers
     * Cette fonction permet d'afficher les erreurs liées à un dossier spécifique en activant le flag showErrorDossier.
     *
     * @param {any} dossier - Le dossier contenant les erreurs à afficher.
     * @returns {void}
     */
    showErrorsFichiers(dossier: any): void {
        this.dossier_error = dossier
        this.showErrorDossier = true
    }

    /**
     * closeErrorFichiers
     * Cette fonction permet de fermer la fenêtre d'affichage des erreurs en réinitialisant les valeurs des variables dossier_error et showErrorDossier.
     *
     * @returns {void}
     */
    closeErrorFichiers(): void {
        this.dossier_error = null
        this.showErrorDossier = false
    }

    /**
     * changement de fichier ?
     *
     * @param event - L'événement de changement de fichier.
     * @returns {void}
     */
    fileChange(event: any): void {
        this.file = event.target.files[0]
    }

    /**
     * loadListesDossiers
     * Cette fonction permet de charger la liste des dossiers de type "importation de choix de postes" en utilisant l'action 'getDossiers' du store Vuex.
     *
     * @returns {void}
     */
    loadListesDossiers(): void {
        this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_IMPORT_CHOIX_POSTES })
            .then(() => this.liste_dossiers_postes = this.$store.state.candidat.liste_dossiers)
        this.currentTimeoutID = setTimeout(() => {
            this.currentTimeoutIDArray.push(this.currentTimeoutID)
            this.loadListesDossiers()
        }, 5000)
    }

    /**
     * reinitTimeOut
     * Cette fonction permet de réinitialiser tous les timeouts en utilisant les identifiants stockés dans currentTimeoutIDArray.
     *
     * @returns {void}
     */
    reinitTimeOut(): void {
        for (const idTimeout in this.currentTimeoutIDArray) {
            clearTimeout(parseInt(this.currentTimeoutIDArray[idTimeout]))
        }
        if (this.currentTimeoutID !== -1) {
            clearTimeout(this.currentTimeoutID)
        }
        this.currentTimeoutIDArray = []
        this.currentTimeoutID = -1
    }

    /**
     * envoiFichier
     * Cette fonction permet d'envoyer un fichier pour l'importation des données des postes ouverts. Elle affiche également des toasts pour informer l'utilisateur sur l'état de l'envoi.
     *
     * @returns {void}
     */
    envoiFichier(): void {
        // Création du toaster en cours
        this.messagesErreur = []
        const idInfo        = 't_info_' + Math.random()
        const infosToaster  = {
            id:             idInfo,
            toaster:        'b-toaster-top-right',
            variant:        'primary',
            noCloseButton:  true,
            fade:           true,
            noAutoHide:     true
        }

        this.$bvToast.toast('Envoi en cours ...', infosToaster)
        this.importEnCours = true

        // Appel de la fonction pour importer les etabs
        this.$store.dispatch('posteOuvert/import', this.file)
            .then(() => {
                this.importEnCours = false

                const idSucces      = 't_succes_' + Math.random()
                const succesToaster = {
                    id:             idSucces,
                    toaster:        'b-toaster-top-right',
                    variant:        'success',
                    noCloseButton:  true,
                    fade:           true,
                    autoHideDelay:  5000
                }
                this.$bvToast.toast('Fichier déposé avec succès !', succesToaster)
                this.$store.dispatch('posteOuvert/getPosteOuverts')
                    .then(() => {
                        this.showModalImportPoste = false
                        this.reinitTimeOut()
                    })
            })
            .catch((error) => {
                this.importEnCours = false

                // Création du message d'erreurs
                if (error.response && error.response.data && error.response.data.errors) {
                    for (const err in error.response.data.errors) {
                        if (error.response.data.errors[err]) {
                            if (error.response.data.errors[err].row) {
                                const retourError = 'erreur ligne' + error.response.data.errors[err].row + ' : ' + error.response.data.errors[err].errors
                                this.messagesErreur.push(retourError)
                            }
                        }
                    }
                }

                // Toaster it's a fail !
                const idError = 't_error_' + Math.random()
                const errorToaster = {
                    id:             idError,
                    toaster:        'b-toaster-top-right',
                    variant:        'danger',
                    noCloseButton:  true,
                    fade:           true,
                    autoHideDelay:  5000
                }
                this.$bvToast.toast("Une erreur s'est produite lors de l'import", errorToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * closeModalImport
     * Cette fonction permet de fermer la modal d'importation des postes. Elle réinitialise les variables et effectue une action pour récupérer les postes ouverts.
     *
     * @returns {void}
     */
    closeModalImport(): void {
        this.messagesErreur                 = []
        this.showModalImportPoste = false
        this.reinitTimeOut()
        this.closeErrorFichiers()
        this.$store.dispatch('posteOuvert/getPosteOuverts', this.params)
            .then(() => this.setDataForGenericTab(this.$store.state.posteOuvert.posteOuverts))
    }

    // ---- generic table --------------------------
    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     *
     * @param {any} poData - Les données à afficher dans le tableau générique.
     * @param {boolean} isLoadMore - Indique si les données sont chargées en mode 'load more'.
     * @returns {Array<any>} - Les données formatées pour l'affichage dans le tableau générique.
     */
    setDataForGenericTab(poData: any, isLoadMore = false): Array<any> {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const line = [
                    { label: '', item: result.name_ars, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.concour ? result.concour.name : '', type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.candidat ? result.candidat.code : '', type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.candidat ? result.candidat.name + ' ' + result.candidat.first_name : '', type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.liste ? result.liste.name : '', type: 'text', typeAction: null, class: '' }
                ]

                this.dataForTab.push(line)
            }
        }
        return this.dataForTab
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     *
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        this.filtres = [
            {
                libelle: 'ARS',
                defautOptionlibelle: 'Rechercher un',
                model: 'name_ars',
                value: '',
                index: 'name_ars',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name_ars', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'Poste',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Spécialité',
                defautOptionlibelle: 'Rechercher une',
                model: 'concour_name',
                value: '',
                index: 'concour_name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'concour.name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'candidat_code',
                value: '',
                index: 'candidat_code',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'candidat.code' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Candidat',
                defautOptionlibelle: 'Rechercher un',
                model: 'candidat_name',
                value: '',
                index: 'candidat_name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'candidat.name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Liste',
                defautOptionlibelle: 'Rechercher une',
                model: 'liste_name',
                value: '',
                index: 'liste_name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'liste.name' } // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * Récupération des events de la table
     *
     * @param {any} paParams - Les paramètres de l'événement de la table.
     * @returns {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
            }
        }
    }

    // --------------------------------------

    /**
     * loadHandler
     * Cette fonction permet de charger les données des postes ouverts en utilisant les paramètres spécifiés. Si les paramètres sont différents de ceux déjà enregistrés, les données sont récupérées à l'aide de l'action 'getMorePosteOuverts' du store Vuex.
     *
     * @param {any} params - Les paramètres utilisés pour charger les données des postes ouverts.
     * @returns {void}
     */
    loadHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params

            this.$store.dispatch('posteOuvert/getMorePosteOuverts', params)
                .then(() => this.setDataForGenericTab(this.$store.state.posteOuvert.posteOuverts))
        }
    }

    /**
     * filtreSortHandler
     * Cette fonction permet de gérer le filtrage et le tri des données des postes ouverts en utilisant les paramètres spécifiés. Si les paramètres sont différents de ceux déjà enregistrés, les données sont récupérées à l'aide de l'action 'getPosteOuverts' du store Vuex.
     *
     * @param {any} params - Les paramètres utilisés pour filtrer et trier les données des postes ouverts.
     * @returns {void}
     */
    filtreSortHandler(params: any): void {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params

            this.$store.dispatch('posteOuvert/getPosteOuverts', params)
                .then(() => this.setDataForGenericTab(this.$store.state.posteOuvert.posteOuverts))
        }
    }

    /**
     * beforeUnmount
     * Cette fonction est appelée avant que le composant ne soit démonté. Elle réinitialise les timeouts en utilisant la fonction reinitTimeOut.
     *
     * @returns {void}
     */
    beforeUnmount(): void {
        this.reinitTimeOut()
    }

    /**
     * mounted
     * Cette fonction est appelée lorsque le composant est monté. Elle effectue les actions suivantes :
     * - Réinitialise les timeouts en utilisant la fonction reinitTimeOut.
     * - Ajoute des écouteurs d'événements pour la gestion de l'historique de navigation.
     * - Récupère la session actuelle à partir du store Vuex.
     *
     * @returns {void}
     */
    mounted(): void {
        window.onpopstate = () => {
            this.reinitTimeOut()
        }
        window.addEventListener('popstate', () => {
            this.reinitTimeOut()
        })
        this.reinitTimeOut()
        if (this.$store.state.session.sessionSelect) {
            this.currentSession = this.$store.state.session.sessionSelect
            this.setFiltersForGenericTab()
        } else {
            this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] })
                .then(() => {
                    this.currentSession = this.$store.state.session.sessionSelect
                    this.setFiltersForGenericTab()
                })
        }
    }
}
