






































import { Vue, Component, Watch }   from 'vue-property-decorator'
import { FontAwesomeIcon }  from '@fortawesome/vue-fontawesome'

import CandidatsInscriptions        from '@/views/Candidats/CandidatsInscriptions.vue'
import CandidatsMesuresHandicaps    from '@/views/Candidats/CandidatsMesuresHandicaps.vue'
import CandidatsTabEcrits           from '@/views/Candidats/CandidatsTabEcrits.vue'
import CandidatsTabOraux            from '@/views/Candidats/CandidatsTabOraux.vue'
import { mapGetters, mapState } from 'vuex'

/**
 * Composant principal de la gestion des candidats
*/
@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        CandidatsInscriptions,
        CandidatsMesuresHandicaps,
        CandidatsTabEcrits,
        CandidatsTabOraux
    }
})
export default class Candidats extends Vue
{
    tabSelected = ''

    route_actuelle = ''

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    load() {
        this.route_actuelle = this.$route.path

        if (this.route_actuelle === '/candidats_concours' && this.tabSelected === '') {
            this.tabSelected = 'inscriptions'
        } else if (this.route_actuelle === '/candidats_oraux' && this.tabSelected === '') {
            this.tabSelected = 'organisationOraux'
        }
    }

    beforeMount () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
