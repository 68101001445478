import { render, staticRenderFns } from "./CentresOraux.vue?vue&type=template&id=f17a4264&scoped=true&"
import script from "./CentresOraux.vue?vue&type=script&lang=ts&"
export * from "./CentresOraux.vue?vue&type=script&lang=ts&"
import style0 from "./CentresOraux.vue?vue&type=style&index=0&id=f17a4264&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f17a4264",
  null
  
)

export default component.exports