







































import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapMutations, mapState } from 'vuex'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        FontAwesomeIcon
    },
    methods: {
        ...mapMutations('dossieracademique', ['UPDATE_DATAS_DERNIER_DIPLOME', 'UPDATE_DATAS_DERNIER_DIPLOME_AUTRE'])
    }
})
export default class SelectMatieres extends Vue {
    @Prop() selectedValues?: Array<any>
    @Prop() other?: string
    @Prop() readOnly?: boolean
    @Prop({default: 'CCMP'}) mode_formulaire?: string

    listeMatieres: Array<any> = []
    matiereSelected:any = null

    selectedMatieres: Array<any> = []

    ajouterMatiere () {
        const matiere_temp = this.$store.state.matiere.matieres.find((m: any) => Number(m.id) === Number(this.matiereSelected))
        if(matiere_temp) {
            let currentId = 0
            for(let i = 0; i < this.selectedMatieres.length; i++) {
                if(this.selectedMatieres[i].id > currentId || this.selectedMatieres[i].id === currentId) {
                    currentId = this.selectedMatieres[i].id + 1
                }
            }
            this.selectedMatieres.push(matiere_temp)
        }
        this.matiereSelected = null
        this.cleanListeMatieres()
        this.updateDatasPro()
    }

    deleteMatiere (matiere_id: number) {
        const index = this.selectedMatieres.findIndex((d:any) => (d.id === matiere_id))
        this.selectedMatieres.splice(index, 1)
        this.cleanListeMatieres()
        this.updateDatasPro()
    }

    cleanListeMatieres () {
        this.listeMatieres = []
        this.listeMatieres = [...this.$store.state.matiere.matieres]
        for(let i = 0; i < this.selectedMatieres.length; i++) {
            const index = this.listeMatieres.findIndex((m: any) => Number(m.id) === Number(this.selectedMatieres[i].id))
            if(index !== -1) {
                this.listeMatieres.splice(index, 1)
            }
        }
    }


    // Event change sur le select
    updateDatasPro () {
        this.$store.commit('dossieracademique/UPDATE_DISCIPLINES_MAITRISEES', this.selectedMatieres)

    }

    mounted () {
        if(this.selectedValues) {
            this.selectedMatieres = [...this.selectedValues]
        }
        this.cleanListeMatieres()
    }
}
