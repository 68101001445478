










































































































import { Watch, Prop, Vue, Component } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapGetters, mapState } from 'vuex'
import { TypeDossier, getEtatTraitementDossier, EtatTraitementDossier } from '@/types/Candidat'
import { Ability } from '@/types/Ability'
import { isObject } from '@/utils/helpers'
/**
 * Composant relatif à l'import de candidats et aux dossiers de handicaps
 */
@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapState('candidat', ['liste_dossiers', 'loading', 'loading_import']),
        ...mapGetters('auth', [
            'authUser',
            'can',
            'cannot',
            'isA',
            'isNotA'
        ])
    }
})
export default class PopupImportCandidat extends Vue {
    @Prop() showEdit?: boolean;
    showModal?: boolean = false
    liste_dossiers_candidats: Array<any> = []
    liste_dossiers_handicaps: Array<any> = []
    liste_import_candidats_viatique: Array<any> = []
    selectedDossier = null
    getEtatTraitementDossier = getEtatTraitementDossier
    EtatTraitementDossier = EtatTraitementDossier
    showErrorDossier = false
    dossier_error = null
    lockActionsButtons = false
    Ability = Ability
    isObject = isObject

    /**
     * Affichage ou non de la modale
     */
    @Watch('showEdit')
    showNoShow () {
        this.showModal = this.$props.showEdit

        // Appel des listes
        this.loadListesDossiers()
    }

    file: any = null
    showModalImportPoste = false
    messagesErreur: Array<string> = []
    importEnCours = false

    /**
     * changement de fichier ?
     */
    fileChange (event: any) {
        this.file = event.target.files[0]
    }


    /**
     * Permet d'envoyer un fchier au back  ?
     */
    envoiFichier ()
    {
        // Création du toaster en cours
        this.messagesErreur = []
        const idInfo        = 't_info_' + Math.random()
        const infosToaster  = {
            id:             idInfo,
            toaster:        'b-toaster-top-right',
            variant:        'primary',
            noCloseButton:  true,
            fade:           true,
            noAutoHide:     true
        }

        this.$bvToast.toast('Envoi en cours ...', infosToaster)
        this.importEnCours = true

        // Appel de la fonction pour importer les etabs

        this.$store.dispatch('candidat/import', this.file)
            .then(() => {
                this.importEnCours = false

                const idSucces      = 't_succes_' + Math.random()
                const succesToaster =
                {
                    id:             idSucces,
                    toaster:        'b-toaster-top-right',
                    variant:        'success',
                    noCloseButton:  true,
                    fade:           true,
                    autoHideDelay:  5000
                }
                this.$bvToast.toast('Fichier déposé avec succès !', succesToaster)
                this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_IMPORT_CANDIDATS_VIATIQUE }).then(() => {
                    this.liste_import_candidats_viatique = this.$store.state.candidat.liste_dossiers
                })
            })
            .catch((error) => {
                this.importEnCours = false
                console.log('ko:' + error)

                // Création du message d'erreurs
                if (error.response && error.response.data && error.response.data.errors) {
                    for (const err in error.response.data.errors) {
                        if (error.response.data.errors[err]) {
                            if (error.response.data.errors[err].row) {
                                const retourError = 'erreur ligne' + error.response.data.errors[err].row + ' : ' + error.response.data.errors[err].errors
                                this.messagesErreur.push(retourError)
                            }
                        }
                    }
                }
                // Toaster it's a fail !
                const idError = 't_error_' + Math.random()
                const errorToaster = {
                    id:             idError,
                    toaster:        'b-toaster-top-right',
                    variant:        'danger',
                    noCloseButton:  true,
                    fade:           true,
                    autoHideDelay:  5000
                }
                this.$bvToast.toast("Une erreur s'est produite lors de l'import", errorToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Importation du fichier selectionné
     */
    importDatas () {
        this.$store.dispatch('candidat/updateDossier', { dossier_id: this.selectedDossier }).then(() => {
            this.selectedDossier = null
            this.loadListesDossiers()
        })
        // this.$emit('reinitShow', null)
    }

    /**
     * Fermeture de la modale
     */
    closeModal() {
        this.showErrorDossier = false
        this.dossier_error = null
        this.$emit('reinitShow', null)
    }

    /** Chargement des listes des dossiers */
    loadListesDossiers () {
        this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_DOSSIER_CANDIDATS }).then(() => {
            this.liste_dossiers_candidats = this.$store.state.candidat.liste_dossiers
        })
        this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_DOSSIER_HANDICAPS }).then(() => {
            this.liste_dossiers_handicaps = this.$store.state.candidat.liste_dossiers
        })
        this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_IMPORT_CANDIDATS_VIATIQUE }).then(() => {
            this.liste_import_candidats_viatique = this.$store.state.candidat.liste_dossiers
        })
        this.currentTimeoutID = setTimeout(() => {
            if (this.showModal) {
                this.currentTimeoutIDArray.push(this.currentTimeoutID)
                this.loadListesDossiers()
            } else {
                this.reinitTimeOut()
            }
        }, 5000)
    }

    /** Affiche les erreurs d'un fichier */
    showErrorsDossier (dossier: any) {
        this.dossier_error = dossier
        this.showErrorDossier = true
    }

    /** Ferme la visualisation des errors */
    closeErrorDossiers () {
        this.dossier_error = null
        this.showErrorDossier = false
    }

    currentTimeoutID = -1
    currentTimeoutIDArray: Array<any> = []
    reinitTimeOut() {
        // console.log('Dans reinitTimeOut - this.currentTimeoutIDArray', this.currentTimeoutIDArray)
        for (const idTimeout in this.currentTimeoutIDArray) {
            clearTimeout(parseInt(this.currentTimeoutIDArray[idTimeout]))
        }
        if (this.currentTimeoutID !== -1) {
            clearTimeout(this.currentTimeoutID)
        }
        this.currentTimeoutIDArray = []
        this.currentTimeoutID = -1
    }


    beforeUnmount () {
        this.reinitTimeOut()
    }

    mounted () {
        window.onpopstate = () => {
            this.reinitTimeOut()
        }
        window.addEventListener('popstate', () => {
            this.reinitTimeOut()
        })
        this.reinitTimeOut()
    }
}
