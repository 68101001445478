






































































import { Watch, Prop, Vue, Component }                                      from 'vue-property-decorator'
import { FontAwesomeIcon }                                                  from '@fortawesome/vue-fontawesome'
import { mapState, mapGetters }                                             from 'vuex'
import { TypeDossier, getEtatTraitementDossier, EtatTraitementDossier }     from '@/types/Candidat'
import { formatDate, isObject }                                                       from '@/utils/helpers'
import { Ability }                                                          from '@/types/Ability'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapState('candidat', ['liste_dossiers', 'loading', 'loading_import']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class PopupImportFichiersOrgaEcrits extends Vue {
    @Prop() showEdit?: boolean;
    showModal?: boolean                                     = false

    Ability = Ability
    isObject = isObject

    liste_fichiers_souhaits: Array<any>                       = []

    selectedFichier                                         = null
    getEtatTraitementDossier                                = getEtatTraitementDossier
    EtatTraitementDossier                                   = EtatTraitementDossier
    showErrorDossier                                        = false
    dossier_error                                           = null
    lockActionsButtons                                      = false

    tabSelected                                             = 'souhaits'

    loading_souhaits                                        = true

    formatDate                                              = formatDate

    needToWatchFilesState                                   = false
    currentTimeoutID                                        = -1
    currentTimeoutIDArray: Array<any>                       = []
    shouldRefreshTableCandiat                               = false // A partir du moment où on va lancer une importation ou init avec un fichier en cours de traitement, on va utiliser ce switch pour préciser à la sortie de l'interface de refresh la liste des candidats. Si on l'init à true, on le laisse ensuite à true.


    /**
     * Affichage ou non de la modale
     */
    @Watch('showEdit')
    showNoShow () {
        // si des timeouts étaient en cours, on les arrête
        if (this.currentTimeoutIDArray.length > 0) {
            this.reinitTimeOut()
        }

        this.showModal = this.$props.showEdit
        if (this.showModal) {
            this.initDatas('souhaits')
        }
    }

    @Watch('tabSelected')
    majTabSelected () {
        this.initDatas(this.tabSelected)
    }

    @Watch('needToWatchFilesState')
    gestionNeedToWatchFilesState () {
        // lance en récurcif le check des fichiers pour récupérer les états d'avancement (toutes les 5 secondes)
        if (this.needToWatchFilesState && this.showModal) {
            this.currentTimeoutID = setTimeout(() => {
                this.currentTimeoutIDArray.push(this.currentTimeoutID)
                switch (this.tabSelected) {
                    case 'souhaits': {
                        this.loadListesFichiersSouhaits()
                        this.gestionNeedToWatchFilesState()
                        break
                    }
                }
            }, 5000)
        } else {
            this.reinitTimeOut()
        }
    }

    /** réinitialise l'ensemble des éléments timeout qui auraient été lancés */
    reinitTimeOut() {
        // console.log('Dans reinitTimeOut - this.currentTimeoutIDArray', this.currentTimeoutIDArray)
        for (const idTimeout in this.currentTimeoutIDArray) {
            clearTimeout(parseInt(this.currentTimeoutIDArray[idTimeout]))
        }
        if (this.currentTimeoutID !== -1) {
            clearTimeout(this.currentTimeoutID)
        }
        this.currentTimeoutIDArray = []
        this.currentTimeoutID = -1
    }

    initDatas (tabSelected: string) {
        this.selectedFichier = null
        this.needToWatchFilesState = false

        // si des timeouts étaient en cours, on les arrête
        if (this.currentTimeoutIDArray.length > 0) {
            this.reinitTimeOut()
        }

        switch (tabSelected) {
            case 'souhaits': {
                this.loading_souhaits = true
                this.loadListesFichiersSouhaits()
                break
            }
        }
    }

    /** détermine si la liste passée en paramètre contient au moins un fichier en curs d'importation */
    hasFichierImportationEnCours (listeFichiers: Array<any>) {
        let hasFichierImportationEnCours = false
        for (const fichier of listeFichiers) {
            if (fichier.etat === EtatTraitementDossier.ETAT_EN_COURS) {
                hasFichierImportationEnCours = true
                break
            }
        }
        return hasFichierImportationEnCours
    }

    /**
     * Importation du fichier selectionné
     */
    importDatas ()
    {
        this.$store.dispatch('candidat/updateDossier', { dossier_id: this.selectedFichier }).then(() => {
            this.selectedFichier = null
            this.initDatas(this.tabSelected)
        })
    }

    /**
     * Fermeture de la modale
     */
    closeModal() {
        this.showErrorDossier = false
        this.dossier_error = null
        this.selectedFichier = null

        // si des timeouts étaient en cours, on les arrête et on suppose par précaution qu'on a potentiellement eu au moins un fichier traité.
        if (this.currentTimeoutIDArray.length > 0) {
            this.reinitTimeOut()
        }

        this.$emit('reinitShow', this.shouldRefreshTableCandiat)
    }

    /** Chargement des listes des fichiers des souhaits de ville des candidats */
    loadListesFichiersSouhaits () {
        this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_CHOIX_VILLES_ECRITS }).then(() => {
            this.liste_fichiers_souhaits = this.$store.state.candidat.liste_dossiers
            this.loading_souhaits = false
            this.needToWatchFilesState = this.hasFichierImportationEnCours(this.liste_fichiers_souhaits)
            if (!this.shouldRefreshTableCandiat) {
                if (this.needToWatchFilesState) {
                    this.shouldRefreshTableCandiat = true
                }
            }
        })
    }


    /** Affiche les erreurs d'un fichier */
    showErrorsFichiers (dossier: any) {
        this.dossier_error = dossier
        this.showErrorDossier = true
    }

    /** Ferme la visualisation des errors */
    closeErrorFichiers () {
        this.dossier_error = null
        this.showErrorDossier = false
    }

    mounted() {
        // mise en place d'un event pour gérer un reinit des timeout lorsqu'on clique sur boutons de navigation back/next button
        window.onpopstate = () => {
            this.reinitTimeOut()
        }
    }
}
