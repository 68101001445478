


































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { PosteContext, PosteType } from '@/types/Poste'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getFileNameFromHeader } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import { FontAwesomeIcon }        from '@fortawesome/vue-fontawesome'



@Component({
    components: {
        ExaGenericTable,
        ErrorDisplay,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('pv', ['pvs', 'postesPv', 'loading', 'error', 'avancemmentAffectationPct', 'epreuvesCompletesAffectation', 'pvSigne']),
        ...mapGetters('poste', ['postes', 'loading', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        intervAffectationManquant() {
            if (this.$store.getters['pv/intervAffectationManquant']) {
                const intervAffectationManquant = this.$store.getters['pv/intervAffectationManquant'].toString().split('.')
                return intervAffectationManquant[1] ? `${intervAffectationManquant[0]}.${intervAffectationManquant[1].substring(0, 2)}` : intervAffectationManquant[0]
            }
            return 0
        }
    },
    methods: {
        getStringConcours (concours) {
            return concours?.map((concour: any) => concour.name)
                ?.join(', ') || ''
        }
    }
})
export default class AffectationIntervenants extends Vue {
    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    // DATAS
    sortBy          = ''
    sortDesc        = false
    sortDirection   = 'asc'
    filter          = ''
    filterOn        = []
    stickyHeader    = true
    dossier_id      = 0
    dataForTab: any = []

    filtres = [
        {
            libelle: 'Épreuves',
            model: 'epreuve.name',
            value: '',
            index: 'name',
            datas: null,
            loading: false
        }
    ]

    Ability = Ability

    page = 1
    tabParams = {}
    timeOut = 0
    fields: Array<any> = [{ key: 'matiere_name', label: 'Matière', sortable: false, sortDirection: 'asc', class: 'text-start' }]
    exportingIsWorking = false
    // lastPage = 1;

    // Methods
    loadHandler () {
        this.$store.dispatch('pv/getPvs', { isPrecedente: false, filters: null, postes: this.$store.state.poste.postes })
    }

    // Exporte le récapitulatif de répartition au format PDF
    getAffectationRecapitulatif () {
        let fileName = 'recapitulatif_affectations_intervenants.xlsx'
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)
        this.$store.dispatch('affectationExaminateur/getAffectationRecapitulatif')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Exporte un fichier excel pour le jury
    getExport () {
        this.exportingIsWorking = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('dossieracademique/getExportDossiersAcademiques')
            .then((response) => {
                // Logique de téléchargement
                const fileName: any = getFileNameFromHeader(response.headers)
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const matiere = result.matiere_name ? result.matiere_name : '-'
                const nbEpreuves = result.epreuvecorrections_count ? result.epreuvecorrections_count : '-'
                const line: any = [

                    { label: 'Matière',             item: matiere,             type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'Nb. Épreuves',             item: nbEpreuves,             type: 'text', typeAction: null, class: 'text-center' }

                ]
                // Les deux let sont là pour calculer la colonne Selection
                let valeurSelectionne   = 0
                let valeurTotale        = 0
                for (let i = 0; i < this.$store.state.pv.postesPv.length; i++) {
                    const prop =  this.$store.state.pv.postesPv[i].name
                    let infos = ''
                    if (result.postes[prop]) {
                        valeurSelectionne   += result.postes[prop].affectation
                        valeurTotale        += result.postes[prop].besoin
                        const diff = result.postes[prop].besoin - result.postes[prop].precedent
                        infos =  result.postes[prop].affectation + '/' + result.postes[prop].besoin + ' (' + (diff > 0 ? '+' : '') + diff + ')'
                    } else {
                        infos = ''
                    }
                    line.push({ label: '',             item: infos,             type: 'text', typeAction: null, class: 'text-center' })
                }

                // ligne selection
                const pourcentage = valeurSelectionne && valeurTotale ? Math.trunc(valeurSelectionne * 100 / valeurTotale) + '%' : '-'
                line.push({ label: '',             item: pourcentage,             type: 'text', typeAction: null, class: 'text-center' })

                // ligne intervenants manquants
                let intervenantsManquants: any = '-'
                if (valeurSelectionne && valeurTotale) {
                    intervenantsManquants = (valeurTotale - valeurSelectionne).toString().split('.')
                    intervenantsManquants = intervenantsManquants[1] ? `${intervenantsManquants[0]}.${intervenantsManquants[1].substring(0, 2)}` : intervenantsManquants[0]
                }
                line.push({ label: '',             item: intervenantsManquants,             type: 'text', typeAction: null, class: 'text-center' })

                // Lien
                line.push({
                    label: 'actionEpreuveSelect',
                    item:
                    [{
                        name: 'arrow-circle-right',
                        class: 'text-info',
                        infos : result
                    }],
                    type: 'icons',
                    typeAction: 'actionEpreuveSelect',
                    class: 'text-center'
                })
                this.dataForTab.push(line)
            }
        }
    }

    // Creation des fields (maintenant pour generic fields)
    buildFields () {
        this.fields = []
        this.fields.push({ key: 'matiere_name', label: 'Matière', sortable: false, type: 'text', class: 'text-start ps-4' })
        this.fields.push({ key: 'epreuvecorrections_count', label: 'Nb. épreuves', sortable: false, type: 'text', class: 'text-center' })

        for (let i = 0; i < this.$store.state.pv.postesPv.length; i++) {
            this.fields.push({
                key: this.$store.state.pv.postesPv[i].name,
                label: this.$store.state.pv.postesPv[i].name,
                sortable: false,
                type: 'text',
                class: 'text-center'
            })
        }
        this.fields.push({
            key: 'avancemmentPctAffectation',
            label: 'Affectation',
            sortable: false,
            type: 'text',
            class: 'text-center'
        })
        this.fields.push({ key: 'intervManquantAffect', label: 'Intervenants manquants', sortable: false, type: 'text', class: 'text-center' })

        this.fields.push({ key: 'actionEpreuve', label: '', class: '', type: 'text' })
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'sortHandler':
                    break
                case 'actionEpreuveSelect':
                    this.actionEpreuveSelect(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    /** ouvre la page selection intervenant  */
    actionEpreuveSelect(paParams1: any) {
        const selection = paParams1[0].infos.id
        const session   = this.$store.getters['auth/user_session_id']
        const matiere   = paParams1[0].infos.matiere_id
        const link      = '/affectations/' + selection + '/sessions/' + session + '/matiere/' + matiere
        this.$router.push(link)
    }


    codeClient = 'CCMP'
    load() {
        if(this.$store.state.auth.user && this.$store.state.auth.user.parameters) {
            for(let i = 0; i < this.$store.state.auth.user.parameters.length; i ++) {
                if(this.$store.state.auth.user.parameters[i].code === 'codeClient') {
                    this.codeClient = this.$store.state.auth.user.parameters[i].value
                }
            }
        }
        this.$store.state.pv.error = null
        this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_PEDAGOGIQUE, context: PosteContext.CONTEXT_AFFECTATION }).then(() => {
            this.$store.dispatch('pv/getPvs', { isPrecedente: false, filters: null, postes: this.$store.state.poste.postes }).then(() => {
                this.buildFields()
                this.setDataForGenericTab(this.$store.state.pv.pvs)
            })
        })
    }

    mounted () {
        this.load()
    }
}

