
































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import FormPerso from '@/components/DossierAcademique/FormPerso.vue'
import FormPro from '@/components/DossierAcademique/FormPro.vue'
import FormCandidature from '@/components/DossierAcademique/FormCandidature.vue'
import FormDocument from '@/components/DossierAcademique/FormDocument.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { Etat } from '@/types/DossierAcademique'
import store from '@/store/index'
import { PosteContext, PosteInterface, PosteType } from '@/types/Poste'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { DocumentTypeInterface, Dossier_type } from '@/types/DocumentType'

@Component({
    components: {
        ItemIntervenant,
        FormPerso,
        FormPro,
        FormCandidature,
        FormDocument,
        ErrorDisplay
    },
    computed: {
        ...mapState('dossieracademique', ['hasAvatar', 'isValide', 'error']),
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('dossieracademique', ['dossierAcademiqueSelect', 'dacaConcours', 'avatar', 'loading', 'posteSelect', 'isValide', 'error']),
        ...mapState('etablissement', ['etablissements'])
    },
    methods: {
        ...mapActions('dossieracademique', ['getDossierAcademique', 'updateDossierAcademique'])
    }
})
export default class DossierAcademique extends Vue {
    @Prop() dossier_id?: number;

    @Watch('isValide')
    updateDossierValide () {
        this.dossierValide = this.$store.state.dossieracademique.isValide
    }

    @Watch('dossierAcademiqueSelect')
    updateItem () {
        if (this.$store.state.dossieracademique.dossierAcademiqueSelect.hasAvatar) {
            this.$store.dispatch('dossieracademique/getAvatar', { dossier_id: this.dossier_id })
        }

        this.getCompteur()
    }

    // DATAS
    concours = []
    edition = true
    validationLock = 0
    dossierValide = false
    etat_depose: Etat = Etat.ETAT_DEPOSE
    etat_rejete: Etat = Etat.ETAT_REJETE
    etapes_valide = 0
    etapes_totales = 4
    document_required = true
    showFormCandidature = false
    Etat = Etat

    formCandidatureVisible = false
    formPersoVisible = false
    formProVisible = false
    formDocumentVisible = false

    codeClient = 'CCMP'

    // METHODS

    // Passe en mode validation
    showValidation () {
        this.edition = false
    }

    // Coche la case de confirmation
    unlockValidation () {
        this.validationLock = (this.validationLock ? 1 : 0)
    }

    // Envoie la validation du dossier
    submitValidation () {
        const payload = {
            submit: 1
        }
        this.$store.dispatch('dossieracademique/updateDossierAcademique', { dossier_id: this.$props.dossier_id, payload: payload }).then(() => {
            if (this.$store.state.dossieracademique.isValide) {
                this.$router.push('/dashboard_i')
            }
        })
    }

    // Annule la validation du dossier
    backValidation () {
        this.edition = true
        this.validationLock = 0
    }

    // Créer le compteur d'étapes validées
    getCompteur () {
        this.etapes_valide = 0
        if (this.$store.state.dossieracademique.dossierAcademiqueSelect) {
            if (this.$store.state.dossieracademique.dossierAcademiqueSelect.etat_souhait === Etat.ETAT_DEPOSE || this.$store.state.dossieracademique.dossierAcademiqueSelect.etat_souhait === Etat.ETAT_VALIDE) {
                this.etapes_valide += 1
            }
            if (this.$store.state.dossieracademique.dossierAcademiqueSelect.etat_form_perso === Etat.ETAT_DEPOSE || this.$store.state.dossieracademique.dossierAcademiqueSelect.etat_form_perso === Etat.ETAT_VALIDE) {
                this.etapes_valide += 1
            }
            if (this.$store.state.dossieracademique.dossierAcademiqueSelect.etat_form_pro === Etat.ETAT_DEPOSE || this.$store.state.dossieracademique.dossierAcademiqueSelect.etat_form_pro === Etat.ETAT_VALIDE) {
                this.etapes_valide += 1
            }
            if (this.$store.state.dossieracademique.dossierAcademiqueSelect.etat_pieces === Etat.ETAT_DEPOSE || this.$store.state.dossieracademique.dossierAcademiqueSelect.etat_pieces === Etat.ETAT_VALIDE) {
                this.etapes_valide += 1
            }
        }
    }

    // Chargement des postes du dossier
    loadPostesDossier () {
        const postes_souhaits_select = this.$store.state.dossieracademique.dossierAcademiqueSelect.postes.filter((p: PosteInterface) => p.context_type === PosteContext.CONTEXT_SOUHAIT)

        if ((this.$store.state.dossieracademique.dacaConcours.length === undefined && this.$props.dossier_id !== 0) || (this.$store.state.dossieracademique.dacaConcours.length === 0 && this.$props.dossier_id !== 0)) {
            this.$store.dispatch('dossieracademique/getConcoursDossierAcademique', { dossier_id: this.$props.dossier_id })
        }

        this.$store.commit('poste/SET_POSTES_SELECTED', postes_souhaits_select)

        if (this.$store.state.matiere.matieres.length === undefined || this.$store.state.matiere.matieres.length === 0) {
            this.$store.dispatch('matiere/getMatieres')
        }
        if (this.$store.state.poste.postes.length === undefined || this.$store.state.poste.postes.length === 0) {
            this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_PEDAGOGIQUE, context: PosteContext.CONTEXT_SOUHAIT }).then(() => {
                const postes_souhaits = this.$store.getters['poste/postes_souhaits']
                this.$store.commit('poste/SET_LISTE_POSTES', postes_souhaits)
            })
        } else {
            const postes_souhaits = this.$store.getters['poste/postes_souhaits']
            this.$store.commit('poste/SET_LISTE_POSTES', postes_souhaits)
        }
    }

    // Chargement des établissements
    loadEtablissements () {
        const academie = this.$store.state.dossieracademique.academie
        const type_etablissement = this.$store.state.dossieracademique.typeEtab
        if (academie !== '' && type_etablissement !== '') {
            this.$store.dispatch('etablissement/getEtablissements', { academie: academie, type: type_etablissement })
        }
    }

    beforeMount () {
        for(let i = 0; i < this.$store.state.auth.user.parameters.length; i ++) {
            if(this.$store.state.auth.user.parameters[i].code === 'codeClient') {
                this.codeClient = this.$store.state.auth.user.parameters[i].value
            }
        }
        store.dispatch('dossieracademique/getDossierAcademique', this.dossier_id).then(async () => {
            // Regarde s'il y a des dossiers requis
            if (this.$store.getters['dossieracademique/dossierAcademiqueSelect'].etat_pieces === Etat.ETAT_VIERGE) {
                await this.$store.dispatch('documenttype/getDocumenttypes')
                this.document_required = !!this.$store.getters['documenttype/documenttypes']
                    .filter((document: DocumentTypeInterface) => document.dossier_type === Dossier_type.DOSSIER_ACADEMIQUE)
                    .map((document: any) => document.required)
                    .find((value: boolean) => value)
            }

            this.loadPostesDossier()
            this.loadEtablissements()

            if (this.$store.state.dossieracademique.dossierAcademiqueSelect.hasAvatar) {
                this.$store.dispatch('dossieracademique/getAvatar', { dossier_id: this.dossier_id })
            }

            this.getCompteur()

            if (this.$store.state.dossieracademique.dossierAcademiqueSelect.submitted_at === null || this.$store.state.dossieracademique.dossierAcademiqueSelect.submitted_at === undefined) {
                this.dossierValide = false
            } else {
                this.dossierValide = true
            }

            const modalFormCandidature = document.getElementById('set_poste')
            if (modalFormCandidature) {
                modalFormCandidature.addEventListener('hide.bs.modal', () => {
                    this.formCandidatureVisible = false
                })
                modalFormCandidature.addEventListener('show.bs.modal', () => {
                    this.formCandidatureVisible = true
                })
            }
            const modalPersoVisible = document.getElementById('set_infos_perso')
            if (modalPersoVisible) {
                modalPersoVisible.addEventListener('hide.bs.modal', () => {
                    this.formPersoVisible = false
                })
                modalPersoVisible.addEventListener('show.bs.modal', () => {
                    this.formPersoVisible = true
                })
            }
            const modalFormPro = document.getElementById('set_infos_pro')
            if (modalFormPro) {
                modalFormPro.addEventListener('hide.bs.modal', () => {
                    this.formProVisible = false
                })
                modalFormPro.addEventListener('show.bs.modal', () => {
                    this.formProVisible = true
                })
            }
            const modalFormDocument = document.getElementById('set_documents')
            if (modalFormDocument) {
                modalFormDocument.addEventListener('hide.bs.modal', () => {
                    this.formDocumentVisible = false
                })
                modalFormDocument.addEventListener('show.bs.modal', () => {
                    this.formDocumentVisible = true
                })
            }
        })
    }
}
