export interface ListeInterface {
    id: number | 0,
    session_id: number | null,
    name: string,
    type: number | 0,
    ordre: number | 0,
    code: string
}

export interface StateListes {
    listes: Array<ListeInterface> | [],
    meta: Array<string> | null,
    links: Array<string> | null,
    loading: boolean | false,
    error: Array<string> | null,
    totalRows: number | 0,
    listeSelect: ListeInterface | {}
}

export enum TypeListe {
    TYPE_PREAFFECTATION = 1,
    TYPE_VOEUX = 2,
}

interface ListeContextSpec {
    index: number,
    libelle: string
}

export function getTypeListeSpec(posteType: TypeListe): ListeContextSpec {
    switch (posteType) {
        case TypeListe.TYPE_PREAFFECTATION:
            return { index: 1, libelle: 'Préaffectation' }
        case TypeListe.TYPE_VOEUX:
            return { index: 2, libelle: 'Vœux' }
        default:
            return { index: 1, libelle: 'Préaffectation' }
    }
}

