



















































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import AdressesCentre from '@/components/Centre/AdressesCentre.vue'
import MessageCandidatsCentre from '@/components/Centre/MessageCandidatsCentre.vue'
import store from '@/store/index'
import PopupAddDossierSol from '@/components/Centre/PopupAddDossierSol.vue'
import PopupValidationIntervenants from '@/components/Centre/PopupValidationIntervenants.vue'
import { PosteContext, PosteType } from '@/types/Poste'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        AdressesCentre,
        PopupAddDossierSol,
        MessageCandidatsCentre,
        PopupValidationIntervenants,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('centre', ['centreSelect', 'loading']),
        ...mapGetters('ville', ['villes']),
        ...mapState('centre', ['centreSelect', 'centre_users_select', 'loading', 'error'])
    },
    beforeRouteEnter (to: any, from: any, next: any) {
    // console.log('---ffff------ : ' + to.params.centre_id)
        if (to.params.centre_id !== '0') {
            store.dispatch('centre/getCentre', { centre_id: to.params.centre_id }).then(() => {
                store.dispatch('centre/getUsersCount', { centre_id: to.params.centre_id }).then(() => {
                    next()
                })
            })
        } else {
            store.commit('centre/SET_CENTRE_SELECT', null)
            next()
        }
    }
})
export default class EditionCentre extends Vue {
    Ability = Ability
    tabSelected = 'descriptif'
    centre_select: any = null
    showConfirmationPublier = false
    modeEditionADM = false
    message_candidat_old: any = null
    // CDC_Temp: any = null
    // RDC_Temp: any = null

    @Watch('tabSelected')
    clean () {
        this.$store.state.centre.error = null
    }

    @Watch('centreSelect')
    updateCentreSelect () {
        // this.centre_select = this.$store.state.centre.centreSelect
    }

    get_UserByPosteContext (posteContextSearch: PosteContext) {
        const usersMatch: Array<any> = []
        if (this.centre_select) {
            for (const indexUser in this.centre_select.centreusers) {
                const posteMatch = this.centre_select.centreusers[indexUser].postes.filter((poste: any) => parseInt(poste.context) === posteContextSearch)
                if (posteMatch.length > 0) {
                    usersMatch.push(this.centre_select.centreusers[indexUser])
                }
            }
        }
        return usersMatch
    }

    // Ouvre le popup de confirmation de publication
    confirmPublier () {
        this.$store.state.centre.error = null
        this.showConfirmationPublier = true
    }

    // Annule la confirmation de publication
    cancelConfirmPublier () {
        this.$store.state.centre.error = null
        this.showConfirmationPublier = false
    }

    // Confirmer la publication du centre
    publierCentre () {
        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            submit: 1
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                this.showConfirmationPublier = false

                store.dispatch('centre/getCentre', { centre_id: this.$store.state.centre.centreSelect.id })
                    .then(() => {
                        this.centre_select = this.$store.state.centre.centreSelect
                    })
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Publication terminée.', succesToaster)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Appelle une page lors du scroll
    loadHandler (params: any) {
        if (this.$store.state.centre.centreSelect) {
            this.$store.dispatch('centre/getMoreCentreUsers', { centre_id: this.$store.state.centre.centreSelect.id, params: params })
        }
    }

    // Applique les filtres sur l'interface
    filtreSortHandler (params: any) {
        if (this.$store.state.centre.centreSelect) {
            this.$store.dispatch('centre/getCentreUsers', { centre_id: this.$store.state.centre.centreSelect.id, params: params })
        }
    }

    save () {
        if (this.tabSelected === 'descriptif') {
            (this.$refs.descriptif as any).editFin()
        } else if (this.tabSelected === 'message') {
            (this.$refs.message as any).editFin()
            if (this.modeEditionADM) {
                (this.$refs.message as any).lockInput = true;
                (this.$refs.popValidationMessage as any).lockForAdminEdition = false
                this.modeEditionADM = false
            }
        }
    }

    edit () {
        if (this.tabSelected === 'adresses') {
            (this.$refs.adresses as any).majModeEditionADM(true)
            this.modeEditionADM = true
        } else if (this.tabSelected === 'message') {
            // this.message_candidat_old = (this.$refs.message as any).centreTemp.message_candidat;
            this.message_candidat_old = this.$store.state.centre.centreSelect.message_candidat;
            (this.$refs.message as any).lockInput = false;
            (this.$refs.popValidationMessage as any).lockForAdminEdition = true
            this.modeEditionADM = true
        }
    }

    annulerEdition () {
        if (this.tabSelected === 'adresses') {
            (this.$refs.adresses as any).majModeEditionADM(false)
            this.modeEditionADM = false
            /*
      store.dispatch('centre/getCentre', { centre_id: this.centre_select.id }).then(() => {
        this.centre_select = this.$store.state.centre.centreSelect
      })
      */
        } else if (this.tabSelected === 'message') {
            // (this.$refs.message as any).centreTemp.message_candidat = this.message_candidat_old;
            this.$store.state.centre.centreSelect.message_candidat = this.message_candidat_old;
            (this.$refs.message as any).lockInput = true;
            (this.$refs.popValidationMessage as any).lockForAdminEdition = false
            this.modeEditionADM = false
        }
    }

    retourValidationEvent () {
        if (this.tabSelected === 'message') {
            (this.$refs.message as any).initInterface()
        }
        if (this.tabSelected === 'adresses') {
            //
        }
    }

    beforeMount () {
        if (this.$store.state.centre) {
            this.$store.state.centre.error = null
            this.centre_select = this.$store.state.centre.centreSelect

            // this.CDC_Temp = this.get_UserByPosteContext(PosteContext.CONTEXT_CHEF_CENTRE)
            // this.RDC_Temp = this.get_UserByPosteContext(PosteContext.CONTEXT_RESPONSABLE_CENTRE)
        }
        // if (this.$store.state.poste.postes && this.$store.state.poste.postes.length === 0) {
        this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_LOGISTIQUE })
        // }
    }
}
