







































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import { TypePassation } from '@/types/Epreuve'
import PopupEditionCentre from '@/components/Centre/PopupEditionCentre.vue'
import { checkIcone, formatDateDocument, formatDate } from '@/utils/helpers'
import PopupReglesOrganisation from '@/components/Centre/PopupReglesOrganisation.vue'
import PopupLivraisonFournitures from '@/components/Centre/PopupLivraisonFournitures.vue'

@Component({
    components: {
        ExaGenericTable,
        PopupEditionCentre,
        PopupReglesOrganisation,
        PopupLivraisonFournitures,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('centre', ['use_real_data', 'loading', 'error', 'centres', 'meta', 'links', 'totalRows', 'lastPage', 'totalPage', 'save_in_progress', 'importEstimationsList', 'getCandidatsPrevision', 'etat_centre_global']),
        ...mapState('centre', ['meta', 'centreSelect']),
        ...mapState('session', ['sessionActive', 'sessionSelect']),
        ...mapState('banque', ['banques']),
        ...mapState('concour', ['concours']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('organisationecrit', ['get_evolution_salles_centre', 'get_evolution_surveillants_centre', 'get_ratio_candidat_intervenant', 'get_total_candidats', 'get_evolution_candidats'])
    }
})

export default class CentresEcrits extends Vue {
    Ability = Ability
    formatDateDocument = formatDateDocument
    // DATAS
    filtres_ecrits: any = []
    genericfields: Array<any> = []

    formatDate = formatDate

    dataForTab: Array<any> = []
    centre: any = null
    TypePassation = TypePassation

    totalCentres = 0
    showGestionCentre = false
    centresFilter: Array<any> = []
    lock_exit = false

    showModalImportationEstimations = false
    formData = new FormData()
    file_select = false
    showModalBilanImportationEstimations = false
    localImportEstimationsList: Array<any> = []
    collectionDynamique = { cle: 'concours', datas: {} }

    showError = false
    messageErreurSimple = ''
    session_en_cours: any = null
    session_precedente: any = null
    params: any = {}
    timeoutid = 0
    showPopupReglesOrganisation = false
    showLivraisonFournitures = false
    show_indispos_centre = false


    @Watch('banques')
    setColonneConcours() {
        if (this.$store.getters['banque/banques'].length) {
            this.addGenericFields()
            this.genericfields.push({ key: 'candidats_n1',   label: 'Candidats ' + (this.session_precedente ? this.session_precedente.code : ''), sortable: false,  class: 'text-center', type: 'text' })
            this.genericfields.push({ key: 'total_concours', label: 'Total', sortable: false,  class: 'text-center bg-gray-100', type: 'text', doubleHeaderLabel: 'Candidats ' + this.session_en_cours.code, doubleHeaderColSpan: 4, doubleHeaderClass: 'bg-gray-100 text-center' })

            let loop = 0
            for (const c in this.$store.getters['banque/banques']) {
                if (this.$store.getters['banque/banques'][c]) {
                    this.genericfields.push({ key: 'etat_concour_' + this.$store.getters['banque/banques'][c].id, label: this.$store.getters['banque/banques'][c].name, sortable: false,  class: 'bg-gray-100 text-center', type: 'text' })
                    loop++
                }

                if (loop === this.$store.getters['banque/banques'].length) {
                    this.buildOtherFields()
                }
            }
        }
    }

    @Watch('centres')
    filterCentres() {
        this.centresFilter = this.$store.getters['centre/centres'].filter((c: any) => c.type_passation === TypePassation.TYPE_PASSATION_ECRIT)
        this.totalCentres = this.centresFilter.length
        this.setDataForGenericTab(this.centresFilter)
    }

    @Watch('save_in_progress')
    lockExit() {
        this.lock_exit = !!this.$store.getters['centre/save_in_progress'];
    }

    @Watch('user_session_id')
    refreshInterface() {
        const params = {}
        Vue.set(params, 'page', 1)
        Vue.set(params, 'sort', 'ville.name')
        Vue.set(params, 'direction', 'asc')
        Vue.set(params, 'filter-submitted', 1)

        this.$store.dispatch('centre/getCentres', params).then(() => {
            this.load()
        })
    }

    // METHODS

    invaliderIndispo() {
        this.showConfirmationAnnulationSoumissionIndispos = true
    }

    showConfirmationAnnulationSoumissionIndispos = false

    cancelAnnulSoumettreIndispos() {
        this.showConfirmationAnnulationSoumissionIndispos = false
    }

    confirmAnnulSoumettreIndispos() {
        this.$store.commit('centre/SET_ERROR_FULL', null)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)

        const payload = {
            id: this.$store.state.centre.centreSelect.id,
            indispo_fournitures_validate : 0
        }

        this.$store.dispatch('centre/updateCentre', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.showConfirmationAnnulationSoumissionIndispos = false
            })
            .finally(() => this.$bvToast.hide(idInfo))
    }

    // Retourne la période de la journée
    getPeriodeJournee(periode: number) {
        switch(periode) {
            case 0:
                return 'Matin'
            case 1:
                return 'Après-midi'
            case 2:
                return 'Journée complète'
        }
    }

    /** Build le reste des colonnes */
    buildOtherFields() {
        this.genericfields.push({ key: 'evolution_c',           label: 'Évolution',         sortable: false,  class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'salles_count',          label: 'Salles ' + this.session_en_cours.code, sortable: false,  class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'evolution_s',           label: 'Évolution', sortable: false,  class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'surveillants_count',    label: 'Surveillants ' + this.session_en_cours.code, sortable: false,  class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'evolution_i',           label: 'Évolution', sortable: false,  class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'candidats_intervenant', label: 'Candidats par Surveillants', sortable: false,  class: 'text-center', type: 'text' })
        this.genericfields.push({ key: 'etat_ecrit',            label: 'Organisation écrits', sortable: true,  class: 'text-center', type: 'text' })
    }

    /** Ouvre la gestion des salles d'un centre selectionné */
    gestionCentre(row: any) {
        this.$store.dispatch('centre/getCentre', { centre_id: row.id })
            .then(() =>
                this.$store.dispatch('centre/getUsersCount', { centre_id: row.id })
                    .then(() => this.showGestionCentre = true)
            )
    }

    /** Annule la gestion d'un centre */
    cancelGestionCentre() {
        this.$store.commit('organisationecrit/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.centre = null
        this.showGestionCentre = false
        this.$store.state.centre.centreSelect.salles = []
    }

    /** Ouvre l'importation des estimations */
    ouvrirImportationEstimations() {
        this.showModalImportationEstimations = true
    }

    /** Annuler l'importation des estimations */
    cancelImportationEstimations() {
        this.showError = false
        this.showModalImportationEstimations = false
        this.showModalBilanImportationEstimations = false
        this.$store.state.centre.importEstimationsList = []
        this.localImportEstimationsList = []
        this.$store.state.centre.error = null
    }

    /**
     * Exporte le fichier Excel des estimations
     */
    exporterEstimations() {
        const date: Date = new Date()
        const fileName = `export_centres_estimations_${date.getFullYear()}${(date.getMonth() + 1) < 10 ? '0' : ''}${date.getMonth() + 1}${date.getDate()}.xlsx`

        this.$store.dispatch('centre/getExportCentresEstimations')
            .then((response) => {
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
    }

    /**
     * Sélection du fichier à importer
     */
    selectFile(e: Event) {
        if (e.target) {
            const target = e.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]

            this.formData.set('document', file)

            this.file_select = true
            this.importerEstimations()
        } else {
            this.file_select = false
        }
    }

    /**
     * Importer le fichier des estimations
     * Lance la première étape de l'importation du fichier sélectionné et qui affiche la partie bilan d'importation
     */
    importerEstimations () {
        this.$store.state.centre.error = null
        this.showError = false

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Importation en cours ...', infosToaster)

        this.formData.set('confirmed', '0')

        this.$store.dispatch('centre/uploadCentresEstimations', this.formData)
            .then(() => {
                this.localImportEstimationsList = this.$store.state.centre.importEstimationsList
                this.showModalBilanImportationEstimations = true
            })
            .catch(() => {
                this.showError = true
                this.messageErreurSimple = 'Une erreur est survenue :'
            })
            .finally(() => this.$bvToast.hide(idInfo))
    }

    /**
     * Confirmation de l'importation
     */
    confirmerImporterEstimations () {
        this.$store.state.centre.error = null
        this.showError = false

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Validation en cours ...', infosToaster)

        this.formData.set('confirmed', '1')

        this.$store.dispatch('centre/uploadCentresEstimations', this.formData)
            .then(() => {
                // Fermeture de la popup aprés validation
                const closeImportationEstimations: HTMLElement = document.getElementsByClassName('closeImportationEstimations')[0] as HTMLElement
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Importation terminée !', succesToaster)

                const params = {}
                Vue.set(params, 'page', 1)
                Vue.set(params, 'sort', 'ville.name')
                Vue.set(params, 'direction', 'asc')
                Vue.set(params, 'filter-submitted', 1)

                this.$store.dispatch('centre/getCentres', params)
                    .then(() => closeImportationEstimations.click())
            })
            .catch(() => {
                this.showError = true
                this.messageErreurSimple = 'Une erreur est survenue :'
            })
            .finally(() => this.$bvToast.hide(idInfo))
    }

    /** Appelle une page lors du scroll */
    loadHandler (params: any) {
        this.$store.dispatch('centre/getMoreCentres', params)
            .then(() => {
                this.centresFilter = this.$store.state.centre.centres.filter((c: any) => c.type_passation === TypePassation.TYPE_PASSATION_ECRIT)
                this.setDataForGenericTab(this.centresFilter)
            })
    }

    /** Initialisation des données du tableau + rappel si filtres appliqués */
    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            Vue.set(this.params, 'filter-submitted', 1)

            // Des filtres sont appliqués on rappele le serveur
            this.$store.dispatch('centre/getCentres', params)
                .then(() => {
                    this.centresFilter = this.$store.state.centre.centres.filter((c: any) => c.type_passation === TypePassation.TYPE_PASSATION_ECRIT)
                    this.setDataForGenericTab(this.centresFilter)
                })
        }
    }

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const can = this.$store.getters['auth/can'](Ability.GC_CENTER_MANAGE)
                const icone = checkIcone(Ability.GC_CENTER_MANAGE, can)

                const puce_etat = []
                puce_etat.push({ name:'circle', class: 'text-' + this.$store.getters['centre/etat_centre_global'](result).color, title: this.$store.getters['centre/etat_centre_global'](result).title })

                const line: any = [
                    { label: icone.label, item: result, type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: puce_etat,  type: 'icons', typeAction: null, class: 'text-center' }
                ]

                // Etat de réponse indisponibilités de livraison
                let etat_indispos: any = { class: 'text-light', title: '-', action: null}
                if (result.indispo_fournitures_validated_at) { // !result.indisponibiliteFournitures ||
                    etat_indispos = { class: 'text-danger', title: 'Le centre à indiqué des indisponibilités de réception', action: 'showIndispos' }
                }

                const puce_etat_indispo = [{ name: 'dolly', class: etat_indispos.class, title: etat_indispos.title, centre_id: result.id}]

                line.push({ label: '', item: puce_etat_indispo,  type: 'icons', typeAction: etat_indispos.action, class: 'text-center' })
                line.push({ label: '', item: result.name, type: 'text', typeAction: null, class: 'text-start' })
                line.push({ label: '', item: result.ville.name, type: 'text', typeAction: null, class: 'text-start' })
                line.push({ label: '', item: result.ville.academie, type: 'text', typeAction: null, class: 'text-start text-uppercase' })

                const candidat_n_1 = result.precedent ? this.$store.getters['organisationecrit/get_total_candidats'](result.precedent) : '--'
                line.push({ label: '', item: candidat_n_1, type: 'text', typeAction: null, class: 'text-center' })

                const total = this.$store.getters['organisationecrit/get_total_candidats'](result)
                line.push({ label: '', item: total, type: 'text', typeAction: null, class: 'text-center bg-gray-100 fw-bold text-secondary' })

                for (const c in this.$store.getters['banque/banques']) {
                    let nb_candidats = '--'
                    const banq = this.$store.getters['banque/banques'][c]
                    let concour = null

                    if (banq) {
                        concour = result.concours.find((conc: any) => conc.code === banq.code)
                    }
                    if (concour) {
                        nb_candidats = this.$store.getters['centre/use_real_data'](result) ? concour.nb_candidats : concour.nb_candidats_prevision
                    }

                    line.push({ label: '', item: nb_candidats, type: 'text', typeAction: null, class: 'text-center bg-gray-100 text-secondary' })
                }

                const evolution_c = this.$store.getters['organisationecrit/get_evolution_candidats'](result)
                const evolution_c_lib = evolution_c && evolution_c.value !== 0 ? (evolution_c.signe !== '-' ? evolution_c.signe : '') + ' ' + evolution_c.value + ' %' : (evolution_c.signe !== '=' ? '-' : evolution_c.signe)

                line.push({ label: '', item: evolution_c_lib, type: 'text', typeAction: null, class: 'text-center fw-bold text-' + evolution_c.color })
                line.push({ label: '', item: result.salles_count, type: 'text', typeAction: null, class: 'text-center' })

                const evolution_s = this.$store.getters['organisationecrit/get_evolution_salles_centre'](result)
                const evolution_s_lib = evolution_s && evolution_s.value !== 0 ? (evolution_s.signe !== '-' ? evolution_s.signe : '') + ' ' + evolution_s.value + ' %' : (evolution_s.signe !== '=' ? '-' : evolution_s.signe)
                line.push({ label: '', item: evolution_s_lib, type: 'text', typeAction: null, class: 'text-center fw-bold text-' + evolution_s.color })

                const nb_surveillants = result.besoins ? this.$store.getters['organisationecrit/get_total_intervenants_with_besoins'](result.besoins, 'Surveillance') : 0
                const nb_surveillants_precedent = result.precedent && result.precedent.besoins ? this.$store.getters['organisationecrit/get_total_intervenants_with_besoins'](result.precedent.besoins, 'Surveillance') : 0
                line.push({ label: '', item: nb_surveillants, type: 'text', typeAction: null, class: 'text-center tex-secondary fw-bold' })

                const evolution_i = this.$store.getters['organisationecrit/get_evolution_surveillants_centre_with_besoins'](nb_surveillants, nb_surveillants_precedent)
                const evolution_i_lib = evolution_i && evolution_i.value !== 0 ? (evolution_i.signe !== '-' ? evolution_i.signe : '') + ' ' + evolution_i.value + ' %' : (evolution_i.signe !== '=' ? '-' : evolution_i.signe)
                line.push({ label: '', item: evolution_i_lib, type: 'text', typeAction: null, class: 'text-center fw-bold text-' + evolution_i.color })

                const candidats_intervenant = this.$store.getters['organisationecrit/get_ratio_candidat_intervenant_with_besoins'](result, nb_surveillants)
                line.push({ label: '', item: candidats_intervenant, type: 'text', typeAction: null, class: 'text-center' })


                // Etat de l'organisation des écrits
                let etat_ecrit = { class: 'text-tertiary', title: 'Non définie' }
                if (result.salles_submitted_at && result.nb_intervenants_submitted_at) {
                    etat_ecrit = { class: 'text-info', title: 'L\'organisation des écrits a été soumise' }
                } else if (result.commentaire_rejet && (result.commentaire_rejet.besoins || result.commentaire_rejet.salles)) {
                    etat_ecrit = { class: 'text-danger', title: 'Corrections attendues' }
                }
                if (result.salles_validated_at && result.nb_intervenants_validated_at) {
                    etat_ecrit = { class: 'text-success', title: 'L\'organisation des écrits a été validée' }
                }
                const puce_etat_ecrit = [{ name:'circle', class: etat_ecrit.class, title: etat_ecrit.title }]
                line.push({ label: '', item: puce_etat_ecrit,  type: 'icons', typeAction: null, class: 'text-center' })

                this.dataForTab.push(line)
            }
            this.$store.commit('centre/SET_LOADING', false)
        }
    }

    // Events
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    this.gestionCentre(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'showIndispos':
                    this.showIndisposCentre(paParams[1][0].centre_id)
                    break
            }
        }
    }

    // Initialise les filtres
    setFilters() {
        const options_etat = [
            { index: 'cree', name: 'Centre créé' },
            { index: 'valide', name: 'Centre validé' },
            { index: 'non valide', name: 'Centre non validé' },
            { index: 'rejete', name: 'Corrections attendues' }
        ]

        this.filtres_ecrits = [
            { libelle: 'état', defautOptionlibelle: 'Rechercher un', model: 'etat',       value: '', index: 'etat',  datas: options_etat, loading: this.$store.state.centre.loading, options: { type: 'deroulant', fieldsKey: 'etat' } },
            { libelle: 'libellé', defautOptionlibelle: 'Rechercher un', model: 'name',       value: '', index: 'name',  datas: '', loading: this.$store.state.centre.loading, options: { type: 'form', fieldsKey: 'name', strict: false } },
            { libelle: 'ville', defautOptionlibelle: 'Rechercher une',  model: 'ville.name', value: '', index: 'ville.name',  datas: '', loading: this.$store.state.centre.loading, options: { type: 'form', fieldsKey: 'ville.name', strict: false } },
            { libelle: 'académie', defautOptionlibelle: 'Rechercher une',  model: 'ville.academie', value: '', index: 'ville.academie',  datas: '', loading: this.$store.state.centre.loading, options: { type: 'form', fieldsKey: 'ville.academie', strict: false } }
        ]
    }

    // init des generic fields
    addGenericFields() {
        this.genericfields = [
            { key: 'edit',         label: '',       sortable: false, class: '', type: 'action' },
            { key: 'etat',         label: 'État',   sortable: true, class: 'text-center', type: 'text' },
            { key: 'indispos_livraison',    label: '', sortable: false, class: 'text-center', type: 'text' },
            { key: 'name',         label: 'Libellé', sortable: true, class: '', type: 'text' },
            { key: 'ville.name',   label: 'Ville', sortable: true,  class: '', type: 'text' },
            { key: 'ville.academie',   label: 'Académie', sortable: true,  class: '', type: 'text' }
        ]
    }

    // Ouvre le popup de gestion des règles d'organisation des écrits
    edit_regles_organisation() {
        this.showPopupReglesOrganisation = true
    }

    // Ferme la popup de gestion des règles d'organisation des écrits
    cancelReglesOrganisation() {
        this.showPopupReglesOrganisation = false
    }

    // Recharge la liste des centres suite aux paramétrage des règles
    refreshReglesOrganisations() {
        this.load()
        this.showPopupReglesOrganisation = false
    }

    // Ouvre la popup de paramétrage des dates de livraison des fournitures
    open_livraison_fournitures() {
        this.showLivraisonFournitures = true
    }

    // Ferme la popup de paramétrage des dates de livraison des fournitures
    close_livraison_fournitures() {
        this.showLivraisonFournitures = false
    }

    // Ouvre la visualisation des indispos du centre
    showIndisposCentre(centre_id: number) {
        this.$store.dispatch('centre/getCentre', { centre_id: centre_id }).then(() => {
            this.show_indispos_centre = true
        })
    }

    // Ferme la popup de consultation des indispos du centre
    cancel_show_indispos() {
        this.$store.commit('centre/SET_CENTRE_SELECT', null)
        this.show_indispos_centre = false
    }

    load() {
        if (this.$store.getters['session/sessions']) {
            this.session_en_cours = this.$store.getters['session/sessions'].find((s: any) => s.id === this.$store.getters['auth/user_session_id'])
            this.session_precedente = this.$store.getters['session/sessions'].find((s: any) => s.id === this.session_en_cours.session_id)
        }
        this.addGenericFields()
        this.$store.dispatch('banque/getBanques', { isPrecedente: false })
            .then(async () => {
                if (this.$store.getters['centre/centres'].length) {
                    this.centresFilter = this.$store.getters['centre/centres'].filter((c: any) => c.type_passation === TypePassation.TYPE_PASSATION_ECRIT && c.submitted_at)
                    this.totalCentres = this.centresFilter.length
                    this.setFilters()
                    this.setDataForGenericTab(this.centresFilter)
                } else {
                    const params = {}
                    Vue.set(params, 'page', 1)
                    Vue.set(params, 'sort', 'ville.name')
                    Vue.set(params, 'direction', 'asc')
                    Vue.set(params, 'filter-submitted', 1)

                    await this.$store.dispatch('centre/getCentres', params)
                }
            })
    }

    mounted () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
