









































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapMutations, mapState } from 'vuex'
import SelectTitre from '@/components/selects/SelectTitre.vue'
import SelectDiplome from '@/components/selects/SelectDiplome.vue'
import SelectMatieres from '@/components/selects/SelectMatieres.vue'
import SelectEtablissements from '@/components/selects/SelectEtablissements.vue'
import SelectFonction from '@/components/selects/SelectFonction.vue'
import SelectAcademie from '@/components/selects/SelectAcademie.vue'
import SelectTypeEtablissement from '@/components/selects/SelectTypeEtablissement.vue'
import SelectEtablissement from '@/components/selects/SelectEtablissement.vue'
import SelectConcours from '@/components/selects/SelectConcours.vue'
import SelectFonctionAutreConcours from '@/components/selects/SelectFonctionAutreConcours.vue'
import { Etat } from '@/types/DossierAcademique'
import { Ability } from '@/types/Ability'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        SelectTitre,
        SelectDiplome,
        SelectFonction,
        SelectAcademie,
        SelectTypeEtablissement,
        SelectEtablissement,
        SelectConcours,
        SelectFonctionAutreConcours,
        SelectMatieres,
        SelectEtablissements,
        FontAwesomeIcon
    },
    methods: {
        ...mapMutations('dossieracademique', ['UPDATE_DATAS_AUTRE_CONCOURS'])
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('dossieracademique', ['academie', 'typeEtab'])
    }
})
export default class FormPro extends Vue {
    @Prop() dossierAcademiqueSelect?: any
    @Prop() readOnly?: boolean
    @Prop() mode?: string
    @Prop() visible?: boolean
    @Prop({default: 'CCMP'}) mode_formulaire?: string

    Ability = Ability

    @Watch('visible')
    visibleHandler () {
        if(this.mode !== 'intervenant'){
            if (this.visible) {
                this.activeEdition()
            } else {
                this.cancelEdition()
            }
        }
    }

    @Watch('typeEtab')
    getTypeEtablissement () {
        if (this.$store.state.dossieracademique.academie !== '') {
            this.loadEtablissements()
        }
    }

    @Watch('academie')
    getAcademie () {
        if (this.$store.state.dossieracademique.typeEtab !== '') {
            this.loadEtablissements()
        }
    }

    @Watch('dossierAcademiqueSelect')
    init () {
        // this.loadEtablissements()
        this.initDatasPro()
    }

    key = 0

    was_validated = false
    datas_init = false

    title = ''
    function = ''
    level_last_diplome = ''
    establishment_id = null
    establishment_code = ''
    establishment_type = ''
    establishment_name = ''
    establishment_address = ''
    academy = ''
    other_mission = ''
    other_mission_function = ''
    other_mission_name = ''
    title_other = ''
    function_other = ''
    other_mission_name_other = ''

    disciplines_maitrisees = []
    diplomes = []
    selected_etablissements = []

    editResponsable = false
    Etat = Etat
    old_datas = {
        title: '',
        function: '',
        level_last_diplome: '',
        establishment_id: '',
        establishment_code: '',
        establishment_type: '',
        establishment_name: '',
        establishment_address: '',
        academy: '',
        other_mission: '',
        other_mission_name: '',
        other_mission_function: '',
        other_mission_name_other: '',
        diplome_othe: '',
        function_other: '',
        title_other: '',
        diplome_other: '',

        disciplines_maitrisees : [],
        diplomes: [],
        selected_etablissements: []
    }

    retour_erreurs = ''

    // Recuperation du champ
    updateChamps (e: any) {
        switch (e.target.name) {
            case 'other_mission':
                this.$store.commit('dossieracademique/UPDATE_DATAS_AUTRE_CONCOURS', e.target.value)
                break
            default:
                break
        }
    }

    // Init des datas persos pour le validator
    initDatasPro () {
        this.title = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.title ? this.dossierAcademiqueSelect.datas_pro.title : '')
        this.function = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.function ? this.dossierAcademiqueSelect.datas_pro.function : '')
        this.level_last_diplome = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.level_last_diplome ? this.dossierAcademiqueSelect.datas_pro.level_last_diplome : '')
        this.establishment_id = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.establishment_id ? this.dossierAcademiqueSelect.datas_pro.establishment_id : '')
        this.establishment_code = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.establishment_code ? this.dossierAcademiqueSelect.datas_pro.establishment_code : '')
        this.establishment_type = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.establishment_type ? this.dossierAcademiqueSelect.datas_pro.establishment_type : '')
        this.establishment_name = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.establishment_name ? this.dossierAcademiqueSelect.datas_pro.establishment_name : '')
        this.establishment_address = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.establishment_address ? this.dossierAcademiqueSelect.datas_pro.establishment_address : '')
        this.academy = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.academy ? this.dossierAcademiqueSelect.datas_pro.academy : '')
        this.other_mission = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.other_mission ? this.dossierAcademiqueSelect.datas_pro.other_mission : '')
        this.other_mission_name = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.other_mission_name ? this.dossierAcademiqueSelect.datas_pro.other_mission_name : '')
        this.other_mission_function = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.other_mission_function ? this.dossierAcademiqueSelect.datas_pro.other_mission_function : '')

        // Other
        this.title_other = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.title_other ? this.dossierAcademiqueSelect.datas_pro.title_other : '')
        this.function_other = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.function_other ? this.dossierAcademiqueSelect.datas_pro.function_other : '')
        this.other_mission_name_other = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.other_mission_name_other ? this.dossierAcademiqueSelect.datas_pro.other_mission_name_other : '')

        this.diplomes = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.diplomes ? this.dossierAcademiqueSelect.datas_pro.diplomes : [])
        this.disciplines_maitrisees = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.disciplines_maitrisees ? this.dossierAcademiqueSelect.datas_pro.disciplines_maitrisees : [])
        this.selected_etablissements = (this.dossierAcademiqueSelect && this.dossierAcademiqueSelect.datas_pro.selected_etablissements ? this.dossierAcademiqueSelect.datas_pro.selected_etablissements : [])


        this.datas_init = true
    }

    // Validation de remplissage des champs
    submitInfosPro (e: Event) {
        e.preventDefault()
        this.was_validated = true
        this.retour_erreurs = ''
        // console.log({ c: this.civility, n: this.name_intervenant, fn: this.first_name, bd: this.birth_date, bp: this.birth_place, e: this.email, t: this.telephone, a: this.address, dv: this.datas_init })
        this.initDatasPro()
        let count_error = 0

        // console.log({ t: this.title, f: this.function, ld: this.level_last_diplome, et: this.establishment_type, en: this.establishment_name, ea: this.establishment_address, a: this.academy, om: this.other_mission, omn: this.other_mission_name, omf: this.other_mission_function })
        // Check le remplissage des champs
        if(this.mode_formulaire === 'CCS') {
            this.was_validated = false
            if(this.title !== '' && this.function !== '' && this.other_mission !== '' && this.diplomes.length > 0 &&  this.disciplines_maitrisees.length > 0) {
                this.saveDatasPro()
                this.was_validated = false
            } else {
                if(this.title === '') {
                    this.retour_erreurs += '- Un titre doit être choisi.</br>'
                }
                if (this.function === '') {
                    this.retour_erreurs += '- Une fonction doit être choisi.</br>'
                }
                if (this.other_mission === '') {
                    this.retour_erreurs += '- Une réponse à la question "Êtes-vous correcteur et/ou examinateur sur d\'autres concours ?" est attendu.</br>'
                }
                if (this.diplomes.length === 0) {
                    this.retour_erreurs += '- Au moins un diplôme doit être renseigné.</br>'
                }
                if (this.disciplines_maitrisees.length === 0) {
                    this.retour_erreurs += '- Au moins une discipline doit être choisi.</br>'
                }
                this.initDatasPro()
            }
        } else {
            if (this.title !== '' && this.function !== '' && this.level_last_diplome && this.establishment_type !== null && this.establishment_name !== '' && this.establishment_address !== '' && this.academy !== '' && this.other_mission !== '') {
                if (this.other_mission === 'oui') {
                    // Check des infos autres missions
                    if (this.other_mission_name !== '' && this.other_mission_function !== '') {
                        if (this.other_mission_name === 'Autre' && this.other_mission_name_other === '') {
                            count_error++
                        }
                    }

                    if (this.function !== '' && this.function === 'Autre' && this.function_other === '') {
                        count_error++
                    }

                    if (this.title !== '' && this.title === 'Autre' && this.title_other === '') {
                        count_error++
                    }

                    if (count_error === 0) {
                        // Enregistre les données
                        this.saveDatasPro()
                        this.was_validated = false
                    }
                } else if (this.other_mission === 'non') {
                    // Enregistre les données
                    this.saveDatasPro()
                    this.was_validated = false
                }
            } else {
                // reinit les données pour la prochaine tentative de validation
                this.initDatasPro()
                // console.log({ t: this.title, f: this.function, ld: this.level_last_diplome, et: this.establishment_type, en: this.establishment_name, ea: this.establishment_address, a: this.academy, om: this.other_mission, omn: this.other_mission_name, omf: this.other_mission_function })
            }
        }
    }

    // Sauvegarde des datas pros formulaire
    saveDatasPro () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('dossieracademique/updateDossierAcademique', { dossier_id: this.$props.dossierAcademiqueSelect.id, payload: { datas_pro: JSON.stringify(this.$store.state.dossieracademique.dossierAcademiqueSelect.datas_pro) } })
            .then(() => {
                if (this.$props.mode === 'responsable') {
                    this.editResponsable = false
                } else {
                    this.editResponsable = false
                    // Fermeture de la popup aprés validation
                    const closeInfosPersos: HTMLElement = document.getElementsByClassName('closeInfosPro')[0] as HTMLElement
                    closeInfosPersos.click()
                }

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                this.activeEdition()
                this.cancelEdition()
                this.$emit('edition_form_pro', false)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    loadEtablissements () {
        const academie = this.$store.state.dossieracademique.academie
        const type_etablissement = this.$store.state.dossieracademique.typeEtab
        if (academie !== '' && type_etablissement !== '') {
            this.$store.dispatch('etablissement/getEtablissements', { academie: academie, type: type_etablissement, perPage: 1000 })
        }
    }

    // Activation de l'édition pour le responsable
    activeEdition () {
        // if (this.mode === 'responsable') {
        this.editResponsable = true
        // }
        this.$emit('edition_form_pro', true)
        this.old_datas.title = this.dossierAcademiqueSelect.datas_pro.title
        this.old_datas.function = this.dossierAcademiqueSelect.datas_pro.function
        this.old_datas.level_last_diplome = this.dossierAcademiqueSelect.datas_pro.level_last_diplome
        this.old_datas.establishment_id = this.dossierAcademiqueSelect.datas_pro.establishment_id
        this.old_datas.establishment_code = this.dossierAcademiqueSelect.datas_pro.establishment_code
        this.old_datas.establishment_type = this.dossierAcademiqueSelect.datas_pro.establishment_type
        this.old_datas.establishment_name = this.dossierAcademiqueSelect.datas_pro.establishment_name
        this.old_datas.establishment_address = this.dossierAcademiqueSelect.datas_pro.establishment_address
        this.old_datas.academy = this.dossierAcademiqueSelect.datas_pro.academy
        this.old_datas.other_mission = this.dossierAcademiqueSelect.datas_pro.other_mission
        this.old_datas.other_mission_name = this.dossierAcademiqueSelect.datas_pro.other_mission_name
        this.old_datas.other_mission_function = this.dossierAcademiqueSelect.datas_pro.other_mission_function
        this.old_datas.other_mission_name_other = this.dossierAcademiqueSelect.datas_pro.other_mission_name_other
        this.old_datas.other_mission_function = this.dossierAcademiqueSelect.datas_pro.other_mission_function
        this.old_datas.diplome_other = this.dossierAcademiqueSelect.datas_pro.diplome_other
        this.old_datas.function_other = this.dossierAcademiqueSelect.datas_pro.function_other
        this.old_datas.title_other = this.dossierAcademiqueSelect.datas_pro.title_other
        this.old_datas.disciplines_maitrisees = this.dossierAcademiqueSelect.datas_pro.disciplines_maitrisees
        this.old_datas.selected_etablissements = this.dossierAcademiqueSelect.datas_pro.selected_etablissements
        this.old_datas.diplomes = this.dossierAcademiqueSelect.datas_pro.diplomes
    }

    // Annule l'édition en cours du formulaire
    cancelEdition () {
        // On remet les datas d'init
        this.$store.commit('dossieracademique/UPDATE_DATAS_AUTRE_CONCOURS', this.old_datas.other_mission)
        this.$store.commit('dossieracademique/UPDATE_DATAS_ACADEMIE', this.old_datas.academy)
        this.$store.commit('dossieracademique/UPDATE_DATAS_AUTRE_CONCOURS_NOM', this.old_datas.other_mission_name)
        this.$store.commit('dossieracademique/UPDATE_DATAS_DERNIER_DIPLOME', this.old_datas.level_last_diplome)
        this.$store.commit('dossieracademique/UPDATE_DATAS_ETABLISSEMENT_ID', this.old_datas.establishment_id)
        this.$store.commit('dossieracademique/UPDATE_DATAS_ETABLISSEMENT_CODE', this.old_datas.establishment_code)
        this.$store.commit('dossieracademique/UPDATE_DATAS_ETABLISSEMENT_NAME', this.old_datas.establishment_name)
        this.$store.commit('dossieracademique/UPDATE_DATAS_ETABLISSEMENT_ADRESSE', this.old_datas.establishment_address)
        this.$store.commit('dossieracademique/UPDATE_DATAS_FONCTION_ACTUELLE', this.old_datas.function)
        this.$store.commit('dossieracademique/UPDATE_DATAS_AUTRE_CONCOURS_FONCTION', this.old_datas.other_mission_function)
        this.$store.commit('dossieracademique/UPDATE_DATAS_TITRE', this.old_datas.title)
        this.$store.commit('dossieracademique/UPDATE_DATAS_TYPE_ETABLISSEMENT', this.old_datas.establishment_type)
        this.$store.commit('dossieracademique/UPDATE_DATAS_AUTRE_CONCOURS_NOM_AUTRE', this.old_datas.other_mission_name_other)
        this.$store.commit('dossieracademique/UPDATE_DATAS_DERNIER_DIPLOME_AUTRE', this.old_datas.diplome_other)
        this.$store.commit('dossieracademique/UPDATE_DATAS_FONCTION_ACTUELLE_AUTRE', this.old_datas.function_other)
        this.$store.commit('dossieracademique/UPDATE_DATAS_AUTRE_CONCOURS_FONCTION', this.old_datas.other_mission_function)
        this.$store.commit('dossieracademique/UPDATE_DATAS_TITRE_AUTRE', this.old_datas.title_other)
        this.$store.commit('dossieracademique/UPDATE_DISCIPLINES_MAITRISEES', this.old_datas.disciplines_maitrisees)

        this.dossierAcademiqueSelect.datas_pro.title = this.old_datas.title
        this.dossierAcademiqueSelect.datas_pro.function = this.old_datas.function
        this.dossierAcademiqueSelect.datas_pro.level_last_diplome = this.old_datas.level_last_diplome
        this.dossierAcademiqueSelect.datas_pro.establishment_id = this.old_datas.establishment_id
        this.dossierAcademiqueSelect.datas_pro.establishment_code = this.old_datas.establishment_code
        this.dossierAcademiqueSelect.datas_pro.establishment_type = this.old_datas.establishment_type
        this.dossierAcademiqueSelect.datas_pro.establishment_name = this.old_datas.establishment_name
        this.dossierAcademiqueSelect.datas_pro.establishment_address = this.old_datas.establishment_address
        this.dossierAcademiqueSelect.datas_pro.academy = this.old_datas.academy
        this.dossierAcademiqueSelect.datas_pro.other_mission = this.old_datas.other_mission
        this.dossierAcademiqueSelect.datas_pro.other_mission_name = this.old_datas.other_mission_name
        this.dossierAcademiqueSelect.datas_pro.other_mission_function = this.old_datas.other_mission_function
        this.dossierAcademiqueSelect.datas_pro.other_mission_name_other = this.old_datas.other_mission_name_other
        this.dossierAcademiqueSelect.datas_pro.other_mission_function = this.old_datas.other_mission_function
        this.dossierAcademiqueSelect.datas_pro.diplome_other = this.old_datas.diplome_other
        this.dossierAcademiqueSelect.datas_pro.function_other = this.old_datas.function_other
        this.dossierAcademiqueSelect.datas_pro.title_other = this.old_datas.title_other
        this.dossierAcademiqueSelect.datas_pro.disciplines_maitrisees = this.old_datas.disciplines_maitrisees
        this.dossierAcademiqueSelect.datas_pro.selected_etablissements = this.old_datas.selected_etablissements
        this.dossierAcademiqueSelect.datas_pro.diplomes = this.old_datas.diplomes

        // this.initDatasPro()
        this.key = Math.random()

        this.editResponsable = false

        this.$emit('edition_form_pro', false)
    }

    // fermeture de la popup
    closeInfosPros () {
        this.$store.state.dossieracademique.error = null
        this.initDatasPro()
        const closeInfosPro: HTMLElement = document.getElementsByClassName('closeInfosPro')[0] as HTMLElement
        if(closeInfosPro) {
            closeInfosPro.click()
        }
    }


}
