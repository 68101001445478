






























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'


@Component({
    components: {
        ExaGenericTable,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('pv', ['besoins', 'pvSelect', 'loading', 'error', 'meta', 'links', 'totalRows', 'currentPage', 'lastPage', 'totalPage', 'sortby']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    methods: {
        ...mapMutations('dossieracademique', ['SET_DOSSIER_SELECTION_CURRENT', 'SET_VIEW_DOSSIER']),
        ...mapMutations('pv', ['SET_RECONDUCTIONS_SANS_DECISION', 'SET_NOUVELLE_CANDIDATURE_SANS_DECISION']),
        ...mapActions('dossieracademique', ['getDossiersSelections', 'addDossierAcademique', 'getDossierAcademique']),
        ...mapActions('epreuve', ['getEpreuvesCorrections']),
        getStringConcours (concours) {
            return concours?.map((concour: any) => concour.name)
                ?.join(', ') || ''
        },
        ...mapActions('poste', ['getPostes']),
        ...mapActions('pv', ['getBesoins'])
    }
})
export default class BesoinsPV extends Vue {
    @Prop() session_id?: number
    @Prop() matiere_id?: number
    @Prop() pv_id?: number
    @Prop() session_id_precedente?: number

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    dossier_id = 0
    totalPostes = 0
    dataForTab: Array<any> = []
    genericfields: Array<any> = []
    currentParams: any = null
    timeOut = 0

    @Watch('besoins')
    buildFields () {
        this.setFields()
        // On récupère le nombre total de postes depuis pv.besoins
        this.totalPostes = this.$store.state.pv.besoins.length
    }


    // Initialisation des fields du tableau
    setFields () {
        this.genericfields = [
            { key: 'poste',    label: 'Poste',    sortable: false,  class: 'text-start', type: 'text' },
            { key: 'filiere',  label: 'Filière',  sortable: false,  class: 'text-center', type: 'text' },
            { key: 'epreuve',  label: 'Épreuve',  sortable: false,  class: 'text-start', type: 'text' },
            { key: 'intervenantsPrecedent',  label: 'Intervenants', sortable: false,  class: 'text-center bg-gray-light', type: 'text', doubleHeaderLabel: 'Année précédente', doubleHeaderColSpan: 3, doubleHeaderClass: 'bg-gray-light text-center' },
            { key: 'candidatsPrecedent',     label: 'Candidats', sortable: false,  class: 'text-center bg-gray-light', type: 'text' },
            { key: 'cpariAnci',              label: 'Candidats par intervenant', sortable: false,  class: 'text-center bg-gray-light', type: 'text' },
            { key: 'intervenantsSelection',  label: 'Intervenants', sortable: false,  class: 'text-center', type: 'text' },
            { key: 'candidatsCourant',       label: 'Candidats', sortable: false,  class: 'text-center', type: 'text' },
            { key: 'cpariCourant',           label: 'Candidats par intervenant', sortable: false,  class: 'text-center', type: 'text' }
        ]

        const besoinOptions = this.$store.state.pv.besoinOptions
        for (const option in besoinOptions) {
            this.genericfields.push({ key: option, label: option, sortable: false,  class: 'text-center', type: 'text' })
        }

        this.setDataForGenericTab(this.$store.state.pv.besoins)
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const line: any = []

                // Filière
                const filiere = result?.epreuve?.concours
                    ?.map((concour: any) => concour.name)
                    ?.join(', ') || ''

                // Candidats / Intervenants precedent
                let candidat_par_intervenants_precedent: any = 0
                if (result.candidats && result.intervenants.precedent > 0) {
                    candidat_par_intervenants_precedent = (result.candidats.precedent / result.intervenants.precedent).toFixed(0)
                }

                // Candidats / Intervants courant
                let candidat_par_intervenants_courant: any = 0
                if (result.candidats && result.intervenants.besoin > 0) {
                    candidat_par_intervenants_courant = (result.candidats.courant / result.intervenants.besoin).toFixed(0)
                }

                line.push({ label: '', item: result.poste, type: 'actionText', typeAction: 'showPosteAssocie', class: 'text-info item_action', text: result.poste.name})
                line.push({ label: '', item: result.epreuve ? result.epreuve.concours : result.epreuve, type: 'actionText', typeAction: 'showFilliereAssocie', class: 'text-info item_action', text: filiere })
                line.push({ label: '', item: result.epreuve, type: 'actionText', typeAction: 'showEpreuveAssocie', class: 'text-info item_action', text: result.epreuve ? result.epreuve.name : '-' })
                line.push({ label: '', item: result.intervenants ? result.intervenants.precedent : '-', type: 'text', typeAction: null, class: 'text-center bg-gray-light' })
                line.push({ label: '', item: result.candidats ? result.candidats.precedent : '-', type: 'text', typeAction: null, class: 'text-center bg-gray-light' })
                line.push({ label: '', item: candidat_par_intervenants_precedent, type: 'text', typeAction: null, class: 'text-center bg-gray-light' })

                if (this.$store.getters['auth/can'](Ability.RH_SPS_MANAGE) && result.intervenants && !this.$store.state.pv.pvSelect.submitted_at && !this.$store.state.pv.pvSelect.validated_at) {
                    line.push({ label: '', item: result, type: 'inputBesoinPV', typeAction: 'incrementBesoins', class: 'text-center', show: true, matiere_id: this.$props.matiere_id })
                } else {
                    const compteurs_intervenants = result.intervenants.selection + ' / ' + result.intervenants.besoin
                    line.push({ label: '', item: compteurs_intervenants, type: 'text', typeAction: null, class: 'text-center' })
                }



                line.push({ label: '', item: result.candidats ? result.candidats.courant : '-', type: 'text', typeAction: null, class: 'text-center' })
                line.push({ label: '', item: candidat_par_intervenants_courant, type: 'text', typeAction: null, class: 'text-center' })

                const besoinOptions = this.$store.state.pv.besoinOptions
                for (const option in besoinOptions) {
                    line.push({ label: '', item: result[option], type: 'text', typeAction: null, class: 'text-center' })
                }

                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'incrementBesoins':
                    console.log(paParams[1])
                    break

                case 'showPosteAssocie':
                    // console.log('showPosteAssocie', paParams[1])
                    this.$emit('goReconduction', { from: 'showPosteAssocie', data: paParams[1] })
                    break
                case 'showFilliereAssocie':
                    // console.log('showFilliereAssocie', paParams[1])
                    this.$emit('goReconduction', { from: 'showFilliereAssocie', data: paParams[1] })
                    break
                case 'showEpreuveAssocie':
                    // console.log('showEpreuveAssocie', paParams[1])
                    this.$emit('goReconduction', { from: 'showEpreuveAssocie', data: paParams[1] })
                    break
                default:
                    break
            }
        }
    }

    // Increment des besoins d'intervenants
    incrementBesoins (action: any, item: any) {
        const matiere = this.$store.state.matiere.matiereSelect
        if (this.currentParams && this.currentParams.poste_id !== item.poste.id && this.currentParams.entity_id !== (item.epreuve ? item.epreuve.id : matiere.id)) {
            return
        }
        this.currentParams = {
            pv_id: this.pv_id,
            poste_key: item.poste.regroupement,
            poste_id: item.poste.id,
            entity_id: item.epreuve ? item.epreuve.id : matiere.id,
            besoin: item.intervenants.besoin
        }

        const steppers = document.getElementsByClassName('arrowStepper')
        for (let i = 0; i < steppers.length; i++) {
            if (
                steppers[i].id !== 'up_' + item.poste.id + '_' + (item.epreuve ? item.epreuve.id : matiere.id) &&
        steppers[i].id !== 'down_' + item.poste.id + '_' + (item.epreuve ? item.epreuve.id : matiere.id) &&
        steppers[i].id !== 'upDown_' + item.poste.id + '_' + (item.epreuve ? item.epreuve.id : matiere.id)
            ) {
                steppers[i].setAttribute('disabled', 'true')
                steppers[i].classList.add('disabled-item')
            }
        }
        if (action === 'more') {
            item.intervenants.besoin++
        } else {
            if (item.intervenants.besoin > 0 && item.intervenants.besoin > item.intervenants.selection) {
                item.intervenants.besoin--
            } else {
                const steppers3 = document.getElementsByClassName('arrowStepper')
                for (let i = 0; i < steppers3.length; i++) {
                    steppers3[i].removeAttribute('disabled')
                    steppers[i].classList.remove('disabled-item')
                }
                this.currentParams = null
                return
            }
        }
        this.currentParams.besoin = item.intervenants.besoin
        clearTimeout(this.timeOut)
        if (this.currentParams) {
            this.timeOut = setTimeout(() => {
                if (this.currentParams) {
                    this.$store.dispatch('pv/updateBesoin', this.currentParams).then(() => {
                        const steppers2 = document.getElementsByClassName('arrowStepper')
                        for (let i = 0; i < steppers2.length; i++) {
                            steppers2[i].removeAttribute('disabled')
                            steppers[i].classList.remove('disabled-item')
                        }
                        this.setDataForGenericTab(this.$store.state.pv.besoins)
                        this.currentParams = null
                        this.setDataForGenericTab(this.$store.state.pv.besoins)
                    })
                }
            }, 1500)
        }
    }

    load () {
        this.$store.dispatch('epreuve/getEpreuvesCorrections', { isPrecedente: false, filters: null }).then(() => {
            this.$store.dispatch('matiere/getMatiere', { matiere_id: this.matiere_id }).then(() => {
                this.$store.dispatch('pv/getPv', this.pv_id)
            })
        })
    }

    beforeMount () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
