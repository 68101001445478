















































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import PhoneInput from '@/components/Tools/PhoneInput.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        'phone-input': PhoneInput
    },
    computed: {
        ...mapGetters('session', ['sessions', 'loading']),
        ...mapGetters('dossieracademique', ['error', 'countError', 'error_conflict']),
        ...mapState('dossieracademique', ['error', 'countError', 'error_conflict']),
        ...mapState('session', ['sessionActive', 'sessionSelect']),
        ...mapState('matiere', ['matieres', 'matiereSelect'])
    },
    methods: {
        ...mapActions('dossieracademique', ['getDossiersAcademiques', 'ouvrirDossierAcademique'])
    }
})
export default class PopupAddDossier extends Vue {
    @Prop() context?: string
    // DATAS
    name_intervenant = ''
    first_name = ''
    email = ''
    session_id = ''
    matiere_id = ''
    matiere_ids: Array<any> = []
    showError = false
    showErrorConflict = false
    lockInput = false
    confirmation = 0
    phone_1 = ''
    phone_2 = ''

    popupIsOpen = false

    // METHODS

    updatePhoneNumberInputData () {
        if ((this.$refs.phoneInput as any)) {
            if ((this.$refs.phoneInput as any).infosPhoneInput) {
                if ((this.$refs.phoneInput as any).infosPhoneInput.isValidatedPhoneNumber) {
                    this.phone_1 = (this.$refs.phoneInput as any).infosPhoneInput.formattedPhoneNumber
                } else {
                    this.phone_1 = ''
                }
            }
        }
        if ((this.$refs.phoneInput2 as any)) {
            if ((this.$refs.phoneInput2 as any).infosPhoneInput) {
                if ((this.$refs.phoneInput2 as any).infosPhoneInput.isValidatedPhoneNumber) {
                    this.phone_2 = (this.$refs.phoneInput2 as any).infosPhoneInput.formattedPhoneNumber
                } else {
                    this.phone_2 = ''
                }
            }
        }
    }

    @Watch('popupIsOpen')
    popupIsOpenChangeHandler () {
        this.$emit('popupAddDossierOpen', this.popupIsOpen)
    }

    closeHandler () {
        this.name_intervenant = ''
        this.first_name = ''
        this.email = ''
        this.session_id = ''
        this.matiere_id = ''
        this.matiere_ids = []
        this.showError = false
        this.showErrorConflict = false
        this.lockInput = false
        this.confirmation = 0
        this.phone_1 = ''
        this.phone_2 = ''
        this.popupIsOpen = false
    }

    updateChamps (e: any) {
        switch (e.target.name) {
            case 'name':
                this.name_intervenant = e.target.value
                break
            case 'first_name':
                this.first_name = e.target.value
                break
            case 'email':
                this.email = e.target.value
                break
            case 'session_id':
                this.session_id = e.target.value
                break
            case 'confirmation':
                this.confirmation = this.confirmation === e.target.value ? 0 : e.target.value
                break
            default:
                break
        }
    }

    // Création d'un dossier
    submitDossier (e: Event) {
        e.preventDefault()


        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        // Récupère l'id de la session active si une seule session est en cours
        if (this.$store.state.session.sessions.length === 1) {
            this.session_id = this.$store.state.session.sessionSelect.id
        }
        if (this.$store.state.matiere.matiereSelect && this.$props.context !== 'admin') {
            this.matiere_ids = []
            this.matiere_ids.push(this.$store.state.matiere.matiereSelect.id)
        }

        this.updatePhoneNumberInputData()
        const data: any = {
            name: this.name_intervenant,
            first_name: this.first_name,
            email: this.email,
            matiere_ids: this.matiere_ids,
            telephone: this.phone_1 || null,
            telephone_fixe: this.phone_2 || null,
            confirmation: this.confirmation
        }

        this.$store.dispatch('dossieracademique/addDossierAcademique', data)
            .then(() => {
                this.showError = false
                this.showErrorConflict = false
                this.first_name = ''
                this.name_intervenant = ''
                this.email = ''
                this.matiere_id = ''
                this.confirmation = 0
                // Fermeture de la popup aprés validation
                const closeAddDossier: HTMLElement = document.getElementsByClassName('closeAddDossier')[0] as HTMLElement
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Dossier ajouté avec succès !', succesToaster)
                closeAddDossier.click()
                if (this.$props.context === 'admin') {
                    const params = {
                        page: 1,
                        sort: 'user.name',
                        direction: 'asc'
                    }
                    this.$store.dispatch('dossieracademique/getDossiersAcademiques', params)
                } else {
                    this.$store.dispatch('dossieracademique/getDossiersSelections', { matiere_id: this.$store.state.matiere.matiereSelect.id })
                }
            })
            .catch(() => {
                if (this.$store.state.dossieracademique.error_conflict && this.$store.state.dossieracademique.error_conflict.message) {
                    this.showErrorConflict = true
                    this.showError = false
                    this.lockInput = true
                } else {
                    this.showErrorConflict = false
                    this.showError = true
                }
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    numTelRequired = false
    beforeMount () {
        for(let i = 0; i < this.$store.state.auth.user.parameters.length; i ++) {
            if(this.$store.state.auth.user.parameters[i].code === 'NumTelRequired') {
                this.numTelRequired = this.$store.state.auth.user.parameters[i].value === '1'
            }
        }
    }

}
