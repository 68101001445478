




































































































































































































































import { Watch, Prop, Vue, Component } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapState, mapGetters } from 'vuex'
import { TypeDossier, getEtatTraitementDossier, EtatTraitementDossier } from '@/types/Candidat'
import { formatDate, getFileNameFromHeader, isObject } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import _ from 'lodash'

/**
 * Composant relatif à l'import de candidats et aux dossiers de handicaps
 */
@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapState('candidat', ['liste_dossiers', 'loading', 'loading_import']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})

export default class PopupImportFichiersOrgaOraux extends Vue {
    @Prop() showEdit?: boolean;
    showModal?: boolean = false

    Ability = Ability
    isObject = isObject

    liste_fichiers_series_sci: Array<any> = []
    liste_fichiers_indisponibilites: Array<any> = []

    selectedFichier = null
    getEtatTraitementDossier = getEtatTraitementDossier
    EtatTraitementDossier = EtatTraitementDossier
    showErrorDossier = false
    dossier_error = null
    lockActionsButtons = false

    tabSelected = 'series-scei'

    etatPrerequisRepartitionEquipes: any = {}
    seriePrerequisAllOK = false
    seriePrerequisAllCandStatutToPhase = false // Tous les candidats ont un statut à la phase / barre d'admissibilité validées ?
    seriePrerequisAucuneBarresAdmissionValidee = false // Aucune barre d'admission n'est validée
    seriePrerequisHasNoSeancesPlanifiees = false // Aucune séance n'est planifiée
    seriePrerequisHasDatesValidees = false // Les dates des séries sont validées
    seriePrerequisHasDossiersCandidats = false // Il existe des dossiers candidats

    indispoPrerequisAllOK = false
    indispoPrerequisHasNoSeancesPlanifiees = false // Aucune séance n'est planifiée
    indispoPrerequisAucuneBarresAdmissionValidee = false // Les barres d'admission sont validées
    indispoPrerequisHasDossiersCandidats = false // Il existe des dossiers candidats

    loading_serie_sci = true
    loading_serie = true
    loading_indispo = true

    formatDate = formatDate

    needToWatchFilesState = false
    currentTimeoutID = -1
    currentTimeoutIDArray: Array<any> = []
    shouldRefreshTableCandiat = false // A partir du moment où on va lancer une importation ou init avec un fichier en cours de traitement, on va utiliser ce switch pour préciser à la sortie de l'interface de refresh la liste des candidats. Si on l'init à true, on le laisse ensuite à true.
    file: any = null
    loadingImportSeries = false
    intervalImportSeries: any = null
    statusImportSeries: any = null

    /**
     * Affichage ou non de la modale
     *
     * @returns {void}
     */
    @Watch('showEdit')
    showNoShow(): void {
        // si des timeouts étaient en cours, on les arrête
        if (this.currentTimeoutIDArray.length > 0) {
            this.reinitTimeOut()
        }

        this.showModal = this.$props.showEdit
        if (this.showModal) {
            this.initDatas('series-scei')
        }
    }

    @Watch('tabSelected')
    majTabSelected(): void {
        this.initDatas(this.tabSelected)
    }

    @Watch('needToWatchFilesState')
    gestionNeedToWatchFilesState(): void {
        // lance en récurcif le check des fichiers pour récupérer les états d'avancement (toutes les 5 secondes)
        if (this.needToWatchFilesState && this.showModal) {
            this.currentTimeoutID = setTimeout(() => {
                this.currentTimeoutIDArray.push(this.currentTimeoutID)
                switch (this.tabSelected) {
                    case 'series-scei':
                        this.loadListesFichiersSeriesSCI()
                        this.gestionNeedToWatchFilesState()
                        break
                    case 'series':
                        this.loadListesFichiersSeries()
                        this.gestionNeedToWatchFilesState()
                        break
                    case 'indisponibilites':
                        this.loadListesFichiersIndisponibilites()
                        this.gestionNeedToWatchFilesState()
                        break
                }
            }, 5000)
        } else {
            this.reinitTimeOut()
        }
    }

    mounted(): void {
        // mise en place d'un event pour gérer un reinit des timeout lorsqu'on clique sur boutons de navigation back/next button
        window.onpopstate = () => {
            this.reinitTimeOut()
        }
    }

    /**
     * Réinitialise l'ensemble des éléments timeout qui auraient été lancés
     *
     * @returns {void}
     */
    reinitTimeOut(): void {
        // console.log('Dans reinitTimeOut - this.currentTimeoutIDArray', this.currentTimeoutIDArray)
        for (const idTimeout in this.currentTimeoutIDArray) {
            clearTimeout(parseInt(this.currentTimeoutIDArray[idTimeout]))
        }
        if (this.currentTimeoutID !== -1) {
            clearTimeout(this.currentTimeoutID)
        }
        this.currentTimeoutIDArray = []
        this.currentTimeoutID = -1
    }

    initDatas(tabSelected: string): void {
        this.selectedFichier = null
        this.needToWatchFilesState = false
        this.statusImportSeries = null
        this.file = null

        // si des timeouts étaient en cours, on les arrête
        if (this.currentTimeoutIDArray.length > 0) {
            this.reinitTimeOut()
        }

        if (this.intervalImportSeries) {
            clearInterval(this.intervalImportSeries)
            this.intervalImportSeries = null
        }

        switch (tabSelected) {
            case 'series-scei': {
                this.loading_serie_sci = true
                this.$store.dispatch('candidat/getEtatPrerequisRepartitionEquipes').then(() => {
                    this.etatPrerequisRepartitionEquipes = this.$store.state.candidat.datasRepartitionEquipes.prerequis
                    this.checkSeriePrerequis()
                    if (this.seriePrerequisAllOK) {
                        // Appel de la liste uniquement si les prérequis sont OK
                        this.loadListesFichiersSeriesSCI()
                    } else {
                        this.loading_serie_sci = false
                    }
                })
                break
            }
            case 'series': {
                this.loading_serie = true
                this.$store.dispatch('candidat/getEtatPrerequisRepartitionEquipes').then(() => {
                    this.etatPrerequisRepartitionEquipes = this.$store.state.candidat.datasRepartitionEquipes.prerequis
                    this.checkSeriePrerequis()
                    this.loading_serie = false
                    if (this.seriePrerequisAllOK) {
                        this.getDataImportSeries()
                    } else {
                        this.loading_serie_sci = false
                    }
                })
                break
            }
            case 'indisponibilites': {
                this.loading_indispo = true
                this.$store.dispatch('candidat/getEtatPrerequisRepartitionEquipes').then(() => {
                    this.etatPrerequisRepartitionEquipes = this.$store.state.candidat.datasRepartitionEquipes.prerequis
                    this.checkIndisponibilitesPrerequis()
                    if (this.indispoPrerequisAllOK) {
                        // Appel de la liste des fichiers uniquement si les prérequis sont OK
                        this.loadListesFichiersIndisponibilites()
                    } else {
                        this.loading_indispo = false
                    }
                })
                break
            }
        }
    }

    /**
     * Détermine si la liste passée en paramètre contient au moins un fichier en curs d'importation
     *
     * @param {Array<any>} listeFichiers - Liste des fichiers à vérifier
     * @returns {boolean}
     */
    hasFichierImportationEnCours(listeFichiers: Array<any>): boolean {
        let hasFichierImportationEnCours = false
        // check si aucune séance n'est planifiée
        for (const fichier of listeFichiers) {
            if (fichier.etat === EtatTraitementDossier.ETAT_EN_COURS) {
                hasFichierImportationEnCours = true
                break
            }
        }
        return hasFichierImportationEnCours
    }

    /**
     * Importation du fichier selectionné
     *
     * @returns {void}
     */
    importDatas(): void {
        this.$store.dispatch('candidat/updateDossier', { dossier_id: this.selectedFichier })
            .then(() => {
                this.selectedFichier = null
                this.initDatas(this.tabSelected)
            })
    }

    /**
     * Fermeture de la modale
     *
     * @returns {void}
     */
    closeModal(): void {
        this.showErrorDossier = false
        this.dossier_error = null
        this.selectedFichier = null

        // si des timeouts étaient en cours, on les arrête et on suppose par précaution qu'on a potentiellement eu au moins un fichier traité.
        if (this.currentTimeoutIDArray.length > 0) {
            this.reinitTimeOut()
        }

        this.$emit('reinitShow', this.shouldRefreshTableCandiat)
    }

    /**
     * Chargement des listes des dossiers pour les séries scei
     *
     * @returns {void}
     */
    loadListesFichiersSeriesSCI(): void {
        this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_DOSSIER_SERIES })
            .then(() => {
                this.liste_fichiers_series_sci = this.$store.state.candidat.liste_dossiers
                this.needToWatchFilesState = this.hasFichierImportationEnCours(this.liste_fichiers_series_sci)
                if (!this.shouldRefreshTableCandiat) {
                    if (this.needToWatchFilesState) {
                        this.shouldRefreshTableCandiat = true
                    }
                }
            })
            .finally(() => this.loading_serie_sci = false)
    }

    /**
     * Chargement des listes des dossiers pour les séries
     *
     * @returns {void}
     */
    loadListesFichiersSeries(): void {
        this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_DOSSIER_SERIES })
            .then(() => {
                this.liste_fichiers_series_sci = this.$store.state.candidat.liste_dossiers
                this.needToWatchFilesState = this.hasFichierImportationEnCours(this.liste_fichiers_series_sci)
                if (!this.shouldRefreshTableCandiat) {
                    if (this.needToWatchFilesState) {
                        this.shouldRefreshTableCandiat = true
                    }
                }
            })
            .finally(() => this.loading_serie = false)
    }

    /**
     * Chargement des listes des dossiers pour les indisponibilités
     *
     * @returns {void}
     */
    loadListesFichiersIndisponibilites(): void {
        this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_DOSSIER_TIPE })
            .then(() => {
                this.liste_fichiers_indisponibilites = this.$store.state.candidat.liste_dossiers
                this.loading_indispo = false
                this.needToWatchFilesState = this.hasFichierImportationEnCours(this.liste_fichiers_indisponibilites)
                if (!this.shouldRefreshTableCandiat) {
                    if (this.needToWatchFilesState) {
                        this.shouldRefreshTableCandiat = true
                    }
                }
            })
    }

    /**
     * Check l'ensemble des prérequis permettant l'accès à l'import de fichiers de séries
     *
     * @returns {void}
     */
    checkSeriePrerequis(): void {
        let checkPrerequis_ok = true
        this.seriePrerequisAllCandStatutToPhase = true // Tous les candidats ont un statut à la phase / barre d'admissibilité validées ?
        this.seriePrerequisAucuneBarresAdmissionValidee = true // Aucune barre d'admission n'est validée
        this.seriePrerequisHasNoSeancesPlanifiees = true // Aucune séance n'est planifiée
        this.seriePrerequisHasDatesValidees = true // Les dates des séries sont validées
        this.seriePrerequisHasDossiersCandidats = true // Il existe des dossiers candidats

        // Check si les barres ne sont pas toutes validées
        if (this.etatPrerequisRepartitionEquipes.nb_candidats !== this.etatPrerequisRepartitionEquipes.nb_candidats_statut || this.etatPrerequisRepartitionEquipes.nb_barres !== this.etatPrerequisRepartitionEquipes.nb_barres_validees) {
            this.seriePrerequisAllCandStatutToPhase = false
            checkPrerequis_ok = false
        }
        // Check si aucune barre d'admission n'est validée
        if (this.etatPrerequisRepartitionEquipes.nb_barres_validees_phase_en_cours > 0) {
            this.seriePrerequisAucuneBarresAdmissionValidee = false
            checkPrerequis_ok = false
        }
        // check si aucune séance n'est planifiée
        for (const serie of this.$store.state.serie.series) {
            if (serie.planifie !== 0) {
                this.seriePrerequisHasNoSeancesPlanifiees = false
                checkPrerequis_ok = false
                break
            }
        }
        // Check si les dates des séries sont validées
        if (!this.$store.state.session.sessionSelect.series_validated_at) {
            this.seriePrerequisHasDatesValidees = false
            checkPrerequis_ok = false
        }
        // Check s'il existe des dossiers candidats
        if (this.$store.state.candidat.candidats.length === 0) {
            this.seriePrerequisHasDossiersCandidats = false
            checkPrerequis_ok = false
        }

        this.seriePrerequisAllOK = checkPrerequis_ok
    }

    /**
     * Check l'ensemble des prérequis permettant l'accès à l'import de fichiers d'indisponibilités
     *
     * @returns {void}
     */
    checkIndisponibilitesPrerequis(): void {
        let checkPrerequis_ok = true
        this.indispoPrerequisAucuneBarresAdmissionValidee = true // Aucune barre d'admission n'est validée
        this.indispoPrerequisHasNoSeancesPlanifiees = true // Aucune séance n'est planifiée
        this.indispoPrerequisHasDossiersCandidats = true // Il existe des dossiers candidats

        // Check si aucune barre d'admission n'est validée
        if (this.etatPrerequisRepartitionEquipes.nb_barres_validees_phase_en_cours > 0) {
            this.indispoPrerequisAucuneBarresAdmissionValidee = false
            checkPrerequis_ok = false
        }
        // check si aucune séance n'est planifiée
        for (const serie of this.$store.state.serie.series) {
            if (serie.planifie !== 0) {
                this.indispoPrerequisHasNoSeancesPlanifiees = false
                checkPrerequis_ok = false
                break
            }
        }
        // Check s'il existe des dossiers candidats
        if (this.$store.state.candidat.candidats.length === 0) {
            this.indispoPrerequisHasDossiersCandidats = false
            checkPrerequis_ok = false
        }

        this.indispoPrerequisAllOK = checkPrerequis_ok
    }

    /**
     * Affiche les erreurs d'un fichier
     *
     * @param {any} dossier - Dossier à afficher
     * @returns {void}
     */
    showErrorsFichiers(dossier: any): void {
        this.dossier_error = dossier
        this.showErrorDossier = true
    }

    /**
     * Ferme la visualisation des errors
     *
     * @returns {void}
     */
    closeErrorFichiers(): void {
        this.dossier_error = null
        this.showErrorDossier = false
    }

    /**
     * Télécharge le modèle de fichier d'import des séries
     *
     * @return {void}
     */
    downloadGabarit(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours ...', infosToaster)

        this.$store.dispatch('candidat/exportTemplateSeries')
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || 'modele_import_notes.csv')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => this.$bvToast.hide(idInfo))
    }

    /**
     * Événement de sélection de fichier
     *
     * @param {any} event - Événement
     * @return {void}
     */
    selectFile(event: any): void {
        this.file = event.target.files[0]
    }

    /**
     * Importation des séries
     *
     * @return {void}
     */
    importSeries(): void {
        if (!this.file || this.loadingImportSeries) {
            return
        }
        this.loadingImportSeries = true

        this.$store.dispatch('candidat/importSeries', this.file)
            .then(this.getDataImportSeries)
            .catch((error) => {
                console.log('ko:' + error)
                const idError = 't_error_' + Math.random()
                const errorToaster = {
                    id: idError,
                    toaster: 'b-toaster-top-right',
                    variant: 'danger',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast("Une erreur s'est produite.", errorToaster)
            })
            .finally(() => this.loadingImportSeries = false)
    }

    /**
     * Charge le statut de l'import des series
     *
     * @return {Promise<void>}
     */
    async getDataImportSeries(): Promise<void> {
        this.loadingImportSeries = true
        const response = await this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_IMPORT_CANDIDATS_SERIES })
        if (response?.data?.data?.length) {
            this.statusImportSeries = _.orderBy(response.data.data, ['id'], ['desc'])[0]

            if (this.statusImportSeries?.erreur_message?.message?.endsWith('.')) {
                this.statusImportSeries.erreur_message.message = this.statusImportSeries.erreur_message.message.slice(0, -1)
            }

            if (typeof this.statusImportSeries?.erreur_message?.errors === 'object') {
                this.statusImportSeries.erreur_message.errors =
                    Object.entries(this.statusImportSeries.erreur_message.errors)
                        .map(([, value]: any) => `${value.charAt(0).toUpperCase() + value.slice(1)}`)
            }

            switch (this.statusImportSeries.etat) {
                case EtatTraitementDossier.ETAT_NON_LANCER:
                case EtatTraitementDossier.ETAT_EN_COURS:
                    if (!this.intervalImportSeries) {
                        this.intervalImportSeries = setInterval(() => {
                            this.getDataImportSeries()
                        }, 3000)
                    }
                    break
                case EtatTraitementDossier.ETAT_ERREUR:
                case EtatTraitementDossier.ETAT_TRAITE:
                    if (this.intervalImportSeries) {
                        clearInterval(this.intervalImportSeries)
                        this.intervalImportSeries = null
                    }
                    break
            }
        }
        this.loadingImportSeries = false
    }
}
