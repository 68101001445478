
































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import GestionCandidatures from '@/components/SelectionRH/GestionCandidatures.vue'
import GestionNewCandidatures from '@/components/SelectionRH/GestionNewCandidatures.vue'
import BesoinsPV from '@/components/SelectionRH/BesoinsPV.vue'
import GestionPV from '@/components/SelectionRH/GestionPV.vue'
import Back from '@/components/Tools/Back.vue'

@Component({
    components: {
        GestionCandidatures,
        GestionNewCandidatures,
        BesoinsPV,
        GestionPV,
        Back
    },
    computed: {
        ...mapState('matiere', ['matiereSelect', 'loading']),
        ...mapState('session', ['sessionSelect', 'loading']),
        ...mapState('pv', ['pvSelect', 'loading']),
        ...mapGetters('pv', ['pvSelect']),
        ...mapGetters('session', ['sessionSelect']),
        ...mapGetters('matiere', ['matiereSelect']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    methods: {
        ...mapActions('matiere', ['getMatiere']),
        ...mapActions('session', ['getSession']),
        ...mapActions('pv', ['getPv'])
    }
})

export default class SelectionDossiersAcademiques extends Vue {
    @Prop() session_id?: number
    @Prop() matiere_id?: number
    @Prop() pv_id?: number

    showPV = false
    ongletSelect = 'besoins'

    @Watch('user_session_id')
    refreshInterface () {
        const link = '/selections'
        if (this.$router) {
            this.$router.push(link)
        }
    }

    gestionCandidatureFiltre: any = null

    goReconduction (data:any) {
        // console.log('goReconduction', data)
        // this.ongletSelect = 'reconductions'
        const ong: any = this.$refs['nav-reconduction-demissions-tab']
        this.gestionCandidatureFiltre = data
        ong.click()
    }

    resetFiltre () {
        this.gestionCandidatureFiltre = null
    }

    @Watch('pvSelect')
    setPvSelect () {
        if (this.$store.state.pv.pvSelect !== null && this.$store.state.session.sessionSelect !== null) {
            this.loadDossiersSelections()
        }
    }

    // Charge la liste des dossiers selections
    async loadDossiersSelections () {
        // Epreuves corrections session en cours
        await this.$store.dispatch('epreuve/getEpreuvesCorrections', { isPrecedente: false })

        // Epreuves corrections session precedente
        await this.$store.dispatch('epreuve/getEpreuvesCorrections', { isPrecedente: true, session_replace: this.$store.state.session.sessionSelect.session_id })

        await this.$store.dispatch('dossieracademique/getDossiersSelections', { session_id: this.$store.getters['auth/user_session_id'], matiere_id: this.$props.matiere_id, new_candidature: this.$props.new_candidature })
    }

    loadPv () {
        this.showPV = true
    }
}
