






























































import { mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import AnnexeConventionEditor from '@/components/Conventions/AnnexeConventionEditor.vue'
import { Ability } from '@/types/Ability'

@Component({
    computed: {
        ...mapState('centre', ['error', 'loading', 'source_pdf', 'centreSelect']),
        ...mapGetters('conventiontype', ['conventiontypes', 'loading', 'totalRows', 'lastPage']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    components: {
        VuePdfApp,
        AnnexeConventionEditor
    }
})
export default class GestionAnnexeConvention extends Vue {
    @Prop() show?: boolean
    @Prop() centre?: any
    // @Prop() centre_id?: any

    Ability = Ability
    retour_serveur = null
    centre_select: any = null
    convention_type_select: any = null
    annexeIsRejected = false
    file_name = ''

    source_doc: any = null
    showPreview = false
    modeEditionAnnexe = false

    @Watch('centre')
    loadCentreSelect () {
        this.$store.state.centre.error = null
        this.centre_select = this.$props.centre

        if (this.$store.state.conventiontype.conventiontypes.length !== 0) {
            if (this.centre_select) {
                const index = this.$store.state.conventiontype.conventiontypes.findIndex((e: any) => e.id === this.$props.centre.convention_type_id)
                if (index !== null) {
                    this.convention_type_select = this.$store.state.conventiontype.conventiontypes[index]
                }
            }
        } else {
            this.$store.dispatch('conventiontype/getConventionTypes').then(() => {
                if (this.centre_select) {
                    const index = this.$store.state.conventiontype.conventiontypes.findIndex((e: any) => e.id === this.$props.centre.convention_type_id)
                    if (index !== null) {
                        this.convention_type_select = this.$store.state.conventiontype.conventiontypes[index]
                    }
                }
            })
        }
    }

    changeModeEditionAnnexe (infoModeEdition: any) {
        this.modeEditionAnnexe = infoModeEdition.modeEdition
        if (infoModeEdition.annexeSaved === true) {
            this.annexeIsRejected = false
        }
    }

    // Enregistre le convention_type_id
    saveTypeConvention () {
        const payload = {
            id: this.centre_select.id,
            convention_type_id: this.convention_type_select.id
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('centre/updateCentre', payload)
            .then(async () => {
                await this.$store.dispatch('centre/getCentre', { centre_id: this.centre_select.id })
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Type de convention enregistré.', succesToaster)
                this.centre_select = this.$store.state.centre.centreSelect
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // reset
    reset (close: boolean) {
        this.$store.state.centre.error = null
        this.annexeIsRejected = false
        this.centre_select = null
        this.convention_type_select = null
        this.source_doc = null
        this.showPreview = false
        if (close) {
            this.$emit('close', true)
        }
    }

    // Active le preview de l'annexe de convention
    previewAnnexeConvention () {
        this.source_doc = null
        this.showPreview  = true
        this.$store.dispatch('centre/getAnnexeConventionPDF', { centre_id: this.$props.centre.id }).then(() => {
            this.source_doc = base64ToArrayBuffer(this.$store.state.centre.source_pdf)
            this.file_name = 'Convention_' + this.$store.state.centre.code
        })
    }

    // Close preview
    closePreview () {
        this.source_doc = null
        this.showPreview = false
        this.file_name = ''
    }
}
