























































































































































































import {Vue, Component, Prop} from 'vue-property-decorator'
import { BModal } from 'bootstrap-vue'
import {PublicationInterface} from "@/types/Publication";
import {CompteCandidatInterface} from "@/types/CompteCandidat";
import {Etat, getEtatDossier} from "@/types/DossierInscription";
import {formatDate} from "@/utils/helpers";


@Component({
    methods: {
        getEtatDossier,
        formatDate
    },
    computed: {
        Etat() {
            return Etat
        }
    },
    components: {
        BModal
    },
    watch: {
        open: {
            handler: 'onOpenChange',
            immediate: true
        },
        modal: {
            handler: 'onModalChange'
        },
        'inscrireCandidatData.procedure_inscription': {
            handler: 'loadCandidats'
        },
        'inscrireCandidatData.nom_candidat': {
            handler: 'onSelectCandidatChange'
        }
    }
})
export default class PopupShowHistoriqueInscriptions extends Vue {
    @Prop({ type: Boolean, required: true, default: false }) readonly open!: boolean

    modal = false
    loadingInscrireCandidat = {
        data: false,
        save: false,
        candidats: false,
        nb_dossiers: false
    }

    listePublications: Array<PublicationInterface> = []
    listeCandidats: Array<CompteCandidatInterface> = []
    previsionsTransfert: any = null
    inscrireCandidatData = {
        procedure_inscription: 0,
        etat_dossier: 0,
        nom_candidat: '',
        code_candidat: 0,
        justification: ''
    }

    /**
     * onOpenChange
     * Mise à jour de l'état de la modale
     * @return {void}
     */
    onOpenChange(): void {
        this.modal = this.open
    }

    /**
     * onModalChange
     * Mise à jour des variables en fonction de l'état de la modale
     * @return {Promise<void>}
     */
    async onModalChange(): Promise<void> {
        if (this.modal) {
            // Chargement des données
            this.loadingInscrireCandidat.data = true

            await this.$store.dispatch('publication/getPublications')
            this.listePublications = this.$store.getters['publication/get_publications_inscriptions']
            for (let i = 0; i < this.listePublications.length; i++) {
                // "..." ajouté en fin de libellé s'il est trop long
                if (this.listePublications[i].name.length >= 48) {
                    this.listePublications[i].name = this.listePublications[i].name.substring(0, 48) + '...'
                }
            }

            this.loadingInscrireCandidat.data = false
        } else {
            // Réinitialisation des variables
            this.loadingInscrireCandidat = {
                data: false,
                save: false,
                candidats: false,
                nb_dossiers: false
            }
            this.inscrireCandidatData = {
                procedure_inscription: 0,
                etat_dossier: 0,
                nom_candidat: '',
                code_candidat: 0,
                justification: ''
            }
            this.listePublications = []
            this.listeCandidats = []
            this.previsionsTransfert = null
            this.$emit('close')
        }
    }

    /**
     * Chargement de la liste des candidats lors de la sélection d'une procédure d'inscription
     */
    loadCandidats() {
        this.loadingInscrireCandidat.candidats = true
        this.inscrireCandidatData.nom_candidat = ''
        this.listeCandidats = []

        if (this.inscrireCandidatData.procedure_inscription) {
            this.$store.dispatch('comptecandidat/getCompteCandidats', {
                'filter-publication_id': this.inscrireCandidatData.procedure_inscription,
                perPage: 0
            }).then(() => {
                this.listeCandidats = this.$store.getters['comptecandidat/comptes']
            }).finally(() => {
                this.loadingInscrireCandidat.candidats = false
            })
        }
    }

    /**
     * Sélection du code lors de la sélection d'un candidat
     */
    onSelectCandidatChange(): void {
        this.inscrireCandidatData.code_candidat = 0
        if (this.inscrireCandidatData.nom_candidat.trim()) {
            for (let i = 0; i < this.listeCandidats.length; i++) {
                if (`${this.listeCandidats[i].name} ${this.listeCandidats[i].first_name}` === this.inscrireCandidatData.nom_candidat) {
                    this.inscrireCandidatData.code_candidat = this.listeCandidats[i].code
                    break
                }
            }
        }
    }

    /**
     * Construction du payload en fonction des informations du formulaire
     * @returns object Payload
     */
    constructInscrireCandidatPayload(): object {
        const payload: { 'filter-etat'?: number; 'filter-code_compte_candidat'?: number } = {}
        if (this.inscrireCandidatData.etat_dossier) {
            payload['filter-etat'] = this.inscrireCandidatData.etat_dossier
        }
        if (this.inscrireCandidatData.code_candidat) {
            payload['filter-code_compte_candidat'] = this.inscrireCandidatData.code_candidat
        }
        return payload
    }

    /**
     * Mise à jour des informations de l'interface concernant le / les dossiers sélectionnés
     * @returns void
     */
    updateNBDossiers(): void {
        if (this.loadingInscrireCandidat.nb_dossiers) {
            return
        }

        this.loadingInscrireCandidat.nb_dossiers = true
        if (this.inscrireCandidatData.procedure_inscription) {
            // Récupération des données à afficher
            this.$store.dispatch('candidat/getPrevisionnelTransfere', {
                publication_id: this.inscrireCandidatData.procedure_inscription,
                payload: this.constructInscrireCandidatPayload()
            }).then((response) => {
                // Transformation des données pour faciliter l'affichage
                this.previsionsTransfert = {
                    nbDossiers: response.data.nbDossiers ?? 0,
                    dossierConcerne: response.data.dossierConcerne ?? null,
                    concours: response.data
                }
                const keys = Object.keys(this.previsionsTransfert.concours)
                for (let i = 0; i < keys.length; i++) {
                    switch (keys[i]) {
                        case 'nbDossiers':
                        case 'dossierConcerne':
                            delete this.previsionsTransfert.concours[keys[i]]
                            break
                        default:
                            this.previsionsTransfert.concours[keys[i].toString().slice(11)] = this.previsionsTransfert.concours[keys[i]]
                            delete this.previsionsTransfert.concours[keys[i]]
                            break
                    }
                }
            }).finally(() => {
                this.loadingInscrireCandidat.nb_dossiers = false
            })
        }
    }

    /**
     * Inscrire les dossiers des candidats répondants aux critères sélectionnés par l'utilisateur
     * @returns void
     */
    saveInscrireCandidat(): void {
        if (!this.inscrireCandidatData.procedure_inscription || !this.inscrireCandidatData.justification) {
            return
        }
        this.loadingInscrireCandidat.save = true

        // Suppression du préfixe "filter-" des clés du payload
        const payload: any = this.constructInscrireCandidatPayload()
        const keys = Object.keys(payload)
        for (let i = 0; i < keys.length; i++) {
            payload[keys[i].toString().slice(7)] = payload[keys[i]]
            delete payload[keys[i]]
        }

        this.$store.dispatch('candidat/postTransfereDossier', {
            publication_id: this.inscrireCandidatData.procedure_inscription,
            payload: {
                ...payload,
                justificatif: this.inscrireCandidatData.justification
            }
        }).then(() => {
            this.modal = false
        }).finally(() => {
            this.loadingInscrireCandidat.save = false
        })
    }
}
