





















































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import FormPerso from '@/components/DossierAdministratif/FormPerso.vue'
// import FormPro from '@/components/DossierAdministratif/FormPro.vue'
import FormSituationStatuaire from '@/components/DossierAdministratif/FormSituationStatuaire.vue'
import FormDocument from '@/components/DossierAdministratif/FormDocument.vue'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { Etat, getEtatSpec } from '@/types/DossierAdministratif'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { base64ToArrayBuffer } from '@/utils/helpers'
import VuePdfApp from 'vue-pdf-app'
import { Ability } from '@/types/Ability'

@Component({
    components: {
        ItemIntervenant,
        FormPerso,
        // FormPro,
        FormSituationStatuaire,
        FormDocument,
        VuePdfApp,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapState('dossieradministratif', ['hasAvatar', 'isValide', 'viewDossier', 'source_pdf', 'error']),
        ...mapState('document', ['nb_rejete']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('dossieradministratif', ['dossierAdministratifSelect', 'avatar', 'loading', 'isValide', 'error'])
    },
    methods: {
        ...mapActions('dossieradministratif', ['getDossiersAdministratifs', 'getDossierAdministratif', 'updateDossierAdministratif']),
        ...mapMutations('document', ['SET_NB_PJ_REJETE', 'RESET_NB_PJ_REJETE']),
        ...mapMutations('dossieradministratif', ['SET_HAS_AVATAR', 'SET_DOSSIER_ACADEMIQUE_AVATAR', 'SET_VIEW_DOSSIER'])
    }
})
export default class AssistantValidationDossierAdministratif extends Vue {
    @Prop() dossier_id?: number;
    @Prop() params?: any;
    @Prop() load_avatar?: boolean;

    @Watch('viewDossier')
    showPopup () {
        if (this.$store.state.dossieradministratif.viewDossier === true) {
            if (this.$props.dossier_id !== 0 && this.$props.dossier_id !== null) {
                this.$store.dispatch('dossieradministratif/getDossierAdministratif', this.$props.dossier_id).then(() => {
                    this.show = this.$store.state.dossieradministratif.viewDossier
                })
            }
        } else {
            this.show = this.$store.state.dossieradministratif.viewDossier
        }
    }

    @Watch('dossierAdministratifSelect')
    checkDossierAdministratif () {
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect.hasAvatar && this.$props.dossier_id !== 0) {
            this.$store.dispatch('dossieradministratif/getAvatar', { dossier_id: this.$store.state.dossieradministratif.dossierAdministratifSelect.id })
        }
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect.user.code) {
            this.valeurCodeIntervenant = this.$store.state.dossieradministratif.dossierAdministratifSelect.user.code
        } else {
            this.valeurCodeIntervenant = ''
        }
    }

    @Watch('error')
    setError () {
        if (this.$store.state.dossieradministratif.error) {
            this.errorInterface = this.$store.state.dossieradministratif.error
        } else {
            this.errorInterface = ''
        }
    }

    // DATAS
    Ability = Ability
    concours = []
    etat_depose: Etat = Etat.ETAT_DEPOSE
    etape_consultee = 1
    etapes_totales = 3
    showError = false
    hideValid = false
    show = false
    showMessage = false
    messageRejet = ''
    getEtatSpec = getEtatSpec
    Etat = Etat
    disabledButtons = false
    modePreview = false
    modeCodeIntervenant = false
    editionEnCoursCodeIntervenant = false
    valeurCodeIntervenant = ''
    messageErreurSimple = ''

    source_doc: any = ''
    errorInterface = ''
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    // METHODS
    etapeSuivante () {
        this.etape_consultee++
    }

    // Check l'édition en cours
    checkEditionEnCours (en_cours: boolean) {
        this.disabledButtons = en_cours
    }

    etapePrecedente () {
        this.etape_consultee--
    }

    setEtape (etape: number) {
        this.etape_consultee = etape
    }

    // Fermeture de la popup
    closeAssistant () {
        this.etape_consultee = 1
        this.hideValid = false
        this.showMessage = false
        this.modePreview = false
        this.modeCodeIntervenant = false
        this.editionEnCoursCodeIntervenant = false
        this.$store.state.dossieradministratif.error = null
        this.$store.state.dossieradministratif.source_pdf = ''
        this.$store.commit('document/RESET_NB_PJ_REJETE')
        this.$store.commit('dossieradministratif/SET_VIEW_DOSSIER', false)
    }

    // Reinit la consultation des étapes
    reinitEtape () {
        this.etape_consultee = 1
        this.hideValid = false
        this.showMessage = false
        this.modePreview = false
        this.modeCodeIntervenant = false
        this.editionEnCoursCodeIntervenant = false
        this.$store.state.dossieradministratif.error = null
        this.$store.state.dossieradministratif.source_pdf = ''
        this.$store.commit('document/RESET_NB_PJ_REJETE')
        this.$store.commit('dossieradministratif/SET_VIEW_DOSSIER', false)
        this.$store.dispatch('dossieradministratif/getDossiersAdministratifs', this.$props.params)
    }

    // Valide le dossier
    validationDossier () {
        const params = { etat: Etat.ETAT_VALIDE }
        this.$store.dispatch('dossieradministratif/updateDossierAdministratif', { dossier_id: this.$props.dossier_id, payload: params }).then(() => {
            if (this.$store.state.dossieracademique.error !== '') {
                this.$store.commit('dossieradministratif/SET_VIEW_DOSSIER', false)
                this.reinitEtape()
            } else {
                this.showError = true
            }
        })
    }

    // Rejette le dossier
    rejeterDossier () {
        const params = { etat: Etat.ETAT_REJETE, commentaire_rejet: this.messageRejet }
        this.$store.dispatch('dossieradministratif/updateDossierAdministratif', { dossier_id: this.$props.dossier_id, payload: params }).then(() => {
            if (this.$store.state.dossieracademique.error !== '') {
                this.messageRejet = ''
                this.$store.commit('dossieradministratif/SET_VIEW_DOSSIER', false)
                this.reinitEtape()
            } else {
                this.showError = true
            }
        })
    }

    // Cache les boutons de validation
    hideValidation (showDocument: boolean) {
        this.hideValid = showDocument
    }

    // Aperçu du contrat en pdf
    previewDossier () {
        this.source_doc = null
        this.hideValid = false
        this.modePreview = true
        this.$store.dispatch('dossieradministratif/getDossierPDF', { dossier_id: this.$props.dossier_id }).then(() => {
            this.source_doc = base64ToArrayBuffer(this.$store.state.dossieradministratif.source_pdf)
        })
    }

    // Ferme le preview pdf
    backpreviewDossier () {
        this.modePreview = false
        this.$store.state.dossieradministratif.error = null
        this.$store.state.dossieradministratif.source_pdf = ''
    }

    // Ouvre la gestion du code de l'intervenant
    gestionCodeIntervenant () {
        this.hideValid = false
        this.modeCodeIntervenant = true
    }

    // Ferme ou annule la gestion du code intervenant
    backgestionCodeIntervenant () {
        this.modeCodeIntervenant = false
        this.$store.state.dossieradministratif.error = null
    }

    // Ouvre l'édition du code intervenant
    ouvrirEditiongestionCodeIntervenant () {
        this.editionEnCoursCodeIntervenant = true
    }

    // annule l'édition du code intervenant
    annulerEditiongestionCodeIntervenant () {
        this.editionEnCoursCodeIntervenant = false
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect.user.code) {
            this.valeurCodeIntervenant = this.$store.state.dossieradministratif.dossierAdministratifSelect.user.code
        } else {
            this.valeurCodeIntervenant = ''
        }
    }

    // enregistre le code de l'intervenant
    saveCodeintervenant () {
        const datas = {
            code: this.valeurCodeIntervenant
        }

        this.$store.state.dossieradministratif.error = null
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        this.$store.dispatch('dossieradministratif/storeCodeIntervenant', { dossier_id: this.$props.dossier_id, payload: datas })
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Code intervenant enregistré avec succès !', succesToaster)
                this.editionEnCoursCodeIntervenant = false
            })
            .catch(() => {
                this.annulerEditiongestionCodeIntervenant()
                this.messageErreurSimple = 'Une erreur est survenue :'
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}
