


















































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import PopupEditMesureHandicaps from '@/components/Mesure/PopupEditMesureHandicaps.vue'
import EditMesureHandicaps from '@/components/Mesure/EditMesureHandicaps.vue'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupImportAmenagements from '@/components/Candidat/PopupImportAmenagements.vue'
import { dateDuJourFoDocument, checkIcone } from '@/utils/helpers'
import { getTypeMesure, TypeMesure } from '@/types/Amenagement'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getTypeAmenagement, TypeAmenagement } from '@/types/Salle'

@Component({
    components: {
        ExaGenericTable,
        PopupEditMesureHandicaps,
        EditMesureHandicaps,
        'font-awesome-icon': FontAwesomeIcon,
        PopupImportAmenagements,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('gestionParticuliere', ['gestionParticulieres', 'loading', 'totalRows', 'lastPage', 'meta', 'error']),
        ...mapGetters('amenagement', ['amenagements', 'loading', 'totalRows', 'lastPage', 'meta']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    }
})
export default class AmenagementsMesuresHandicaps extends Vue {
    @Prop() type? : string
    genericfields: Array<any> = []

    filtres: any                = []
    dataForTab: Array<any>      = []

    sortBy                      = ''
    sortDesc                    = false
    sortDirection               = 'asc'
    filter                      = ''
    filterOn                    = []
    stickyHeader                = true
    amenagement: any            = null
    amenagementTemp: any        = null
    totalMesuresFiltered        = 0 // pour l'affichage du total réel contenu dans le tableau des mesures affiché (filtrées et non filtrées)

    Ability                     = Ability

    showModalEditionAmenagement             = false
    showModalMessageDelete                  = false
    formulaire_incomplet                    = false
    showModalImportAmenagements?: boolean   = false
    params: any = {}

    @Watch('amenagements')
    updateAmenagements () {
        const amenagements = this.$store.getters['amenagement/amenagements']
        this.buildGenericFields()
        this.setDataForGenericTab(amenagements)
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    buildGenericFields () {
        this.genericfields = []
        this.genericfields.push({ key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' })
        this.genericfields.push({ key: 'code', label: 'Code', sortable: true, class: 'text-start col-min-width', type: 'text' })
        this.genericfields.push({ key: 'name', label: 'Libellé', sortable: true, class: 'text-start', type: 'text' })
        this.genericfields.push({ key: 'type', label: 'Type', sortable: !this.type || !['ecrit', 'oral'].includes(this.type), class: 'text-center col-min-width', type: 'text' })
        if (this.type === 'ecrit') {this.genericfields.push({ key: 'placement', label: 'Salle', sortable: true, class: 'text-center col-min-width', type: 'text' })}
        this.genericfields.push({ key: 'gestionPart', label: 'Gestion Part.', sortable: true, class: 'text-center col-min-width', type: 'text' })
        if (this.type === 'ecrit') {this.genericfields.push({ key: 'temps', label: 'Temps', sortable: true, class: 'text-center col-min-width', type: 'text' })}
        this.genericfields.push({ key: 'nb_candidats', label: 'Nb. candidats', sortable: true, class: 'text-center col-min-width', type: 'text' })
        this.genericfields.push({ key: 'delete', label: '', sortable: false, class: '', type: 'action' })
    }

    /**
     * Initialisation de l'ensemble des aménagements affichés dans le tableau
     */
    initDatasAmenagements() {
        const amenagements = this.$store.getters['amenagement/amenagements']
        this.buildGenericFields()
        this.setDataForGenericTab(amenagements)
        this.setFiltersForGenericTab()
    }

    /**
     * ouverture de la fenetre de creation
     **/
    openAddAmenagement () {
        this.$store.getters['amenagement/error'] = null
        this.amenagementTemp = {
            id: 0,
            name: ''
        }
        this.showModalEditionAmenagement = true
    }


    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        const options_mesures = [
            { index: TypeMesure.TYPE_ECRIT, name: getTypeMesure(TypeMesure.TYPE_ECRIT).libelle },
            { index: TypeMesure.TYPE_ORAL, name: getTypeMesure(TypeMesure.TYPE_ORAL).libelle } // ,
        ]

        this.filtres = [
            { libelle: 'Code', defautOptionlibelle: 'Rechercher un', model: 'code', value: '', index: 'code', datas: '', loading: this.$store.getters['amenagement/loading'], options: { type: 'form', fieldsKey: 'code', strict: true } },
            { libelle: 'Name', defautOptionlibelle: 'Rechercher un', model: 'name', value: '', index: 'name', datas: '', loading: this.$store.getters['amenagement/loading'], options: { type: 'form', fieldsKey: 'name' } }
        ]

        if (!this.type || !['ecrit', 'oral'].includes(this.type)) {
            this.filtres.push({ libelle: 'Type', defautOptionlibelle: 'Rechercher un', model: 'type', value: '', index: 'type', datas: options_mesures, loading: this.$store.getters['amenagement/loading'], options: { type: 'deroulant', fieldsKey: 'type' } })
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        const can = this.$store.getters['auth/can'](Ability.CAND_MANAGE)
        const icone = checkIcone(Ability.CAND_MANAGE, can)
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                if ((this.type === 'ecrit' && result.type === TypeMesure.TYPE_ECRIT) || (this.type === 'oral' && result.type === TypeMesure.TYPE_ORAL)) {
                    let disabled = false
                    if (result.nb_candidats > 0) {
                        disabled = true
                    }

                    let type = ''
                    if (result.type) {
                        switch (result.type) {
                            case 1:
                                type = 'Ecrit'
                                break
                            case 2:
                                type = 'Oral'
                                break
                            case 3:
                                type = 'Ecrit/Oral'
                                break
                        }
                    }
                    let placement = ''
                    if (result.accessibilite !== TypeAmenagement.AMENAGEMENT_AUCUN) {
                        placement = getTypeAmenagement(result.accessibilite).libelle
                    }
                    let gestionPart = ''
                    if (result.gestion_particulieres && result.gestion_particulieres.length > 0) {
                        for (let k  = 0; k < result.gestion_particulieres.length; k++) {
                            const gp = this.getGestionParticuliereById(result.gestion_particulieres[k].id)
                            let nameGestionPart = ''
                            if (gp) {
                                nameGestionPart = gp.code
                            }
                            if (!gestionPart.includes(nameGestionPart)) {
                                gestionPart = gestionPart + '<div class="text-center icone_oral_ecrit m-0 p-0" title="' + gp.name + '">' + nameGestionPart + '</div>'
                            }
                        }
                    }

                    const icons: any[] = []

                    if (result.accessibilite === 1) {
                        icons.push({ name:'wheelchair', class:'text-secondary' })
                    }
                    if (result.temps === 1) {
                        icons.push({ name:'clock', class:'text-secondary' })
                    }

                    const temps = result.temps_supplementaire_id !== null && this.getTempsSuplementaireById(result.temps_supplementaire_id) ? this.getTempsSuplementaireById(result.temps_supplementaire_id).code + ' TEMPS' : ''

                    const line = []
                    line.push({ label: icone.label, item: result, type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: !icone.canValue })
                    line.push({ label: '', item: result.code, type: 'text', typeAction: null, class: '' })
                    line.push({ label: '', item: result.name, type: 'text',    typeAction: null, class: '' })
                    line.push({ label: '', item: type, type: 'text', typeAction: null, class: 'text-center icone_oral_ecrit' })
                    if (this.type === 'ecrit') {line.push({ label: '', item: placement, type: 'text', typeAction: null, class: 'text-center icone_oral_ecrit' })}
                    if (this.type === 'ecrit') {line.push({ label: '', item: gestionPart, type: 'html', typeAction: null, class: 'text-center' })}
                    if (this.type === 'oral') {line.push({ label: '', item: icons, type: 'icons', typeAction: null, class: 'text-center' })}
                    if (this.type === 'ecrit') {line.push({ label: '', item: temps, type: 'text', typeAction: null, class: 'text-center icone_oral_ecrit' })}
                    line.push({ label: '', item: result.nb_candidats, type: 'text', typeAction: null, class: 'text-center' })

                    if (this.$store.getters['auth/can'](Ability.CAND_MANAGE)) {
                        const trashLine =  { label: 'Supprimer',   item: result, type: 'action',  typeAction: 'delete',  class: 'text-secondary', icon:'trash-alt', disabled: disabled }
                        line.push(trashLine)
                    }
                    this.dataForTab.push(line)
                }
            }
        }
    }

    getGestionParticuliereById(id: number) {
        for (let i = 0; i < this.$store.getters['gestionParticuliere/gestionParticulieres'].length; i++) {
            if (id === this.$store.getters['gestionParticuliere/gestionParticulieres'][i].id) {
                return this.$store.getters['gestionParticuliere/gestionParticulieres'][i]
            }
        }
        return null
    }

    getTempsSuplementaireById(id: number) {
        for (let i = 0; i < this.$store.getters['tempsSupplementaire/tempsSupplementaires'].length; i++) {
            if (id === this.$store.getters['tempsSupplementaire/tempsSupplementaires'][i].id) {
                return this.$store.getters['tempsSupplementaire/tempsSupplementaires'][i]
            }
        }
        return null
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    this.editAmenagement(paParams[1])
                    break
                case 'delete':
                    this.deleteAmenagement(paParams[1])
                    break
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
            }
        }
    }

    /**
     * Appel des datas avec un sort en paramètres
     */
    filtreSortHandler (params: any) {
        this.params = {
            ...this.params,
            ...params
        }

        if (this.type === 'oral' && this.params?.sort === 'gestionPart') {
            this.params.sort = 'gestionPart_oral'
        }

        this.$store.dispatch('amenagement/getAmenagements', this.params)
    }

    /**
     * Appel d'une nouvelle page de candidats lors du scroll
     */
    loadHandler (params: any) {
        // Rajout du paramètre pour spécifier qu'on est sur un appel pour les oraux
        params.phase = ''
        this.params = {
            ...this.params,
            ...params
        }
        this.$store.dispatch('amenagement/getMoreAmenagements', this.params)
    }

    /** Ouverture du popup de création d'une mesure d'aménagement */
    addMesure () {
        this.$store.commit('amenagement/SET_ERROR', null)
        this.formulaire_incomplet = false
        this.amenagementTemp = {
            accessibilite: 0,
            code: null,
            gestion_particulieres: [],
            id: -1,
            name: null,
            temps: 0,
            temps_supplementaire_id: null,
            type: 0
        }
        this.showModalEditionAmenagement = true
    }

    /**
     * ouverture de la fenetre d'edition de l'amenagement
     * @row les données de la ranger source de l'appel
     **/
    editAmenagement (item: any) {
        this.$store.commit('amenagement/SET_ERROR', null)
        this.formulaire_incomplet = false

        this.$store.dispatch('amenagement/getAmenagement', { amenagement_id: item.id })
            .then((response) => {
                this.amenagement = response.data.data
                this.amenagementTemp = {
                    accessibilite: this.amenagement.accessibilite,
                    code: this.amenagement.code,
                    gestion_particulieres: this.amenagement.gestion_particulieres,
                    id: this.amenagement.id,
                    name: this.amenagement.name,
                    temps: this.amenagement.temps,
                    temps_supplementaire_id: this.amenagement.temps_supplementaire_id === 'null' ? null : this.amenagement.temps_supplementaire_id,
                    type: this.amenagement.type,
                    to_viatique: this.amenagement.to_viatique

                }
                this.showModalEditionAmenagement = true
            })
    }

    /**
     * annulation et fermeture de la fenêtre de l'édition
     **/
    reinitShowModalMesure () {
        this.amenagement = null
        this.amenagementTemp = null
        this.showModalEditionAmenagement = false
    }

    /**
     * validation envoi des donnée et fermeture de la fenêtre d'édition
     **/
    saveAmenagement () {
        this.formulaire_incomplet = false
        if (this.amenagementTemp.name !== null && this.amenagementTemp.name !== '' && this.amenagementTemp.code !== null && this.amenagementTemp.code !== '') {
            const payload = {
                id: this.amenagementTemp.id,
                name: this.amenagementTemp.name,
                code: this.amenagementTemp.code,
                type: this.amenagementTemp.type,
                to_viatique: (this.amenagementTemp.to_viatique === true || this.amenagementTemp.to_viatique === 1 ? 1 : 0),
                temps_supplementaire_id: this.amenagementTemp.temps_supplementaire_id === 'null' ? null : this.amenagementTemp.temps_supplementaire_id,
                gestion_particulieres: this.amenagementTemp.gestion_particulieres,
                temps: (this.amenagementTemp.temps === true || this.amenagementTemp.temps === 1 ? 1 : 0),
                accessibilite: this.amenagementTemp.accessibilite,
                session_id: this.$store.getters['auth/user_session_id']
            }
            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }
            this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
            if (this.amenagementTemp.id === 0 || this.amenagementTemp.id === -1) {
                this.$store.dispatch('amenagement/addAmenagement', payload)
                    .then(() => {
                        this.amenagement = null
                        this.amenagementTemp = null
                        this.showModalEditionAmenagement = false
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                        this.initDatasAmenagements()
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            } else {
                this.$store.dispatch('amenagement/updateAmenagement', payload)
                    .then(() => {
                        this.amenagement = null
                        this.amenagementTemp = null
                        this.showModalEditionAmenagement = false
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                        this.initDatasAmenagements()
                    })
                    .catch((error) => {
                        console.log('ko:' + error)
                    })
                    .finally(() => {
                        this.$bvToast.hide(idInfo)
                    })
            }
        } else {
            this.formulaire_incomplet = true
        }
    }

    /** ouverture de la fentre de confirmation de suppression */
    deleteAmenagement (item: any) {
        this.amenagement = item
        this.showModalMessageDelete = true
    }

    /**
     * anulation et fermeture de la fenêtre de confirmation de suppression
     **/
    cancelDelete () {
        this.amenagement = null
        this.showModalMessageDelete = false
    }

    /**
     * confirmation, suppression et fermeture de la ffenêtre de confiration de supression
     **/
    deleteSuite () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Supression en cours ...', infosToaster)
        this.$store.dispatch('amenagement/deleteAmenagement', this.amenagement.id)
            .then(() => {
                this.amenagement = null
                this.amenagementTemp = null
                this.showModalEditionAmenagement = false
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée.', succesToaster)
                this.amenagement = null
                this.showModalMessageDelete = false
                this.initDatasAmenagements()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Import des aménagements
     */
    openImportAmenagements () {
        this.showModalImportAmenagements = true
    }

    /**
     * Fermeture de la modale d'import des aménagements
     */
    reinitShowModalImportAmenagements () {
        this.showModalImportAmenagements = false
    }

    /** Exporter les aménagements */
    exporterAmenagements () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours...', infosToaster)

        this.$store.dispatch('amenagement/exportAmenagements')
            .then((response) => {
                let fileName = 'export_amenagements'
                fileName += '_' + dateDuJourFoDocument() + '.xlsx'
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }


    // ------------------gestion des gestionParticulieres --------------------------------

    showModalGestionParticuliere = false
    showConfirmDeleteGestionParticuliere = false
    gestionParticuliere_select = {
        id: 0,
        code: '',
        name: ''
    }

    showFormulaireGestionParticuliere = false

    openGestionParticuliere() {
        this.getListeGestionParticuliere()
    }

    getListeGestionParticuliere() {
        this.$store.dispatch('gestionParticuliere/getGestionParticulieres')
            .then(() => {
                this.showModalGestionParticuliere = true
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    cancelGestionParticuliere () {
        this.$store.commit('gestionParticuliere/SET_ERROR', null)
        this.showFormulaireGestionParticuliere = false
        this.gestionParticuliere_select = {
            id: 0,
            name: '',
            code: ''
        }
        this.showModalGestionParticuliere = false
        this.showConfirmDeleteGestionParticuliere = false
    }

    edit_gestionParticuliere(gestionParticuliere: any) {
        this.gestionParticuliere_select.id = gestionParticuliere.id
        this.gestionParticuliere_select.code = gestionParticuliere.code
        this.gestionParticuliere_select.name = gestionParticuliere.name
        this.showFormulaireGestionParticuliere = true
    }

    add_gestionParticuliere () {
        this.gestionParticuliere_select = {
            id: 0,
            code: '',
            name: ''
        }
        this.showFormulaireGestionParticuliere = true
    }

    // Set les value depuis le formulaire
    updateChamps (e: any) {
        switch (e.target.name) {
            case 'code':
                this.gestionParticuliere_select.code = e.target.value
                break
            case 'name':
                this.gestionParticuliere_select.name = e.target.value
                break
        }
    }

    save_gestionParticuliere () {
        this.$store.commit('gestionParticuliere/SET_ERROR', null)
        const data: any = {
            code: this.gestionParticuliere_select.code,
            name: this.gestionParticuliere_select.name,
            gestionParticuliere_id: this.gestionParticuliere_select.id
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        if (this.gestionParticuliere_select.id === 0) {
            // Création d'un role
            this.$store.dispatch('gestionParticuliere/addGestionParticuliere', data)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)

                    this.showFormulaireGestionParticuliere = false
                    this.gestionParticuliere_select = {
                        id:0,
                        code: '',
                        name: ''
                    }
                    this.$store.dispatch('gestionParticuliere/getGestionParticulieres')
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.dispatch('gestionParticuliere/updateGestionParticuliere', data)
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    this.showFormulaireGestionParticuliere = false
                    this.gestionParticuliere_select = {
                        id:0,
                        code: '',
                        name: ''
                    }
                    this.$store.dispatch('gestionParticuliere/getGestionParticulieres')
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        }
    }

    delete_gestionParticuliere (gestionParticuliere: any) {
        this.showFormulaireGestionParticuliere = false
        this.gestionParticuliere_select = gestionParticuliere
        this.showConfirmDeleteGestionParticuliere = true
    }

    cancel_delete_gestionParticuliere () {
        this.gestionParticuliere_select = {
            id: 0,
            code: '',
            name: ''
        }
        this.showConfirmDeleteGestionParticuliere = false
    }

    confirm_delete_gestionParticuliere () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Suppression en cours ...', infosToaster)

        // Suppression d'un rôle
        this.$store.dispatch('gestionParticuliere/deleteGestionParticuliere', this.gestionParticuliere_select.id)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Suppression terminée !', succesToaster)
                await this.$store.dispatch('gestionParticuliere/getGestionParticulieres')
                this.showConfirmDeleteGestionParticuliere = false
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    getLibelleGestionParticuliere(gestionParticuliere_id: number) {
        if (gestionParticuliere_id !== null) {
            for (let i = 0; i < this.$store.getters['gestionParticuliere/gestionParticulieres'].length; i++) {
                if (gestionParticuliere_id === this.$store.getters['gestionParticuliere/gestionParticulieres'][i].id) {
                    return this.$store.getters['gestionParticuliere/gestionParticulieres'][i].name
                }
            }
        }
    }

    // --------------------------------------------------

    load() {
        if (this.type && ['ecrit', 'oral'].includes(this.type)) {
            this.params = {
                'filter-type': this.type === 'ecrit' ? TypeMesure.TYPE_ECRIT : TypeMesure.TYPE_ORAL
            }
        }

        this.$store.dispatch('tempsSupplementaire/getTempsSupplementaires').then(() => {
            this.$store.dispatch('amenagement/getAmenagements', this.params).then(() => {
                this.initDatasAmenagements()
            })
        })
    }

    /** chargement des données apres construction */
    mounted () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
