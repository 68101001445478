


























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import { DocumentInterface } from '@/types/Document'
import { Etat } from '@/types/DossierAcademique'
import { DocumentTypeInterface, Dossier_type } from '@/types/DocumentType'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import { Ability } from '@/types/Ability'

@Component({
    components: {
        ItemIntervenant,
        VuePdfApp
    },
    methods: {
        ...mapActions('documenttype', ['getDocumenttypes'])
    },
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapState('document', ['errorDocument']),
        ...mapGetters('documenttype', ['documenttypes'])
    }
})
export default class FormDocument extends Vue {
    @Prop() dossierAcademiqueSelect?: any;
    @Prop() dossier_id?: number;
    @Prop() readOnly?: boolean;
    @Prop() mode?: string;
    @Prop() visible?: boolean;

    Ability = Ability
    /*
@Watch('visible')
visibleHandler () {
  if (!this.visible) {
    // this.cancelEdition()
  }
}
*/

    showDocument = false
    source_doc: any = ''
    errors = ''
    showError = false
    showSuccess = false
    success = ''
    zoneViewerClass = 'zoneViewer'
    document = {}
    etat_rejete = Etat.ETAT_REJETE
    etat_valide = Etat.ETAT_VALIDE
    documents_type = []
    dossier_type_academique = Dossier_type.DOSSIER_ACADEMIQUE
    editResponsable = false
    Etat = Etat
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    @Watch('errorDocument')
    getErrors () {
        if (this.$store.state.document.errorDocument !== '') {
            this.errors = this.$store.state.document.errorDocument
            this.showError = true
        } else {
            this.errors = ''
            this.showError = false
        }
    }

    @Watch('dossierAcademiqueSelect')
    loadElements () {
        // Chargement des documents liés au dossier
        this.$store.commit('document/SET_DOCUMENTS', this.dossierAcademiqueSelect.documents)
    }

    @Watch('documenttypes')
    filterDocumentsTypes () {
        // Filtre les documents types selon le type de dossier
        this.documents_type = this.$store.state.documenttype.documenttypes.filter((dt: DocumentTypeInterface) => dt.dossier_type === Dossier_type.DOSSIER_ACADEMIQUE)
    }

    // Visualisation d'un document
    viewDocument (doc: DocumentInterface) {
        this.source_doc = ''
        this.document = doc
        this.zoneViewerClass = (doc.mime_type === 'image/jpeg' ? 'zoneViewerImage' : 'zoneViewer')
        this.$store.dispatch('document/getDocument', { dossier_id: this.dossierAcademiqueSelect.id, document_id: doc.id }).then(() => {
            this.showDocument = true
            this.source_doc = base64ToArrayBuffer(this.$store.state.document.documentSelect.data)
        })
        this.$emit('showDocument', true)
    }

    // Ferme la popup document
    closeFormDocument () {
        this.backDocument()
        this.errors = ''
        this.showError = false
        const closeInfosDocument: HTMLElement = document.getElementsByClassName('closeInfosDocument')[0] as HTMLElement
        if(closeInfosDocument) {
            closeInfosDocument.click()
        }
    }

    // Fermer la visualisation d'un document
    backDocument () {
        this.source_doc = ''
        this.showDocument = false
        this.$emit('showDocument', false)
    }

    // Rejeté une pièce jointe
    rejetePJ (document: any) {
        const params = { etat: this.etat_rejete }
        this.$store.dispatch('document/rejeteDocument', { dossier_id: this.dossierAcademiqueSelect.id, document_id: document.id, etat: params }).then(() => {
            for (const d in this.$store.getters['document/documents']) {
                if (this.$store.getters['document/documents'][d].id === document.id) {
                    this.$store.getters['document/documents'][d].etat = this.etat_rejete
                }
            }
            this.showDocument = false
        })
    }

    // Activation de l'édition pour le responsable
    activeEdition (actif: boolean) {
        this.editResponsable = actif
        this.$emit('edition_form_docs', actif)
    }

    mounted () {
        if (!this.$store.getters['documenttype/documenttypes'].length) {
            this.$store.dispatch('documenttype/getDocumenttypes')
        }
        this.filterDocumentsTypes()
    }
}
