













































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { CandidatInterface } from '@/types/Candidat'
import { TypeEnsemble } from '@/types/Ensemble'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import PopupEditCommentCandidat from '@/components/Candidat/PopupEditCommentCandidat.vue'
import PopupImportFichiersOrgaOraux from '@/components/Candidat/PopupImportFichiersOrgaOraux.vue'
import PopupRepartitionEquipes from '@/components/Candidat/PopupRepartitionEquipes.vue'
import { SerieInterface } from '@/types/Serie'
import { DemandeAmenagement, TypeMesure } from '@/types/Amenagement'
import EditCommentFromDashboard from '@/components/Candidat/EditCommentFromDashboard.vue'
import { Ability } from '@/types/Ability'
import { checkIcone, formatDate, getFileNameFromHeader } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { DecisionAmenagementInterface, EtatDecisionAmenagement } from '@/types/DecisionAmenagement'
import { isEmpty } from 'lodash'
import { TypePassation } from '@/types/Epreuve'

@Component({
    computed: {
        ...mapGetters('candidat', ['candidats', 'loading', 'totalRows', 'lastPage', 'totalPage', 'meta', 'error']),
        ...mapGetters('epreuve', ['epreuves']),
        ...mapState('serie', ['series']),
        ...mapGetters('serie', ['series']),
        ...mapGetters('concour', ['banques']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('planification', ['error_planification'])
    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        PopupEditCommentCandidat,
        'font-awesome-icon': FontAwesomeIcon,
        PopupImportFichiersOrgaOraux,
        EditCommentFromDashboard,
        PopupRepartitionEquipes,
        ErrorDisplay
    }
})
export default class CandidatsTabOraux extends Vue {
    candidatsAssoc: any[] = [] // Données candidats affichées dans le tableau

    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'commentaires', label: '', sortable: true, class: '', type: 'action' },
        { key: 'code', label: 'Code', sortable: true, class: 'text-start col-min-width', type: 'text' },
        { key: 'name', label: 'Nom', sortable: true, class: 'text-start col-min-width', type: 'text' },
        { key: 'filiere', label: 'Filière', sortable: true, class: 'text-center col-min-width', type: 'text' },
        { key: 'equipes_inter', label: "Équipes d'interclassement", sortable: true, class: 'text-center', type: 'text' },
        { key: 'equipes_par_epreuve', label: 'Équipes par épreuve', sortable: true, class: 'text-center', type: 'text' },
        { key: 'equipes_tp', label: 'Équipes TP', sortable: true, class: 'text-center', type: 'text' },
        { key: 'serie', label: 'Série', sortable: true, class: 'text-center col-min-width', type: 'text' },
        { key: 'demande_amenagement', label: 'Demande aménagements', sortable: true, class: 'text-center col-min-width', type: 'icons' },
        { key: 'mesures', label: 'Mesures', sortable: true, class: 'text-center col-min-width', type: 'icons' },
        { key: 'gestionPart', label: 'Gestion particulière', sortable: true, class: 'text-center', type: 'icons' }
    ]

    showModalEditionCandidat?: boolean = false
    showModalImportFichiersOrgaOraux?: boolean = false
    showModalEditionCommentCandidat?: boolean = false
    showModalRepartitionEquipes?: boolean = false
    showExportAdmissionModal = false
    concourIdSelected = 0
    candidats_ensembles_validated_at: any = null
    dataForTab: Array<any> = []
    filtres: any = []
    selected_tab = 'oraux'
    Ability = Ability
    totalCandidatsFiltered = 0 // pour l'affichage du total réel contenu dans le tableau des candidats affiché (filtrés et non filtrés)
    formatDate = formatDate
    canAnnulerRepartitionEquipes = false // flag si on est en contexte ou non de pouvoir Annuler la répartition des candidats dans les équipes (Non si la planif est déjà lancée)
    showModalAnnulerRepartitionEquipes = false
    sessionHasHistoriquePlanification = false // flag si la session a déjà au moins une entrée dans l'historique de planification.
    loading_local = false
    loading_global = true // Permet de masquer les boutons d'action de la barre d'entête le temps du chargement. Est à true par défaut.
    firstInit = true // Définit si on est sur le premier chargement de l'interface.
    exportInProgress = false
    filieres: any[] = []

    @Watch('candidats')
    updateCandidats(): void {
        this.initDatas()
        if (this.firstInit) {
            this.firstInit = false
            this.loading_global = false
        }
    }

    @Watch('meta')
    majInfosTable(): void {
        // On récupère le nombre total d'affectations filrées depuis les infos des Metadonnées
        if (this.$store.state.candidat.meta !== null) {
            if (this.totalCandidatsFiltered !== this.$store.state.candidat.meta.total) {
                this.totalCandidatsFiltered = this.$store.state.candidat.meta.total
            }
        } else {
            this.totalCandidatsFiltered = 0
        }
    }

    @Watch('user_session_id')
    refreshInterface(): void {
        this.load()
    }

    /**
     * Récupération sur le serveur de la session active
     *
     * @param {boolean} force - Force le chargement de la session active
     * @returns {Promise<boolean>}
     */
    loadSessionActiveIfNotExists(force = false): Promise<boolean> {
        return new Promise((resolve) => {
            if (!this.$store.getters['session/sessionSelect'] || force) {
                this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] })
                    .then(() => resolve(true))
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Récupération sur le serveur des séries de la session
     *
     * @returns {Promise<boolean>}
     */
    loadSeriesSessionActive(): Promise<boolean> {
        return new Promise((resolve) => {
            // on récupère systématiquement les séries de la session active
            this.$store.dispatch('serie/getSeries')
                .then(() => resolve(true))
        })
    }

    /**
     * Récupérétion des 100 premiers candidats sur le serveur si cela est nécéssaire
     *
     * @returns {Promise<boolean>}
     */
    loadCandidats(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.$store.getters['candidat/candidats'].length === 0) {
                this.$store.dispatch('candidat/getCandidats', { sort: 'name', direction: 'asc', phase: 'admission' })
                    .then(() => resolve(true))
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Récupérétion des candidats sur le serveur si cela est nécéssaire
     *
     * @returns {Promise<boolean>}
     */
    loadConcours(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.$store.getters['concour/banques'].length === 0) {
                this.$store.dispatch('concour/getConcoursActifs')
                    .then(() => resolve(true))
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Récupération des epreuves sur le serveur si cela est nécéssaire
     *
     * @returns {Promise<boolean>}
     */
    loadEpreuves(): Promise<boolean> {
        return new Promise((resolve) => {
            this.$store.dispatch('epreuve/getEpreuves', {
                isPrecedente: false,
                session_id: this.$store.getters['auth/user_session_id']
            })
                .then(() => resolve(true))
        })
    }

    /**
     * Initialisation des données affichées dans le tableau
     *
     * @returns {void}
     */
    initDatas(): void {
        this.candidatsAssoc = Object.assign([], this.$store.state.candidat.candidats)
        const allEpreuves = this.$store.getters['epreuve/epreuves']
        const epreuvesById: any = {}

        if (allEpreuves && allEpreuves.length > 0) {
            for (const epreuve of allEpreuves) {
                epreuvesById[epreuve.id] = epreuve
            }
        }

        for (const candidat of this.candidatsAssoc) {
            this.updateCandidatFiliere(candidat)

            candidat.serie = '-'
            if (candidat.serie_id) {
                const serie_cand = this.$store.state.serie.series.find(
                    (s: SerieInterface) => s.id === candidat.serie_id
                )
                if (serie_cand !== undefined) {
                    candidat.serie = serie_cand.name
                }
            }
            candidat.indisponibilites = []
            candidat.incompatibilites = []
            candidat.equipes_inter = '-'
            candidat.equipes_par_epreuve = '-'
            candidat.equipes_tp = '-'

            if (candidat.ensembles && candidat.ensembles.length > 0) {
                const arrayEquipesInter: string[] = []
                const arrayEquipesParEpreuve: string[] = []
                const arrayEquipesTp: string[] = []

                for (const ensemble of candidat.ensembles) {
                    switch (ensemble.type_ensemble) {
                        case TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE:
                            arrayEquipesParEpreuve.push(ensemble.name)
                            break
                        case TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT:
                            arrayEquipesInter.push(ensemble.name)
                            break
                        case TypeEnsemble.TYPE_ENSEMBLE_TP:
                            arrayEquipesTp.push(ensemble.name)
                            break
                    }
                }

                if (arrayEquipesInter.length > 0) {
                    candidat.equipes_inter = arrayEquipesInter.join(', ')
                }
                if (arrayEquipesParEpreuve.length > 0) {
                    candidat.equipes_par_epreuve = arrayEquipesParEpreuve.join(', ')
                }
                if (arrayEquipesTp.length > 0) {
                    candidat.equipes_tp = arrayEquipesTp.join(', ')
                }
            }
        }

        this.setDataForGenericTab(this.candidatsAssoc)
    }

    /**
     * Affectation si cela n'est pas fait, du nom de la filière d'un candidat donné
     *
     * @param {CandidatInterface} candidat - Candidat à traiter
     */
    updateCandidatFiliere(candidat: CandidatInterface): void {
        if (candidat && candidat.concour_id && !candidat.filiere) {
            const concour_found = this.$store.state.concour.concours.find((c: any) => c.id === candidat.concour_id)

            if (concour_found) {
                const params = {
                    candidatId: candidat.id,
                    nomFiliere: concour_found.name
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
            }
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     *
     * @param {any} poData - Données à afficher
     * @param {boolean} isLoadMore - Indique si on charge plus de données
     * @returns {void}
     */
    setDataForGenericTab(poData: any, isLoadMore = false): void {
        const can = this.$store.getters['auth/can'](Ability.CAND_MANAGE)
        const icone = checkIcone(Ability.CAND_MANAGE, can)

        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                let styleButtonComment = 'text-tertiary commons_comment_button text-center'
                let nameIcon = 'comment-alt'

                if (result.hasComments === true) {
                    styleButtonComment = 'commons_comment_button text-center'
                    if (result.hasImportantComments === false) {
                        nameIcon = 'comment-alt'
                    } else {
                        nameIcon = 'comment-alt-exclamation'
                    }
                }

                let tooltip_comments = ''
                for (const c in result.comments) {
                    tooltip_comments += result.comments[c].important
                        ? '---------------TACHE A FAIRE---------------\n'
                        : '---------------COMMENTAIRE---------------\n'
                    tooltip_comments += result.comments[c].body
                    tooltip_comments += '\n'
                }

                const icons: any[] = []
                let hasAmenagement = false
                let hasTemps = false

                const iconDemandeAm: any[] = []
                let hasMesureEcrit = false
                let hasMesureOral = false
                let libelle_type = ''

                if (result.amenagements && result.amenagements.length > 0) {
                    for (const amenagement of result.amenagements) {
                        if (amenagement.accessibilite === 1 || amenagement.accessibilite_am === 1) {
                            hasAmenagement = true
                        }
                        if (amenagement.temps === 1 || amenagement.temps_am === 1) {
                            hasTemps = true
                        }

                        switch (amenagement.type) {
                            case 1:
                                hasMesureEcrit = true
                                break
                            case 2:
                                hasMesureOral = true
                                break
                            case 3:
                                hasMesureEcrit = true
                                hasMesureOral = true
                                break
                            default:
                                break
                        }
                    }
                }

                if (hasAmenagement) {
                    icons.push({ name: 'wheelchair', class: 'text-secondary' })
                }
                if (hasTemps) {
                    icons.push({ name: 'clock', class: 'text-secondary' })
                }
                if (hasMesureEcrit && !hasMesureOral) {
                    libelle_type = 'ECRIT'
                }
                if (hasMesureOral && !hasMesureEcrit) {
                    libelle_type = 'ORAL'
                }
                if (hasMesureOral && hasMesureEcrit) {
                    libelle_type = 'ECRIT / ORAL'
                }

                let titleDemandeAmenagement = ''
                let decision: DecisionAmenagementInterface = {} as DecisionAmenagementInterface

                // Récupération de la décision d'aménagement
                if (!isEmpty(result.decisionAmenagements)) {
                    const index = result.decisionAmenagements.findIndex(
                        (d: DecisionAmenagementInterface) => d.type === TypeMesure.TYPE_ORAL
                    )
                    decision = result.decisionAmenagements[index]
                }

                if (decision?.etat || result.demande_amenagement || result.amenagements.filter((d: any) => d.type === TypeMesure.TYPE_ORAL || d.type === TypeMesure.TYPE_ECRIT_ET_ORAL).length > 0) {
                    const title =
                        !isEmpty(decision) && decision.etat === EtatDecisionAmenagement.ETAT_VALIDE
                            ? "Traitée"
                            : !isEmpty(decision) && decision.etat === EtatDecisionAmenagement.ETAT_SOUMIS
                                ? "En cours"
                                : 'Non traitée'
                    iconDemandeAm.push({
                        name: 'check',
                        class:
                            !isEmpty(decision) && decision.etat === EtatDecisionAmenagement.ETAT_VALIDE
                                ? 'text-success'
                                : !isEmpty(decision) && decision.etat === EtatDecisionAmenagement.ETAT_SOUMIS
                                    ? 'text-primary'
                                    : 'text-secondary',
                        title: title
                    })
                    titleDemandeAmenagement = title
                }

                const line = [
                    { label: icone.label, item: result.id, type: 'action', typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: tooltip_comments, item: result.id, type: 'action', typeAction: 'openComment', class: styleButtonComment, icon: nameIcon, disabled: false },
                    { label: 'Fiche candidat', item: result.id, type: 'actionText', typeAction: 'editCandidat', class: 'text-info item_action ', text: result.code },
                    { label: 'Fiche candidat', item: result.id, type: 'actionText', typeAction: 'editCandidat', class: 'text-info item_action width_col_identity ', text: result.name + ' ' + result.first_name },
                    { label: '', item: result.filiere, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.equipes_inter, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.equipes_par_epreuve, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.equipes_tp, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.serie, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: iconDemandeAm, type: 'icons', typeAction: null, class: 'text-center', title: titleDemandeAmenagement },
                    { label: '', item: libelle_type, type: 'text', typeAction: null, class: 'text-center icone_oral_ecrit' },
                    { label: '', item: icons, type: 'icons', typeAction: null, class: 'text-center' }
                ]

                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     *
     * @returns {void}
     */
    setFiltersForGenericTab(): void {
        // Options filières
        this.filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in this.filieres) {
            options_filieres.push({ index: this.filieres[f].id, name: this.filieres[f].name })
        }

        // Options séries
        const series = this.$store.getters['serie/series']
        const options_series = []
        for (const s in series) {
            options_series.push({ index: series[s].id, name: series[s].name })
        }

        const etats_demande_amenagement = []
        etats_demande_amenagement.push({
            index: DemandeAmenagement.DEMANDE_AMENAGEMENT_NON_HANDI,
            name: "Candidats sans demande d'aménagement"
        })
        etats_demande_amenagement.push({
            index:
                DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI + DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ORAL,
            name: "Candidats avec une demande d'aménagement"
        })
        etats_demande_amenagement.push({
            index:
                DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI - DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ORAL,
            name: "Candidats avec une demande d'aménagement non traitée"
        })
        etats_demande_amenagement.push({
            index:
                DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_SOUMIS_ORAL,
            name: "Candidats avec une demande d'aménagement en cours"
        })
        etats_demande_amenagement.push({
            index: DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ORAL,
            name: "Candidats avec une demande d'aménagement traitée"
        })

        // Options commentaire
        const etat_comments: any = []
        etat_comments.push({ index: 0, name: 'Commentaires' })
        etat_comments.push({ index: 1, name: 'Tâches à faire' })
        etat_comments.push({ index: 2, name: 'Alerte' })

        this.filtres = [
            { libelle: 'Type de commentaire', defautOptionlibelle: 'Rechercher par', model: 'commentaires', value: '', index: 'commentaires', datas: etat_comments, loading: this.$store.state.candidat.loading, options: { type: 'deroulant', fieldsKey: 'commentaires' } },
            { libelle: 'Code', defautOptionlibelle: 'Rechercher un', model: 'code', value: '', index: 'code', datas: this.$store.state.candidat.code, loading: this.$store.state.candidat.loading, options: { type: 'form', fieldsKey: 'code', strict: true } },
            { libelle: 'Nom', defautOptionlibelle: 'Rechercher un', model: 'name', value: '', index: 'name', datas: this.$store.state.candidat.name, loading: this.$store.state.candidat.loading, options: { type: 'form', fieldsKey: 'name' } },
            { libelle: 'Prénom', defautOptionlibelle: 'Rechercher une', model: 'first_name', value: '', index: 'first_name', datas: this.$store.state.candidat.first_name, loading: this.$store.state.candidat.loading, options: { type: 'form', fieldsKey: 'first_name' } },
            { libelle: 'Filière', defautOptionlibelle: 'Rechercher une', model: 'concour_id', value: '', index: 'filiere', datas: options_filieres, loading: this.$store.state.candidat.loading, options: { type: 'deroulant', fieldsKey: 'filiere' } },
            { libelle: "Equipe d'interclassement", defautOptionlibelle: 'Rechercher une', model: 'equipes_inter', value: '', index: 'equipes_inter', datas: '', loading: this.$store.state.candidat.loading, options: { type: 'form', fieldsKey: 'equipes_inter', strict: true } },
            { libelle: 'Equipe par épreuve', defautOptionlibelle: 'Rechercher une', model: 'equipes_par_epreuve', value: '', index: 'equipes_par_epreuve', datas: '', loading: this.$store.state.candidat.loading, options: { type: 'form', fieldsKey: 'equipes_par_epreuve' } },
            { libelle: 'Equipe TP', defautOptionlibelle: 'Rechercher une', model: 'equipes_tp', value: '', index: 'equipes_tp', datas: '', loading: this.$store.state.candidat.loading, options: { type: 'form', fieldsKey: 'equipes_tp' } },
            { libelle: "État de Demande d'aménagement", defautOptionlibelle: 'Rechercher par ', model: 'demande_amenagement', value: '', index: 'demande_amenagement', datas: etats_demande_amenagement, loading: false, options: { type: 'deroulant', fieldsKey: 'demande_amenagement' } },
            { libelle: 'Série', defautOptionlibelle: 'Rechercher une', model: 'serie_id', value: '', index: 'type', datas: options_series, loading: this.$store.state.candidat.loading, options: { type: 'deroulant', fieldsKey: 'serie' } }
        ]
    }

    /**
     * Récupération des events de la table
     *
     * @param {any} paParams - Paramètres de l'event
     * @returns {void}
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const candidats = this.$store.state.candidat.candidats
            let selectedCandidat = null

            switch (paParams[0]) {
                case 'edit':
                    // Récupération de l'étab by ID
                    selectedCandidat = candidats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.selected_tab = 'oraux'
                        this.editCandidat(selectedCandidat)
                    }
                    break
                case 'openComment':
                    // Récupération de l'étab by ID
                    selectedCandidat = candidats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.selected_tab = 'commentaires'
                        this.editCandidat(selectedCandidat)
                    }
                    break
                case 'editCandidat':
                    selectedCandidat = candidats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.selected_tab = 'compte'
                        this.editCandidat(selectedCandidat)
                    }
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    /**
     * Appel des datas avec un sort en paramètres
     *
     * @param {any} params - Paramètres de tri
     * @returns {void}
     */
    filtreSortHandler(params: any): void {
        // Rajout du paramètre pour spécifier qu'on est sur un appel pour les oraux
        params.phase = 'admission'
        params.passation = TypePassation.TYPE_PASSATION_ORAL
        this.$store.dispatch('candidat/getCandidats', params)
    }

    /**
     * @description Télécharger le fichier d'export des demandes d'aménagements
     */
    exportAmenagementsOral() {
        this.$store.commit('candidat/SET_ERROR', null)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store.dispatch('candidat/getDemandeAmenagementCandidatOralExport')
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute(
                    'Download',
                    getFileNameFromHeader(response.headers) || 'demande_amenagement_candidat_oral.xlsx'
                )
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Edition des commentaires associés à un candidat
     *
     * @param {any} item - Candidat à traiter
     * @returns {void}
     */
    openCommentaireCandidat(item: any): void {
        if (item && item.id !== undefined) {
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
            this.showModalEditionCommentCandidat = true
        }
    }

    /**
     * Fermeture de la modale d'édition des commentaires candidats
     *
     * @param {CandidatInterface} candidatSelected - Candidat sélectionné
     * @returns {void}
     */
    reinitShowModalCommentCandidat(candidatSelected: CandidatInterface): void {
        // On met à jour dans le tableau des candidats les informations relatives
        // aux commentaires du candidat selectionné
        if (candidatSelected) {
            const indexTab = this.dataForTab.findIndex(
                (itemTab: any) => itemTab.length > 0 && itemTab[0].item === candidatSelected.id
            )
            if (indexTab !== -1 && this.dataForTab[indexTab] && this.dataForTab[indexTab].length > 1) {
                // On met à jour le bouton de commentaires en fonction des mises à jour effectuée
                // sur les commentaires du candidat
                let styleButtonComment = 'text-tertiary commons_comment_button text-center'
                let nameIcon = 'comment-alt'
                if (candidatSelected.hasComments) {
                    styleButtonComment = 'commons_comment_button text-center'
                    if (candidatSelected.hasImportantComments) {
                        nameIcon = 'comment-alt'
                    }
                }

                this.dataForTab[indexTab][1].class = styleButtonComment
                this.dataForTab[indexTab][1].icon = nameIcon
            }
        }

        this.showModalEditionCommentCandidat = false
    }

    /**
     * Edition d'un candidat: enregistrement du candidat en tant que candidat sélectionnée
     * et affichage de la modale
     *
     * @param {any} item - Candidat à traiter
     * @returns {void}
     */
    editCandidat(item: any): void {
        if (item && item.id !== undefined) {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.dispatch('candidat/getCandidat', item).then(() => {
                const params = {
                    candidatId: item.id,
                    nomFiliere: item.filiere
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                this.showModalEditionCandidat = true
            })
        }
    }

    /**
     * Fermeture de la modale d'édition candidat
     *
     * @returns {void}
     */
    reinitShowModalCandidat(): void {
        this.showModalEditionCandidat = false
    }

    /**
     * Appel d'une nouvelle page de candidats lors du scroll
     *
     * @param {any} params - Paramètres de la page
     * @returns {void}
     */
    loadHandler(params: any): void {
        // Rajout du paramètre pour spécifier qu'on est sur un appel pour les oraux
        params.phase = 'admission'
        params.passation = TypePassation.TYPE_PASSATION_ORAL
        this.$store.dispatch('candidat/getMoreCandidats', params)
    }

    /**
     * Import de séries
     *
     * @returns {void}
     */
    openImportFichiersOrgaOraux(): void {
        this.showModalImportFichiersOrgaOraux = true
    }

    /**
     * Fermeture de la modale d'import de séries
     *
     * @param {boolean} shouldRefreshTableCandiat - Indique si on doit rafraichir le tableau des candidats
     * @returns {void}
     */
    reinitShowModalImportFichiersOrgaOraux(shouldRefreshTableCandiat: boolean): void {
        if (shouldRefreshTableCandiat) {
            this.$store.dispatch('candidat/getCandidats', { sort: 'name', direction: 'asc', phase: 'admission' })
        }
        this.showModalImportFichiersOrgaOraux = false
    }

    /**
     * Ouverture modale Répartition des équipes
     *
     * @returns {void}
     */
    openRepartitionEquipes(): void {
        this.showModalRepartitionEquipes = true
    }

    /**
     * Fermeture modale Répartition des équipes
     *
     * @param {boolean} repartitionDone - Indique si la répartition a été effectuée
     * @returns {void}
     */
    reinitshowModalRepartitionEquipes(repartitionDone: boolean): void {
        if (repartitionDone) {
            // Si la répartition a été effectuée, on doit récupérer la session active pour avoir la date de la répartition.
            this.loadSessionActiveIfNotExists(true)
                .then(() => {
                    this.candidats_ensembles_validated_at = this.$store.state.session.sessionSelect.candidats_ensembles_validated_at
                    this.$store.dispatch('candidat/getCandidats', { sort: 'name', direction: 'asc', phase: 'admission' })
                })
        }
        this.showModalRepartitionEquipes = false
    }

    /**
     * Ouverture modale Répartition des équipes
     *
     * @returns {void}
     */
    openAnnulerRepartitionEquipes(): void {
        this.loading_local = true
        this.$store.commit('candidat/SET_ERROR', null) // reinit de l'erreur éventuelle sur candidat
        this.$store.commit('planification/SET_ERROR', null) // reinit de l'erreur éventuelle sur planification
        this.showModalAnnulerRepartitionEquipes = true
        this.loadSeriesSessionActive()
            .then(() => {
                this.$store.dispatch('planification/getHistoPlanifSessionActive')
                    .then(() => {
                        this.checkCanAnnulerRepartitionEquipes()
                        this.checkSessionHasHistoriquePlanification()
                    })
                    .catch((err) => console.log("DashboardOrauxPlanification: erreur chargement de l'historique de planification : ", err))
            })
            .catch((err) => console.log('DashboardOrauxPlanification: erreur chargement des séries : ', err))
            .finally(() => this.loading_local = false)
    }

    /**
     * Vérifie si le contexte permet d'annuler la répartition des candidats dans les équipes
     *
     * @returns {void}
     */
    checkCanAnnulerRepartitionEquipes(): void {
        this.canAnnulerRepartitionEquipes = true
        for (const serie of this.$store.state.serie.series) {
            if (serie.planifie !== 0) {
                // au moins une série est déjà en cours de planif ou a déjà été planifiée. On ne peut pas Annuler la répartition.
                this.canAnnulerRepartitionEquipes = false
                break
            }
        }
    }

    /**
     * Récupération de l'historique de planification de la session active et init de l'info sessionHasHistoriquePlanification
     *
     * @returns {void}
     */
    checkSessionHasHistoriquePlanification(): void {
        this.sessionHasHistoriquePlanification = this.$store.state.planification.historiques_sessionActive &&
            this.$store.state.planification.historiques_sessionActive.length > 0;
    }

    /**
     * Lance l'annulation de la répartition des candidats dans les équipes
     *
     * @returns {void}
     */
    confirmerAnnulerRepartitionEquipes(): void {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Annulation en cours ...', infosToaster)

        const param = {
            session_id: this.$store.getters['session/sessionSelect'].id
        }
        this.$store.dispatch('candidat/annulerRepartitionEquipes', param)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('La répartition par équipes a été annulée avec succès !', succesToaster)

                await this.loadSessionActiveIfNotExists(true)
                if (this.$store.state.session.sessionSelect) {
                    this.candidats_ensembles_validated_at = this.$store.state.session.sessionSelect.candidats_ensembles_validated_at
                    await this.$store.dispatch('candidat/getCandidats', {
                        sort: 'name',
                        direction: 'asc',
                        phase: 'admission'
                    })
                }
                this.cancelAnnulerRepartitionEquipes()
            })
            .finally(() => this.$bvToast.hide(idInfo))
    }

    /**
     * Annule l'opération d'annulation de la répartition des candidats dans les équipes
     *
     * @returns {void}
     */
    cancelAnnulerRepartitionEquipes(): void {
        this.$store.commit('candidat/SET_ERROR', null) // reinit de l'erreur éventuelle sur candidat
        this.$store.commit('planification/SET_ERROR', null) // reinit de l'erreur éventuelle sur planification
        this.showModalAnnulerRepartitionEquipes = false
    }

    @Watch('showExportAdmissionModal')
    onShowExportAdmissionModalChange(): void {
        this.concourIdSelected = 0;
    }

    openExportAdmissionModal(): void {
        this.showExportAdmissionModal = true;
    }

    closeExportAdmissionModal(): void {
        this.showExportAdmissionModal = false;
    }

    // Export des candidats admissibles
    exporter_admission() {
        if (this.exportInProgress && !this.concourIdSelected) {
            return
        }
        this.exportInProgress = true
        this.$store.commit('candidat/SET_ERROR', null)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store.dispatch('candidat/getExportCandidatsAdmission', {
            banque_id: this.concourIdSelected,
            phase_id: 2
        })
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || 'Candidats_admission.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportInProgress = false
            })
    }

    /**
     * Initialisation des données du composant au montage
     *
     * @returns {void}
     */
    load(): void {
        this.loadSeriesSessionActive().then(() => {
            if (this.$store.state.session.sessionSelect) {
                this.candidats_ensembles_validated_at = this.$store.state.session.sessionSelect.candidats_ensembles_validated_at
            }

            // Si aucun concours n'as été chargé, alors on les récupère
            this.loadConcours()
                .then(() => {
                    // Chargement des epreuves si nécéssaire
                    this.loadEpreuves()
                        .then(() => {
                            this.initDatas()
                            this.setFiltersForGenericTab()
                        })
                        .catch((err) => console.log('CandidatsTabEcrits: erreur chargement des epreuves: ', err))
                })
                .catch((err) => console.log('CandidatsTabEcrits: erreur chargement des concours: ', err))
        })
    }

    /**
     * Initialisation des données du composant au montage
     *
     * @returns {void}
     */
    mounted(): void {
        this.load()
    }
}
