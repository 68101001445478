




























































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import FormPerso from '@/components/DossierAcademique/FormPerso.vue'
import FormPro from '@/components/DossierAcademique/FormPro.vue'
import FormCandidature from '@/components/DossierAcademique/FormCandidature.vue'
import FormDocument from '@/components/DossierAcademique/FormDocument.vue'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { Etat, getEtatSpec } from '@/types/DossierAcademique'
import { PosteContext, PosteInterface, PosteType } from '@/types/Poste'
import { Ability } from '@/types/Ability'

@Component({
    components: {
        ItemIntervenant,
        FormPerso,
        FormPro,
        FormCandidature,
        FormDocument
    },
    computed: {
        ...mapState('dossieracademique', ['hasAvatar']),
        ...mapState('document', ['nb_rejete']),
        ...mapGetters('auth', ['authUser', 'can']),
        ...mapGetters('dossieracademique', ['dossierAcademiqueSelect', 'viewDossier', 'dacaConcours', 'avatar', 'loading', 'posteSelect', 'isValide', 'error'])
    },
    methods: {
        ...mapActions('dossieracademique', ['getDossiersAcademiques', 'getDossierAcademique', 'updateDossierAcademique']),
        ...mapMutations('document', ['SET_NB_PJ_REJETE', 'RESET_NB_PJ_REJETE']),
        ...mapMutations('dossieracademique', ['SET_HAS_AVATAR', 'SET_DOSSIER_ACADEMIQUE_AVATAR', 'SET_VIEW_DOSSIER'])
    }
})
export default class AssistantValidationDossier extends Vue {
    @Prop() dossier_id?: number;
    @Prop() params?: any;
    @Prop() toSelection?: boolean;
    @Prop() environnement?: string;
    @Prop() new?: boolean;
    @Prop() from?: string | undefined
    @Prop({default: 'CCMP'}) codeClient?: string

    Ability = Ability

    // codeClient = 'CCMP'

    @Watch('viewDossier')
    showPopup () {
        if (this.$store.state.dossieracademique.viewDossier === true) {
            if (this.$props.dossier_id !== 0 && this.$props.dossier_id !== null) {
                this.$store.dispatch('dossieracademique/getDossierAcademique', this.$props.dossier_id).then(() => {
                    // Chargement des postes du dossier
                    this.loadPostesDossier()
                    this.loadEtablissements()
                    this.show = this.$store.state.dossieracademique.viewDossier
                })
            }
        } else {
            this.show = this.$store.state.dossieracademique.viewDossier
        }
    }

    @Watch('dossierAcademiqueSelect')
    checkDossierAcademique () {
        if (this.$store.state.dossieracademique.dossierAcademiqueSelect.hasAvatar && this.$props.dossier_id !== 0) {
            this.$store.dispatch('dossieracademique/getAvatar', { dossier_id: this.$props.dossier_id })
        }
    }

    // DATAS
    concours = []
    etat_depose: Etat = Etat.ETAT_DEPOSE
    etape_consultee = 1
    etapes_totales = 4
    showError = false
    hideValid = false
    show = false
    showMessage = false
    messageRejet = ''
    disabledButtons = false
    reloadAvatar = true
    getEtatSpec = getEtatSpec
    Etat = Etat

    showModalMessageDelete = false
    showModalMessageInvalide = false

    deleteEnCours = false
    invalidationEnCours = false


    // METHODS


    cancelInvalide () {
        this.showModalMessageInvalide = false
    }

    invalideDossier () {
        this.showModalMessageInvalide = true
    }

    /**
     * invalideDossierSuite
     * Cette fonction lance l'invalidation d'un dossier académique.
     */
    invalideDossierSuite () {
        this.invalidationEnCours = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Invalidation en cours ...', infosToaster)

        const params = { etat: Etat.ETAT_DEPOSE }
        this.$store.dispatch('dossieracademique/updateDossierAcademique', {
            dossier_id: this.$store.state.dossieracademique.dossierAcademiqueSelect.id,
            payload: params,
            mode: this.environnement,
            new: this.new
        })
            .then(() => {
                this.showModalMessageInvalide = false
                this.$emit('reload')
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.invalidationEnCours =  false
            })
    }

    cancelDelete() {
        this.showModalMessageDelete = false
    }

    deleteDossier () {
        this.showModalMessageDelete = true
    }

    /**
     * deleteDossierSuite
     * Cette fonction lance la suppression d'un dossier académique.
     */
    deleteDossierSuite () {
        this.deleteEnCours = true
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Supression en cours ...', infosToaster)

        this.$store.dispatch('dossieracademique/deleteDossierAcademique', this.dossier_id)
            .then(() => {
                this.showModalMessageDelete = false
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.deleteEnCours = false
            })
    }

    etapeSuivante () {
        this.etape_consultee++
    }

    etapePrecedente () {
        this.etape_consultee--
    }

    setEtape (etape: number) {
        this.etape_consultee = etape
    }

    // Reinit la consultation des étapes
    reinitEtape () {
        this.etape_consultee = 1
        this.hideValid = false
        this.showMessage = false
        this.$store.commit('document/RESET_NB_PJ_REJETE')
        this.$store.commit('dossieracademique/SET_VIEW_DOSSIER', false)
    }

    // Ouvre le popup de saisie du message de rejet
    reouvertureDossier () {
        this.showMessage = true
    }

    // Fermeture de la popup
    closeAssistant () {
        this.etape_consultee = 1
        this.hideValid = false
        this.showMessage = false
        this.$store.commit('document/RESET_NB_PJ_REJETE')
        this.$store.commit('dossieracademique/SET_VIEW_DOSSIER', false)
    }

    // Valide le dossier
    validationDossier () {
        const params = { etat: Etat.ETAT_VALIDE }
        this.$store.dispatch('dossieracademique/updateDossierAcademique', {
            dossier_id: this.$store.state.dossieracademique.dossierAcademiqueSelect.id,
            payload: params,
            mode: this.environnement,
            new: this.new
        })
            .then(() => {
                if (this.$store.state.dossieracademique.error !== '') {
                    this.$store.commit('dossieracademique/SET_VIEW_DOSSIER', false)
                    this.reinitEtape()
                    this.$emit('reload')
                } else {
                    this.showError = true
                }
            })
    }

    // Rejette le dossier
    rejeterDossier () {
        const params = { etat: Etat.ETAT_REJETE, commentaire_rejet_cle: 'rejet_global', commentaire_rejet_message: this.messageRejet }
        this.$store.dispatch('dossieracademique/updateDossierAcademique', {
            dossier_id: this.$store.state.dossieracademique.dossierAcademiqueSelect.id,
            payload: params,
            mode: this.environnement,
            new: this.new
        })
            .then(() => {
                if (this.$store.state.dossieracademique.error !== '') {
                    this.messageRejet = ''
                    this.$store.commit('dossieracademique/SET_VIEW_DOSSIER', false)
                    this.reinitEtape()
                    this.$emit('reload')
                } else {
                    this.showError = true
                }
            })
    }

    // Met le dossier à disposition de l'intervenant
    publierDossier () {
        const params = { etat: Etat.ETAT_VIERGE }
        this.$store.dispatch('dossieracademique/updateDossierAcademique', {
            dossier_id: this.$props.dossier_id,
            payload: params,
            mode: this.environnement,
            new: this.new
        })
            .then(() => {
                if (this.$store.state.dossieracademique.error !== '') {
                    this.$store.commit('dossieracademique/SET_VIEW_DOSSIER', false)
                    this.reinitEtape()
                    this.$emit('reload')
                } else {
                    this.showError = true
                }
            })
    }

    // Cache les boutons de validation
    hideValidation (showDocument: boolean) {
        this.hideValid = showDocument
    }

    // Check l'édition en cours
    checkEditionEnCours (en_cours: boolean) {
        this.disabledButtons = en_cours
    }

    // Chargement des postes du dossier
    loadPostesDossier () {
        const postes_souhaits_select = this.$store.state.dossieracademique.dossierAcademiqueSelect.postes.filter((p: PosteInterface) => p.context_type === PosteContext.CONTEXT_SOUHAIT)

        if ((this.$store.state.dossieracademique.dacaConcours.length === undefined && this.$props.dossier_id !== 0) || (this.$store.state.dossieracademique.dacaConcours.length === 0 && this.$props.dossier_id !== 0)) {
            this.$store.dispatch('dossieracademique/getConcoursDossierAcademique', { dossier_id: this.$props.dossier_id })
        }

        this.$store.commit('poste/SET_POSTES_SELECTED', postes_souhaits_select)

        if (this.$store.state.matiere.matieres.length === undefined || this.$store.state.matiere.matieres.length === 0) {
            this.$store.dispatch('matiere/getMatieres')
        }
        if (this.$store.state.poste.postes.length === undefined || this.$store.state.poste.postes.length === 0) {
            this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_PEDAGOGIQUE, context: PosteContext.CONTEXT_SOUHAIT })
        } else {
            const postes_souhaits = this.$store.getters['poste/postes_souhaits']
            this.$store.commit('poste/SET_LISTE_POSTES', postes_souhaits)
        }
    }

    // Chargement des établissements
    loadEtablissements () {
        const academie = this.$store.state.dossieracademique.academie
        const type_etablissement = this.$store.state.dossieracademique.typeEtab
        if (academie !== '' && type_etablissement !== '') {
            this.$store.dispatch('etablissement/getEtablissements', { academie: academie, type: type_etablissement })
        }
    }
}
