































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Prop } from 'vue-property-decorator'
import EditorTinyMCE from '@/components/Tools/EditorTinyMCE.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapState } from 'vuex'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import { getTypeRemuneration, TypeRemuneration } from '@/types/BaremeRemuneration'
import { EntityType } from '@/types/Poste'
import { MatiereInterface } from '@/types/Matiere'
import { EpreuveInterface } from '@/types/Epreuve'

@Component({
    components: {
        VuePdfApp,
        EditorTinyMCE,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapState('avenanttype', ['avenanttypes']),
        ...mapState('avenant', ['avenant_select'])
    }
})
export default class GestionAvenants extends Vue {
    @Prop() contrat?: any;
    @Prop() postes?: any;
    @Prop() remunerations?: any;
    @Prop() contrat_type_select?: any;
    @Prop() remuneration_select?: any;
    @Prop() volume_select?: any;
    @Prop() remunerations_ids?: any;
    @Prop() poste_affectations_filter?: any;
    @Prop() remunerations_filter?: any;
    @Prop() modeSaisieQuantitesReelle?: any;
    @Prop() errorInterface?: any;
    @Prop() volume_reel_select?: any;


    typeRemuneration = TypeRemuneration

    avenant_text: any = null
    avenant_type_id: any = null
    source_doc: any = null
    modePreviewAvenant = false
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    countRemunerations = 0

    getTypeRemuneration = getTypeRemuneration
    getLibelleTypeRemuneration (bareme_id: number) {
        return getTypeRemuneration(bareme_id)
    }

    // Selectionne une remuneration
    selectRemuneration (index: number, bareme_id: number, poste: any) {
        // \\('Dans selectRemuneration')
        if (this.remuneration_select[poste.id + '_' + poste.entity_id][index]) {
            this.remunerations_ids[poste.id + '_' + poste.entity_id][index] = bareme_id
            this.countRemunerations++
        } else if (this.remunerations_ids[poste.id + '_' + poste.entity_id]) {
            this.remunerations_ids[poste.id + '_' + poste.entity_id].splice(index, 1)
            this.countRemunerations--
        }
    }



    // Récupère le texte depuis l'Editor
    setText (text: string) {
        this.avenant_text = text
    }

    // Ouvre l'aperçu d'un avenant
    apercuPdf () {
        this.$store.state.avenant.avenant_select.avenant_type_id = this.avenant_type_id
        this.$store.state.avenant.avenant_select.content = this.avenant_text

        // Formatte l'objet baremes_remunerations
        const baremes_remunerations = []
        this.$store.state.avenant.avenant_select.bareme_remunerations = null

        for (const b in this.$props.contrat.bareme_remunerations) {
            baremes_remunerations.push({
                bareme_remuneration_id: this.$props.contrat.bareme_remunerations[b].id,
                poste_id: this.$props.contrat.bareme_remunerations[b].poste_id,
                entity_type: this.$props.contrat.bareme_remunerations[b].entity_type,
                entity_id: this.$props.contrat.bareme_remunerations[b].entity_id,
                options: this.$props.contrat.bareme_remunerations[b].options ? this.$props.contrat.bareme_remunerations[b].options : null,
                quantite: this.volume_select[this.$props.contrat.bareme_remunerations[b].poste_id + '_' + this.$props.contrat.bareme_remunerations[b].entity_id][b]
            })
        }
        this.$store.state.avenant.avenant_select.bareme_remunerations = baremes_remunerations

        this.$store.dispatch('avenant/updateAvenant', { contrat_id: this.$props.contrat.id, avenant_id: this.$store.state.avenant.avenant_select.id, payload: this.$store.state.avenant.avenant_select }).then(() => {
            this.source_doc = ''
            this.modePreviewAvenant = true
            this.$store.dispatch('avenant/getAvenantPDF', { contrat_id: this.$props.contrat.id, avenant_id: this.$store.state.avenant.avenant_select.id }).then(() => {
                this.source_doc = base64ToArrayBuffer(this.$store.state.avenant.source_pdf_avenant)
            })
        })
    }

    // Enregistrement du contenu de l'avenant
    enregistrer () {
        if (this.$store.state.avenant.avenant_select) {
            // Modification
            this.$store.state.avenant.avenant_select.avenant_type_id = this.avenant_type_id
            this.$store.state.avenant.avenant_select.content = this.avenant_text

            // Formatte l'objet baremes_remunerations
            const baremes_remunerations = []
            this.$store.state.avenant.avenant_select.bareme_remunerations = null
            for (const b in this.$props.contrat.bareme_remunerations) {
                baremes_remunerations.push({
                    bareme_remuneration_id: this.$props.contrat.bareme_remunerations[b].id,
                    poste_id: this.$props.contrat.bareme_remunerations[b].poste_id,
                    entity_type: this.$props.contrat.bareme_remunerations[b].entity_type,
                    entity_id: this.$props.contrat.bareme_remunerations[b].entity_id,
                    options: this.$props.contrat.bareme_remunerations[b].options ? this.$props.contrat.bareme_remunerations[b].options : null,
                    quantite: this.volume_select[this.$props.contrat.bareme_remunerations[b].poste_id + '_' + this.$props.contrat.bareme_remunerations[b].entity_id][b]
                })
            }
            this.$store.state.avenant.avenant_select.bareme_remunerations = baremes_remunerations

            this.$store.dispatch('avenant/updateAvenant', { contrat_id: this.$props.contrat.id, avenant_id: this.$store.state.avenant.avenant_select.id, payload: this.$store.state.avenant.avenant_select })
                .then(() => {
                    this.avenant_type_id = this.$store.state.avenant.avenant_select.avenant_type_id
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    this.$emit('save_finalise', this.$props.contrat.dossier_administratif_id)
                })
        } else {
            const remunerations = []
            for (const r in this.remunerations_ids) {
                const splitKey = r.split('_')

                const poste_aff: any = []
                if (this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique) {
                    for (const p in this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique.poste_affectations) {
                        const poste_ac = this.$store.state.dossieradministratif.dossierAdministratifSelect.dossier_academique.poste_affectations.filter((p: any) => p.id.toString() === splitKey[0] && p.entity_id.toString() === splitKey[1])
                        for (const c in poste_ac) {
                            poste_aff.push(poste_ac[c])
                        }
                    }
                }
                if (this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user) {
                    for (const p in this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user.postes) {
                        const poste_lo = this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user.postes.filter((p: any) => p.id.toString() === splitKey[0])
                        for (const l in poste_lo) {
                            poste_aff.push(poste_lo[l])
                        }
                    // poste_aff += this.$store.state.dossieradministratif.dossierAdministratifSelect.centre_user.postes.filter((p: any) => p.id.toString() === splitKey[0])
                    }
                }

                if (this.remunerations_ids[r].length !== 0) {
                    for (const b in this.remunerations_ids[r]) {
                        if (!this.modeSaisieQuantitesReelle) {
                            if (this.remunerations_ids[r][b] && this.volume_select[r][b]) {
                                remunerations.push({ poste_id: splitKey[0], bareme_remuneration_id: this.remunerations_ids[r][b], quantite: this.volume_select[r][b], entity_type: poste_aff[0].entity_type, entity_id: poste_aff[0].entity_id, options: poste_aff[0].options_affectes })
                                this.errorInterface = ''
                            } else {
                                this.errorInterface = 'Merci de renseigner un volume pour chacun des barèmes de rémunération sélectionnés'
                            }
                        } else {
                            if (this.remunerations_ids[r][b] && this.volume_select[r][b] && this.volume_reel_select[r][b]) {
                                remunerations.push({
                                    poste_id: splitKey[0],
                                    bareme_remuneration_id: this.remunerations_ids[r][b],
                                    quantite: this.volume_select[r][b],
                                    quantite_reelle: this.volume_reel_select[r][b],
                                    entity_type: poste_aff[0].entity_type,
                                    entity_id: poste_aff[0].entity_id,
                                    options: poste_aff[0].options_affectes
                                })
                                this.errorInterface = ''
                            } else {
                                this.errorInterface = 'Merci de renseigner un volume de quantité réelle pour chacun des barèmes de rémunération sélectionnés'
                            }
                        }
                    }
                }
            }
            const avenant_save = {
                avenant_type_id: this.avenant_type_id,
                content: this.avenant_text,
                bareme_remunerations: remunerations
            }

            this.$store.dispatch('avenant/saveAvenant', { contrat_id: this.$props.contrat.id, payload: avenant_save })
                .then(() => {
                    this.avenant_type_id = this.$store.state.avenant.avenant_select.avenant_type_id
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    this.$emit('save_finalise', this.$props.contrat.dossier_administratif_id)
                })
        }
    }

    // Fermeture du mode édition / création
    back () {
        this.$emit('back')
    }

    // Fermeture de l'aperçu pdf
    back_preview () {
        this.source_doc = null
        this.modePreviewAvenant = false
    }

    // Retourne le poste affectation formaté
    getStringPoste (poste: any) {
        let retour = ''
        retour += poste.name
        if (poste.options_affectes && poste.options_affectes !== '[]' && poste.options_affectes !== null) {
            const opt = JSON.parse(poste.options_affectes)
            retour += ' - ' + opt[0]
        }
        // Recupere le nom de l'épreuve ou de la matiere
        if (poste.entity_type === EntityType.ET_EPREUVE_CORRECTION) {
            const epr = this.$store.state.epreuve.epreuvesCorrections.filter((epr: EpreuveInterface) => epr.id === poste.entity_id)
            if (epr.length !== 0) {
                retour += ' sur ' + epr[0].concours[0].name + ' - ' + epr[0].name
            }
        }

        if (poste.entity_type === EntityType.ET_MATIERE) {
            const matiere = this.$store.state.matiere.matieres.filter((mat: MatiereInterface) => mat.id === poste.entity_id)
            retour += ' de ' + matiere[0].name
        }

        return retour
    }

    mounted () {
        // Load les avenants types
        this.$store.dispatch('avenanttype/getAvenantTypes')

        // Init si modification
        if (this.$store.state.avenant.avenant_select !== null) {
            this.avenant_type_id = this.$store.state.avenant.avenant_select.avenant_type_id
        }
    }
}
