





















import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapMutations } from 'vuex'

@Component({
    methods: {
        ...mapMutations('dossieracademique', ['UPDATE_DATAS_TITRE', 'UPDATE_DATAS_TITRE_AUTRE'])
    }
})
export default class SelectTitre extends Vue {
    @Prop() selectedValue?: string
    @Prop() other?: string
    @Prop() readOnly?: boolean
    @Prop() validation?: string

    options = [
        { id: 'Docteur', value: 'Docteur' },
        { id: 'Professeur', value: 'Professeur' },
        { id: 'Ingénieur', value: 'Ingénieur' },
        { id: 'Autre', value: 'Autre' }
    ]

    // Event change sur le select
    updateDatasPro (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLTextAreaElement
            if (target.name === 'title') {
                this.$store.commit('dossieracademique/UPDATE_DATAS_TITRE', target.value)
            }
            if (target.name === 'title_other') {
                this.$store.commit('dossieracademique/UPDATE_DATAS_TITRE_AUTRE', target.value)
            }
        }
    }
}
