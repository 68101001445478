





































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import { PosteInterface, PosteContext, PosteType } from '@/types/Poste'
import { Etat, getEtatSpec, getEtatForFilterSelect, EtatDepot } from '@/types/DossierAdministratif'
import AssistantValidationDossierAdministratif from '@/views/Dossiers/AssistantValidationDossierAdministratif.vue'
import GestionContrats from '@/components/DossierAdministratif/GestionContrats.vue'
import GestionContratsMasse from '@/components/DossierAdministratif/GestionContratsMasse.vue'
import PopupRelaunchContract from '@/components/DossierAdministratif/PopupRelaunchContract.vue'
import DepotBulletinSalaire from '@/components/DossierAdministratif/DepotBulletinSalaire.vue'
import DepotAttestationPoleEmploi from '@/components/DossierAdministratif/DepotAttestationPoleEmploi.vue'
import ImportQuantitesReelles from '@/components/DossierAdministratif/ImportQuantitesReelles.vue'
import ExportPaie from '@/components/DossierAdministratif/ExportPaie.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { checkIcone, formatDate } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'



@Component({
    components: {
        ExaGenericTable,
        AssistantValidationDossierAdministratif,
        GestionContrats,
        GestionContratsMasse,
        PopupRelaunchContract,
        DepotBulletinSalaire,
        DepotAttestationPoleEmploi,
        ImportQuantitesReelles,
        ExportPaie,
        ErrorDisplay,
        PopUpInfoIntervenant,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('dossieradministratif', ['loading', 'error', 'totalRows', 'currentPage', 'lastPage', 'totalPage']),
        ...mapGetters('session', ['sessions']),
        ...mapState('poste', ['postes', 'loading']),
        ...mapState('matiere', ['matieres', 'loading']),
        ...mapState('ville', ['villes', 'loading']),
        ...mapState('dossieradministratif', ['meta', 'dossiersAdministratifs']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    methods: {
        ...mapMutations('dossieradministratif', ['SET_VIEW_DOSSIER']),
        ...mapActions('dossieradministratif', ['getDossiersAdministratifs', 'updateDossierAdministratif', 'getContrats'])
    }
})
export default class ContratsRespAdm extends Vue {
    @Prop() mode?: string;
    PosteType = PosteType


    Ability = Ability
    // DATAS
    fields: Array<any> = [
        {
            key: 'Édit',
            label: '',
            sortable: true,
            class: 'text-center'
        },
        {
            key: 'etat',
            label: '',
            sortable: true,
            class: 'text-center'
        },
        { key: 'user.name', label: 'Identité', sortable: true, sortDirection: 'asc', class: 'text-start ps-4',  type: 'actionText'  },
        {
            key: 'centre_user.postes',
            label: 'Poste',
            sortable: false,
            sortDirection: 'asc',
            class: 'text-start'
        },
        {
            key: 'centre_user.centre.name',
            label: 'Centre',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start'
        },
        {
            key: 'etat_contrats_interne',
            label: 'Contrat(s)',
            class: 'text-center',
            sortable: false,
            sortDirection: 'asc'
        },
        {
            key: 'etat_avenants_interne',
            label: 'Avenant(s)',
            class: 'text-center',
            sortable: false,
            sortDirection: 'asc'
        },
        {
            key: 'contrats_has_bulletin',
            label: 'Bulletin(s) de salaire',
            class: 'text-center',
            sortable: true,
            sortDirection: 'asc'
        },
        {
            key: 'contrats_has_attestations',
            label: 'Attestation(s) Pôle emploi',
            class: 'text-center',
            sortable: true,
            sortDirection: 'asc'
        },
        {
            key: 'relanceSignature',
            label: 'Relance',
            class: 'text-center',
            sortable: false,
            sortDirection: 'asc'
        }
    ]

    dataForTab: any = []

    filtres: any = []
    Etat = Etat

    showModalInfoIntervenant = false
    user_id_temp = 0
    mode_relance = ''


    @Watch('postes')
    buildFiltre () {
        const matieres = this.$store.getters['matiere/matieres']
        const options_matieres = []
        for (const m in matieres) {
            options_matieres.push({ index: matieres[m].id, name: matieres[m].name })
        }

        const postes = this.$store.state.poste.postes.filter((p: PosteInterface) => (p.context & PosteContext.CONTEXT_AFFECTATION) === PosteContext.CONTEXT_AFFECTATION)
        const option_postes = []
        for (const p in postes) {
            option_postes.push({ index: postes[p].name, name: postes[p].name })
        }

        const middle = Math.floor(Object.keys(Etat).length / 2)
        const etats = [
            { index: 'null', name: 'Aucun' },
            ...Object.keys(Etat).slice(0, middle)
                .map((etat: any) => {
                    const etat_spec = getEtatSpec(parseInt(etat.toString()))
                    return { index: etat_spec.index, name: etat_spec.libelle }
                })
        ]
        this.filtres = []
        this.filtres = [
            /*
            {
                libelle: 'Nom',
                defautOptionlibelle: 'Rechercher un',
                model: 'user.name',
                value: '',
                index: 'name',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'user.name' } // 'form' , 'deroulant'
            },
            */
            {
                libelle: 'Nom',
                defautOptionlibelle: 'Rechercher un',
                model: 'user.name',
                value: '',
                datas: '',
                loading:false,
                options: { type: 'form', fieldsKey: 'user.name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Postes',
                defautOptionlibelle: 'Tous les',
                model: 'centre_user.postes.name',
                value: '-',
                index: 'name',
                datas: option_postes,
                loading: this.$store.state.poste.loading,
                options: { type: 'deroulant', fieldsKey: 'centre_user.postes' } // 'form' , 'deroulant'
            },
            {
                libelle: 'État',
                defautOptionlibelle: 'Tous les',
                model: 'etat_contrats_interne',
                value: '-',
                index: 'name',
                datas: etats,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'etat_contrats_interne' } // 'form' , 'deroulant'
            },
            {
                libelle: 'Avenant',
                defautOptionlibelle: 'Rechercher un',
                model: 'etat_avenants_interne',
                value: '-',
                index: 'etat_avenants_interne',
                datas: etats,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'etat_avenants_interne' } // 'form' , 'deroulant'
            }
        ]
    }

    @Watch('meta')
    majInfosTable () {
        // On récupère le nombre total de dossiers filtrés depuis les infos des Metadonnées
        if (this.$store.state.dossieradministratif.meta !== null) {
            if (this.totalContrats !== this.$store.state.dossieradministratif.meta.total) {
                this.totalContrats = this.$store.state.dossieradministratif.meta.total
            }
        } else {
            this.totalContrats = 0
        }
    }

    @Watch('dossiersAdministratifs')
    reloadDossiers () {
        this.setDataForGenericTab(this.$store.state.dossieradministratif.dossiersAdministratifs)
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    dossier_id = 0
    params: any = []
    showGestionContrats = false
    showGestionContratsMasse = false
    showConfirmRelance = false
    rowSelect: any = null
    document_select_id: any = null
    enumEtats: Array<any> = []
    totalContrats = 0
    envoiEnCours = false
    showDepotBulletin = false
    modeBulletin = ''
    showDepotAttestation = false
    showOpenExport = false
    modeAttestation = ''
    showImportQuantitesReelles = false
    modeQuantitesReelles = ''
    datas: any = []
    chargementTableau = false

    // METHODS

    // Récupère une énum des états de poste possible
    getEnumEtats () {
        this.enumEtats = []
        for (const etatName in Etat) {
            if (isNaN(Number(etatName))) {
                this.enumEtats.push({ id: getEtatForFilterSelect(etatName).index, title: getEtatForFilterSelect(etatName).libelle, name:'circle' })
            }
        }
        return this.enumEtats
    }

    // création de la collection complète en fonction d'une demande de filtrage ou de tri
    filtreSortHandler (params: any) {
        this.params = params
        if (this.$props.mode === 'sol') {
            this.params.section = 'sol'
        }
        this.chargementTableau = true
        this.$store.state.dossieradministratif.dossiersAdministratifs = []
        this.$store.dispatch('dossieradministratif/getDossiersAdministratifs', this.params).then(() => {
            this.chargementTableau = false
        })
    }

    // ajout des nouvelles entrées de liste lors d'un scroll
    loadHandler (params: any) {
        this.params = params
        if (this.$props.mode === 'sol') {
            this.params.section = 'sol'
        }
        this.chargementTableau = true
        this.$store.dispatch('dossieradministratif/getMoreDossiersAdministratifs', this.params).then(() => {
            this.chargementTableau = false
        })
    }

    // Ouvre le validateur de dossier
    openValidator (row: any) {
        this.dossier_id = row.id
        if (this.dossier_id !== 0) {
            this.$store.commit('dossieradministratif/SET_VIEW_DOSSIER', true)
        }
    }

    // Ouvre le popup de gestion des contrats
    openGestionContrats (row: any) {
        this.$store.commit('dossieradministratif/SET_DOSSIER_ADMINISTRATIF_CURRENT', row)
        this.$store.dispatch('dossieradministratif/getContrats', { dossier_id: row.id }).then(() => {
            this.showGestionContrats = true
            this.rowSelect = row

            // J'ai déplacé ce commit avant le disptach car depuis que l'action dispatchée est intégrée dans un promise, le commit est fait trop tard s'il est dans le .then()
            // this.$store.commit('dossieradministratif/SET_DOSSIER_ADMINISTRATIF_CURRENT', row.item)
        })
    }

    // Ferme la popup de gestion des contrats
    closeGestionContrats (rowMaj: any) {
        this.showGestionContrats = false
        const index = this.$store.state.dossieradministratif.dossiersAdministratifs.map((item: any) => item.id).indexOf(rowMaj.id)
        Vue.set(this.$store.state.dossieradministratif.dossiersAdministratifs, index, rowMaj)
        this.rowSelect = null
    }

    // Relance par email l'intervenant sur la signature de son contrat
    relanceSignature (row: any) {
        this.rowSelect = row
        this.rowSelect.avenants = []
        for (const c in row.contrats) {
            if (row.contrats[c].avenants) {
                for (const a in row.contrats[c].avenants) {
                    this.rowSelect.avenants.push(row.contrats[c].avenants[a])
                }
            }
        }

        this.showConfirmRelance = true
    }

    // Valide la demande de relance
    confirmRelanceContrat () {
        this.envoiEnCours = true
        let contrat_id: null
        if (this.rowSelect.contrats.length === 1) {
            contrat_id = this.rowSelect.contrats[0].id
        } else {
            contrat_id = this.document_select_id
        }

        this.$store.state.dossieradministratif.error = null
        const datas = {
            relaunch: 1
        }

        this.$store.dispatch('dossieradministratif/updateContrat', { dossier_id: this.rowSelect.id, contrat_id: contrat_id, payload: datas })
            .then(() => {
                if (!this.$store.state.dossieradministratif.error) {
                    this.showConfirmRelance = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Courriel envoyé !', succesToaster)
                    this.envoiEnCours = false
                }
            })
    }

    // Valide la demande de relance d'un avenant
    confirmRelanceAvenant () {
        this.envoiEnCours = true
        const value = this.document_select_id.split('_')
        const avenant_id =  value[1]
        const contrat_id = value[0]

        this.$store.state.avenant.error_avenant = null
        const datas = {
            relaunch: 1
        }

        this.$store.dispatch('avenant/updateAvenant', { contrat_id: contrat_id, avenant_id: avenant_id, payload: datas })
            .then(() => {
                if (!this.$store.state.avenant.error_avenant) {
                    this.showConfirmRelance = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Courriel envoyé !', succesToaster)
                    this.envoiEnCours = false
                }
            })
    }

    // Close la popup de confirmation de relance
    closeConfirmRelance () {
        this.rowSelect = null
        this.showConfirmRelance = false
    }

    // Ouvre le popup de gestion des contrats
    openGestionContratsMasse () {
        this.showGestionContratsMasse = true
    }

    // Ferme la popup de gestion des contrats
    closeGestionContratsMasse () {
        this.showGestionContratsMasse = false
    }

    // Ouvre le popup de depot d'un bulletin de paie
    depotBulletin (row: any) {
        this.$store.dispatch('dossieradministratif/getContrats', { dossier_id: row.id, mode: 'bulletin' }).then(() => {
            this.showDepotBulletin = true
            this.rowSelect = row
            this.modeBulletin = 'simple'
            this.$store.commit('dossieradministratif/SET_DOSSIER_ADMINISTRATIF_CURRENT', row)
        })
    }

    // Ferme la popup de depot des bulletins
    closeDepotBulletin () {
        this.showDepotBulletin = false
    }

    // Ouvre la gestion des bulletins de salaire en masse
    openGestionBulletins () {
        this.modeBulletin = 'masse'
        this.showDepotBulletin = true
    }

    // Ouvre le popup de depot d'une attestation pôle emploi
    depotAttestation (row: any) {
        this.$store.dispatch('dossieradministratif/getContrats', { dossier_id: row.id, mode: 'attestation' }).then(() => {
            this.showDepotAttestation = true
            this.rowSelect = row
            this.modeAttestation = 'simple'
            this.$store.commit('dossieradministratif/SET_DOSSIER_ADMINISTRATIF_CURRENT', row)
        })
    }

    // Ferme la popup de dépôt d'une attestation pôle emploi
    closeDepotAttestation () {
        this.showDepotAttestation = false
    }

    // Ouvre la gestion des attestations en masse
    openGestionAttestations () {
        this.modeAttestation = 'masse'
        this.showDepotAttestation = true
    }

    // Ouvre le popup d'importation des quantités réelles en masse
    openImportQuantitesReelles () {
        this.modeQuantitesReelles = 'masse'
        this.showImportQuantitesReelles = true
    }

    // Ferme la popup d'importation des quantités réelles en masse
    closeImportQuantitesReelles () {
        this.showImportQuantitesReelles = false
    }

    // Ouvre la sélection des postes pour l'export de la paie
    openOptionsExport () {
        this.showOpenExport = true
    }

    // Ferme la popup d'export de paie
    closeOpenExport () {
        this.showOpenExport = false
    }


    /*

        fonctions pour generic table

    */

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            const line = paParams && paParams[1] && paParams[1][0] && paParams[1][0].result ? paParams[1][0].result : undefined
            switch (paParams[0])
            {
                case 'edit':
                    this.openValidator(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'relance':
                    this.relanceSignature(line)
                    break
                case 'attestations':
                    this.depotAttestation(line)
                    break
                case 'bulletins':
                    this.depotBulletin(line)
                    break
                case 'contrats':
                    this.openGestionContrats(line)
                    break
                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break

                default:
                    break
            }
        }
    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        const can = this.$store.getters['auth/can'](Ability.ORAL_AFEX_MANAGE)
        const icone = checkIcone(Ability.ORAL_AFEX_MANAGE, can)
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const etatDossier   = [{ name: 'circle', class: this.checkEtatDossier(result.etat) }]
                const name          = result.datas_perso && result.datas_perso.name ? result.datas_perso.name : ' - '
                const first_name    = result.datas_perso && result.datas_perso.first_name ? result.datas_perso.first_name : ' - '


                const postes =  result && result.centre_user && result.centre_user.postes ? this.formatPoste(result.centre_user.postes) : '-'
                const centre = result && result.centre_user && result.centre_user.centre && result.centre_user.centre.name ? result.centre_user.centre.name : '-'

                const contrat: any  = this.etatContratInterne(result)
                const avenant: any  = this.etatAvenantInterne(result)

                const bulletinSalaire      = this.constratHas(result, 'bulletin')
                const attestationPoleEmp   = this.constratHas(result, 'attestation')

                const relanceSignature     = this.relanceSignatureAffichage(result)

                const line = [
                    {
                        label: icone.label,
                        item: result,
                        type: 'action',
                        typeAction: 'edit',
                        class: 'commons_first_action_button',
                        icon: icone.icon,
                        disabled: false
                    },
                    {
                        label: '',
                        item: etatDossier,
                        type: 'icons',
                        icon: etatDossier[0].name,
                        typeAction: null,
                        class: 'text-center' + etatDossier[0].class
                    },
                    { label: '', item: result.user.id, type: 'actionText', typeAction: 'infoIntervenant', class: 'text-info item_action', text: name + ' ' + first_name },
                    {
                        label: 'Poste',
                        item: postes,
                        type: 'text',
                        typeAction: null,
                        class: ''
                    },
                    {
                        label: 'centre',
                        item: centre,
                        type: 'text',
                        typeAction: null,
                        class: ''
                    },
                    {
                        label: '',
                        item: contrat,
                        type: 'icons',
                        typeAction: 'contrats',
                        class: 'text-center ' + contrat[0].class
                    },
                    {
                        label: '',
                        item: avenant,
                        type: 'icons',
                        typeAction: 'contrats',
                        class: 'text-center ' + avenant[0].class
                    },
                    {
                        label: '',
                        item: bulletinSalaire,
                        type: 'icons',
                        typeAction: 'bulletins',
                        class: 'text-center '  + bulletinSalaire[0].class
                    },
                    {
                        label: '',
                        item: attestationPoleEmp,
                        type: 'icons',
                        typeAction: 'attestations',
                        class: 'text-center ' + attestationPoleEmp[0].class
                    },
                    {
                        label: '',
                        item: relanceSignature,
                        type: this.$store.getters['auth/can'](Ability.ORAL_AFEX_MANAGE) && relanceSignature[0].button ? 'icons' : null,
                        typeAction: this.$store.getters['auth/can'](Ability.ORAL_AFEX_MANAGE) && relanceSignature[0].button ? 'relance' : 'null',
                        class: 'text-center ' + relanceSignature[0].class
                    }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /** prendre contrats_has_ soit bulletin soit attestation, return les bonnes infos d'affichage */
    constratHas(result: any, type: string) {
        let retour = [{ name:'times-circle', class: 'text-secondary', title: '', result: result }]
        switch (true) {
            case result['contrats_has_' + type] === EtatDepot.NON_DEPOSER:
                retour = [{
                    name:'times-circle',
                    class: 'text-secondary',
                    title: type === 'bulletin' ?  'Pas de bulletin(s) de salaire' : 'Pas d\'attesation(s) Pôle emploi',
                    result: result
                }]
                break
            case result['contrats_has_' + type] === EtatDepot.DEPOT_EN_COURS:
                retour = [{
                    name:'file-alt',
                    class: 'text-primary',
                    title: type === 'bulletin' ? 'Consulter le(s) bulletin(s) de salaire' : 'Consulter l\'attestation Pôle emploi',
                    result: result
                }]
                break
            case result['contrats_has_' + type] === EtatDepot.DEPOT_COMPLET:
                retour = [{
                    name:'file-alt',
                    class: 'text-success',
                    title: type === 'bulletin' ? 'Consulter le(s) bulletin(s) de salaire' : 'Consulter l\'attestation Pôle emploi',
                    result: result
                }]
                break
        }
        return retour
    }

    /** formatte la colonne poste */
    formatPoste(postes: string|any[]) {
        if (typeof postes === 'string') {
            return postes
        }
        return postes?.map((poste: any) => poste.name)
            ?.join(', ') || ''
    }

    // Retourne pour une ligne l'état des contrats
    etatContratInterne(result: any) {
        const puce_contrats = []
        if (result && result.contrats && result.contrats.length > 0) {
            result.contrats.forEach((contrat: { etat: any; submitted_at: any; signed_at: any; validated_at: any; contrat_type_name: any; id: any  }) => {
                switch (contrat.etat) {
                    case Etat.ETAT_INVISIBLE:
                        puce_contrats.push({ name:'file-alt', class: 'text-tertiary', title: 'Contrat ' + contrat.contrat_type_name + ' #' + contrat.id + ' non publié', result: result })
                        break
                    case Etat.ETAT_VIERGE:
                        puce_contrats.push({ name:'file-alt', class: 'text-secondary', title: 'Contrat ' + contrat.contrat_type_name + ' #' + contrat.id + ' publié le ' + formatDate(contrat.submitted_at), result: result })
                        break
                    case Etat.ETAT_DEPOSE:
                        puce_contrats.push({ name:'file-alt', class: 'text-info', title: 'Contrat ' + contrat.contrat_type_name + ' #' + contrat.id + ' signé par l\'intervenant le ' + formatDate(contrat.signed_at), result: result })
                        break
                    case Etat.ETAT_VALIDE:
                        puce_contrats.push({ name:'file-alt', class: 'text-success', title: 'Contrat ' + contrat.contrat_type_name + ' #' + contrat.id + ' signé par le concours le ' + formatDate(contrat.validated_at), result: result })
                        break
                }
            })
        }
        else {
            puce_contrats.push({ name:'times-circle', class: 'text-secondary', title: 'Pas de contrat', result: result })
        }

        return puce_contrats
    }

    // Retourne pour une ligne l'état des avenants
    etatAvenantInterne (result: any) {
        const puce_avenants = []
        let nb_avenants = 0
        if (result && result.contrats && result.contrats.length > 0) {
            result.contrats.forEach((contrat: { avenants: Array<any>; etat: any }) => {
                if (contrat.avenants && contrat.avenants.length !== 0) {
                    nb_avenants += contrat.avenants.length
                }
            })

            if (nb_avenants !== 0) {
                result.contrats.forEach((contrat: { avenants: Array<any>; etat: any }) => {
                    if (contrat.avenants) {
                        contrat.avenants.forEach((avenant: { etat: any; submitted_at: any; signed_at: any; validated_at: any; contrat_type_name: any; id: any  }) => {
                            switch (avenant.etat) {
                                case Etat.ETAT_INVISIBLE:
                                    puce_avenants.push({ name:'file-alt', class: 'text-tertiary', title: 'Avenant non publié', result: result })
                                    break
                                case Etat.ETAT_VIERGE:
                                    puce_avenants.push({ name:'file-alt', class: 'text-secondary', title: 'Avenant #' + avenant.id + ' publié le ' + formatDate(avenant.submitted_at), result: result })
                                    break
                                case Etat.ETAT_DEPOSE:
                                    puce_avenants.push({ name:'file-alt', class: 'text-info', title: 'Avenant #' + avenant.id + ' signé par l\'intervenant le ' + formatDate(avenant.signed_at), result: result })
                                    break
                                case Etat.ETAT_VALIDE:
                                    puce_avenants.push({ name:'file-alt', class: 'text-success', title: 'Avenant #' + avenant.id + ' signé par le concours le ' + formatDate(avenant.validated_at), result: result })
                                    break
                            }
                        })
                    }
                })
            } else {
                puce_avenants.push({ name:'times-circle', class: 'text-secondary', title: 'Pas d\'avenants', result: result })
            }
        } else {
            puce_avenants.push({ name:'times-circle', class: 'text-secondary', title: 'Pas d\'avenants', result: result })
        }

        return puce_avenants
    }

    /** Affichage de l icone enveloppe  */
    relanceSignatureAffichage(result: any) {
        let retour = [{ name:'envelope', class: 'text-' + result.etat_contrats_interne, title: '', result: result, button: false }]
        if (result.etat_infos_contrat !== null && result.contrats && result.contrats.length > 0) {
            result.contrats.forEach((element: { etat: Etat; avenants: any }) => {
                if (element.etat === Etat.ETAT_VIERGE) {
                    retour = [{
                        name:'envelope',
                        class: 'text-info',
                        title: result.contrats ? this.formatteDateSignature(result.contrats, 'contrat') : 'non relancé',
                        result: result,
                        button: true
                    }]
                }

                // Check aussi la collection d'avenants
                if (element.avenants && element.avenants.length !== 0) {
                    element.avenants.forEach((avenant: { etat: Etat}) => {
                        if (avenant.etat === Etat.ETAT_VIERGE) {
                            retour = [{
                                name:'envelope',
                                class: 'text-info',
                                title:  element.avenants ? this.formatteDateSignature(element.avenants, 'avenant') : 'non relancé',
                                result: result,
                                button: true
                            }]
                        }
                    })
                }
            })
        }
        return retour
    }

    /** Formatte les dates des signatures pour colonne du tableau  */
    formatteDateSignature (elems: Array<any>, mode: string) {
        this.mode_relance = mode
        let retour = ''
        if (elems && elems.length > 0) {
            for (let i = 0; i < elems.length; i++) {
                if (i > 0) {
                    retour += ', '
                }
                retour += mode === 'contrat' ? 'Contrat #' + elems[i].id : 'Avenant #' +  elems[i].id
                if (elems[i].relaunched_at) {
                    retour += ' relancé le ' + formatDate(elems[i].relaunched_at)
                } else {
                    retour += ' non relancé'
                }
            }
        } else {
            return ''
        }
        return retour
    }

    checkEtatDossier(etat: any) {
        const retour = ' text-' + (etat !== null && etat !== Etat.ETAT_INVISIBLE ? getEtatSpec(etat).color : 'tertiary')
        return retour
    }

    load () {
        this.$store.state.dossieradministratif.error = null
        if (this.$props.mode === 'sol') {
            this.$store.dispatch('ville/getVilles').then(() => {
                this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_LOGISTIQUE, context: PosteContext.CONTEXT_AFFECTATION })
            })
        }
    }

    created () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
        }
    }
}
