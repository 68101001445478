




















































































import { Watch, Prop, Vue, Component }  from 'vue-property-decorator'
import { FontAwesomeIcon }              from '@fortawesome/vue-fontawesome'
import { mapState }                     from 'vuex'
import ErrorDisplay                     from '@/components/ErrorDisplay.vue'
import { TypePassation }                from '@/types/Epreuve'

/**
 * Composant relatif à l'import de candidats et aux dossiers de handicaps
 */
@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ErrorDisplay
    },
    computed: {
        ...mapState('candidat', ['datasRepartitionEquipes', 'loading', 'error'])
    }
})
export default class PopupRepartitionEquipes extends Vue {
    @Prop() show?: boolean;
    showModal?: boolean             = false
    prerequis_ok                    = false
    selectedElement                 = null
    showErrorElement                = false
    element_error                   = null
    repartitionEnCours              = false

    etatPrerequisRepartitionEquipes: any   = {}
    prerequisAllCandStatutToPhase               = false // Tous les candidats ont un statut à la phase ?
    prerequisAllCandSerie                       = false // Tous les candidats reçus ont une série ?
    prerequisAffectExaminateurValidated         = false // Affectation des examinateurs validée ?
    prerequisIncompatExaminateurValidated       = false // Incompatibilités des examinateurs validée ?

    equipes_tp: Array<any> = []

    /**
     * show/hide de la modale
     */
    @Watch('show')
    showNoShow () {
        this.showModal = this.$props.show
        if (this.showModal) {
            this.$store.dispatch('candidat/getEtatPrerequisRepartitionEquipes').then(() => {
                this.initDatas()
            })
        }
    }

    initDatas () {
        this.etatPrerequisRepartitionEquipes = this.$store.state.candidat.datasRepartitionEquipes.prerequis
        this.setEtatPrerequis()
        this.setEquipesTP()
    }

    /** Met à jour les état des prérequis pour savoir si on est en contexte de permettre le lancement de la répartition */
    setEtatPrerequis () {
        let checkPrerequis_ok = true
        if (Object.keys(this.etatPrerequisRepartitionEquipes).length) {
            // Check si les barres ne sont pas toutes validées
            if (this.etatPrerequisRepartitionEquipes.nb_candidats === this.etatPrerequisRepartitionEquipes.nb_candidats_statut && this.etatPrerequisRepartitionEquipes.nb_barres === this.etatPrerequisRepartitionEquipes.nb_barres_validees) {
                this.prerequisAllCandStatutToPhase = true
            } else {
                this.prerequisAllCandStatutToPhase = false
                checkPrerequis_ok = false
            }

            // Check si tous les candidats reçus ont une série
            if (this.etatPrerequisRepartitionEquipes.nb_candidats_recu === this.etatPrerequisRepartitionEquipes.nb_candidats_serie) {
                this.prerequisAllCandSerie = true
            } else {
                this.prerequisAllCandSerie = false
                checkPrerequis_ok = false
            }

            // Check l'affectation des examinateurs est validée
            if (this.etatPrerequisRepartitionEquipes.ensembles_affectations_valides) {
                this.prerequisAffectExaminateurValidated = true
            } else {
                this.prerequisAffectExaminateurValidated = false
                checkPrerequis_ok = false
            }

            // Check si les incompatibilités des examinateurs validés
            if (this.etatPrerequisRepartitionEquipes.ensembles_affectations_affectes === this.etatPrerequisRepartitionEquipes.incompatibilites_nb_valides) {
                this.prerequisIncompatExaminateurValidated = true
            } else {
                this.prerequisIncompatExaminateurValidated = false
                checkPrerequis_ok = false
            }
        } else {
            this.prerequisAllCandStatutToPhase              = false
            this.prerequisAllCandSerie                      = false
            this.prerequisAffectExaminateurValidated        = false
            this.prerequisIncompatExaminateurValidated      = false
            checkPrerequis_ok = false
        }

        this.prerequis_ok = checkPrerequis_ok
    }

    /** création de la collection des équipes pour les épreuves TP */
    setEquipesTP () {
        this.equipes_tp = []
        if (this.$store.state.candidat.datasRepartitionEquipes && this.$store.state.candidat.datasRepartitionEquipes.concours) {
            for (let i = 0; i < this.$store.state.candidat.datasRepartitionEquipes.concours.length; i++) {
                if (this.$store.state.candidat.datasRepartitionEquipes.concours[i].epreuves_tirage) {
                    const epreuves_tirage_temp = []
                    for (let j = 0; j < this.$store.state.candidat.datasRepartitionEquipes.concours[i].epreuves_tirage.length; j++) {
                        if (this.$store.state.candidat.datasRepartitionEquipes.concours[i].epreuves_tirage[j].type_passation === TypePassation.TYPE_PASSATION_TP) {
                            epreuves_tirage_temp.push({
                                name: this.$store.state.candidat.datasRepartitionEquipes.concours[i].epreuves_tirage[j].name,
                                taux_tirage: this.$store.state.candidat.datasRepartitionEquipes.concours[i].epreuves_tirage[j].taux_tirage
                            })
                        }
                    }
                    if (epreuves_tirage_temp.length > 0) {
                        this.equipes_tp.push({
                            name: this.$store.state.candidat.datasRepartitionEquipes.concours[i].name,
                            epreuves_tirage: epreuves_tirage_temp
                        })
                    }
                }
            }
        }
    }

    /**
     * Fermeture de la modale
     */
    closeModale() {
        this.showErrorElement = false
        this.element_error = null
        this.$emit('reinitShow', true)
    }

    /** Lance la répartition des équipes */
    lancerRepartition () {
        const param = {
            session_id: this.$store.getters['session/sessionSelect'].id
        }

        this.repartitionEnCours = true

        this.$store.dispatch('candidat/lancementRepartitionEquipes', param).then(() => {
            const idSucces = 't_succes_' + Math.random()
            const succesToaster = {
                id: idSucces,
                toaster: 'b-toaster-top-right',
                variant: 'success',
                noCloseButton: true,
                fade: true,
                autoHideDelay: 5000
            }
            this.repartitionEnCours = false
            this.$bvToast.toast('La répartition par équipes a été lancée avec succès !', succesToaster)

            this.closeModale()
        }).catch(() => {
            this.repartitionEnCours = false
        })
    }
}
