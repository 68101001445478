
























































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { mapActions, mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { getTypeRemuneration, TypeRemuneration } from '@/types/BaremeRemuneration'
import { Etat, getEtatSpecContrat } from '@/types/DossierAdministratif'
import { getEtatSpec } from '@/types/DossierAcademique'
import { ContratTypeInterface } from '@/types/ContratType'
import { EntityType, PosteInterface, PosteType } from '@/types/Poste'
import { EpreuveInterface } from '@/types/Epreuve'
import { MatiereInterface } from '@/types/Matiere'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer } from '@/utils/helpers'
import { FontAwesomeIcon }          from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        VuePdfApp,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('dossieradministratif', ['error', 'contrats', 'loading', 'postes_affectations_sans_contrat']),
        ...mapState('dossieradministratif', ['source_pdf', 'error', 'contrats', 'postes_affectations_sans_contrat', 'contrats_create', 'loading']),
        ...mapState('contrattype', ['contrattypes']),
        ...mapState('poste', ['postes_affectations']),
        ...mapGetters('contrattype', ['contrattypes']),
        ...mapGetters('baremeremuneration', ['baremesRemuneration'])
    },
    methods: {
        ...mapActions('contrattype', ['getContratTypes']),
        ...mapActions('baremeremuneration', ['getBaremesRemuneration']),
        ...mapActions('dossieradministratif', ['updateDossierAdministratif', 'getContrat', 'getPostesAffectationsSansContrat', 'storeMultiContrats'])
    }
})

export default class GestionContratsMasse extends Vue {
    @Prop() showContrats?: boolean;
    @Prop() mode?: string;
    @Prop() params?: any;

    typeRemuneration = TypeRemuneration

    @Watch('error')
    setError () {
        if (this.$store.state.dossieradministratif.error) {
            this.errorInterface = this.$store.state.dossieradministratif.error
        } else {
            this.errorInterface = ''
        }
    }

    @Watch('poste_select')
    setContratType () {
        this.contrats_types_filter = []
        this.contrat_type_select = null
        if (this.poste_select !== null) {
            this.filterContratTypes()
        }
    }

    @Watch('contrat_type_select')
    setRemunerations () {
        this.remunerations_filter = []
        if (this.contrat_type_select !== null) {
            // Filtre les postes affectés a afficher selon le type de contrats
            this.initRemunerations()
        }
    }

    @Watch('showContrats')
    initDatas () {
        if (this.$props.showContrats) {
            this.$store.state.dossieradministratif.loading = true

            if (this.$props.mode === PosteType.TYPE_PEDAGOGIQUE) {
                this.$props.params.section = 'sps'
            } else if (this.$props.mode === PosteType.TYPE_LOGISTIQUE) {
                this.$props.params.section = 'sol'
            }

            this.$store.dispatch('contrattype/getContratTypes').then(() => {
                this.$store.dispatch('baremeremuneration/getBaremesRemuneration').then(() => {
                    this.$store.dispatch('dossieradministratif/getPostesAffectationsSansContrat', this.$props.params)
                })
            })
        }
    }

    contrat_type_select: any = null
    poste_select: any = null

    contrats_types_filter: Array<ContratTypeInterface> = []
    remuneration_select: Record<string, any> = {}
    volume_select: Record<string, any> = {}
    remunerations_ids: Record<string, any> = {}
    remunerations_filter: Array<any> = []
    poste_affectations_filter: Array<PosteInterface> = []
    errorInterface = ''
    Etat = Etat
    getEtatSpec = getEtatSpec
    modeCreation = true
    modePreview = false
    countRemunerations = 0
    showCreation = true
    getEtatSpecContrat = getEtatSpecContrat

    waitCreate = false
    retour_serveur = null

    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''

    btnSaveIsHidden () {
        return !(!this.retour_serveur && this.$store.state.dossieradministratif.postes_affectations_sans_contrat.length !== 0);
    }

    saveIsDisabled = true
    @Watch('poste_select', { deep: true })
    checkSaveIsDisabled() {
        this.saveIsDisabled = true
        let countRemuneration = 0
        let countVolumeSelect = 0
        if ((this.waitCreate) || (this.poste_select && this.contrats_types_filter.length === 0) || (this.poste_select && this.contrat_type_select)) {
            for (const r in this.remunerations_ids) {
                if (this.remunerations_ids[r].length !== 0) {
                    countRemuneration++
                    for (const b in this.remunerations_ids[r]) {
                        if (this.remunerations_ids[r][b] && this.volume_select[r][b]) {
                            countVolumeSelect++
                        }
                    }
                }
            }
        }
        if (countRemuneration > 0 && countRemuneration === countVolumeSelect) {
            this.saveIsDisabled = false
        }
    }

    // Selectionne une remuneration
    selectRemuneration (index: number, bareme_id: number, poste: any) {
        if (this.remuneration_select[poste.id + '_' + poste.entity_id][index]) {
            this.remunerations_ids[poste.id + '_' + poste.entity_id][index] = bareme_id
            this.countRemunerations++
        } else {
            this.remunerations_ids[poste.id + '_' + poste.entity_id].splice(index, 1)
            this.countRemunerations--
        }
        this.checkSaveIsDisabled()
    }

    // Ajoute / Update un contrat à l'intervenant
    saveContrat () {
        this.waitCreate = true
        const remunerations = []
        for (const r in this.remunerations_ids) {
            const splitKey = r.split('_')
            const poste_aff = this.poste_select
            if (this.remunerations_ids[r].length !== 0) {
                for (const b in this.remunerations_ids[r]) {
                    if (this.remunerations_ids[r][b] && this.volume_select[r][b]) {
                        remunerations.push({ poste_id: splitKey[0], bareme_remuneration_id: this.remunerations_ids[r][b], quantite: this.volume_select[r][b], entity_type: poste_aff.entity_type, entity_id: poste_aff.entity_id, options: poste_aff.options_affectes })
                        this.errorInterface = ''
                    } else {
                        this.errorInterface = 'Merci de renseigner un volume pour chacun des barèmes de rémunération sélectionnés'
                    }
                }
            }
        }

        if (this.errorInterface === '') {
            const datas = {
                poste_id: this.poste_select.id,
                poste_entity_type: this.poste_select.entity_type,
                poste_entity_id: this.poste_select.entity_id,
                poste_options_affectes: this.poste_select.options_affectes,
                contrat_type_id: this.contrat_type_select.id,
                bareme_remunerations: remunerations,
                etat: Etat.ETAT_INVISIBLE,
                section: this.$props.mode
            }

            if (this.modeCreation) {
                this.$store.dispatch('dossieradministratif/storeMultiContrats', { payload: datas })
                    .then(() => {
                        const idSucces = 't_succes_' + Math.random()
                        const succesToaster = {
                            id: idSucces,
                            toaster: 'b-toaster-top-right',
                            variant: 'success',
                            noCloseButton: true,
                            fade: true,
                            autoHideDelay: 5000
                        }
                        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                        this.waitCreate = false

                        this.retour_serveur = this.$store.state.dossieradministratif.contrats_create
                        this.$store.dispatch('dossieradministratif/getDossiersAdministratifs', this.$props.params)
                    })
                    .catch(() => {
                        this.waitCreate = false
                        this.errorInterface = this.$store.state.dossieradministratif.error
                    })
            }
        } else {
            this.waitCreate = false
        }
    }

    // reset
    reset (close: boolean) {
        this.$store.state.dossieradministratif.contratSelect = {}
        this.contrat_type_select = null
        this.poste_select = null
        this.remuneration_select = []
        this.remunerations_ids = []
        this.contrats_types_filter = []
        this.poste_affectations_filter = []
        this.volume_select = []
        this.modeCreation = true
        this.modePreview = false
        this.$store.state.dossieradministratif.error = null
        this.waitCreate = false
        this.retour_serveur = null

        if (close) {
            this.$emit('close', true)
        }
    }

    // Retourne le libelle du type de bareme de remuneration
    getLibelleTypeRemuneration (bareme_id: number) {
        return getTypeRemuneration(bareme_id)
    }

    // Retourne le poste affectation formaté
    getStringPoste (poste: any) {
        let retour = ''
        retour += poste.name
        if (poste.options_affectes && poste.options_affectes !== '[]' && poste.options_affectes !== null) {
            const opt = JSON.parse(poste.options_affectes)
            retour += ' - ' + opt[0]
        }
        // Recupere le nom de l'épreuve ou de la matiere
        if (poste.entity_type === EntityType.ET_EPREUVE_CORRECTION) {
            const epr = this.$store.state.epreuve.epreuvesCorrections.filter((epr: EpreuveInterface) => epr.id === poste.entity_id)
            if (epr[0] && epr[0].concours) {
                retour += ' sur ' + epr[0].concours[0].name + ' - ' + epr[0].name
            }
        }

        if (poste.entity_type === EntityType.ET_MATIERE) {
            const matiere = this.$store.state.matiere.matieres.filter((mat: MatiereInterface) => mat.id === poste.entity_id)
            retour += ' de ' + matiere[0].name
        }

        return retour
    }

    // Aperçu du contrat en pdf
    previewContrat (contrat: any) {
        this.source_doc = ''
        this.modePreview = true
        this.$store.dispatch('dossieradministratif/getContrat', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id }).then(() => {
            this.$store.dispatch('dossieradministratif/getContratPDF', { dossier_id: contrat.dossier_administratif_id, contrat_id: contrat.id }).then(() => {
                this.source_doc = base64ToArrayBuffer(this.$store.state.dossieradministratif.source_pdf)
            })
        })
    }

    // Ferme le retour serveur et revient sur la creation
    back () {
        this.$store.dispatch('dossieradministratif/getPostesAffectationsSansContrat', this.$props.params).then(() => {
            this.$store.state.dossieradministratif.contratSelect = {}
            this.contrat_type_select = null
            this.poste_select = null
            this.remuneration_select = []
            this.remunerations_ids = []
            this.contrats_types_filter = []
            this.poste_affectations_filter = []
            this.volume_select = []
            this.modeCreation = true
            this.modePreview = false
            this.$store.state.dossieradministratif.error = null
            this.waitCreate = false
            this.retour_serveur = null
        })
    }

    // Filtres les contrats types selon les postes affectations
    filterContratTypes () {
        this.contrats_types_filter = []
        for (const c in this.$store.state.contrattype.contrattypes) {
            const contrattype = this.$store.state.contrattype.contrattypes[c]
            const contrattypes_filter = contrattype.associations.filter((ass: any) => ass.poste_id === this.poste_select.id)
            for (const ctf in contrattypes_filter) {
                if (contrattypes_filter[ctf]) {
                    // Check si le contrat type n'est pas deja dans la collection
                    const isPush = this.contrats_types_filter.filter((ct: any) => ct.id === contrattype.id)
                    if (isPush.length === 0) {
                        this.contrats_types_filter.push(contrattype)
                    }
                }
            }
        }
    }

    // Création des listes de rémunérations selon les postes affectations
    initRemunerations () {
        if (this.contrat_type_select && this.contrat_type_select.associations) {
            this.poste_affectations_filter = []
            const poste_affectation = this.poste_select
            const option_poste_affectation: any = this.getOptionsPosteAffectation(poste_affectation)
            const match_contrattype_poste = this.contrat_type_select.associations.filter((ass: any) => ass.poste_id === poste_affectation.id)

            if (match_contrattype_poste.length !== 0) {
                this.poste_affectations_filter.push(poste_affectation)
                this.remuneration_select[poste_affectation.id + '_' + poste_affectation.entity_id] = []
                this.remunerations_ids[poste_affectation.id + '_' + poste_affectation.entity_id] = []
                this.volume_select[poste_affectation.id + '_' + poste_affectation.entity_id] = []
                this.remunerations_filter[poste_affectation.id] = []

                // Charge les barèmes de remunerations selon le poste et les options
                this.loadBaremesRemunerations(poste_affectation, option_poste_affectation)

                // Refiltre les baremes selon les épreuves
                this.filtreBaremeByEpreuve(poste_affectation)
            }
        }
    }

    // Retourne les options si le poste affectation en a de sélectionné
    getOptionsPosteAffectation (poste_affectation: any) {
        let option_poste_affectation = null
        if (poste_affectation.options_affectes && poste_affectation.options_affectes !== '[]') {
            const json_options = JSON.parse(poste_affectation.options_affectes)
            const tab_options: Array<any> = []
            for (const o in json_options) {
                tab_options.push(json_options[o])
            }
            option_poste_affectation = JSON.stringify(tab_options)
        }

        return option_poste_affectation
    }

    // Charge les barèmes de remunerations selon le poste et les options
    loadBaremesRemunerations (poste_affectation: any, option_poste_affectation: any) {
        if (this.contrat_type_select && this.contrat_type_select.associations) {
            for (const a in this.contrat_type_select.associations) {
                for (const b in this.$store.state.baremeremuneration.baremesRemuneration) {
                    if (this.$store.state.baremeremuneration.baremesRemuneration[b].associations.length !== 0) {
                        let filter: Array<any> = []
                        if (option_poste_affectation !== null) {
                            filter = this.$store.state.baremeremuneration.baremesRemuneration[b].associations.find((ass: any) => (ass.poste_id === poste_affectation.id) || (ass.poste_id === poste_affectation.id && JSON.stringify(ass.options) === option_poste_affectation))
                        } else {
                            filter = this.$store.state.baremeremuneration.baremesRemuneration[b].associations.find((ass: any) => ass.poste_id === poste_affectation.id && ass.options === null)
                        }
                        if (filter !== undefined) {
                            const isPush = this.remunerations_filter[poste_affectation.id].filter((br: any) => br.id === this.$store.state.baremeremuneration.baremesRemuneration[b].id)
                            if (isPush.length === 0) {
                                this.remunerations_filter[poste_affectation.id].push(this.$store.state.baremeremuneration.baremesRemuneration[b])
                            }
                        }
                    }
                }
            }
        }
    }

    // Filtre la collection des baremes selon les epreuves affectées
    filtreBaremeByEpreuve (poste_affectation: any) {
        const baremes_filter = []
        for (const b in this.remunerations_filter[poste_affectation.id]) {
            let filter = this.remunerations_filter[poste_affectation.id][b].associations.filter((b: any) => (b.entity_type === poste_affectation.entity_type) && (b.entity_id === null || b.entity_id === poste_affectation.entity_id))
            if (filter.length === 0 && poste_affectation.entity_type === EntityType.ET_EPREUVE_CORRECTION && this.$props.mode === 'sps') {
                // On vérifie sur la matiere si un bareme est compatible
                const epreuve = this.$store.state.epreuve.epreuvesCorrections.filter((e: any) => e.id === poste_affectation.entity_id)
                if (epreuve.length !== 0) {
                    filter = this.remunerations_filter[poste_affectation.id][b].associations.filter((b: any) => (b.entity_type === EntityType.ET_MATIERE) && (b.entity_id === epreuve[0].matiere_id || b.entity_id === null))
                }
            } else if (this.$props.mode === 'sol') {
                baremes_filter.push(this.remunerations_filter[poste_affectation.id][b])
            }
            if (filter.length !== 0) {
                baremes_filter.push(this.remunerations_filter[poste_affectation.id][b])
            }
        }
        this.remunerations_filter[poste_affectation.id] = baremes_filter
    }
}

