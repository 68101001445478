


































import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { Ability } from '@/types/Ability'
import { codeRestrict, formatDate, getFileNameFromHeader } from '@/utils/helpers'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        Table,
        ExaGenericTable,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('historiqueNotification', ['loading', 'error', 'historiqueNotifications', 'meta', 'links', 'totalRows', 'lastPage']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class HistoriqueNotifications extends Vue {
    codeRestrict = codeRestrict
    // DATAS
    params = 'sort=name&direction=asc'
    dataForTab: Array<any> = []
    filtres: any = []


    genericfields = [
        { key: 'user_code', label: 'Code', sortable: true, class: 'text-left col-min-width', type: 'text' },
        { key: 'user_name', label: 'Candidat', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'notificationTemplate.champ_descriptif', label: 'Notification', sortable: false, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'created_at', label: 'Date', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' }

    ]


    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    notification: any = null
    notificationTemp: any = null
    Ability = Ability

    showModalEditionNotification = false
    showModalMessageDelete = false
    showModalValideNotification = false

    // getTypeNotificationSpec = getTypeNotificationSpec
    // TypeNotification = TypeNotification




    // ---- generic table --------------------------

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const line = [
                    { label: '', item: result.user.code, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.user.name + ' ' + result.user.first_name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.notificationTemplate.name, type: 'text', typeAction: null, class: 'text-left' },
                    { label: '', item: formatDate(result.created_at), type: 'text', typeAction: null, class: 'text-left' }
                ]


                this.dataForTab.push(line)
            }
        }
        return this.dataForTab
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        /* const types_notification = [
            { index: TypeNotification.TYPE_PREAFFECTATION, name: getTypeNotificationSpec(TypeNotification.TYPE_PREAFFECTATION).libelle },
            { index: TypeNotification.TYPE_VOEUX, name: getTypeNotificationSpec(TypeNotification.TYPE_VOEUX).libelle }
        ] */
        this.filtres = [
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'user_code',
                value: '',
                index: 'user_code',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'user_code', strict: false } // 'form' , 'deroulant'
            },
            {
                libelle: 'Nom',
                defautOptionlibelle: 'Rechercher un',
                model: 'user_name',
                value: '',
                index: 'user_name',
                datas: null,
                loading: false,
                options: { type: 'form', fieldsKey: 'user_name' } // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':

                    break
                case 'openComment':

                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                default:
                    break
            }
        }
    }

    // --------------------------------------

    exportingIsWorking =  false
    exportHistoriqueNotification () {
        this.exportingIsWorking = true
        let fileName = 'export_historique_notification.xlsx'

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('historiqueNotification/export')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('historiqueNotification/getMoreHistoriqueNotifications', params).then(() => {
                this.setDataForGenericTab(this.$store.state.historiqueNotification.historiqueNotifications)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    // Appelle une page lors du scroll
    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('historiqueNotification/getHistoriqueNotifications', params).then(() => {
                this.setDataForGenericTab(this.$store.state.historiqueNotification.historiqueNotifications)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }



    mounted () {
        this.setFiltersForGenericTab()
    }
}
