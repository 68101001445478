


















































import { Vue, Component } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import SaisieSalles from '@/components/Centre/Salles/Ecrits/SaisieSalles.vue'
import SaisieIntervenants from '@/components/Centre/Salles/Ecrits/SaisieIntervenants.vue'
import PopupSubmitEcrit from '@/components/Centre/Validations/PopupSubmitSalles.vue'
import { Ability } from '@/types/Ability'
import { formatDate } from '@/utils/helpers'
import MessageGeneralIntervenant from '@/components/MessageGeneralIntervenant.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        SaisieSalles,
        SaisieIntervenants,
        PopupSubmitEcrit,
        MessageGeneralIntervenant,
        ErrorDisplay
    },
    computed: {
        ...mapState('centre', ['centreSelect', 'error', 'loading']),
        ...mapGetters('centre', ['informations_is_submit', 'informations_is_validate', 'orga_is_submit', 'orga_is_validate', 'etat_centre_select']),
        ...mapGetters('organisationecrit', ['has_salle_amenagee', 'has_salle_isolee']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class OrganisationEcrit extends Vue {
    Ability = Ability
    showPopupSubmitEcrit = false

    /** Ouvre le popup de confirmation de soumission de l'organisation écrite */
    submitEcrit () {
        this.showPopupSubmitEcrit = true
    }

    /** Sauvegarde des deux collections : salles et intervenants */
    save () {
        const idSucces = 't_succes_' + Math.random()
        const succesToaster = {
            id: idSucces,
            toaster: 'b-toaster-top-right',
            variant: 'success',
            noCloseButton: true,
            fade: true,
            autoHideDelay: 5000
        }
        this.$bvToast.toast('Enregistrement terminé !', succesToaster)
    }

    /** Formattage les dates */
    formatteDate (date: Date) {
        return formatDate(date)
    }

    /** Reset showPopups */
    resetPopup () {
        this.showPopupSubmitEcrit = false
    }

    /** Retourne une classe css selon l'état de validation des infos */
    getClassBarreBoutons () {
        let class_css = 'barre_default'

        if (this.$store.getters['centre/orga_is_submit'] && !this.$store.getters['centre/orga_is_validate']) {
            class_css = 'barre_soumis'
        } else if (this.$store.getters['centre/orga_is_submit'] && this.$store.getters['centre/orga_is_validate']) {
            class_css = 'barre_valide'
        } else if (this.$store.getters['centre/has_commentaire_rejet_ecrit']) {
            class_css = 'barre_rejet'
        }

        return class_css
    }

    beforeMount () {
        this.$store.dispatch('banque/getBanques', { isPrecedente: false }).then(() => {
            this.$store.dispatch('centre/getCentre', { centre_id: 1 })
        })
    }
}
