
















import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapMutations } from 'vuex'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        FontAwesomeIcon
    },
    methods: {
        ...mapMutations('dossieracademique', ['UPDATE_DATAS_AUTRE_CONCOURS_FONCTION'])
    }
})
export default class SelectFonctionAutreConcours extends Vue {
    @Prop() selectedValue?: string
    @Prop() readOnly?: boolean
    @Prop() required?: boolean

    options = [
        { id: 'Correcteur', value: 'Correcteur' },
        { id: 'Examinateur', value: 'Examinateur' }
    ]

    // Event change sur le select
    updateDatasPro (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLTextAreaElement
            this.$store.commit('dossieracademique/UPDATE_DATAS_AUTRE_CONCOURS_FONCTION', target.value)
        }
    }
}
