
















import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        FontAwesomeIcon
    },
    methods: {
        ...mapMutations('dossieracademique', ['UPDATE_DATAS_ACADEMIE'])
    }
})
export default class SelectAcademie extends Vue {
    @Prop() selectedValue?: string
    @Prop() readOnly?: boolean

    options = [
        { id: 'Aix-Marseille', value: 'Aix-Marseille' },
        { id: 'Amiens', value: 'Amiens' },
        { id: 'Besançon', value: 'Besançon' },
        { id: 'Bordeaux', value: 'Bordeaux' },
        { id: 'Caen', value: 'Caen' },
        { id: 'Clermont-Ferrand', value: 'Clermont-Ferrand' },
        { id: 'Corse', value: 'Corse' },
        { id: 'Créteil', value: 'Créteil' },
        { id: 'Dijon', value: 'Dijon' },
        { id: 'Grenoble', value: 'Grenoble' },
        { id: 'Guadeloupe', value: 'Guadeloupe' },
        { id: 'Guyane', value: 'Guyane' },
        { id: 'La Réunion', value: 'La Réunion' },
        { id: 'Lille', value: 'Lille' },
        { id: 'Limoges', value: 'Limoges' },
        { id: 'Lyon', value: 'Lyon' },
        { id: 'Martinique', value: 'Martinique' },
        { id: 'Mayotte', value: 'Mayotte' },
        { id: 'Montpellier', value: 'Montpellier' },
        { id: 'Nancy-Metz', value: 'Nancy-Metz' },
        { id: 'Nantes', value: 'Nantes' },
        { id: 'Nice', value: 'Nice' },
        { id: 'Nouvelle Calédonie', value: 'Nouvelle Calédonie' },
        { id: 'Orléans-Tours', value: 'Orléans-Tours' },
        { id: 'Paris', value: 'Paris' },
        { id: 'Poitiers', value: 'Poitiers' },
        { id: 'Polynésie Française', value: 'Polynésie Française' },
        { id: 'Reims', value: 'Reims' },
        { id: 'Rennes', value: 'Rennes' },
        { id: 'Rouen', value: 'Rouen' },
        { id: 'Saint Pierre et Miquelon', value: 'Saint Pierre et Miquelon' },
        { id: 'Strasbourg', value: 'Strasbourg' },
        { id: 'Toulouse', value: 'Toulouse' },
        { id: 'Versailles', value: 'Versailles' },
        { id: 'Wallis et Futuna', value: 'Wallis et Futuna' },
        { id: 'Autre', value: 'Autre' }
    ]

    // Event change sur le select
    updateDatasPro (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLTextAreaElement
            this.$store.commit('dossieracademique/UPDATE_DATAS_ACADEMIE', target.value)
        }
    }
}
