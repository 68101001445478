
















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import PopupEditCommentCandidat from '@/components/Candidat/PopupEditCommentCandidat.vue'
import PopupImportCandidat from '@/components/Candidat/PopupImportCandidat.vue'
import { BModal, BPopover } from 'bootstrap-vue'
import { ConcourInterface } from '@/types/Concour'
import { CandidatInterface } from '@/types/Candidat'
import { DemandeAmenagement, TypeMesure } from '@/types/Amenagement'
import { checkIcone, dateDuJourFoDocument, format_phone_number, getFileNameFromHeader } from '@/utils/helpers'
import PhoneInput from '@/components/Tools/PhoneInput.vue'
import { isEmpty, orderBy } from 'lodash'
import PopupShowHistoriqueInscriptions from '@/components/Candidat/PopupShowHistoriqueInscriptions.vue'
import PopupInscrireCandidats from '@/components/Candidat/PopupInscrireCandidats.vue'
import { DecisionAmenagementInterface, EtatDecisionAmenagement } from '@/types/DecisionAmenagement'

/**
 * Composant contenant l'ensemble des candidats
 */
@Component({
    computed: {
        ...mapGetters('candidat', [
            'candidats',
            'loading',
            'totalRows',
            'lastPage',
            'totalPage',
            'meta',
            'candidats_avec_commentaires_importants'
        ]),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        PopupInscrireCandidats,
        PopupShowHistoriqueInscriptions,
        ExaGenericTable,
        PopupEditCandidat,
        PopupEditCommentCandidat,
        PopupImportCandidat,
        PhoneInput,
        'font-awesome-icon': FontAwesomeIcon,
        BPopover,
        BModal
    }
})
export default class CandidatsInscriptions extends Vue {
    candidatsAssoc: CandidatInterface[] = [] // Données candidats affichées dans le tableau
    cptGlobal?: number = 0 // Nombre total de candidats
    compteurs_concours: Array<any> = []
    tabSelected = ''
    showModalEditionCandidat?: boolean = false
    showModalEditionCommentCandidat?: boolean = false
    showModalImportCandidat?: boolean = false
    showModalHistoriqueTransfert = false
    showModalInscrireCandidat = false
    Ability = Ability
    totalCandidatsFiltered = 0 // pour l'affichage du total réel contenu dans le tableau des candidats affiché (filtrés et non filtrés)

    // Ensemble des colonnes du tableau de candidats

    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'commentaires', label: '', sortable: false, class: '', type: 'action' },
        { key: 'code', label: 'Code', sortable: true, class: '', type: 'text' },
        { key: 'name', label: 'Nom', sortable: true, class: '', type: 'text' },
        { key: 'first_name', label: 'Prénom', sortable: true, class: '', type: 'text' },
        { key: 'concours.name', label: 'Concours', sortable: false, class: 'text-center', type: 'text' },
        { key: 'filiere', label: 'Filière', sortable: true, class: 'text-center', type: 'text' },
        {
            key: 'demande_amenagement',
            label: 'Demande aménagements',
            sortable: true,
            class: 'text-center',
            type: 'icons'
        },
        { key: 'mesures', label: 'Mesures', sortable: true, class: 'text-center', type: 'icons' },
        { key: 'email', label: 'Courriel', sortable: true, class: '', type: 'text' },
        { key: 'portable', label: 'Tél. portable', sortable: true, class: '', type: 'text' }
    ]

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    filtres: any = []
    dataForTab: Array<any> = []

    @Watch('candidats')
    initDatasCand() {
        this.initDatas()
    }

    @Watch('meta')
    majInfosTable() {
        // On récupère le nombre total de candidats filrés depuis les infos des Metadonnées
        if (this.$store.state.candidat.meta !== null) {
            if (this.totalCandidatsFiltered !== this.$store.state.candidat.meta.total) {
                this.totalCandidatsFiltered = this.$store.state.candidat.meta.total
            }
        } else {
            this.totalCandidatsFiltered = 0
        }
    }

    @Watch('user_session_id')
    refreshInterface() {
        this.load()
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        const can = this.$store.getters['auth/can'](Ability.CAND_MANAGE)
        const icone = checkIcone(Ability.CAND_MANAGE, can)
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                let styleButtonComment = 'text-tertiary commons_comment_button text-center'
                let nameIcon = 'comment-alt'
                if (result.comments.length !== 0) {
                    styleButtonComment = 'commons_comment_button text-center'

                    if (result.comments.filter((c: any) => c.important === 1).length === 0) {
                        nameIcon = 'comment-alt'
                    } else {
                        nameIcon = 'comment-alt-exclamation'
                    }
                }
                let tooltip_comments = ''

                for (const c in result.comments) {
                    tooltip_comments += result.comments[c].important
                        ? '---------------TACHE A FAIRE---------------\n'
                        : '---------------COMMENTAIRE---------------\n'
                    tooltip_comments += result.comments[c].body
                    tooltip_comments += '\n'
                }

                let libelle_type = ''
                const iconDemandeAm: any[] = []
                let hasMesureEcrit = false
                let hasMesureOral = false

                if (result.amenagements) {
                    for (const amenagement of result.amenagements) {
                        if (amenagement && amenagement.type) {
                            switch (amenagement.type) {
                                case TypeMesure.TYPE_ECRIT:
                                    hasMesureEcrit = true
                                    break
                                case TypeMesure.TYPE_ORAL:
                                    hasMesureOral = true
                                    break
                                case TypeMesure.TYPE_ECRIT_ET_ORAL:
                                    hasMesureEcrit = true
                                    hasMesureOral = true
                                    break
                                default:
                                    break
                            }
                        }
                    }
                }

                if (hasMesureEcrit === true && hasMesureOral === false) {
                    libelle_type = 'ECRIT'
                }

                if (hasMesureOral === true && hasMesureEcrit === false) {
                    libelle_type = 'ORAL'
                }

                if (hasMesureOral === true && hasMesureEcrit === true) {
                    libelle_type = 'ECRIT / ORAL'
                }

                let titleDemandeAmenagement = ''
                let decision_ecrit: DecisionAmenagementInterface = {} as DecisionAmenagementInterface
                let decision_oral: DecisionAmenagementInterface = {} as DecisionAmenagementInterface

                // Récupération de la décision d'aménagement
                if (!isEmpty(result.decisionAmenagements)) {
                    const index_ecrit = result.decisionAmenagements.findIndex(
                        (d: DecisionAmenagementInterface) => d.type === TypeMesure.TYPE_ECRIT
                    )
                    const index_oral = result.decisionAmenagements.findIndex(
                        (d: DecisionAmenagementInterface) => d.type === TypeMesure.TYPE_ORAL
                    )
                    decision_ecrit = result.decisionAmenagements[index_ecrit]
                    decision_oral = result.decisionAmenagements[index_oral]
                }

                if (result.demande_amenagement) {
                    let state: { title: string; class: string } = { title: 'Non traitée', class: 'text-secondary' }

                    if (!isEmpty(decision_oral) && decision_oral.etat === EtatDecisionAmenagement.ETAT_VALIDE) {
                        state = { title: "Demande d'aménagements ORAL - Traitée", class: 'text-success' }
                    }
                    if (!isEmpty(decision_ecrit) && decision_ecrit.etat === EtatDecisionAmenagement.ETAT_VALIDE) {
                        state = { title: "Demande d'aménagements ECRIT - Traitée", class: 'text-success' }
                    }
                    iconDemandeAm.push({
                        name: 'check',
                        class: state.class,
                        title: state.title
                    })
                    titleDemandeAmenagement = state.title
                }

                // Inscriptions concours
                const concours_result = result?.concours
                    ?.map((concour: any) => concour.name)
                    ?.join(', ') || ''

                const line = [
                    {
                        label: icone.label,
                        item: result.id,
                        type: 'action',
                        typeAction: 'edit',
                        class: 'commons_first_action_button btn_action_ligne',
                        icon: icone.icon,
                        disabled: false
                    },
                    {
                        label: tooltip_comments,
                        item: result.id,
                        type: 'action',
                        typeAction: 'openComment',
                        class: styleButtonComment,
                        icon: nameIcon,
                        disabled: false
                    },
                    { label: '', item: result.code, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.first_name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: concours_result, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: result.filiere, type: 'text', typeAction: null, class: 'text-center' },
                    {
                        label: '',
                        item: iconDemandeAm,
                        type: 'icons',
                        typeAction: null,
                        class: 'text-center',
                        title: titleDemandeAmenagement
                    },
                    {
                        label: '',
                        item: libelle_type,
                        type: 'text',
                        typeAction: null,
                        class: 'text-center icone_oral_ecrit'
                    },
                    { label: '', item: result.email, type: 'text', typeAction: null, class: '' },
                    {
                        label: '',
                        item: format_phone_number(result.portable),
                        type: 'text',
                        typeAction: null,
                        class: ''
                    }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        this.filtres = []
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        const etats_demande_amenagement = []
        etats_demande_amenagement.push({
            index: DemandeAmenagement.DEMANDE_AMENAGEMENT_NON_HANDI,
            name: 'Pas de demande'
        })
        etats_demande_amenagement.push({
            index: DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI,
            name: 'demande non traitée'
        })
        etats_demande_amenagement.push({
            index: DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ECRIT,
            name: 'demande traitée - ECRIT'
        })
        etats_demande_amenagement.push({
            index: DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ORAL,
            name: 'demande traitée - ORAL'
        })

        // Options commentaire
        const etat_comments: any = []
        etat_comments.push({ index: 0, name: 'Commentaires' })
        etat_comments.push({ index: 1, name: 'Tâches à faire' })
        etat_comments.push({ index: 2, name: 'Alerte' })

        this.filtres = [
            {
                libelle: 'type de commentaire',
                defautOptionlibelle: 'Rechercher par',
                model: 'commentaires',
                value: '',
                index: 'commentaires',
                datas: etat_comments,
                loading: this.$store.state.candidat.loading,
                options: { type: 'deroulant', fieldsKey: 'commentaires' }
            },
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'code',
                value: '',
                index: 'code',
                datas: this.$store.state.candidat.code,
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'code', strict: true }
            },
            {
                libelle: 'Nom',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: this.$store.state.candidat.name,
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'name' }
            },
            {
                libelle: 'Prénom',
                defautOptionlibelle: 'Rechercher une',
                model: 'first_name',
                value: '',
                index: 'first_name',
                datas: this.$store.state.candidat.first_name,
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'first_name' }
            },
            {
                libelle: 'Concours',
                defautOptionlibelle: 'Rechercher un',
                model: 'concours.code',
                value: '',
                index: 'concours.code',
                datas: this.$store.state.candidat.concour_name,
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'concours.code' }
            },
            {
                libelle: 'Filière',
                defautOptionlibelle: 'Rechercher une',
                model: 'concour_id',
                value: '',
                index: 'filiere',
                datas: options_filieres,
                loading: this.$store.state.candidat.loading,
                options: { type: 'deroulant', fieldsKey: 'filiere' }
            },
            {
                libelle: "État de Demande d'aménagement",
                defautOptionlibelle: 'Rechercher par ',
                model: 'demande_amenagement',
                value: '',
                index: 'demande_amenagement',
                datas: etats_demande_amenagement,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'demande_amenagement' }
            }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const candidats = this.$store.state.candidat.candidats
            let selectedCandidat = null

            switch (paParams[0]) {
                case 'edit':
                    // Récupération de l'étab by ID
                    selectedCandidat = candidats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.editCandidat(selectedCandidat)
                    }
                    break

                case 'openComment':
                    // Récupération de l'étab by ID
                    this.tabSelected = 'commentaires'
                    selectedCandidat = candidats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.editCandidat(selectedCandidat)
                    }
                    break

                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                default:
                    break
            }
        }
    }

    /**
     * Récupération sur le serveur des séries de la session
     */
    loadSeriesSessionActiveIfNotExists() {
        return new Promise((resolve) => {
            // on récupère systématiquement les séries de la session active
            this.$store.dispatch('serie/getSeries').then(() => {
                resolve(true)
            })
        })
    }

    /**
     * Récupération sur le serveur des 100 premiers candidats s'ils ne sont pas déja
     * présents dans le state
     */
    loadCandidatIfNotExists() {
        return new Promise((resolve) => {
            if (this.$store.getters['candidat/candidats'].length === 0) {
                this.$store.dispatch('candidat/getCandidats', { sort: 'name', direction: 'asc' }).then(() => {
                    resolve(true)
                })
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Récupération sur le serveur des concours
     */
    loadConcoursIfNotExists() {
        return new Promise((resolve) => {
            this.$store.dispatch('concour/getConcoursActifs').then(() => {
                resolve(true)
            })
        })
    }

    /**
     * Récupération sur le serveur des cmesures d'handicap
     */
    loadMesuresIfNotExists() {
        return new Promise((resolve) => {
            if (this.$store.getters['amenagement/amenagements'].length === 0) {
                this.$store.dispatch('amenagement/getAmenagements')
                resolve(true)
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Intitialisation des statistiques concours (nombre de candidats par concours)
     */
    initStatsConcours() {
        const concours: ConcourInterface[] = orderBy(this.$store.getters['concour/banques'], 'ordre')
        this.compteurs_concours = []
        this.cptGlobal = 0

        if (concours && concours.length > 0) {
            for (const concour of concours) {
                this.cptGlobal += concour.nb_candidats
                this.compteurs_concours.push({ libelle: concour.name, cpt: concour.nb_candidats })
            }
        }
    }

    /**
     * Initialisation des données candidats affichées dans le tableau ainsi que les compteurs
     */
    initDatas() {
        this.candidatsAssoc = this.$store.state.candidat.candidats
        for (const candidat of this.candidatsAssoc) {
            this.updateDatasFiliere(candidat)
        }
        this.setDataForGenericTab(this.candidatsAssoc)
        if (this.filtres.length === 0) {
            // this.setFiltersForGenericTab()
        }
    }

    updateDatasFiliere(candidat: CandidatInterface) {
        if (candidat && candidat.concour_id && !candidat.filiere) {
            const concour_found = this.$store.state.concour.concours.find((c: any) => c.id === candidat.concour_id)
            if (concour_found) {
                const params = {
                    candidatId: candidat.id,
                    nomFiliere: concour_found.name
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
            }
        }
    }

    /**
     * Appel d'une nouvelle page de candidats lors du scroll
     */
    loadHandler(params: any) {
        // console.log(JSON.stringify(params))
        this.$store.dispatch('candidat/getMoreCandidats', params)
    }

    /**
     * Appel des datas avec un sort en paramètres
     */
    filtreSortHandler(params: any) {
        this.$store.dispatch('candidat/getCandidats', params)
    }

    /**
     * Edition d'un candidat: enregistrement du candidat en tant que candidat sélectionnée
     * et affichage de la modale
     */
    editCandidat(item: any) {
        if (item && item.id !== undefined) {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.dispatch('candidat/getCandidat', item).then(() => {
                const params = {
                    candidatId: item.id,
                    nomFiliere: item.filiere
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                this.showModalEditionCandidat = true
            })
        }
    }

    /**
     * Edition des commentaires associés à un candidat
     */
    openCommentaireCandidat(item: any) {
        if (item && item.id !== undefined) {
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
            this.showModalEditionCommentCandidat = true
        }
    }

    /**
     * Export de la liste des candidats
     */
    exportBilan() {
        let fileName = `export_candidats_${dateDuJourFoDocument()}.xlsx`
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours de création...', infosToaster)

        this.$store.dispatch('candidat/exportCandidatsConcours')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Export de la liste des candidats inscrits
     */
    exportCandidats() {
        let fileName = `export_candidats_inscrits_${dateDuJourFoDocument()}.xlsx`
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours de création...', infosToaster)

        this.$store.dispatch('candidat/exportCandidatsInscrits')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Import de candidats ou de dossier de handicaps
     */
    openImportCandidat() {
        this.showModalImportCandidat = true
    }

    /**
     * Fermeture de la modale d'édition candidat
     */
    reinitShowModalCandidat() {
        this.showModalEditionCandidat = false
    }

    /**
     * Fermeture de la modale d'import de candidats
     */
    reinitShowModalImportCandidat() {
        this.showModalImportCandidat = false
    }

    /**
     * Fermeture de la modale d'édition des commentaires candidats
     */
    reinitShowModalCommentCandidat(candidatSelected: CandidatInterface) {
        // On met à jour dans le tableau des candidats les informations relatives
        // aux commentaires du candidat selectionné
        if (candidatSelected) {
            const indexTab = this.dataForTab.findIndex(
                (itemTab: any) => itemTab.length > 0 && itemTab[0].item === candidatSelected.id
            )
            if (indexTab !== -1 && this.dataForTab[indexTab] && this.dataForTab[indexTab].length > 1) {
                // On met à jour le bouton de commentaires en fonction des mises à jour effectuée
                // sur les commentaires du candidat
                let styleButtonComment = 'text-tertiary commons_comment_button text-center'
                let nameIcon = 'comment-alt'
                if (candidatSelected.hasComments === true) {
                    styleButtonComment = 'commons_comment_button text-center'
                    if (candidatSelected.hasImportantComments === true) {
                        nameIcon = 'comment-alt'
                    }
                }

                this.dataForTab[indexTab][1].class = styleButtonComment
                this.dataForTab[indexTab][1].icon = nameIcon
            }
        }

        this.showModalEditionCommentCandidat = false
    }

    load() {
        // Récupération des séries de la session active
        this.loadSeriesSessionActiveIfNotExists().then(() => {
            // Si aucun concours n'as été chargé, alors on les récupère également
            this.loadConcoursIfNotExists().then(() => {
                // Chargement des mesures d'handicap
                this.loadMesuresIfNotExists().then(() => {
                    this.initDatas()
                    this.initStatsConcours()
                    this.setFiltersForGenericTab()
                })
            })
        })
    }

    /**
     * Au montage du composant, on charge les candidats et les concours si cela est nécessaire
     */
    beforeMount() {
        this.load()
    }

    /**
     * checkConcourListWidth
     * @description Vérifie la largeur de la liste des concours
     * @return {void}
     */
    @Watch('compteurs_concours', { deep: true })
    checkConcourListWidth(): void {
        this.$nextTick().then(() => {
            const element: HTMLElement = document.getElementById('candidat_inscription_infos_labels') as HTMLElement
            if (element) {
                const widths: Array<number> = []
                const gap = 32

                element.children[element.children.length - 1].classList.remove('d-none')
                const static_elements = element.children[0].clientWidth + element.children[element.children.length - 1].clientWidth + gap * 2
                element.children[element.children.length - 1].classList.add('d-none')
                element.style.minWidth = static_elements + 'px'

                for (let i = 1; i < element.children.length - 1; i++) {
                    element.children[i].classList.remove('d-none')
                    widths.push(element.children[i].clientWidth + gap)

                    if (widths.reduce((a, b) => a + b, 0) + static_elements > element.clientWidth) {
                        element.children[i].classList.add('d-none')
                        element.children[element.children.length - 1].classList.remove('d-none')
                    }
                }
            }
        })
    }

    /**
     * mounted
     * @description Appelé lorsque le composant est monté
     * @return {void}
     */
    mounted(): void {
        window.addEventListener('resize', this.checkConcourListWidth)
    }

    /**
     * beforeDestroy
     * @description Appelé avant que le composant soit détruit
     * @return {void}
     */
    beforeDestroy(): void {
        window.removeEventListener('resize', this.checkConcourListWidth)
    }
}
