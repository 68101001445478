













































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import { Etat } from '@/types/DossierAdministratif'
import { mapGetters } from 'vuex'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { FontAwesomeIcon }              from '@fortawesome/vue-fontawesome'

@Component({
    components: {
        ItemIntervenant,
        ErrorDisplay,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('dossieradministratif', ['error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class FormSituationStaturaire extends Vue {
    Ability = Ability
    @Prop() dossierAdministratifSelect?: any
    @Prop() readOnly?: boolean
    @Prop() mode?: string
    @Prop() visible?: boolean

    @Watch('visible')
    visibleHandler () {
        if (this.visible) {
            this.activeEdition()
        } else {
            this.cancelEdition()
        }
    }

    was_validated = false
    etat_vierge = Etat.ETAT_VIERGE
    etat_depose = Etat.ETAT_DEPOSE
    list_situations = [
        { name: 'Fonctionnaire en activité', selected: false },
        { name: 'Fonctionnaire retraité', selected: false },
        { name: 'Non fonctionnaire en activité ou retraité', selected: false },
        { name: 'Inscrit au Pôle Emploi', selected: false },
        { name: 'Autre', selected: false }
    ]

    list_cotisations = [
        { name: 'AGIRC-ARRCO', selected: false },
        { name: 'CNRACL', selected: false },
        { name: 'IRCANTEC', selected: false },
        { name: 'RAEP', selected: false },
        { name: 'RAFP', selected: false },
        { name: 'Autre (à préciser)', selected: false, value: '' }
    ]

    Etat = Etat
    showMessageRejet = false
    messageRejet = ''
    editResponsable = false
    oldDatasSituation: any = {}

    @Watch('dossierAdministratifSelect')
    setSituation () {
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation.situations.length !== 0) {
            this.list_situations = this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation.situations
        }
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation.cotisations.length !== 0) {
            this.list_cotisations = this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation.cotisations
        }
    }

    // Sélection d'une situation
    selectSituation (index: number, selected: boolean) {
        if (!this.readOnly || this.editResponsable) {
            for (const s in this.list_situations) {
                if (s === index.toString()) {
                    // Sélectionne ou déselectionne la situation choisie
                    this.list_situations[s].selected = selected
                } else {
                    this.list_situations[s].selected = false
                }
            }
            // Actualise la liste des situations
            this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation.situations = this.list_situations
        }
    }

    // Sélection d'une cotisation
    selectCotisation (index: number, selected: boolean) {
        if (!this.readOnly || this.editResponsable) {
            for (const c in this.list_cotisations) {
                if (c === index.toString()) {
                    // Sélectionne ou déselectionne la cotisation choisie
                    this.list_cotisations[c].selected = !selected
                } else {
                    this.list_cotisations[c].selected = false
                }
            }
            // Actualise la liste des situations
            this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation.cotisations = this.list_cotisations
        }
    }

    // Valide ou rejete le formulaire
    updateEtatForm (etat: Etat) {
        let params
        if (etat === Etat.ETAT_REJETE) {
            params = { etat_form_situation: etat, commentaire_rejet_cle: 'datas_situation', commentaire_rejet_message: this.messageRejet }
        } else {
            params = { etat_form_situation: etat }
        }

        this.$store.dispatch('dossieradministratif/updateDossierAdministratif', { dossier_id: this.$props.dossierAdministratifSelect.id, payload: params }).then(() => {
            this.showMessageRejet = false
        })
    }

    // Enregistre les datas
    submitSituationStatuaire (e: Event) {
        e.preventDefault()
        const ds_true = this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation.situations.find((ds: any) => ds.selected === true)
        const dc_true = this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation.cotisations.find((dc: any) => dc.selected === true)

        if (ds_true !== undefined && dc_true !== undefined) {
            const payload = {
                datas_situation: JSON.stringify(this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation)
            }
            const idInfo = 't_info_' + Math.random()
            const infosToaster = {
                id: idInfo,
                toaster: 'b-toaster-top-right',
                variant: 'primary',
                noCloseButton: true,
                fade: true,
                noAutoHide: true
            }
            this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
            this.$store.dispatch('dossieradministratif/updateDossierAdministratif', { dossier_id: this.$store.state.dossieradministratif.dossierAdministratifSelect.id, payload: payload })
                .then(() => {
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                    if (this.$props.mode === 'responsable') {
                        this.$emit('edition_form_situation_statuaire', false)
                        this.activeEdition()
                        this.cancelEdition()
                        this.editResponsable = false
                    } else {
                        // Fermeture de la popup aprés validation
                        const closeSituationStatuaire: HTMLElement = document.getElementsByClassName('closeSituationStatuaire')[0] as HTMLElement
                        this.activeEdition()
                        this.cancelEdition()
                        closeSituationStatuaire.click()
                        this.editResponsable = false
                    }
                })
                .finally(() => {
                    this.$bvToast.hide(idInfo)
                })
        } else {
            this.$store.state.dossieradministratif.error = 'Merci de sélectionner un statut et un organisme de cotisation.'
        }
    }

    // Ouvre une zone de saisie d'un message de rejet
    addMessageRejet () {
        this.showMessageRejet = true
    }

    // Ferme la zone de message de rejet
    cancelMessageRejet () {
        this.showMessageRejet = false
    }

    // Activation de l'édition pour le chef d'établissement
    activeEdition () {
        this.$store.state.dossieradministratif.error = null
        const test = JSON.stringify(this.dossierAdministratifSelect.datas_situation)
        this.oldDatasSituation = JSON.parse(test)
        if (this.mode === 'responsable') {
            this.editResponsable = true
        }
        this.$emit('edition_form_situation_statuaire', true)
        this.$store.state.dossieradministratif.error = null
    }

    // Annule l'édition en cours du formulaire
    cancelEdition () {
        this.$store.state.dossieradministratif.error = null
        this.dossierAdministratifSelect.datas_situation = this.oldDatasSituation
        this.editResponsable = false
        this.$emit('edition_form_situation_statuaire', false)
        this.$store.state.dossieradministratif.error = null
    }

    mounted () {
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation.situations.length !== 0) {
            this.list_situations = this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation.situations
        }
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation.cotisations.length !== 0) {
            this.list_cotisations = this.$store.state.dossieradministratif.dossierAdministratifSelect.datas_situation.cotisations
        }
    }
}
