























































































































































































import { Vue } from 'vue-property-decorator'

export default class PolitiqueViePrivee extends Vue {
    site = process.env.VUE_APP_URL
    proprietaire_name = process.env.VUE_APP_RGPD_PROPRIETAIRE_NAME
    proprietaire_address = process.env.VUE_APP_RGPD_PROPRIETAIRE_ADDRESS
    droit_email = process.env.VUE_APP_RGPD_DROIT_EMAIL
    dpo_email = process.env.VUE_APP_RGPD_DPO_EMAIL
}
