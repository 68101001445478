






















































import { mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { PosteContext, PosteType } from '@/types/Poste'
import store from '@/store'

@Component({
    computed: {
        ...mapGetters('dossieradministratif', ['error', 'loading']),
        ...mapState('dossieradministratif', ['error', 'loading'])
    // ...mapMutations('dossieradministratif', ['SET_DOSSIER_ADMINISTRATIF_CURRENT', 'SET_BULLETINS', 'MAJ_PRESENCE_BULLETINS'])
    }
})
export default class ImportQuantitesReelles extends Vue {
    @Prop() show?: boolean
    @Prop() mode?: string

    contrat_select: any = null
    formData = new FormData()
    activeBtnAjout = false
    file_select = false
    fileData: any
    retour_serveur = null
    error_aff = ''
    ListingErreurs: Array<any> = []
    contrats_combo: any = []
    bulletins_list: any = []
    confirmDeleteBulletin = false
    document_select = null
    modePreview = false
    errorInterface = ''
    fileInputKey = 0

    // reset
    reset (close: boolean) {
        this.$store.state.dossieradministratif.error = null
        this.error_aff = ''
        this.ListingErreurs = []
        this.activeBtnAjout = false
        this.file_select = false
        this.fileInputKey++ // La modification de la key va faire réséter l'input
        this.errorInterface = ''
        if (close) {
            this.$emit('close', true)
        }
    }

    // Select le fichier d'import
    selectFile (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]

            this.formData.set('document', file) // Pas d'append ici, on ne poste qu'un seul fichier à la fois
            this.file_select = true

            this.activeBtnAjout = this.file_select;
        } else {
            // console.log('e.target === null')
        }
    }

    // importer le fichier de quantités réelles
    importerFichierQuantitesReelles () {
        this.$store.dispatch('dossieradministratif/uploadQuantity', { payload: this.formData })
            .then(() => {
                store.dispatch('dossieradministratif/getDossiersAdministratifs').then(() => {
                    store.dispatch('poste/getPostes', { type: PosteType.TYPE_PEDAGOGIQUE, context: PosteContext.CONTEXT_AFFECTATION }).then(() => {
                        store.dispatch('matiere/getMatieres')
                    })
                })

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Importation des quantités réelles terminée !', succesToaster)

                // this.activeBtnAjout = false
                this.reset(true)
            })
            .catch((error) => {
                const nrbErreurs = Object.keys(this.$store.state.dossieradministratif.error).length

                if (nrbErreurs > 1) {
                    this.error_aff = "Des erreurs se sont produites lors de l'importation du fichier : " + error.response.data.message
                } else {
                    this.error_aff = "Une erreur s'est produite lors de l'importation du fichier : " + error.response.data.message
                }

                this.ListingErreurs = []
                let cpt = 0
                for (const key of Object.keys(this.$store.state.dossieradministratif.error)) {
                    this.ListingErreurs.push(key + ' : ' + this.$store.state.dossieradministratif.error[key])
                    cpt++
                    if (cpt === 10 && nrbErreurs > 10) {
                        this.ListingErreurs.push('...')
                        this.ListingErreurs.push("L'importation a recensé plus de 10 erreurs.")
                        break
                    }
                }
            })
    }
}
