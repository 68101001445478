

























































































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import store from '@/store/index'
import { mapActions, mapGetters, mapState } from 'vuex'
import AssistantValidationDossier from '@/views/Dossiers/AssistantValidationDossier.vue'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { Decision, DecisionIntervenant, Etat, getDecisionSpec, getEtatForFilterSelect, getEtatSpec } from '@/types/DossierAcademique'
import { EntityType, PosteContext, PosteType } from '@/types/Poste'
import PopupDecision from '@/components/SelectionRH/PopupDecision.vue'
import PopupAddDossier from '@/components/DossierAcademique/PopupAddDossier.vue'
import { formatDate, base64ToArrayBuffer, checkIcone } from '@/utils/helpers'
import CommentaireIntervenant from '@/components/SelectionRH/CommentaireIntervenant.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import Back from '@/components/Tools/Back.vue'
import _ from 'lodash'
import { EpreuveInterface } from '@/types/Epreuve'

@Component({
    components: {
        ExaGenericTable,
        AssistantValidationDossier,
        PopupDecision,
        PopupAddDossier,
        CommentaireIntervenant,
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp,
        ErrorDisplay,
        PopUpInfoIntervenant,
        Back
    },
    computed: {
        ...mapState('matiere', ['matiereSelect']),
        ...mapState('session', ['sessionSelect']),
        ...mapState('pv', ['pvSelect']),
        ...mapState('dossieracademique', ['dossierSelectionSelect', 'meta', 'error', 'maj_dsall']),
        ...mapGetters('dossieracademique', ['loading', 'dossiersSelectionsAll', 'totalRows', 'totalPage', 'lastPage', 'error', 'pdfLettre']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        epreuvesCorrectionsSorted() {
            const array = [];
            const epreuves = this.$store.getters['epreuve/epreuvesCorrections']
                .filter((epreuve: EpreuveInterface) => epreuve.session_id === this.$store.getters['auth/user_session_id'])
                .filter((epreuve: EpreuveInterface) => epreuve.matiere_id === parseInt(this.$route.params.matiere_id));
            for (const epreuve of epreuves) {
                array.push({
                    id: epreuve.id,
                    name: `${epreuve.concours[0].code} - ${epreuve.name}`
                });
            }

            // Sort array by name
            array.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }

                if (a.name > b.name) {
                    return 1;
                }

                return 0;
            });

            return array;
        }
    },
    methods: {
        ...mapActions('matiere', ['getMatiere']),
        ...mapActions('session', ['getSession']),
        ...mapActions('pv', ['getPv']),
        ...mapActions('dossieracademique', ['previewLettre', 'envoiLettreStatut', 'envoiLettreIntervenant', 'transfertStatut', 'transfertIntervenant'])
    }
})
export default class AffectationDossiersAcademiques extends Vue {
    @Prop() session_id?: number
    @Prop() matiere_id?: number
    @Prop() pv_id?: number

    Ability = Ability
    dataForTab: any = []


    showModalInfoIntervenant = false
    user_id_temp = 0

    @Watch('pvSelect')
    pvSelectLoaded () {
        if (this.$store.state.pv.pvSelect !== null && this.$store.state.session.sessionSelect !== null) {
            this.loadDossiersSelections()
        }
    }

    @Watch('user_session_id')
    refreshInterface () {
        const link = '/affectations'
        this.$router.push(link)
    }

    @Watch('dossiersSelectionsAll')
    checkDossiersSelections () {
        // Check la présence ou non des postes et les rechargent si besoin
        if (this.$store.state.poste.postes.length === 0) {
            this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_PEDAGOGIQUE, context: PosteContext.CONTEXT_AFFECTATION }).then(() => {
                this.$store.dispatch('pv/getPvs', { isPrecedente: false, filters: null, postes: this.$store.state.poste.postes })
            })
        }

        if (this.$store.state.dossieracademique.dossiersSelectionsAll.length !== 0) {
            for (const d in this.$store.state.dossieracademique.dossiersSelectionsAll) {
                this.setEpreuveCorrectionPostesCourant(this.$store.state.dossieracademique.dossiersSelectionsAll[d])
            }

            // On récupère le nombre total de dossiers depuis le dossiersSelectionsAll
            this.totalDossiers = this.$store.state.dossieracademique.dossiersSelectionsAll.length

            this.count_decision_non_retenu = 0
            this.count_decision_retenu = 0
            this.count_decision_suspension = 0
            this.count_decision_ajourne = 0
            this.count_decision_non_reconduit = 0
            this.count_decision_demission = 0
            this.dispachByStatut()
        }
        this.setFiltersForGenericTab()
        const dossiers_all = _.orderBy(this.$store.state.dossieracademique.dossiersSelectionsAll, 'user.name', 'asc')
        this.setDataForGenericTab(dossiers_all)
    }

    @Watch('maj_dsall')
    maj_epreuve_dsall () {
        if (this.$store.state.dossieracademique.dossiersSelectionsAll.length !== 0 && this.$store.state.dossieracademique.maj_dsall) {
            for (const d in this.$store.state.dossieracademique.dossiersSelectionsAll) {
                this.setEpreuveCorrectionPostesCourant(this.$store.state.dossieracademique.dossiersSelectionsAll[d])
            }

            // On récupère le nombre total de dossiers depuis le dossiersSelectionsAll
            this.totalDossiers = this.$store.state.dossieracademique.dossiersSelectionsAll.length
        }
    }

    showDecision = false
    showDossierIntervenant = false
    showSelectionDecision = false

    showModalMessageCommentaire = false
    currentRow: any = null
    commentaireTemp = ''

    retour_serveur = null
    count_decision_non_retenu = 0
    count_decision_retenu = 0
    count_decision_suspension = 0
    count_decision_ajourne = 0
    count_decision_non_reconduit = 0
    count_decision_demission = 0
    epreuve_decision_envois = null
    decision_envois = null
    showPreviewLettre = false
    showEnvoiLettreIntervenant = false
    showTransfertStatut = false
    intervenant_select: any = {}
    new_candidature = false
    rowSelect = null
    showTransfertIntervenant = false
    totalDossiers = 0

    allSend = false
    allTransfert = false

    decision_aucune = Decision.DECISION_AUCUNE
    decision_retenu = Decision.DECISION_RETENU
    decision_suspendu = Decision.DECISION_SUSPENDU
    decision_non_reconduit = Decision.DECISION_NON_RECONDUIT
    decision_demission = Decision.DECISION_DEMISSION
    decision_non_retenu = Decision.DECISION_NON_RETENU
    decision_ajourne = Decision.DECISION_AJOURNE

    filtres: Array<any> = []

    user_select: any = {}
    showCommentaireIntervenant = false
    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''
    enumEtats: Array<any> = []

    // DATAS
    fields = [
        {
            key: 'edit',
            label: '',
            sortable: true
        },
        { key: 'etat',         label: 'État',   sortable: true,  class: 'text-center', type: 'text' },
        {
            key: 'usercomment',
            label: '',
            sortable: false,
            sortDirection: 'asc',
            class: 'text-start ps-4 w-2'
        },
        // { key: 'user.name', label: 'Nom', sortable: true, sortDirection: 'asc', class: 'text-start' },
        // { key: 'user.first_name', label: 'Prénom', sortable: true, sortDirection: 'asc', class: 'text-start' },
        { key: 'user.name', label: 'Identité', sortable: true, sortDirection: 'asc', class: 'text-start ps-4',  type: 'actionText'  },
        { key: 'courant.annee_premiere_candidature', label: 'Première candidature', sortable: true, sortDirection: 'asc', class: 'text-center' },
        {
            key: 'postes_selection_courant',
            label: 'Sélection',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start'

        },
        {
            key: 'courant.emailed_at',
            label: 'Lettre envoyée',
            sortable: true,
            sortByFormatted: true,
            class: 'text-center '
        },
        {
            key: 'disponibilite_affectation',
            label: 'Poste accepté',
            sortable: true,
            sortDirection: 'asc',
            sortByFormatted: true,
            class: 'text-center '
        },
        {
            key: 'postes_affectation_courant',
            label: 'Affectation',
            sortable: true,
            sortDirection: 'asc',
            class: 'text-start'
        },
        { key: 'courant.dossier_administratif_id', label: 'Transfert', sortable: true, sortDirection: 'asc', class: 'text-center' },
        {
            key: 'classement',
            label: 'Classement',
            sortable: true,
            sortByFormatted: true,
            class: 'text-center'
        }
    ]

    showMessageConfirmCancelLettre = false

    // Ouvre la visualisation du dossier courant
    openValidator (row: any) {
        this.$store.dispatch('dossieracademique/getDossierAcademique', row.courant.id)
        this.$store.commit('dossieracademique/SET_DOSSIER_SELECTION_CURRENT', row)
        this.$store.commit('dossieracademique/SET_VIEW_DOSSIER', true)
    }

    // Ouvre le popup pour saisir un commentaire de lettre
    commentaireLettre (row: any) {
        this.showModalMessageCommentaire = true
        this.currentRow = row
        this.commentaireTemp = row.item.courant.commentaire_lettre
    }

    // Save du commentaire de lettre
    commentaireLettreSuite () {
        const dossier_id = this.currentRow.item.courant.id
        const params = {
            commentaire_lettre: this.commentaireTemp.trim().length > 0 ? this.commentaireTemp.trim() : '',
            set_commentaire_lettre: 1
        }
        if (dossier_id !== 0) {
            this.$store.dispatch('dossieracademique/updateDossierAcademique', { dossier_id: dossier_id, payload: params })
            this.currentRow.item.courant.commentaire_lettre = this.commentaireTemp.trim()
        }
        this.showModalMessageCommentaire = false
        this.currentRow = null
        this.commentaireTemp = ''
    }

    // Fermeture du popup du commentaire de lettre
    commentaireLettreAnnuler () {
        this.$store.state.dossieracademique.error = null
        this.showModalMessageCommentaire = false
        this.currentRow = null
        this.commentaireTemp = ''
    }

    // set le nom des concours d'une épreuve
    getConcoursName (concours: any) {
        return concours?.map((concour: any) => concour.name)
            ?.join(', ') || ''
    }

    // Envoyer les lettres
    envoiLettre () {
        if (this.$store.state.pv.pvSelect.validated_at) {
            this.$store.state.dossieracademique.error = null
            const params = {
                session_id: this.$props.session_id,
                matiere_id: this.$props.matiere_id,
                payload: {
                    epreuve_decision_selection: this.epreuve_decision_envois,
                    decision_selections: [this.decision_envois]
                }
            }
            this.$store.dispatch('dossieracademique/envoiLettreStatut', params)
                .then(() => {
                    this.$store.dispatch('dossieracademique/getDossiersSelections', {
                        session_id: this.$props.session_id,
                        matiere_id: this.$props.matiere_id
                    })
                    this.showSelectionDecision = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 3000
                    }
                    this.$bvToast.toast('Envoi terminé !', succesToaster)
                })
        }
    }

    // Transférer les dossiers académiques vers dossiers administratifs
    transfererDossiers () {
        const tab_decisions = []
        this.$store.state.dossieracademique.error = null
        tab_decisions.push(this.decision_retenu)
        this.$store.dispatch('dossieracademique/transfertStatut', { session_id: this.$props.session_id, matiere_id: this.$props.matiere_id, payload: { decision_affectations: tab_decisions } })
            .then(async (response) => {
                this.retour_serveur = response.data.data.length
                await this.$store.dispatch('dossieracademique/getDossiersSelections', { session_id: this.$props.session_id, matiere_id: this.$props.matiere_id })

                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 3000
                }
                this.$bvToast.toast('Transfert terminé !', succesToaster)
            })
    }

    // Transfère le dossier d'un intervenant en particulier
    transfererDossierIntervenant () {
        this.$store.commit('dossieracademique/MAJ_DSALL', false)
        this.$store.state.dossieracademique.error = null
        if(!this.intervenant_select.courant.dossier_administratif_id) {
            this.$store.dispatch('dossieracademique/transfertIntervenant', { dossierId: this.intervenant_select.courant.id })
                .then(() => {
                    this.showTransfertIntervenant = false
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 3000
                    }
                    this.$bvToast.toast('Transfert terminé !', succesToaster)
                })
        } else {
            this.$store.dispatch('dossieracademique/deleteTransfertDossierAcademique', { dossierId: this.intervenant_select.courant.id })
                .then(() => {
                    this.showTransfertIntervenant = false
                    this.intervenant_select.courant.dossier_administratif_id = null
                    this.checkDossiersSelections()
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 3000
                    }
                    this.$bvToast.toast('Transfert terminé !', succesToaster)
                })
        }
    }

    // Ouvre le modal de selection des décisions
    selectionDecisionOpen () {
        this.decision_envois = null
        this.showPreviewLettre = false
        this.showSelectionDecision = true
    }

    // Ferme la modal de selection des decisions
    selectionDecisionCancel () {
        this.showSelectionDecision = false
        this.decision_envois = null
        this.showPreviewLettre = false
    }

    // Dispatch par statut
    dispachByStatut () {
        this.allSend = false
        this.$store.state.dossieracademique.dossiersSelectionsAll.forEach((dossier: any) => {
            if (dossier.courant) {
                // Check si les lettres sont en cours d'envoi ou envoyée
                if (!dossier.courant.emailed_at) {
                    switch (dossier.courant.decision_selection) {
                        case Decision.DECISION_RETENU:
                            this.count_decision_retenu++
                            break
                        case Decision.DECISION_NON_RETENU:
                            this.count_decision_non_retenu++
                            break
                        case Decision.DECISION_SUSPENDU:
                            this.count_decision_suspension++
                            break
                        case Decision.DECISION_AJOURNE:
                            this.count_decision_ajourne++
                            break
                        case Decision.DECISION_NON_RECONDUIT:
                            this.count_decision_non_reconduit++
                            break
                        case Decision.DECISION_DEMISSION:
                            this.count_decision_demission++
                            break
                    }
                }
            }
        })

        // Check s'il reste des lettres ou si tout est envoyé
        if (this.count_decision_non_retenu === 0 && this.count_decision_retenu === 0 && this.count_decision_suspension === 0 && this.count_decision_ajourne === 0 && this.count_decision_non_reconduit === 0 && this.count_decision_demission === 0) {
            this.allSend = true
        }
    }

    // Open preview lettre du statut selectionné
    openPreview (decision: Decision) {
        // On prend le premier id de la selection pour afficher le preview
        const dossiers_filter = this.$store.state.dossieracademique.dossiersSelectionsAll.filter((dossier: any) => dossier.courant.decision_selection === decision)
        this.$store.dispatch('dossieracademique/previewLettre', { dossierId: dossiers_filter[0].courant.id })
            .then(() => {
                this.source_doc = base64ToArrayBuffer(this.$store.state.dossieracademique.pdfLettre)
                this.showPreviewLettre = true
            })
    }

    // Ouvre lea popup d'envoi de lettre pour un intervenant en particulier
    openEnvoiLettreIntervenant (row: any) {
        this.intervenant_select = row
        // Chargement preview pdf de la lettre
        this.$store.dispatch('dossieracademique/previewLettre', { dossierId: row.courant.id })
            .then(() => {
                this.source_doc = base64ToArrayBuffer(this.$store.state.dossieracademique.pdfLettre)
                this.showEnvoiLettreIntervenant = true
            })
    }

    // Envoi de lettre pour un intervenant en particulier
    envoiLettreIntervenant () {
        // Cabler envoi lettre pour un intervenant
        this.$store.commit('dossieracademique/MAJ_DSALL', false)
        this.$store.state.dossieracademique.error = null
        this.$store.dispatch('dossieracademique/envoiLettreIntervenant', { dossierId: this.intervenant_select.courant.id })
            .then(() => {
                this.showEnvoiLettreIntervenant = false
                this.$store.state.dossieracademique.error = null
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 3000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
            })
    }

    // Ferme la popup d'envoi de lettre pour un intervenant
    envoiLettreIntervenantCancel () {
        this.$store.state.dossieracademique.error = null
        this.showEnvoiLettreIntervenant = false
    }

    // Charge les listes des dossiers selections
    loadDossiersSelections () {
        // Epreuves corrections session en cours
        this.$store.dispatch('epreuve/getEpreuvesCorrections', { isPrecedente: false })

        // Epreuves corrections session precedente
        this.$store.dispatch('epreuve/getEpreuvesCorrections', { isPrecedente: true, session_replace: this.$store.state.session.sessionSelect.session_id })

        // this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_PEDAGOGIQUE, context: PosteContext.CONTEXT_SELECTION })
        this.$store.dispatch('dossieracademique/getDossiersSelections', { session_id: this.$props.session_id, matiere_id: this.$props.matiere_id, new_candidature: this.$props.new_candidature }).then(() => {
            // Check les dossiers pour envoie des lettres
            this.count_decision_non_retenu = 0
            this.count_decision_retenu = 0
            this.count_decision_suspension = 0
            this.count_decision_ajourne = 0
            this.count_decision_non_reconduit = 0
            this.count_decision_demission = 0
            this.dispachByStatut()
        })
    }

    // Set les noms d'épreuves corrections pour les postes -- session couranre
    setEpreuveCorrectionPostesCourant (dossierSelection: any) {
        if (dossierSelection.postes_affectation_courant.length !== 0) {
            const postes_affectation_courant = dossierSelection.postes_affectation_courant
            for (const p in postes_affectation_courant) {
                const epreuve = this.$store.state.epreuve.epreuvesCorrections.find((e: any) => e.id === postes_affectation_courant[p].entity_id)
                dossierSelection.postes_affectation_courant[p].epreuve_name = epreuve !== undefined ? this.getConcoursName(epreuve.concours) + '-' + epreuve.name : ''
            }
        }
        if (dossierSelection.postes_selection_courant.length !== 0) {
            const postes_selection_courant = dossierSelection.postes_selection_courant
            for (const p in postes_selection_courant) {
                const epreuve = this.$store.state.epreuve.epreuvesCorrections.find((e: any) => e.id === postes_selection_courant[p].entity_id)
                dossierSelection.postes_selection_courant[p].epreuve_name = epreuve !== undefined ? this.getConcoursName(epreuve.concours) + '-' + epreuve.name : ''
            }
        }
    }

    // Ouverture popup decision
    openDecision (row: any) {
        this.showDecision = true
        this.rowSelect = row
        this.new_candidature = false

        // Determine si c'est une nouvelle candidature pour lister les statuts
        if (row.precedent !== null) {
            if (row.precedent.decision_selection === this.decision_ajourne || row.precedent.decision_selection === this.decision_suspendu) {
                this.new_candidature = true
            }
        } else {
            this.new_candidature = true
        }
    }

    // Fermeture popup decision
    closeDecision () {
        this.$store.state.dossieracademique.error = null
        this.showDecision = false
    }

    // Popup de confirmation de transferts
    confirmationTransfertOpen () {
        this.showTransfertStatut = true
        this.count_decision_retenu = 0
        this.$store.state.dossieracademique.error = null

        this.$store.state.dossieracademique.dossiersSelectionsAll.forEach((dossier: any) => {
            if (dossier.courant) {
                // Check si les lettres sont en cours d'envoi ou envoyée
                if (!dossier.courant.dossier_administratif_id && dossier.courant.decision_affectation === Decision.DECISION_RETENU) {
                    this.count_decision_retenu++
                }
            }
        })

        // Check s'il reste des lettres ou si tout est envoyé
        if (this.count_decision_retenu === 0) {
            this.allTransfert = true
        }
    }

    // Fermeture de la popup de transfert
    confirmationTransfertCancel () {
        this.$store.state.dossieracademique.error = null
        this.showTransfertStatut = false
        this.showTransfertIntervenant = false
    }

    // Ouvre le popup de confirmation de transfert pour un intervenant en particulier
    transfererIntervenantOpen (row: any) {

        // ----
        this.$store.state.dossieracademique.error = null
        this.intervenant_select = row

        // on ouvre le popup uniquement si le statut d'affectation est retenu
        if (row.courant.decision_affectation === this.decision_retenu) {
            this.showTransfertIntervenant = true
        }
    }

    // Ouvre le popup de classement
    openCommentaireIntervenant (row: any) {
        this.showCommentaireIntervenant = true
        this.user_select = row.user
    }

    // Ferme la popup de commentaire intervenant
    closeCommentaireIntervenant (retour = null) {
        if (retour != null) {
            if (this.user_select.comment) {
                this.user_select.comment.body = retour
            } else {
                this.user_select.comment = {
                    body: retour
                }
            }
            this.$store.dispatch('dossieracademique/getDossiersSelections', { session_id: this.$store.state.session.sessionSelect.id, matiere_id: this.$store.state.matiere.matiereSelect.id })
        }
        this.showCommentaireIntervenant = false
        this.user_select = {}
        this.$store.state.dossieracademique.error = null
        // this.$store.dispatch('dossieracademique/getDossiersSelections', { session_id: this.$store.state.session.sessionSelect.id, matiere_id: this.$store.state.matiere.matiereSelect.id })
    }

    mounted () {
        this.$store.dispatch('matiere/getMatiere', { matiere_id: this.$route.params.matiere_id }).then(() => {
            store.dispatch('pv/getPv', this.$route.params.pv_id).then(() => {
                this.setFiltersForGenericTab()
                // this.getPv(response)
            })
        })
        this.dispachByStatut()

        // Chargement épreuves all selections
        if (this.$store.state.dossieracademique.dossiersSelectionsAll.length !== 0) {
            for (const d in this.$store.state.dossieracademique.dossiersSelectionsAll) {
                this.setEpreuveCorrectionPostesCourant(this.$store.state.dossieracademique.dossiersSelectionsAll[d])
            }
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab()
    {
        const etats = this.getEnumEtats()
        const options_etats = []
        for (const e in etats) {
            options_etats.push({ index: etats[e].id, name: etats[e].name })
        }

        this.filtres = [
            {
                libelle: 'Nom',
                defautOptionlibelle: 'Rechercher un',
                model: 'user.name',
                value: '',
                datas: '',
                loading: this.$store.state.dossieracademique.loading,
                options: { type: 'form', fieldsKey: 'user.name' } // 'form' , 'deroulant'
            },
            // { libelle: 'Nom', defautOptionlibelle: 'Rechercher un', model: 'user.name', value: '', index: 'user.name',  datas: '', loading: this.$store.state.dossieracademique.loading, options: { type: 'form', fieldsKey: 'user.name', strict: true } },
            { libelle: 'Etat', defautOptionlibelle: 'Rechercher un', model: 'etat', value: '', index: 'etat',  datas: options_etats, loading: this.$store.state.dossieracademique.loading, options: { type: 'deroulant', fieldsKey: 'etat' } }
        ]
    }

    // Récupère une énum des états de poste possible
    getEnumEtats () {
        this.enumEtats = []
        for (const etatName in Etat) {
            if (isNaN(Number(etatName))) {
                this.enumEtats.push({ id: getEtatForFilterSelect(etatName).index, name: getEtatForFilterSelect(etatName).libelle })
            }
        }
        return this.enumEtats
    }


    /* Zone nouveau tableau */

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            let data: any = this.$store.state.dossieracademique.dossiersSelectionsAll
            switch (paParams[0])
            {
                case 'edit':
                    this.openValidator(paParams[1])
                    break
                case 'transfert':
                    this.transfererIntervenantOpen(paParams[1])
                    break
                case 'classement':
                    // this.filtreSortHandler(paParams[1])
                    break
                case 'sortHandler':
                    data = _.orderBy(data, paParams[1].sort, paParams[1].direction)
                    this.setDataForGenericTab(data)
                    break
                case 'filterHandler':
                    if (paParams[1]['filter-etat']) {
                        data = data.filter((d: any) => d.etat.toString() === paParams[1]['filter-etat'])
                    }
                    if (paParams[1]['filter-user.name']) {
                        // data = data.filter((d: any) => d.user.name === paParams[1]['filter-user.name'])
                        data = data.filter((d: any) => d.user.name.toLowerCase() === paParams[1]['filter-user.name'].replace(/%/g, '').toLowerCase())
                    }
                    data = _.orderBy(data, paParams[1].sort, paParams[1].direction)
                    this.setDataForGenericTab(data)
                    break
                case 'affectation_selection':
                    this.openDecision(paParams[1])
                    break
                case 'openComment':
                    this.openCommentaireIntervenant(paParams[1])
                    break
                case 'envoiLettre':
                    this.openEnvoiLettreIntervenant(paParams[1])
                    break
                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    setDataForGenericTab(poData: any, isLoadMore = false) {
        const can = this.$store.getters['auth/can'](Ability.RH_SPS_MANAGE)
        const droit = checkIcone(Ability.RH_SPS_MANAGE, can)
        const pvSelect  = this.$store.state.pv.pvSelect
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const puce_etat = []

                if (result) {
                    switch (true) {
                        case result.etat !== Etat.ETAT_INVISIBLE:
                            puce_etat.push({ name:'circle', class: 'text-' + getEtatSpec(result.etat).color, title: getEtatSpec(result.etat).libelle })
                            break
                        case (result.validated_at === null || result.etat === Etat.ETAT_INVISIBLE):
                            puce_etat.push({ name:'circle', class: 'text-tertiary', title: 'Non initialisé' })
                            break
                    }
                }
                const name                  = result.user && result.user.name ? result.user.name : '-'
                const first_name            = result.user && result.user.first_name ? result.user.first_name : '-'

                const premiereCandidature   = result.courant && result.courant.annee_premiere_candidature ? result.courant.annee_premiere_candidature : '-'

                const selectionSpec       = result.courant ? getDecisionSpec(result.courant.decision_selection) : null
                const selection           = result.postes_selection_courant && result.postes_selection_courant.length > 0 ? this.selectionFormatter(result.postes_selection_courant) : (selectionSpec ? selectionSpec.libelle : '-')

                const validation            = result.courant ? this.getPuceColorValidation(result.courant.decision_intervenant_affectation) : null

                const affectationSpec       = result.courant ? getDecisionSpec(result.courant.decision_affectation) : null
                const affectation           = result.postes_affectation_courant && result.postes_affectation_courant.length > 0 ? this.selectionFormatter(result.postes_affectation_courant) : (affectationSpec ? affectationSpec.libelle : '-')

                const transfert: any             = result.courant ? this.getTransfertColor(result.courant) : null
                const classement: any            = result.courant ? this.getClassementColor(result.courant) : null

                const labelEmail = this.mailFormatter(result.courant)

                let class_affectation = affectationSpec && affectationSpec.index !== Decision.DECISION_RETENU ? 'text-start max_width_column item_actif_clic_danger ' : 'text-start max_width_column  item_actif_clic '
                if (pvSelect && !pvSelect.validated_at) {
                    class_affectation = 'text-start max_width_column'
                }

                const line = [
                    {
                        label: droit.label,
                        item: result,
                        type: 'action',
                        typeAction: 'edit',
                        class: 'commons_first_action_button btn_action_ligne',
                        icon:droit.icon,
                        disabled: false
                    },
                    { label: '', item: puce_etat,  type: 'icons', typeAction: null, class: 'text-center' },
                    {
                        label: 'Commentaire',
                        item: result,
                        type: 'action',
                        typeAction: 'openComment',
                        class: result && result.user && result.user.comment && result.user.comment.body ? 'text-primary' : 'text-tertiary',
                        icon:'comment-alt',
                        disabled: !this.$store.getters['auth/can'](Ability.RH_SPS_MANAGE)
                    },
                    {
                        label: '',
                        item: result.user.id,
                        type: 'actionText',
                        typeAction: 'infoIntervenant',
                        class: 'text-info item_action',
                        text: name + ' ' + first_name
                    },
                    {
                        label: 'Première candidature',
                        item: premiereCandidature,
                        type: 'text',
                        typeAction: null,
                        class: 'text-center'
                    },
                    {
                        label: 'Sélection',
                        item: selection,
                        type: 'html',
                        typeAction: null,
                        class: selectionSpec && selectionSpec.index === Decision.DECISION_AUCUNE ? 'text-danger' : ''
                    },
                    {
                        label: labelEmail.label,
                        item: result,
                        type: 'action',
                        typeAction: 'envoiLettre',
                        class: labelEmail.class + ' text-center ',
                        icon: 'envelope',
                        disabled: labelEmail.disabled
                    },

                    {
                        label: '',
                        item: validation,
                        type: 'icons',
                        icon: 'circle',
                        typeAction: null,
                        class: 'text-center'
                    },
                    {
                        label: 'Affectation',
                        item:  result,
                        type: 'actionText',
                        text: affectation !== '' ? affectation : '-',
                        typeAction:  'affectation_selection',
                        class: class_affectation,
                        disabled: pvSelect && !pvSelect.validated_at
                    },
                    // transfert
                    {
                        label: '',
                        item: result.courant && result.courant.decision_affectation === Decision.DECISION_RETENU ?  result : '',
                        type: result.courant && result.courant.decision_affectation === Decision.DECISION_RETENU ? 'action' : 'text',
                        icon: 'exchange-alt',
                        typeAction: 'transfert',
                        class: transfert ? transfert[0].class : 'text-secondary',
                        disabled: transfert && can ? transfert[0].disabled : true
                    },
                    {
                        label: droit.label,
                        item: result.courant && !result.courant.classement ? classement : result.classement,
                        type: result.courant && !result.courant.classement ? 'action' : 'text',
                        typeAction: 'classement',
                        class: result.courant && !result.courant.classement ? 'text-secondary text-center' : 'text-center text-info',
                        icon:result.courant && !result.courant.classement ? 'tag' : '',
                        disabled: !this.$store.getters['auth/can'](Ability.RH_SPS_MANAGE)
                    }


                ]
                this.dataForTab.push(line)
            }
        }
    }

    /** Formatte les informations de decisions pour un affichage en html  */
    selectionFormatter (value: any) {
        let retour = ''
        for (let i = 0; i < value.length; i++) {
            if (i > 0) {
                retour += '<br>'
            }
            if (value[i].entity_type === EntityType.ET_EPREUVE_CORRECTION) {
                if (value[i].options_affectes !== null) {
                    const opts = JSON.parse(value[i].options_affectes)
                    if (opts.length !== 0) {
                        retour += value[i].name + ' - ' + opts[0] + ' sur ' + value[i].epreuve_name
                    } else {
                        retour += value[i].name + ' sur ' + value[i].epreuve_name
                    }
                } else {
                    retour += value[i].name + ' sur ' + value[i].epreuve_name
                }
            } else if (value[i].entity_type === EntityType.ET_MATIERE) {
                retour += value[i].name + ' de ' + value[i].matieres[0].name
            }
        }
        return retour
    }

    /** prend l'objet courant et renvois le bon affichage de l'icône mail dans le generic table */
    mailFormatter (courant: any): { label: string; class: string; disabled: boolean } {
        if (courant && courant.emailed_at && courant.validated_at) {
            return { label: 'Lettre envoyée le ' + formatDate(courant.emailed_at), class: 'text-success', disabled: true }
        } else if (courant && courant.emailed_at && !courant.validated_at) {
            return { label: 'Envoi en attente de signature', class: 'text-primary', disabled: false }
        } else if (courant && courant.decision_selection !== Decision.DECISION_AUCUNE) {
            return { label: 'Lettre non envoyée', class: 'text-secondary', disabled: false }
        } else {
            return { label: 'Lettre non envoyée', class: 'text-secondary', disabled: false }
        }
    }

    /** Puce de decision */
    getPuceColorValidation(dispo_affectation: any) {
        const result = []

        switch (true) {
            case dispo_affectation === DecisionIntervenant.DECISION_INTERVENANT_MASQUE :
                result.push({ name:'circle', class:'text-light', title:'' })
                break
            case dispo_affectation === DecisionIntervenant.DECISION_INTERVENANT_ATTENTE :
                result.push({ name:'circle', class:'text-secondary', title:'En attente de réponse' })
                break
            case dispo_affectation === DecisionIntervenant.DECISION_INTERVENANT_OUI :
                result.push({ name:'circle', class:'text-success', title:'Poste accepté' })
                break
            case dispo_affectation === DecisionIntervenant.DECISION_INTERVENANT_NON :
                result.push({ name:'circle', class:'text-danger', title:'Poste refusé' })
                break
            default:
                result.push({ name:'circle', class:'text-light', title:'' })
                break
        }
        return result
    }

    getTransfertColor(courant: { dossier_administratif_id: null; decision_affectation: Decision }) {
        const result = []

        if (courant && courant.dossier_administratif_id !== null && courant.decision_affectation === Decision.DECISION_RETENU) {
            result.push({ name:'exchange-alt', class:'text-primary', title:'Annuler le tranfert', disabled: false })
        } else if (courant && courant.dossier_administratif_id === null && courant.decision_affectation === Decision.DECISION_RETENU) {
            result.push({ name:'exchange-alt', class:'text-secondary', title:'' })
        } else {
            result.push({ name:'exchange-alt', class:'text-secondary', title:'' })
        }
        return result
    }

    getClassementColor(courant: { classement: any }) {
        const result = []
        if (!courant.classement) {
            result.push({ name:'tag', class:'text-secondary', title:'', type: 'text' })
        } else {
            result.push({ name:'', class:'text-secondary', title:'', type: 'action', classement: courant.classement })
        }
        return result
    }

    // Affiche le message de confirmation d'annulation de signature d'une lettre d'engagement
    cancelEnvoiLettreIntervenant () {
        this.$store.state.dossieracademique.error = null
        this.showMessageConfirmCancelLettre = true
    }

    // Annulation du message de confirmation d'annulation de signature d'une lettre d'engagement
    cancelCancelSignature () {
        this.$store.state.dossieracademique.error = null
        this.showMessageConfirmCancelLettre = false
    }

    // Confirme l'annulation de signature d'une lettre d'engagement
    confirmCancelSignature () {
        this.$store.commit('dossieracademique/MAJ_DSALL', false)
        this.$store.state.dossieracademique.error = null
        this.$store.dispatch('dossieracademique/cancelEnvoiLettreIntervenant', { dossierId: this.intervenant_select.courant.id })
            .then(() => {
                this.showMessageConfirmCancelLettre = false
                this.showEnvoiLettreIntervenant = false
                this.$store.state.dossieracademique.error = null
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 3000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
            })
    }
}
