































import { Vue, Component } from 'vue-property-decorator'
import Centres from '@/views/Sol/Centres.vue'
import CentresEcrits from '@/views/Sol/CentresEcrits.vue'
import CentresOraux from '@/views/Sol/CentresOraux.vue'

@Component({
    components: {
        Centres,
        CentresEcrits,
        CentresOraux
    }
})

export default class GestionCentres extends Vue {
    selectedTap = ''
    route_actuelle = ''

    beforeMount () {
        this.route_actuelle = this.$route.path

        if (this.route_actuelle === '/centres_oraux') {
            this.selectedTap = 'CentresOraux'
        } else if (this.route_actuelle === '/centres_ecrits') {
            this.selectedTap = 'CentresEcrits'
        }
    }
}
