



































































































































































































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { CandidatInterface } from '@/types/Candidat'
import { TypeEnsemble } from '@/types/Ensemble'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditCandidat from '@/components/Candidat/PopupEditCandidat.vue'
import PopupEditCommentCandidat from '@/components/Candidat/PopupEditCommentCandidat.vue'
import PopupImportFichiersOrgaEcrits from '@/components/Candidat/PopupImportFichiersOrgaEcrits.vue'
import PopupRepartitionEquipes from '@/components/Candidat/PopupRepartitionEquipes.vue'
import { SerieInterface } from '@/types/Serie'
import { DemandeAmenagement, TypeMesure } from '@/types/Amenagement'
import EditCommentFromDashboard from '@/components/Candidat/EditCommentFromDashboard.vue'
import { Ability } from '@/types/Ability'
import { checkIcone, formatDate, getFileNameFromHeader, numberWithSpaces } from '@/utils/helpers'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { getEnumTypeAmenagement, getTypeAmenagement, TypeAmenagement } from '@/types/Salle'
import { DecisionAmenagementInterface, EtatDecisionAmenagement } from '@/types/DecisionAmenagement'
import { TypePassation } from '@/types/Epreuve'

@Component({
    computed: {
        ...mapGetters('candidat', ['candidats', 'loading', 'totalRows', 'lastPage', 'totalPage', 'meta', 'error']),
        ...mapGetters('epreuve', ['epreuves']),
        ...mapState('serie', ['series']),
        ...mapGetters('serie', ['series']),
        ...mapGetters('sujet', ['previsionnelSujets']),
        ...mapGetters('concour', ['banques', 'concourById']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapState('planification', ['error_planification'])
    },
    components: {
        ExaGenericTable,
        PopupEditCandidat,
        PopupEditCommentCandidat,
        'font-awesome-icon': FontAwesomeIcon,
        PopupImportFichiersOrgaEcrits,
        EditCommentFromDashboard,
        PopupRepartitionEquipes,
        ErrorDisplay
    }
})
export default class CandidatsTabEcrits extends Vue {
    candidatsAssoc: any[] = [] // Données candidats affichées dans le tableau

    genericfields: Array<any> = [
        /*
        { key: 'etatEdit',                  label: '',                              sortable: false,    class: '',                          type: 'action' },
        // { key: 'commentaires',              label: '',                              sortable: true,     class: '',                          type: 'action' },
        { key: 'code',                      label: 'Code',                          sortable: true,     class: 'text-start col-min-width',  type: 'text' },
        { key: 'name',                      label: 'Identité',                           sortable: true,     class: 'text-start col-min-width',  type: 'text' },
        // { key: 'first_name',                label: 'Prénom',                        sortable: true,     class: 'text-start col-min-width',  type: 'text' },
        { key: 'filiere',                   label: 'Filière',                       sortable: true,     class: 'text-center col-min-width', type: 'text' },

        { key: 'demande_amenagement',       label: 'Demande aménagements',          sortable: true,     class: 'text-center col-min-width', type: 'icons' },
        { key: 'dd', label: 'Domaine', sortable: false,  class: 'text-center bg-gray-100', type: 'text', doubleHeaderLabel: 'test Domaine', doubleHeaderColSpan: 5, doubleHeaderClass: 'bg-gray-100 text-center' },
        { key: 't1', label: 'T1', sortable: true, class: 'text-center col-min-width', type: 'text' },
        { key: 't2', label: 'T2', sortable: true, class: 'text-center col-min-width', type: 'text' },
        { key: 't3', label: 'T3', sortable: true, class: 'text-center col-min-width', type: 'text' }
        // { key: 'epreuve_fac',               label: 'Épreuves facultatives', sortable: true,     class: 'text-center',   type: 'text' },
        // { key: 'epreuve_opt',               label: 'Épreuves optionnelles', sortable: true,     class: 'text-center',   type: 'text' },
        */
    ]

    numberWithSpaces = numberWithSpaces

    showModalEditionCandidat?: boolean = false
    // showModalImportSeries?: boolean             = false
    showModalImportFichiersOrgaEcrits?: boolean = false
    showModalEditionCommentCandidat?: boolean = false
    showModalRepartitionEquipes?: boolean = false
    candidats_ensembles_validated_at: any = null
    dataForTab: Array<any> = []
    filtres: any = []
    selected_tab = 'oraux'
    Ability = Ability
    totalCandidatsFiltered = 0 // pour l'affichage du total réel contenu dans le tableau des candidats affiché (filtrés et non filtrés)
    formatDate = formatDate
    canAnnulerRepartitionEquipes = false // flag si on est en contexte ou non de pouvoir Annuler la répartition des candidats dans les équipes (Non si la planif est déjà lancée)
    showModalAnnulerRepartitionEquipes = false
    sessionHasHistoriquePlanification = false // flag si la session a déjà au moins une entrée dans l'historique de planification.
    loading_local = false
    loading_global = true // Permet de masquer les boutons d'action de la barre d'entête le temps du chargement. Est à true par défaut.
    firstInit = true // Définit si on est sur le premier chargement de l'interface.
    filiere_gestion_nombre_sujets = null
    exportInProgress = false

    @Watch('candidats')
    updateCandidats() {
        this.initDatas()
        if (this.firstInit) {
            this.firstInit = false
            this.loading_global = false
        }
    }

    @Watch('meta')
    majInfosTable() {
        // On récupère le nombre total d'affectations filrées depuis les infos des Metadonnées
        if (this.$store.state.candidat.meta !== null) {
            if (this.totalCandidatsFiltered !== this.$store.state.candidat.meta.total) {
                this.totalCandidatsFiltered = this.$store.state.candidat.meta.total
            }
        } else {
            this.totalCandidatsFiltered = 0
        }
    }

    @Watch('user_session_id')
    refreshInterface() {
        this.load()
    }

    // ----- gestion du nombre de sujets --------------------

    /**
     * on bloque la saisie de '.' et de ',' dans les inputs de type number déstiné à recevoir des entiers
     */
    checkKeyUp(e: any) {
        if (e.target.value.includes(',')) {
            e.target.value = e.target.value.replace(',', '')
        }
        if (e.target.value.includes('.')) {
            e.target.value = e.target.value.replace('.', '')
        }
    }

    showGestionNombreCandidats = false
    filiere_gestion_nombre_candidats = null
    listeEpreuves: Array<any> = []
    listeEpreuvesOriginal: Array<any> = []
    totalPagesImprimees = 0

    /**
     * ouvre la fenetre de paramétrage du nombre prévisionnel de sujets
     */
    openGestionNombreCandidats() {
        this.listeEpreuves = []
        this.$store
            .dispatch('candidat/getPrevionnelCandidatEpreuves')
            .then((response) => {
                for (let i = 0; i < response.data.data.length; i++) {
                    this.listeEpreuves.push({
                        id: response.data.data[i].id,
                        code: response.data.data[i].code,
                        name: response.data.data[i].name,
                        concour_id: response.data.data[i].concour_id,
                        estimation_nb_candidats: response.data.data[i].estimation_nb_candidats,
                        reel_nb_candidats: response.data.data[i].candidats_count
                    })
                    this.listeEpreuvesOriginal = this.listeEpreuves
                }
                this.showGestionNombreCandidats = true
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
    }

    /**
     * ferme la fenetre de paramétrage du nombre prévisionnel de sujets
     */
    cancelGestionNombreCandidats() {
        this.showGestionNombreCandidats = false
    }

    /**
     * enregistrement dans la session de la valeur de la marge de sécurité et du nombre de sujets supplémentaires puis enregistrement du nombres de sujets et l'estimation des nombres de candidats des épreuves
     */
    updatePrevisionnelCandidats() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        const epreuvesTemp: Array<{ id: number; estimation_nb_candidats: number }> = []
        for (let i = 0; i < this.listeEpreuves.length; i++) {
            epreuvesTemp.push({
                id: this.listeEpreuves[i].id,
                estimation_nb_candidats: this.listeEpreuves[i].estimation_nb_candidats
            })
        }

        const datab: { epreuves: Array<{ id: number; estimation_nb_candidats: number }> } = {
            epreuves: epreuvesTemp
        }
        this.$store.dispatch('candidat/updatePrevionnelCandidatEpreuves', datab)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistré avec succès !', succesToaster)
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * filtre les épreuves par filière
     */
    filiereSujetChangeHandler() {
        this.listeEpreuves = []
        if (this.filiere_gestion_nombre_sujets !== 'null') {
            for (let i = 0; i < this.listeEpreuvesOriginal.length; i++) {
                if (this.listeEpreuvesOriginal[i].concour_id === this.filiere_gestion_nombre_sujets) {
                    this.listeEpreuves.push(this.listeEpreuvesOriginal[i])
                }
            }
        } else {
            for (let i = 0; i < this.listeEpreuvesOriginal.length; i++) {
                this.listeEpreuves.push(this.listeEpreuvesOriginal[i])
            }
        }
    }

    // -----------------------

    // Export demandes amenagements candidats ecrit
    exporter_demande_amenagement_candidat_ecrit() {
        this.$store.commit('candidat/SET_ERROR', null)
        let fileName = ''

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store.dispatch('candidat/getDemandeAmenagementCandidatEcritExport')
            .then((response) => {
                const fileNameTemp =
                    getFileNameFromHeader(response.headers) || 'demande_amenagement_candidat_ecrit.xlsx'
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Récupération sur le serveur de la session active
     */
    loadSessionActiveIfNotExists(force = false) {
        return new Promise((resolve) => {
            if (!this.$store.getters['session/sessionSelect'] || force) {
                this.$store
                    .dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] })
                    .then(() => {
                        resolve(true)
                    })
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Récupération sur le serveur des séries de la session
     */
    loadSeriesSessionActive() {
        return new Promise((resolve) => {
            // on récupère systématiquement les séries de la session active
            this.$store.dispatch('serie/getSeries').then(() => {
                resolve(true)
            })
        })
    }

    /**
     * Récupérétion des 100 premiers candidats sur le serveur si cela est nécéssaire
     */
    loadCandidats() {
        return new Promise((resolve) => {
            if (this.$store.getters['candidat/candidats'].length === 0) {
                this.$store
                    .dispatch('candidat/getCandidats', { sort: 'demande_amenagement', direction: 'asc', phase: '' })
                    .then(() => {
                        resolve(true)
                    })
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Récupérétion des candidats sur le serveur si cela est nécéssaire
     */
    loadConcours() {
        return new Promise((resolve) => {
            if (this.$store.getters['concour/banques'].length === 0) {
                this.$store.dispatch('concour/getConcoursActifs').then(() => {
                    resolve(true)
                })
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Récupération des epreuves sur le serveur si cela est nécéssaire
     */
    loadEpreuves() {
        return new Promise((resolve) => {
            this.$store
                .dispatch('epreuve/getEpreuves', {
                    isPrecedente: false,
                    session_id: this.$store.getters['auth/user_session_id']
                })
                .then(() => {
                    resolve(true)
                })
        })
    }

    buildGenericFields() {
        this.$store.dispatch('gestionParticuliere/getGestionParticulieres').then(() => {
            this.buildGenericFieldsSuite()
        })
    }

    buildGenericFieldsSuite() {
        this.genericfields = [
            { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
            // { key: 'commentaires', label: '', sortable: true, class: '', type: 'action' },
            { key: 'code', label: 'Code', sortable: true, class: '', type: 'actionText' },
            { key: 'name', label: 'Identité', sortable: true, class: '', type: 'actionText' },
            // { key: 'first_name', label: 'Prénom', sortable: true, class: 'text-start col-min-width', type: 'text' },
            { key: 'filiere', label: 'Filière', sortable: true, class: 'text-center col-min-width', type: 'text' },

            {
                key: 'demande_amenagement',
                label: 'Demande aménagements',
                sortable: true,
                class: 'text-center col-min-width',
                type: 'icons'
            }
        ]
        for (let i = 0; i < this.$store.state.domaine.domaines.length; i++) {
            this.genericfields.push({
                key: 'domaineid_' + this.$store.state.domaine.domaines[i].id + '-amenagements.accessibilite',
                label: 'Salle',
                sortable: false,
                class: 'text-center bg-gray-100 column_salle',
                type: 'text',
                doubleHeaderLabel: this.$store.state.domaine.domaines[i].name.toUpperCase(),
                doubleHeaderColSpan: 3,
                doubleHeaderClass: 'bg-gray-100 text-center'
            })
            this.genericfields.push({
                key:
                    'domaineid_' + this.$store.state.domaine.domaines[i].id + '-amenagements.gestion_particuliere.name',
                label: 'Gestion Part.',
                sortable: false,
                type: 'text',
                class: 'text-center bg-gray-100 column_gestion_part',
                doubleHeaderClass: 'bg-gray-100 text-center'
            })
            this.genericfields.push({
                key:
                    'domaineid_' + this.$store.state.domaine.domaines[i].id + '-amenagements.temps_supplementaire.name',
                label: 'Temps',
                sortable: false,
                type: 'text',
                class: 'text-center bg-gray-100 column_temps',
                doubleHeaderClass: 'bg-gray-100 text-center'
            })
        }
        // { key: 't1', label: 'T1', sortable: true, class: 'text-center col-min-width', type: 'text' },

        // { key: 'epreuve_fac',               label: 'Épreuves facultatives', sortable: true,     class: 'text-center',   type: 'text' },
        // { key: 'epreuve_opt',               label: 'Épreuves optionnelles', sortable: true,     class: 'text-center',   type: 'text' },
    }

    /**
     * Initialisation des données affichées dans le tableau
     */
    initDatas() {
        this.candidatsAssoc = Object.assign([], this.$store.state.candidat.candidats)

        const allEpreuves = this.$store.getters['epreuve/epreuves']
        const epreuvesById: any = {}
        if (allEpreuves && allEpreuves.length > 0) {
            for (const epreuve of allEpreuves) {
                epreuvesById[epreuve.id] = epreuve
            }
        }
        for (const candidat of this.candidatsAssoc) {
            this.updateCandidatFiliere(candidat)

            candidat.serie = '-'
            if (candidat.serie_id) {
                const serie_cand = this.$store.state.serie.series.find(
                    (s: SerieInterface) => s.id === candidat.serie_id
                )
                if (serie_cand !== undefined) {
                    candidat.serie = serie_cand.name
                }
            }
            candidat.indisponibilites = []
            candidat.incompatibilites = []

            // candidat.epreuve_fac = '-'
            // candidat.epreuve_opt = '-'
            candidat.equipes_inter = '-'
            candidat.equipes_par_epreuve = '-'
            candidat.equipes_tp = '-'

            if (candidat.ensembles && candidat.ensembles.length > 0) {
                const arrayEquipesInter: string[] = []
                const arrayEquipesParEpreuve: string[] = []
                const arrayEquipesTp: string[] = []

                for (const ensemble of candidat.ensembles) {
                    switch (ensemble.type_ensemble) {
                        case TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE: {
                            arrayEquipesParEpreuve.push(ensemble.name)
                            break
                        }
                        case TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT: {
                            arrayEquipesInter.push(ensemble.name)
                            break
                        }
                        case TypeEnsemble.TYPE_ENSEMBLE_TP: {
                            arrayEquipesTp.push(ensemble.name)
                            break
                        }
                    }
                }
                if (arrayEquipesInter.length > 0) {
                    candidat.equipes_inter = arrayEquipesInter.join(', ')
                }
                if (arrayEquipesParEpreuve.length > 0) {
                    candidat.equipes_par_epreuve = arrayEquipesParEpreuve.join(', ')
                }
                if (arrayEquipesTp.length > 0) {
                    candidat.equipes_tp = arrayEquipesTp.join(', ')
                }
            }
            /*
            if (candidat.inscriptions && candidat.inscriptions.length > 0) {
                const arrayEpreuveFac: string[] = []
                const arrayEpreuveOpt: string[] = []
                for (const epreuve of candidat.inscriptions) {
                    if (epreuve.epreuve_id && epreuvesById[epreuve.epreuve_id]) {
                        const currentEpreuve = epreuvesById[epreuve.epreuve_id]
                        if (currentEpreuve.phase_id === 2 && currentEpreuve.obligatoire === 0 && currentEpreuve.type_passation !== -1) {
                            arrayEpreuveFac.push(currentEpreuve.name)
                        }
                        if (currentEpreuve.phase_id === 2 && currentEpreuve.obligatoire === 1 && (currentEpreuve.option === 1 || currentEpreuve.tirage_aleatoire === 1)) {
                            arrayEpreuveOpt.push(currentEpreuve.name)
                        }
                    }
                }
                if (arrayEpreuveFac.length > 0) {
                    candidat.epreuve_fac = arrayEpreuveFac.join(', ')
                }
                if (arrayEpreuveOpt.length > 0) {
                    candidat.epreuve_opt = arrayEpreuveOpt.join(', ')
                }
            }
            */
        }
        this.setDataForGenericTab(this.candidatsAssoc)
    }

    /**
     * Affectation si cela n'est pas fait, du nom de la filière d'un candidat donné
     */
    updateCandidatFiliere(candidat: CandidatInterface) {
        if (candidat && candidat.concour_id && !candidat.filiere) {
            const concour_found = this.$store.state.concour.concours.find((c: any) => c.id === candidat.concour_id)
            if (concour_found) {
                const params = {
                    candidatId: candidat.id,
                    nomFiliere: concour_found.name
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
            }
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        const can = this.$store.getters['auth/can'](Ability.CAND_MANAGE)
        const icone = checkIcone(Ability.CAND_MANAGE, can)
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                let styleButtonComment = 'text-tertiary commons_comment_button text-center'
                let nameIcon = 'comment-alt'
                if (result.hasComments === true) {
                    styleButtonComment = 'commons_comment_button text-center'
                    if (result.hasImportantComments === false) {
                        nameIcon = 'comment-alt'
                    } else {
                        nameIcon = 'comment-alt-exclamation'
                    }
                }

                let tooltip_comments = ''

                for (const c in result.comments) {
                    tooltip_comments += result.comments[c].important
                        ? '---------------TACHE A FAIRE---------------\n'
                        : '---------------COMMENTAIRE---------------\n'
                    tooltip_comments += result.comments[c].body
                    tooltip_comments += '\n'
                }

                const iconDemandeAm: any[] = []
                let titleDemandeAmenagement = ''

                const index = result.decisionAmenagements.findIndex(
                    (d: DecisionAmenagementInterface) => d.type === TypeMesure.TYPE_ECRIT
                )
                const decision = result.decisionAmenagements && result.decisionAmenagements[index] ? result.decisionAmenagements[index].etat : 0
                if (
                    decision ||
                    result.demande_amenagement ||
                    result.amenagements.filter((d: any) => d.type === TypeMesure.TYPE_ECRIT || d.type === TypeMesure.TYPE_ECRIT_ET_ORAL).length > 0
                ) {
                    let color = 'text-secondary'
                    titleDemandeAmenagement = 'Non traitée'

                    if (decision) {
                        switch (decision) {
                            case EtatDecisionAmenagement.ETAT_VALIDE:
                                color = 'text-success'
                                titleDemandeAmenagement = 'Traitée'
                                break
                            case EtatDecisionAmenagement.ETAT_SOUMIS:
                                color = 'text-primary'
                                titleDemandeAmenagement = 'En cours'
                                break
                        }
                    }

                    iconDemandeAm.push({
                        name: 'check',
                        class: color,
                        title: titleDemandeAmenagement
                    })
                }

                const line = [
                    {
                        label: icone.label,
                        item: result.id,
                        type: 'action',
                        typeAction: 'edit',
                        class: 'commons_first_action_button',
                        icon: icone.icon,
                        disabled: false
                    },
                    {
                        label: '',
                        item: result.id,
                        text: result.code,
                        type: 'actionText',
                        typeAction: 'editCivil',
                        class: 'text-info item_action btn_action_ligne_link'
                    },
                    {
                        label: '',
                        item: result.id,
                        text: result.name + ' ' + result.first_name,
                        type: 'actionText',
                        typeAction: 'editCivil',
                        class: 'text-info item_action btn_action_ligne_link'
                    },
                    {
                        label: '',
                        item: result.filiere,
                        type: 'text',
                        typeAction: null,
                        class: 'text-center'
                    },
                    {
                        label: '',
                        item: iconDemandeAm,
                        type: 'icons',
                        typeAction: null,
                        class: 'text-center',
                        title: titleDemandeAmenagement
                    }
                ]

                for (let i = 0; i < this.$store.state.domaine.domaines.length; i++) {
                    let salle = ''
                    let gestionPart = ''
                    let temps = ''

                    for (let j = 0; j < result.amenagements.length; j++) {
                        if (result.amenagements[j].domaine_ids.includes(this.$store.state.domaine.domaines[i].id)) {
                            if (result.amenagements[j].accessibilite !== TypeAmenagement.AMENAGEMENT_AUCUN) {
                                const salleTemps = getTypeAmenagement(result.amenagements[j].accessibilite).libelle
                                if (!salle.includes(salleTemps)) {
                                    salle +=
                                        '<div class="text-center icone_oral_ecrit m-0 p-0" title="' +
                                        getTypeAmenagement(result.amenagements[j].accessibilite).libelle +
                                        '">' +
                                        salleTemps +
                                        '</div>'
                                }
                            }

                            if (
                                result.amenagements[j].gestion_particuliere_ids &&
                                result.amenagements[j].gestion_particuliere_ids.length > 0
                            ) {
                                for (let k = 0; k < result.amenagements[j].gestion_particuliere_ids.length; k++) {
                                    const gp = this.getGestionParticuliereById(
                                        result.amenagements[j].gestion_particuliere_ids[k]
                                    )
                                    let nameGestionPart = ''
                                    if (gp) {
                                        nameGestionPart = gp.code
                                    }
                                    if (!gestionPart.includes(nameGestionPart)) {
                                        gestionPart =
                                            gestionPart +
                                            '<div class="text-center icone_oral_ecrit m-0 p-0" title="' +
                                            gp.name +
                                            '">' +
                                            nameGestionPart +
                                            '</div>'
                                    }
                                }
                            }
                            if (result.amenagements[j].temps_supplementaire_id) {
                                const tempsTemp =
                                    this.getTempsSuplementaireById(result.amenagements[j].temps_supplementaire_id)
                                        .code + ' TEMPS'
                                if (!temps.includes(tempsTemp)) {
                                    temps =
                                        temps +
                                        '<div class="text-center icone_oral_ecrit m-0 p-0" title="' +
                                        this.getTempsSuplementaireById(result.amenagements[j].temps_supplementaire_id)
                                            .name +
                                        '">' +
                                        tempsTemp +
                                        '</div>'
                                }
                            }
                        }
                    }

                    line.push({
                        label: '',
                        item: salle,
                        type: 'html',
                        typeAction: null,
                        class: 'text-center column_salle'
                    })
                    line.push({
                        label: '',
                        item: gestionPart,
                        type: 'html',
                        typeAction: null,
                        class: 'text-center column_gestion_part'
                    })
                    line.push({
                        label: '',
                        item: temps,
                        type: 'html',
                        typeAction: null,
                        class: 'text-center column_temps'
                    })
                }
                this.dataForTab.push(line)
            }
        }
    }

    getGestionParticuliereById(id: number) {
        for (let i = 0; i < this.$store.state.gestionParticuliere.gestionParticulieres.length; i++) {
            if (id === this.$store.state.gestionParticuliere.gestionParticulieres[i].id) {
                return this.$store.state.gestionParticuliere.gestionParticulieres[i]
            }
        }
        return null
    }

    getTempsSuplementaireById(id: number) {
        for (let i = 0; i < this.$store.state.tempsSupplementaire.tempsSupplementaires.length; i++) {
            if (id === this.$store.state.tempsSupplementaire.tempsSupplementaires[i].id) {
                return this.$store.state.tempsSupplementaire.tempsSupplementaires[i]
            }
        }
        return null
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    options_filieres: Array<any> = []
    setFiltersForGenericTab() {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        this.options_filieres = []
        for (const f in filieres) {
            this.options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        // Options séries
        const series = this.$store.getters['serie/series']
        const options_series = []
        for (const s in series) {
            options_series.push({ index: series[s].id, name: series[s].name })
        }

        const etats_demande_amenagement = []
        etats_demande_amenagement.push({
            index: DemandeAmenagement.DEMANDE_AMENAGEMENT_NON_HANDI,
            name: "Candidats sans demande d'aménagement"
        })
        etats_demande_amenagement.push({
            index:
                DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI +
                DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ECRIT,
            name: "Candidats avec une demande d'aménagement"
        })
        etats_demande_amenagement.push({
            index:
                DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI -
                DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ECRIT,
            name: "Candidats avec une demande d'aménagement non traitée"
        })
        etats_demande_amenagement.push({
            index: DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_SOUMIS_ECRIT,
            name: "Candidats avec une demande d'aménagement en cours"
        })
        etats_demande_amenagement.push({
            index: DemandeAmenagement.DEMANDE_AMENAGEMENT_HANDI_TRAITE_ECRIT,
            name: "Candidats avec une demande d'aménagement traitée"
        })

        // Options commentaire
        const etat_comments: any = []
        etat_comments.push({ index: 0, name: 'Commentaires' })
        etat_comments.push({ index: 1, name: 'Tâches à faire' })
        etat_comments.push({ index: 2, name: 'Alerte' })

        this.filtres = [
            {
                libelle: 'Type de commentaire',
                defautOptionlibelle: 'Rechercher par',
                model: 'commentaires',
                value: '',
                index: 'commentaires',
                datas: etat_comments,
                loading: this.$store.state.candidat.loading,
                options: { type: 'deroulant', fieldsKey: 'commentaires' }
            },
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'code',
                value: '',
                index: 'code',
                datas: this.$store.state.candidat.code,
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'code', strict: true }
            },
            {
                libelle: 'Nom',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: this.$store.state.candidat.name,
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'name' }
            },
            {
                libelle: 'Prénom',
                defautOptionlibelle: 'Rechercher une',
                model: 'first_name',
                value: '',
                index: 'first_name',
                datas: this.$store.state.candidat.first_name,
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'first_name' }
            },
            {
                libelle: 'Filière',
                defautOptionlibelle: 'Rechercher une',
                model: 'concour_id',
                value: '',
                index: 'filiere',
                datas: this.options_filieres,
                loading: this.$store.state.candidat.loading,
                options: { type: 'deroulant', fieldsKey: 'filiere' }
            },
            {
                libelle: "Equipe d'interclassement",
                defautOptionlibelle: 'Rechercher une',
                model: 'equipes_inter',
                value: '',
                index: 'equipes_inter',
                datas: '',
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'equipes_inter', strict: true }
            },
            {
                libelle: 'Equipe par épreuve',
                defautOptionlibelle: 'Rechercher une',
                model: 'equipes_par_epreuve',
                value: '',
                index: 'equipes_par_epreuve',
                datas: '',
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'equipes_par_epreuve' }
            },
            {
                libelle: 'Equipe TP',
                defautOptionlibelle: 'Rechercher une',
                model: 'equipes_tp',
                value: '',
                index: 'equipes_tp',
                datas: '',
                loading: this.$store.state.candidat.loading,
                options: { type: 'form', fieldsKey: 'equipes_tp' }
            },
            {
                libelle: "État de Demande d'aménagement",
                defautOptionlibelle: 'Rechercher par ',
                model: 'demande_amenagement',
                value: '',
                index: 'demande_amenagement',
                datas: etats_demande_amenagement,
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'demande_amenagement' }
            },
            {
                libelle: 'Série',
                defautOptionlibelle: 'Rechercher une',
                model: 'serie_id',
                value: '',
                index: 'type',
                datas: options_series,
                loading: this.$store.state.candidat.loading,
                options: { type: 'deroulant', fieldsKey: 'serie' }
            }
        ]

        const accessibilite_temp = getEnumTypeAmenagement()
        const gestionParticulieres = this.$store.getters['gestionParticuliere/gestionParticulieres']
        const tempsSupplementaires = this.$store.getters['tempsSupplementaire/tempsSupplementaires']

        for (let i = 0; i < this.$store.state.domaine.domaines.length; i++) {
            const accessibilites: any = []
            for (const a in accessibilite_temp) {
                accessibilites.push({
                    index: accessibilite_temp[a].id + ':' + this.$store.state.domaine.domaines[i].id,
                    name: accessibilite_temp[a].name
                })
            }

            const gestion_parts: any = []
            for (const g in gestionParticulieres) {
                gestion_parts.push({
                    index: gestionParticulieres[g].id + ':' + this.$store.state.domaine.domaines[i].id,
                    name: gestionParticulieres[g].code
                })
            }

            const temps_sup: any = []
            for (const t in tempsSupplementaires) {
                temps_sup.push({
                    index: tempsSupplementaires[t].id + ':' + this.$store.state.domaine.domaines[i].id,
                    name: tempsSupplementaires[t].code
                })
            }
            this.filtres.push({
                libelle: 'Salle',
                defautOptionlibelle: 'Rechercher une',
                model: 'amenagements_accessibilite',
                value: '',
                index: 'amenagements_accessibilite',
                datas: accessibilites,
                loading: false,
                options: {
                    type: 'deroulant',
                    fieldsKey: 'domaineid_' + this.$store.state.domaine.domaines[i].id + '-amenagements.accessibilite'
                }
            })
            this.filtres.push({
                libelle: 'Temps',
                defautOptionlibelle: 'Rechercher un',
                model: 'amenagements_temps_supplementaire_id',
                value: '',
                index: 'amenagements_temps_supplementaire_id',
                datas: temps_sup,
                loading: false,
                options: {
                    type: 'deroulant',
                    fieldsKey:
                        'domaineid_' +
                        this.$store.state.domaine.domaines[i].id +
                        '-amenagements.temps_supplementaire.name'
                }
            })
            this.filtres.push({
                libelle: 'Gestion particulière',
                defautOptionlibelle: 'Rechercher une',
                model: 'amenagements_gestion_particuliere_id',
                value: '',
                index: 'amenagements_gestion_particuliere_id',
                datas: gestion_parts,
                loading: false,
                options: {
                    type: 'deroulant',
                    fieldsKey:
                        'domaineid_' +
                        this.$store.state.domaine.domaines[i].id +
                        '-amenagements.gestion_particuliere.name'
                }
            })
        }
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent(paParams: any): void {
        if (paParams && paParams[0] && paParams[1]) {
            const candidats = this.$store.state.candidat.candidats
            let selectedCandidat = null

            switch (paParams[0]) {
                case 'edit':
                    // Récupération de l'étab by ID
                    selectedCandidat = candidats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.selected_tab = 'ecrits'
                        this.editCandidat(selectedCandidat)
                    }
                    break
                case 'editCivil':
                    // Récupération de l'étab by ID
                    selectedCandidat = candidats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.selected_tab = 'etat_civil'
                        this.editCandidat(selectedCandidat)
                    }
                    break
                case 'openComment':
                    // Récupération de l'étab by ID
                    selectedCandidat = candidats.filter((candidat: any) => candidat.id === paParams[1])[0]
                    if (selectedCandidat) {
                        this.selected_tab = 'commentaires'
                        this.editCandidat(selectedCandidat)
                    }
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                default:
                    break
            }
        }
    }

    /**
     * Appel des datas avec un sort en paramètres
     */
    filtreSortHandler(params: any) {
        // Rajout du paramètre pour spécifier qu'on est sur un appel pour les oraux
        params.phase = ''
        params.passation = TypePassation.TYPE_PASSATION_ECRIT
        this.$store.dispatch('candidat/getCandidats', params)
    }

    /**
     * Edition des commentaires associés à un candidat
     */
    openCommentaireCandidat(item: any) {
        if (item && item.id !== undefined) {
            this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
            this.showModalEditionCommentCandidat = true
        }
    }

    /**
     * Fermeture de la modale d'édition des commentaires candidats
     */
    reinitShowModalCommentCandidat(candidatSelected: CandidatInterface) {
        // On met à jour dans le tableau des candidats les informations relatives
        // aux commentaires du candidat selectionné
        if (candidatSelected) {
            const indexTab = this.dataForTab.findIndex(
                (itemTab: any) => itemTab.length > 0 && itemTab[0].item === candidatSelected.id
            )
            if (indexTab !== -1 && this.dataForTab[indexTab] && this.dataForTab[indexTab].length > 1) {
                // On met à jour le bouton de commentaires en fonction des mises à jour effectuée
                // sur les commentaires du candidat
                let styleButtonComment = 'text-tertiary commons_comment_button text-center'
                let nameIcon = 'comment-alt'
                if (candidatSelected.hasComments === true) {
                    styleButtonComment = 'commons_comment_button text-center'
                    if (candidatSelected.hasImportantComments === true) {
                        nameIcon = 'comment-alt'
                    }
                }

                this.dataForTab[indexTab][1].class = styleButtonComment
                this.dataForTab[indexTab][1].icon = nameIcon
            }
        }

        this.showModalEditionCommentCandidat = false
    }

    /**
     * Edition d'un candidat: enregistrement du candidat en tant que candidat sélectionnée
     * et affichage de la modale
     */
    editCandidat(item: any) {
        if (item && item.id !== undefined) {
            // On charge à partir de la BDD l'ensemble des informations du candidat sélectionné
            this.$store.dispatch('candidat/getCandidat', item).then(() => {
                const params = {
                    candidatId: item.id,
                    nomFiliere: item.filiere
                }
                this.$store.commit('candidat/SET_CANDIDAT_FILIERE', params)
                this.$store.commit('candidat/SET_SELECTED_CANDIDAT', item.id)
                this.showModalEditionCandidat = true
            })
        }
    }

    /**
     * Fermeture de la modale d'édition candidat
     */
    reinitShowModalCandidat() {
        this.showModalEditionCandidat = false
    }

    /**
     * Appel d'une nouvelle page de candidats lors du scroll
     */
    loadHandler(params: any) {
        // Rajout du paramètre pour spécifier qu'on est sur un appel pour les oraux
        params.phase = ''
        params.passation = TypePassation.TYPE_PASSATION_ECRIT
        this.$store.dispatch('candidat/getMoreCandidats', params)
    }

    /**
     * Import des souhaits des candidats
     */
    openImportFichiersOrgaEcrits() {
        this.showModalImportFichiersOrgaEcrits = true
    }

    /**
     * Fermeture de la modale des souhaits des candidats
     */
    reinitShowModalImportFichiersOrgaEcrits(shouldRefreshTableCandiat: boolean) {
        if (shouldRefreshTableCandiat) {
            this.$store.dispatch('candidat/getCandidats', { sort: 'demande_amenagement', direction: 'asc', phase: '' })
            this.showModalImportFichiersOrgaEcrits = false
        } else {
            this.showModalImportFichiersOrgaEcrits = false
        }
    }

    /**
     * Ouverture modale Répartition des équipes
     */
    openRepartitionEquipes() {
        this.showModalRepartitionEquipes = true
    }

    /**
     * Fermeture modale Répartition des équipes
     */
    reinitshowModalRepartitionEquipes(repartitionDone: boolean) {
        if (repartitionDone) {
            // Si la répartition a été effectuée, on doit récupérer la session active pour avoir la date de la répartition.
            this.loadSessionActiveIfNotExists(true).then(() => {
                this.candidats_ensembles_validated_at =
                    this.$store.state.session.sessionSelect.candidats_ensembles_validated_at
                this.$store.dispatch('candidat/getCandidats', {
                    sort: 'demande_amenagement',
                    direction: 'asc',
                    phase: ''
                })
            })
        }
        this.showModalRepartitionEquipes = false
    }

    /**
     * Ouverture modale Répartition des équipes
     */
    openAnnulerRepartitionEquipes() {
        this.loading_local = true
        this.$store.commit('candidat/SET_ERROR', null) // reinit de l'erreur éventuelle sur candidat
        this.$store.commit('planification/SET_ERROR', null) // reinit de l'erreur éventuelle sur planification
        this.showModalAnnulerRepartitionEquipes = true
        this.loadSeriesSessionActive()
            .then(() => {
                this.$store
                    .dispatch('planification/getHistoPlanifSessionActive')
                    .then(() => {
                        this.checkCanAnnulerRepartitionEquipes()
                        this.checkSessionHasHistoriquePlanification()
                        this.loading_local = false
                    })
                    .catch((err) => {
                        this.loading_local = false
                        console.log(
                            "DashboardOrauxPlanification: erreur chargement de l'historique de planification : ",
                            err
                        )
                    })
            })
            .catch((err) => {
                this.loading_local = false
                console.log('DashboardOrauxPlanification: erreur chargement des séries : ', err)
            })
    }

    /** Vérifie si le contexte permet d'annuler la répartition des candidats dans les équipes */
    checkCanAnnulerRepartitionEquipes() {
        this.canAnnulerRepartitionEquipes = true
        for (const serie of this.$store.state.serie.series) {
            if (serie.planifie !== 0) {
                // au moins une série est déjà en cours de planif ou a déjà été planifiée. On ne peut pas Annuler la répartition.
                this.canAnnulerRepartitionEquipes = false
                break
            }
        }
    }

    /** Récupération de l'historique de planification de la session active et init de l'info sessionHasHistoriquePlanification */
    checkSessionHasHistoriquePlanification() {
        if (
            this.$store.state.planification.historiques_sessionActive &&
            this.$store.state.planification.historiques_sessionActive.length > 0
        ) {
            this.sessionHasHistoriquePlanification = true
        } else {
            this.sessionHasHistoriquePlanification = false
        }
    }

    /** Lance l'annulation de la répartition des candidats dans les équipes */
    confirmerAnnulerRepartitionEquipes() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        const param = {
            session_id: this.$store.getters['session/sessionSelect'].id
        }

        this.$bvToast.toast('Annulation en cours ...', infosToaster)

        this.$store.dispatch('candidat/annulerRepartitionEquipes', param)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('La répartition par équipes a été annulée avec succès !', succesToaster)

                await this.loadSessionActiveIfNotExists(true)
                if (this.$store.state.session.sessionSelect) {
                    this.candidats_ensembles_validated_at =
                        this.$store.state.session.sessionSelect.candidats_ensembles_validated_at
                    await this.$store.dispatch('candidat/getCandidats', {
                        sort: 'demande_amenagement',
                        direction: 'asc',
                        phase: ''
                    })
                }
                this.cancelAnnulerRepartitionEquipes()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Annule l'opération d'annulation de la répartition des candidats dans les équipes */
    cancelAnnulerRepartitionEquipes() {
        this.$store.commit('candidat/SET_ERROR', null) // reinit de l'erreur éventuelle sur candidat
        this.$store.commit('planification/SET_ERROR', null) // reinit de l'erreur éventuelle sur planification
        this.showModalAnnulerRepartitionEquipes = false
    }

    // Export des candidats admissibles
    exporter_admissibles() {
        if (this.exportInProgress) {
            return
        }
        this.exportInProgress = true
        this.$store.commit('candidat/SET_ERROR', null)

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store.dispatch('candidat/getExportCandidatsAdmissibles')
            .then((response) => {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(new Blob([response.data]))
                link.setAttribute('Download', getFileNameFromHeader(response.headers) || 'Candidats_admissibles.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportInProgress = false
            })
    }

    load() {
        this.$store.dispatch('domaine/getDomaines').then(() => {
            this.$store.dispatch('tempsSupplementaire/getTempsSupplementaires').then(() => {
                this.$store.dispatch('gestionParticuliere/getGestionParticulieres').then(() => {
                    this.loadSeriesSessionActive().then(() => {
                        if (this.$store.state.session.sessionSelect) {
                            this.candidats_ensembles_validated_at =
                                this.$store.state.session.sessionSelect.candidats_ensembles_validated_at
                        }
                        // Si aucun concours n'as été chargé, alors on les récupère
                        this.loadConcours()
                            .then(() => {
                                // Chargement des epreuves si nécéssaire
                                this.loadEpreuves()
                                    .then(() => {
                                        this.buildGenericFields()
                                        this.initDatas()
                                        this.setFiltersForGenericTab()
                                    })
                                    .catch((err) => {
                                        console.log('CandidatsTabEcrits: erreur chargement des epreuves: ', err)
                                    })
                            })
                            .catch((err) => {
                                console.log('CandidatsTabEcrits: erreur chargement des concours: ', err)
                            })
                    })
                })
            })
        })
    }

    /**
     * Initialisation des données du composant au montage
     */
    mounted() {
        this.load()
    }
}
