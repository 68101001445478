



























































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ItemIntervenant from '@/components/ItemIntervenant.vue'
import FormPerso from '@/components/DossierAdministratif/FormPerso.vue'
import FormSituationStatuaire from '@/components/DossierAdministratif/FormSituationStatuaire.vue'
// import FormPro from '@/components/DossierAdministratif/FormPro.vue'
import FormDocument from '@/components/DossierAdministratif/FormDocument.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { Etat } from '@/types/DossierAdministratif'
import store from '@/store/index'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        ItemIntervenant,
        FormPerso,
        FormSituationStatuaire,
        // FormPro,
        FormDocument,
        ErrorDisplay
    },
    computed: {
        ...mapState('dossieradministratif', ['error']),
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('dossieradministratif', ['dossierAdministratifSelect', 'avatar', 'error'])
    },
    methods: {
        ...mapActions('dossieradministratif', ['getDossierAdministratif', 'updateDossierAdministratif', 'getAvatar'])
    }
    /* beforeRouteEnter (to: any, from: any, next: any) {
    store.dispatch('dossieradministratif/getDossierAdministratif', to.params.dossier_id).then(() => {
      next()
    })
  } */
})
export default class DossierAdministratif extends Vue {
    @Prop() dossier_id?: number;

    @Watch('dossierAdministratifSelect')
    updateItem () {
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect.hasAvatar) {
            this.$store.dispatch('dossieradministratif/getAvatar', { dossier_id: this.dossier_id })
        }
        this.getCompteur()
    }

    // DATAS
    concours = []
    edition = true
    disabledButton = true
    dossierValide = false
    etat_depose: Etat = Etat.ETAT_DEPOSE
    etapes_valide = 0
    etapes_totales = 3
    showFormCandidature = false
    Etat = Etat
    ro_infos_perso = false
    ro_infos_statut = false
    formPersoVisible = false
    FormSituationStatuaireVisible = false
    formDocumentVisible = false

    @Watch('isValide')
    updateDossierValide () {
        this.dossierValide = this.$store.state.dossieradministratif.isValide
    }

    @Watch('dossierAdministratifSelect')
    setReadOnly () {
        if ((this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_form_perso === Etat.ETAT_VALIDE) || (this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_form_perso === Etat.ETAT_DEPOSE && this.$store.state.dossieradministratif.dossierAdministratifSelect.submitted_at !== null)) {
            this.ro_infos_perso = true
        } else {
            this.ro_infos_perso = false
        }

        // Acces a l'edition du form statut
        if ((this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_form_situation === Etat.ETAT_VALIDE) || (this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_form_situation === Etat.ETAT_DEPOSE && this.$store.state.dossieradministratif.dossierAdministratifSelect.submitted_at !== null)) {
            this.ro_infos_statut = true
        } else {
            this.ro_infos_statut = false
        }
    }

    // METHODS
    // Passe en mode validation
    showValidation () {
        this.edition = false
    }

    // Coche la case de confirmation
    unlockValidation () {
        this.disabledButton = !this.disabledButton
    }

    // Envoie la validation du dossier
    submitValidation () {
        const payload = {
            submit: 1
        }
        this.$store.dispatch('dossieradministratif/updateDossierAdministratif', { dossier_id: this.$props.dossier_id, payload: payload })
            .then(() => {
                if (this.$store.state.dossieradministratif.isValide) {
                    this.edition = true
                    const idSucces = 't_succes_' + Math.random()
                    const succesToaster = {
                        id: idSucces,
                        toaster: 'b-toaster-top-right',
                        variant: 'success',
                        noCloseButton: true,
                        fade: true,
                        autoHideDelay: 5000
                    }
                    this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                }
            })
    }

    // Créer le compteur d'étapes validées
    getCompteur () {
        this.etapes_valide = 0
        if (this.$store.state.dossieradministratif.dossierAdministratifSelect) {
            if (this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_form_perso === Etat.ETAT_DEPOSE || this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_form_perso === Etat.ETAT_VALIDE) {
                this.etapes_valide += 1
            }
            if (this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_form_situation === Etat.ETAT_DEPOSE || this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_form_situation === Etat.ETAT_VALIDE) {
                this.etapes_valide += 1
            }
            if (this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_pieces === Etat.ETAT_DEPOSE || this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_pieces === Etat.ETAT_VALIDE) {
                this.etapes_valide += 1
            }
        }
    }

    // Annule la validation du dossier
    backValidation () {
        this.edition = true
        this.disabledButton = true
    }

    // Check les conditions de validation du dossier
    check_conditions_validation () {
        if ((this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_form_perso === Etat.ETAT_DEPOSE && this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_form_situation === Etat.ETAT_DEPOSE && (this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_pieces === Etat.ETAT_DEPOSE || this.$store.state.dossieradministratif.dossierAdministratifSelect.etat_pieces === Etat.ETAT_VALIDE) && this.$store.state.dossieradministratif.dossierAdministratifSelect.submitted_at === null)) {
            return true
        } else {
            return false
        }
    }

    beforeMount () {
        this.$store.dispatch('dossieradministratif/getDossierAdministratif', this.dossier_id).then(() => {
            if (this.$store.state.dossieracademique.dossierAcademiqueSelect.hasAvatar) {
                this.$store.dispatch('dossieracademique/getAvatar', { dossier_id: this.dossier_id })
            }

            this.getCompteur()

            if (this.$store.state.dossieradministratif.dossierAdministratifSelect.submitted_at === null || this.$store.state.dossieradministratif.dossierAdministratifSelect.submitted_at === undefined) {
                this.dossierValide = false
            } else {
                this.dossierValide = true
            }

            const modalPersoVisible = document.getElementById('set_infos_perso')
            if (modalPersoVisible) {
                modalPersoVisible.addEventListener('hide.bs.modal', () => {
                    this.formPersoVisible = false
                })
                modalPersoVisible.addEventListener('show.bs.modal', () => {
                    this.formPersoVisible = true
                })
            }
            const modalFormSituationStatuaire = document.getElementById('set_situation')
            if (modalFormSituationStatuaire) {
                modalFormSituationStatuaire.addEventListener('hide.bs.modal', () => {
                    this.FormSituationStatuaireVisible = false
                })
                modalFormSituationStatuaire.addEventListener('show.bs.modal', () => {
                    this.FormSituationStatuaireVisible = true
                })
            }
            const modalFormDocument = document.getElementById('set_documents')
            if (modalFormDocument) {
                modalFormDocument.addEventListener('hide.bs.modal', () => {
                    this.formDocumentVisible = false
                })
                modalFormDocument.addEventListener('show.bs.modal', () => {
                    this.formDocumentVisible = true
                })
            }
        })
    }
}
