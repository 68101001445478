































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Ability } from '@/types/Ability'
import { TypePassation } from '@/types/Epreuve'
import PopupEditionCentre from '@/components/Centre/PopupEditionCentre.vue'
import { checkIcone } from '@/utils/helpers'

@Component({
    components: {
        ExaGenericTable,
        PopupEditionCentre,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('centre', ['loading', 'error', 'centres', 'meta', 'links', 'totalRows', 'lastPage', 'totalPage', 'etat_centre_global']),
        ...mapState('centre', ['meta']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    }
})
export default class CentresOraux extends Vue {
    Ability = Ability
    // DATAS
    filtres_oraux: any = []
    genericfields: Array<any> = [
        { key: 'edit',         label: '',       sortable: false, class: '', type: 'action' },
        { key: 'etat',         label: 'État',   sortable: true, class: 'text-center', type: 'text' },
        { key: 'name',         label: 'Libellé', sortable: true, class: '', type: 'text' },
        { key: 'ville.name',   label: 'Ville', sortable: true,  class: '', type: 'text' },
        { key: 'ville.academie',   label: 'Académie', sortable: true,  class: '', type: 'text' },
        { key: 'ensembles_examinateurs_sum_nb_estime',    label: 'Nbre Examinateurs', sortable: true,  class: 'text-center', type: 'text' },
        { key: 'ensembles_examinateurs_tp_sum_nb_estime', label: 'Nbre Examinateurs TP', sortable: true,  class: 'text-center', type: 'text' },
        { key: 'salles_count',          label: 'Salles Examination', sortable: true,  class: 'text-center', type: 'text' },
        { key: 'salles_tp_count',       label: 'Salles Examination TP', sortable: false,  class: 'text-center', type: 'text' },
        { key: 'affectation_salles',    label: 'Affectation Examinateurs', sortable: false,  class: 'text-center', type: 'text' },
        { key: 'etat_oral',             label: 'Organisation Oraux', sortable: false,  class: 'text-center', type: 'text' }
    ]

    dataForTab: Array<any> = []
    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filterOn = []
    stickyHeader = true
    centre: any = null
    TypePassation = TypePassation

    totalCentres = 0
    showGestionCentre = false
    centresFilter: Array<any> = []
    params: any = {}

    @Watch('centres')
    filterCentres() {
        this.centresFilter = this.$store.getters['centre/centres'].filter((c: any) => c.type_passation === TypePassation.TYPE_PASSATION_ORAL)
        this.totalCentres = this.centresFilter.length
        this.setDataForGenericTab(this.centresFilter)
    }

    @Watch('user_session_id')
    refreshInterface() {
        const params = {}
        Vue.set(params, 'page', 1)
        Vue.set(params, 'sort', 'ville.name')
        Vue.set(params, 'direction', 'asc')
        Vue.set(params, 'filter-submitted', 1)

        this.$store.dispatch('centre/getCentres', params)
            .then(async () => await this.load())
    }

    // METHODS

    /** Ouvre la gestion des salles d'un centre selectionné */
    gestionCentre(row: any) {
        this.$store.dispatch('centre/getCentre', { centre_id: row.id })
            .then(async () =>
                await this.$store.dispatch('centre/getUsersCount', { centre_id: row.id })
                    .then(() => this.showGestionCentre = true)
            )
    }

    /** Annule la gestion d'un centre */
    cancelGestionCentre() {
        this.$store.commit('affectationExaminateur/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.centre = null
        this.showGestionCentre = false
        this.$store.state.centre.centreSelect.salles = []
    }

    // Appelle une page lors du scroll
    async loadHandler(params: any) {
        await this.$store.dispatch('centre/getMoreCentres', params)
    }

    async filtreSortHandler(params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            Vue.set(this.params, 'filter-submitted', 1)

            // Des filtres sont appliqués on rappele le serveur
            await this.$store.dispatch('centre/getCentres', params)
        }
    }

    // Remplissage du tableau
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const can = this.$store.getters['auth/can'](Ability.GC_CENTER_MANAGE)
                const icone = checkIcone(Ability.GC_CENTER_MANAGE, can)
                const puce_etat = []

                puce_etat.push({ name:'circle', class: 'text-' + this.$store.getters['centre/etat_centre_global'](result).color, title: this.$store.getters['centre/etat_centre_global'](result).title })

                const line: any = [
                    { label: icone.label, item: result, type: 'action',  typeAction: 'edit', class: 'commons_first_action_button', icon: icone.icon, disabled: false },
                    { label: '', item: puce_etat,  type: 'icons', typeAction: null, class: 'text-center' }
                ]

                line.push({ label: '', item: result.name, type: 'text', typeAction: null, class: 'text-start' })
                line.push({ label: '', item: result.ville.name, type: 'text', typeAction: null, class: 'text-start' })
                line.push({ label: '', item: result.ville.academie, type: 'text', typeAction: null, class: 'text-start text-uppercase' })

                line.push({ label: '', item: result.ensembles_examinateurs_sum_nb_estime, type: 'text', typeAction: null, class: 'text-center' })
                line.push({ label: '', item: result.ensembles_examinateurs_tp_sum_nb_estime, type: 'text', typeAction: null, class: 'text-center' })

                const class_salles_count = result.salles_count >= result.ensembles_examinateurs_sum_nb_estime && result.salles_count > 0 ? 'text-success  fw-bold' : ''
                line.push({ label: '', item: result.salles_count, type: 'text', typeAction: null, class: 'text-center ' + class_salles_count })

                const class_salles_tp_count = result.salles_tp_count >= result.ensembles_examinateurs_tp_sum_nb_estime && result.salles_tp_count > 0 ? 'text-success  fw-bold' : ''
                line.push({ label: '', item: result.salles_tp_count, type: 'text', typeAction: null, class: 'text-center ' + class_salles_tp_count })

                const class_affectation = result.affectation_salles === 100 ? 'text-success  fw-bold' : ''
                line.push({ label: '', item: result.affectation_salles + ' %', type: 'text', typeAction: null, class: 'text-center ' + class_affectation })

                let etat_oral = { class: 'text-tertiary', title: 'Non définie' }
                if (result.salles_submitted_at) {
                    etat_oral = { class: 'text-info', title: 'L\'organisation des oraux a été soumise' }
                } else if (result.commentaire_rejet) {
                    etat_oral = { class: 'text-danger', title: 'Corrections attendues' }
                }
                if (result.salles_validated_at && result.nb_intervenants_validated_at) {
                    etat_oral = { class: 'text-success', title: 'L\'organisation des oraux a été validée' }
                }
                const puce_etat_oral = [{ name:'circle', class: etat_oral.class, title: etat_oral.title }]
                line.push({ label: '', item: puce_etat_oral,  type: 'icons', typeAction: null, class: 'text-center' })

                this.dataForTab.push(line)
            }
            this.$store.commit('centre/SET_LOADING', false)
        }
    }

    // Events
    async handleTableEvent(paParams: any): Promise<void> {
        if (paParams && paParams[0] && paParams[1]) {
            switch (paParams[0]) {
                case 'edit':
                    this.gestionCentre(paParams[1])
                    break
                case 'sortHandler':
                case 'filterHandler':
                    await this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    await this.loadHandler(paParams[1])
                    break
            }
        }
    }

    // Initialise les filtres
    setFilters() {
        const options_etat = [
            { index: 'cree', name: 'Centre créé' },
            { index: 'valide', name: 'Centre validé' },
            { index: 'non valide', name: 'Centre non validé' },
            { index: 'rejete', name: 'Corrections attendues' }
        ]

        this.filtres_oraux = [
            { libelle: 'état', defautOptionlibelle: 'Rechercher un', model: 'etat', value: '', index: 'etat',  datas: options_etat, loading: this.$store.getters['centre/loading'], options: { type: 'deroulant', fieldsKey: 'etat' } },
            { libelle: 'libellé', defautOptionlibelle: 'Rechercher un', model: 'name',       value: '', index: 'name',  datas: '', loading: this.$store.getters['centre/loading'], options: { type: 'form', fieldsKey: 'name', strict: false } },
            { libelle: 'ville', defautOptionlibelle: 'Rechercher une',  model: 'ville.name', value: '', index: 'ville.name',  datas: '', loading: this.$store.getters['centre/loading'], options: { type: 'form', fieldsKey: 'ville.name', strict: false } },
            { libelle: 'académie', defautOptionlibelle: 'Rechercher une',  model: 'ville.academie', value: '', index: 'ville.academie',  datas: '', loading: this.$store.getters['centre/loading'], options: { type: 'form', fieldsKey: 'ville.academie', strict: false } }
        ]
    }

    async load() {
        // Filtres la collection de centres
        if (this.$store.getters['centre/centres'].length) {
            this.centresFilter = this.$store.getters['centre/centres'].filter((c: any) => c.type_passation === TypePassation.TYPE_PASSATION_ORAL && c.submitted_at !== null)
            this.totalCentres = this.centresFilter.length
            this.setFilters()
            this.setDataForGenericTab(this.centresFilter)
        } else {
            const params = {}
            Vue.set(params, 'page', 1)
            Vue.set(params, 'sort', 'ville.name')
            Vue.set(params, 'direction', 'asc')
            Vue.set(params, 'filter-submitt' +
                'ed', 1)

            await this.$store.dispatch('centre/getCentres', params)
        }
    }

    async mounted() {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            await this.load()
        }
    }
}
