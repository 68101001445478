









































































































































































































































import { Vue, Component /* , Watch */ } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Table from '@/components/Table.vue'
import { Ability } from '@/types/Ability'
import { codeRestrict, getFileNameFromHeader, base64ToArrayBuffer, isObject, formatStringDate, formatDate } from '@/utils/helpers'

import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import { TypeDossier, getEtatTraitementDossier, EtatTraitementDossier } from '@/types/Candidat'
// import ListeSalles from '@/components/Centre/Salles/ListeSalles.vue'
import { TypeListe } from '@/types/Liste'
import { getDecisionPosteString } from '@/types/Poste'


@Component({
    components: {
        Table,
        ExaGenericTable,
        ErrorDisplay
    },
    computed: {
        ...mapGetters('candidatConcourPhaseListe', ['candidatConcourPhaseListes', 'loading', 'totalRows', 'lastPage', 'totalPage', 'meta', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class ListeCandidats extends Vue {
    codeRestrict = codeRestrict
    // DATAS
    params = 'sort=name&direction=asc'
    dataForTab: Array<any> = []
    filtres: any = []

    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'code', label: 'Code', sortable: true, class: 'text-left col-min-width', type: 'text' },
        { key: 'name', label: 'Nom', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'concour.name', label: 'Spécialité', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'liste', label: 'Liste', sortable: false, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'rang', label: 'Rang', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'posteOuvert.name_ars', label: 'Affectation ARS', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'posteOuvert.name', label: 'Affectation Poste', sortable: true, sortDirection: 'asc', class: 'text-left', type: 'text' },
        { key: 'verified', label: 'Vérifié', sortable: true, class: 'text-center col-min-width', type: 'icons' }
    ]

    getEtatTraitementDossier = getEtatTraitementDossier
    EtatTraitementDossier = EtatTraitementDossier

    getDecisionPosteString = getDecisionPosteString

    TypeListe = TypeListe


    formatDate = formatDate

    sortBy = ''
    sortDesc = false
    sortDirection = 'asc'
    filter = ''
    filterOn = []
    stickyHeader = true
    Ability = Ability


    file: any = null
    showModalImportCandidat = false
    messagesErreur: Array<string> = []
    importEnCours = false

    showModalModifAffectation = false

    candidatConcourPhaseListeTemp: any = null
    listePostesTemp: Array<any> = []
    listePosteByArs: any = null
    choix_ars: any = null
    choix_poste: any = null
    ne_pas_affecter = false

    exportingIsWorking =  false

    liste_dossiers_postes = []
    alreadyAffect = false

    exportWorking = false

    documentsPostePrioritaire: any = null
    candidatConcourPhaseListe_id: any = null

    marquer_verifier = false
    transferer = false


    // METHODS

    /**
     * exportListeVoeux
     * Cette fonction permet d'exporter la liste des voeux des candidats.
     */
    exportListeVoeux () {
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store.dispatch('candidatConcourPhaseListe/exportListe', { candidatconcourphaseliste: this.candidatConcourPhaseListeTemp.id })
            .then(response => {
                const fileNameTemp = getFileNameFromHeader(response.headers) || `liste_voeux_${this.candidatConcourPhaseListeTemp.name}_${this.candidatConcourPhaseListeTemp.first_name}.pdf`
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    /**
     * exportJustificatifAffectation
     * Cette fonction permet d'exporter la décision d'affectation pour un candidat.
     */
    exportJustificatifAffectation (id: any) {
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store.dispatch('candidatConcourPhaseListe/exportDecision', { candidatconcourphaseliste: id})//this.candidatConcourPhaseListeTemp.id })
            .then(response => {
                const fileNameTemp = getFileNameFromHeader(response.headers) || `decision_${this.candidatConcourPhaseListeTemp.name}_${this.candidatConcourPhaseListeTemp.first_name}.pdf`
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    exportPiecesJustificatifs () {
        this.exportingIsWorking = true
        let fileName = 'justificatifs.zip'

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('candidatConcourPhaseListe/justificatifsChoixPoste')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * exportPiecesJustificatifsByName
     * Cette fonction permet de telecharger des pièces justificatives en utilisant un identifiant et un document spécifié.
     *
     * @param id: number - L'identifiant utilisé pour récupérer les pièces justificatives.
     * @param doc: any - Le document contenant les informations nécessaires pour le téléchargement.
     */
    exportPiecesJustificatifsByName (id: number, doc: any) {
        this.exportingIsWorking = true
        let fileName = 'justificatiffs'

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('candidatConcourPhaseListe/getDocument', { id: id, name : doc.uuid })
            .then((response) => {
                const fileNameTemp = doc.name
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }


    /**
     * exportListeCandidat
     * Cette fonction permet d'exporter la liste des candidats.
     */
    exportListeCandidat () {
        this.exportingIsWorking = true
        let fileName = 'export_historique_notification.xlsx'

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('candidatConcourPhaseListe/export')
            .then((response) => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.exportingIsWorking = false
                this.$bvToast.hide(idInfo)
            })
    }

    currentTimeoutID = -1
    currentTimeoutIDArray: Array<any> = []
    /**
     * openImportCandidat
     * Cette fonction ouvre la fenêtre modale d'importation des candidats et charge les listes de dossiers.
     */
    openImportCandidat () {
        this.showModalImportCandidat = true
        this.loadListesDossiers()
    }

    /**
     * reinitTimeOut
     * Cette fonction permet de réinitialiser tous les timeouts en cours.
     */
    reinitTimeOut() {
        // console.log('Dans reinitTimeOut - this.currentTimeoutIDArray', this.currentTimeoutIDArray)
        for (const idTimeout in this.currentTimeoutIDArray) {
            clearTimeout(parseInt(this.currentTimeoutIDArray[idTimeout]))
        }
        if (this.currentTimeoutID !== -1) {
            clearTimeout(this.currentTimeoutID)
        }
        this.currentTimeoutIDArray = []
        this.currentTimeoutID = -1
    }

    /**
     * loadListesDossiers
     * Cette fonction charge les listes de dossiers pour l'importation des candidats.
     */
    loadListesDossiers () {
        this.$store.dispatch('candidat/getDossiers', { type: TypeDossier.TYPE_IMPORT_CANDIDATS_RESULTATS }).then(() => {
            this.liste_dossiers_postes = this.$store.state.candidat.liste_dossiers
        })
        this.currentTimeoutID = setTimeout(() => {
            if (this.showModalImportCandidat) {
                this.currentTimeoutIDArray.push(this.currentTimeoutID)
                this.loadListesDossiers()
            } else {
                this.reinitTimeOut()
            }
        }, 5000)
    }

    showErrorDossier = false
    dossier_error = null

    isObject = isObject

    /**
     * showErrorsFichiers
     * Cette fonction affiche les erreurs liées à un dossier spécifique.
     * @param dossier: le dossier contenant les erreurs à afficher
     */
    showErrorsFichiers (dossier: any) {
        this.dossier_error = dossier
        this.showErrorDossier = true
    }

    /**
     * closeErrorFichiers
     * Cette fonction ferme la fenêtre d'affichage des erreurs liées à un dossier.
     */
    closeErrorFichiers () {
        this.dossier_error = null
        this.showErrorDossier = false
    }

    /**
     * changement de fichier ?
     */
    fileChange (event: any) {
        this.file = event.target.files[0]
    }

    /**
     * saveModifAffectation
     * Cette fonction permet d'enregistrer les modifications d'affectation pour un candidat.
     */
    saveModifAffectation () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('candidatConcourPhaseListe/updateCandidatConcourPhaseListe', {
            id: this.candidatConcourPhaseListeTemp.id,
            verified: this.marquer_verifier ? 1 : 0,
            transfert: this.transferer ? 1 : 0,
            poste_id: this.ne_pas_affecter ? null : this.choix_poste
        })
            .then((response) => {
                for (let i = 0; i < this.$store.state.candidatConcourPhaseListe.candidatConcourPhaseListes.length; i++) {
                    if (this.$store.state.candidatConcourPhaseListe.candidatConcourPhaseListes[i].id === this.candidatConcourPhaseListeTemp.id) {
                        this.$store.state.candidatConcourPhaseListe.candidatConcourPhaseListes[i] = response.data.data
                    }
                }
                this.setDataForGenericTab(this.$store.state.candidatConcourPhaseListe.candidatConcourPhaseListes)
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé.', succesToaster)
                this.closeModalModifAffectation()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }


    /**
     * openModalModifAffectation
     * Cette fonction ouvre une fenêtre modale pour modifier l'affectation des candidats.
     * @param data: les données nécessaires pour la modification de l'affectation
     */
    openModalModifAffectation (data: any) {
        this.ne_pas_affecter = false
        this.choix_ars = null
        this.choix_poste = null
        this.alreadyAffect = false
        this.candidatConcourPhaseListeTemp = null
        this.listePostesTemp = []
        this.listePosteByArs = {}
        this.$store.dispatch('candidatConcourPhaseListe/getCandidatConcourPhaseListe', data.id).then((responseChoixCand) => {
            const params: any = {}
            params['filter-concour_id'] = responseChoixCand.data.data.concour_id
            params.perPage = -1
            this.$store.dispatch('posteOuvert/getPosteOuverts',  params).then((responsePosteOuvert) => {
                this.openModalModifiAffectationSuite(responseChoixCand.data.data, responsePosteOuvert.data.data)
            })
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    /**
     * openModalModifiAffectationSuite
     * Cette fonction prépare les données nécessaires pour ouvrir une fenêtre modale de modification d'affectation.
     * @param data: les données principales pour la modification d'affectation
     * @param dataBis: les données supplémentaires pour la modification d'affectation
     */
    openModalModifiAffectationSuite(data: any, dataBis: any) {
        this.marquer_verifier = false
        this.candidatConcourPhaseListeTemp = data
        this.documentsPostePrioritaire = null
        this.candidatConcourPhaseListe_id = null
        let count_verified = 0
        for (let i = 0; i < this.candidatConcourPhaseListeTemp.candidatConcourPhaseListe.length; i++) {
            this.candidatConcourPhaseListe_id = this.candidatConcourPhaseListeTemp.candidatConcourPhaseListe[i].id
            if (this.candidatConcourPhaseListeTemp.candidatConcourPhaseListe[i].liste.type === TypeListe.TYPE_VOEUX) {
                this.listePostesTemp = JSON.parse(JSON.stringify(this.candidatConcourPhaseListeTemp.candidatConcourPhaseListe[i].posteOuverts))
            } else if (this.candidatConcourPhaseListeTemp.candidatConcourPhaseListe[i].liste.type === TypeListe.TYPE_PREAFFECTATION) {
                this.documentsPostePrioritaire = this.candidatConcourPhaseListeTemp.candidatConcourPhaseListe[i].media ? this.candidatConcourPhaseListeTemp.candidatConcourPhaseListe[i].media : null
            }
            if(this.candidatConcourPhaseListeTemp.candidatConcourPhaseListe[i].verified_at) {
                count_verified++
            }
        }
        this.listePostesTemp = this.listePostesTemp.sort((a: any, b: any) => {
            return (a.ordre > b.ordre) ? 1 : -1
        })
        if(count_verified === this.candidatConcourPhaseListeTemp.candidatConcourPhaseListe.length) {
            this.marquer_verifier = true
        }
        this.listePosteByArs = {}
        for (let j = 0; j < dataBis.length; j++) {
            if (!this.listePosteByArs[dataBis[j].code_ars]) {
                this.listePosteByArs[dataBis[j].code_ars] = []
            }
            const pTemp = this.listePostesTemp.find((poste: any) => poste.id === dataBis[j].id)
            // dataBis[j].name += pTemp ? ' (choix n° ' + pTemp.ordre + ')' : ''
            dataBis[j].name = (dataBis[j].name_etablissement ? dataBis[j].name_etablissement + ' - ' : '') +''+ (dataBis[j].name_service ?  dataBis[j].name_service + ' - ' : '') + '' + dataBis[j].name + ' ' + (pTemp ? ' (choix n° ' + pTemp.ordre + ')' : '')
            this.listePosteByArs[dataBis[j].code_ars].push(dataBis[j])
        }
        for (const codeArs in this.listePosteByArs) {
            this.listePosteByArs[codeArs].sort((a: any, b: any) => {
                return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1
            })
        }
        if (this.candidatConcourPhaseListeTemp.posteOuvert) {
            this.choix_ars = this.candidatConcourPhaseListeTemp.posteOuvert.code_ars
            this.choix_poste = this.candidatConcourPhaseListeTemp.posteOuvert.id
            this.alreadyAffect = true
        }
        this.showModalModifAffectation = true
    }

    closeModalModifAffectation () {
        this.showModalModifAffectation = false
    }

    /**
     * envoiFichier
     * Cette fonction permet d'envoyer un fichier pour l'importation des candidats.
     */
    envoiFichier () {
        // Création du toaster en cours
        this.messagesErreur = []
        const idInfo        = 't_info_' + Math.random()
        const infosToaster  = {
            id:             idInfo,
            toaster:        'b-toaster-top-right',
            variant:        'primary',
            noCloseButton:  true,
            fade:           true,
            noAutoHide:     true
        }

        this.$bvToast.toast('Envoi en cours ...', infosToaster)
        this.importEnCours = true

        // Appel de la fonction pour importer les etabs
        this.$store.dispatch('candidatConcourPhaseListe/import', this.file)
            .then(() => {
                this.importEnCours = false

                const idSucces      = 't_succes_' + Math.random()
                const succesToaster = {
                    id:             idSucces,
                    toaster:        'b-toaster-top-right',
                    variant:        'success',
                    noCloseButton:  true,
                    fade:           true,
                    autoHideDelay:  5000
                }
                this.$bvToast.toast('Fichier déposé avec succès  !', succesToaster)
                this.$store.dispatch('candidatConcourPhaseListe/getCandidatConcourPhaseListes').then(() => {
                    this.showModalImportCandidat = false
                    this.reinitTimeOut()
                })
            })
            .catch((error) => {
                this.importEnCours = false
                console.log('ko:' + error)

                // Création du message d'erreurs
                if (error.response && error.response.data && error.response.data.errors) {
                    for (const err in error.response.data.errors) {
                        if (error.response.data.errors[err]) {
                            if (error.response.data.errors[err].row) {
                                const retourError = 'erreur ligne' + error.response.data.errors[err].row + ' : ' + error.response.data.errors[err].errors
                                this.messagesErreur.push(retourError)
                            }
                        }
                    }
                }
                // Toaster it's a fail !
                const idError = 't_error_' + Math.random()
                const errorToaster = {
                    id:             idError,
                    toaster:        'b-toaster-top-right',
                    variant:        'danger',
                    noCloseButton:  true,
                    fade:           true,
                    autoHideDelay:  5000
                }
                this.$bvToast.toast("Une erreur s'est produite lors de l'import", errorToaster)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * closeModalImport
     * Cette fonction permet de fermer la fenêtre modale d'importation.
     */
    closeModalImport () {
        this.messagesErreur                 = []
        this.closeErrorFichiers()
        this.showModalImportCandidat = false
        this.reinitTimeOut()
        this.$store.dispatch('candidatConcourPhaseListe/getCandidatConcourPhaseListes', this.params)
            .then(() => {
                this.setDataForGenericTab(this.$store.state.candidatConcourPhaseListe.candidatConcourPhaseListes)
            }).catch((error) => {
                console.log('ko:' + error)
            })
    }

    // ---- generic table --------------------------

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }

        if (poData) {
            for (const result of poData) {
                const classEtatEdit = 'text-light col-w-etat btn_action_ligne'
                const iconEtatEdit =  this.$store.getters['auth/can'](Ability.DLB_CHOIX_POSTES_MANAGE) ? 'pen' : 'eye'

                let rang = ''
                let liste = ''
                const verified: Array<any> = []
                let affectation = ''
                let affectationBis = ''
                let titleVerif = ''
                let count_verified = 0
                let isVerified = false
                if (result.candidatConcourPhaseListe && result.candidatConcourPhaseListe[0]) {
                    if (result.candidatConcourPhaseListe[0].candidatConcourPhase && result.candidatConcourPhaseListe[0].candidatConcourPhase.rang) {
                        rang = result.candidatConcourPhaseListe[0].candidatConcourPhase.rang
                    }
                    for (let i = 0; i < result.candidatConcourPhaseListe.length; i++) {
                        if (result.candidatConcourPhaseListe[i].liste) {
                            if (liste !== '') {
                                liste += ', '
                                titleVerif += ', '
                            }
                            liste += result.candidatConcourPhaseListe[i].liste.name
                            titleVerif += result.candidatConcourPhaseListe[i].liste.name +':'
                            if(result.candidatConcourPhaseListe[i].verified_at) {
                                titleVerif += ' vérifié le ' + formatStringDate(result.candidatConcourPhaseListe[i].verified_at)
                                count_verified++
                            } else {
                                titleVerif += ' non vérifié'

                            }
                            verified.push({listeName: result.candidatConcourPhaseListe[i].liste.name, verified_at: result.candidatConcourPhaseListe[i].verified_at})
                        }
                    }
                    if(count_verified === result.candidatConcourPhaseListe.length) {
                        isVerified = true
                    }
                    if (result.posteOuvert) {
                        affectation = result.posteOuvert.name_ars
                        affectationBis = result.posteOuvert.name
                    }
                }

                let specialite = ''
                if (result.concour) {
                    specialite = result.concour.name
                }

                const iconVerif: any[] = []


                const state = { title: titleVerif, class: (isVerified ? 'text-success' : 'text-secondary') }

                iconVerif.push({
                    name: 'check',
                    class: state.class,
                    title: titleVerif
                })

                const line = [
                    { label: '', item: result, icon: iconEtatEdit, type: 'action', typeAction: 'edit', class: classEtatEdit, disabled: false },
                    { label: '', item: result.code, type: 'text', typeAction: null, class: '' },
                    { label: '', item: result.name + ' ' + result.first_name, type: 'text', typeAction: null, class: '' },
                    { label: '', item: specialite, type: 'text', typeAction: null, class: '' },
                    { label: '', item: liste, type: 'text', typeAction: null, class: '' },
                    { label: '', item: rang, type: 'text', typeAction: null, class: '' },
                    { label: '', item: affectation, type: 'text', typeAction: null, class: '' },
                    { label: '', item: affectationBis, type: 'text', typeAction: null, class: '' },
                    { label: '', item: iconVerif, type: 'icons', typeAction: null, class: 'text-center', title: titleVerif }

                ]


                this.dataForTab.push(line)
            }
        }
        return this.dataForTab
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        this.filtres = [
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'code',
                value: '',
                index: 'code',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'code', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'nom',
                defautOptionlibelle: 'Rechercher un',
                model: 'name',
                value: '',
                index: 'name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'spécialité',
                defautOptionlibelle: 'Rechercher une',
                model: 'concour.name',
                value: '',
                index: 'concour.name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'concour.name' } // 'form' , 'deroulant'
            },
            {
                libelle: 'liste',
                defautOptionlibelle: 'Rechercher une',
                model: 'liste',
                value: '',
                index: 'liste',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'liste' } // 'form' , 'deroulant'
            },
            {
                libelle: 'rang',
                defautOptionlibelle: 'Rechercher un',
                model: 'rang',
                value: '',
                index: 'rang',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'rang', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'ARS',
                defautOptionlibelle: 'Rechercher une',
                model: 'posteOuvert.name_ars',
                value: '',
                index: 'posteOuvert.name_ars',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'posteOuvert.name_ars', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'Poste',
                defautOptionlibelle: 'Rechercher un',
                model: 'posteOuvert.name',
                value: '',
                index: 'posteOuvert.name',
                datas: null, // this.$store.state.user.users.name,
                loading: false,
                options: { type: 'form', fieldsKey: 'posteOuvert.name', strict: true } // 'form' , 'deroulant'
            },
            {
                libelle: 'Vérifié',
                defautOptionlibelle: 'Rechercher ',
                model: 'verified',
                value: '',
                index: 'verified',
                datas: [{index: 0, name: 'non vérifiés'},{index: 1, name: 'vérifiés'}],
                loading: false,
                options: { type: 'deroulant', fieldsKey: 'verified', strict: true } // 'form' , 'deroulant'
            }
        ]
    }

    /**
     * Récupération des events de la table
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':
                    this.openModalModifAffectation(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break

                case 'etatEdit':
                    break

                default:
                    break
            }
        }
    }

    // --------------------------------------

    /**
     * loadHandler
     * Cette fonction charge les données supplémentaires pour la liste des candidats en fonction des paramètres fournis.
     * @param params: les paramètres pour la requête de chargement des données
     */
    loadHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('candidatConcourPhaseListe/getMoreCandidatConcourPhaseListes', params).then(() => {
                this.setDataForGenericTab(this.$store.state.candidatConcourPhaseListe.candidatConcourPhaseListes)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    /**
     * filtreSortHandler
     * Cette fonction gère le filtrage et le tri des données pour la liste des candidats en fonction des paramètres fournis.
     * @param params: les paramètres pour le filtrage et le tri des données
     */
    filtreSortHandler (params: any) {
        if (JSON.stringify(this.params) !== JSON.stringify(params)) {
            this.params = params
            this.$store.dispatch('candidatConcourPhaseListe/getCandidatConcourPhaseListes', params).then(() => {
                this.setDataForGenericTab(this.$store.state.candidatConcourPhaseListe.candidatConcourPhaseListes)
            }).catch((error) => {
                console.log('ko:' + error)
            })
        }
    }

    /**
     * beforeUnmount
     * Cette fonction est appelée avant que le composant ne soit démonté. Elle réinitialise tous les timeouts en cours.
     */
    beforeUnmount () {
        this.reinitTimeOut()
    }

    /**
     * mounted
     * Cette fonction est appelée lorsque le composant est monté dans le DOM. Elle initialise les événements liés à la navigation et réinitialise les timeouts en cours. De plus, elle configure les filtres du générique genericTab.
     */
    mounted () {
        window.onpopstate = () => {
            this.reinitTimeOut()
        }
        window.addEventListener('popstate', () => {
            this.reinitTimeOut()
        })
        this.reinitTimeOut()
        this.setFiltersForGenericTab()
    }
}
