














































import { mapActions, mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { PosteContext, PosteType } from '@/types/Poste'

@Component({
    computed: {
        ...mapGetters('dossieradministratif', ['error', 'contrats']),
        ...mapGetters('poste', ['postes', 'loading']),
        ...mapState('poste', ['postes'])
    },
    methods: {
        ...mapActions('postes', ['getAllPostes']),
        ...mapActions('dossieradministratif', ['getExportPaie'])
    }
})

export default class ExportPaie extends Vue {
    @Prop() show?: boolean;
    @Prop() posteType?: number;


    poste_select = null
    liste_postes: any = []
    error_txt = null

    // Exporte les données au format excel
    exportDatas () {
        const datas = {
            poste_id: this.poste_select,
            type_poste: this.posteType

        }

        let fileName = 'export_paie'

        for (let i = 0; i < this.liste_postes.length; i++) {
            if (this.poste_select === this.liste_postes[i].id) {
                fileName += '_' + this.liste_postes[i].name
            }
        }

        fileName = fileName.toLowerCase()

        /* eslint-disable no-useless-escape */
        fileName = fileName.replace(/[ &\/\\#,+()$~%.'":*?<>{}]/g, '_')
        fileName = fileName.replace(/[éèêë]/g, 'e')
        const date: Date = new Date()
        fileName += '_' + date.getFullYear() + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + (date.getDate()) + '.xlsx'

        this.$store.dispatch('dossieradministratif/getExportPaie', { payload: datas })
            .then((response) => {
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .catch((error) => {
                this.error_txt = error
            })
    }

    // Ferme la popup
    reset (close: boolean) {
        this.poste_select = null
        if (close) {
            this.$emit('close', true)
        }
    }

    beforeMount () {
        if (this.posteType === PosteType.TYPE_PEDAGOGIQUE) {
            this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_PEDAGOGIQUE, context: PosteContext.CONTEXT_AFFECTATION }).then((response) => {
                this.liste_postes = response.data.data
            }).catch((error) => {
                this.error_txt = error
            })
        } else if (this.posteType === PosteType.TYPE_LOGISTIQUE) {
            this.$store.dispatch('poste/getPostes', { type: PosteType.TYPE_LOGISTIQUE, context: PosteContext.CONTEXT_AFFECTATION }).then((response) => {
                this.liste_postes = response.data.data
            }).catch((error) => {
                this.error_txt = error
            })
        }
        /*
        if (this.$store.state.poste.postes.length === 0) {
            this.$store.dispatch('poste/getAllPostes').then((response) => {
                this.liste_postes = response.data.data
            }).catch((error) => {
                this.error_txt = error
            })
        } else {
            this.liste_postes = this.$store.state.poste.postes
        }
        */
    }
}

