



























































import { codeRestrict } from '@/utils/helpers'
import { Watch, Prop, Vue, Component } from 'vue-property-decorator'

@Component({})

export default class PopupEditMesureHandicaps extends Vue {
    codeRestrict = codeRestrict
    @Prop() amenagement?: any;
    @Prop() showEdit?: boolean;

    showModalEditionAmenagement?: boolean = false

    typesAvailable: any[] = [
        { id:1, name: 'Ecrit' },
        { id:2, name: 'Oral' }
        // { id:3, name: 'Ecrit et oral' }
    ]

    typeSelected?: number = 1

    @Watch('showEdit')
    showNoShow () {
        this.showModalEditionAmenagement = this.$props.showEdit
    }

    validateForm () {
        this.amenagement.type = this.typeSelected
        this.$emit('validateForm', null)
    }

    cancelEdit () {
        this.$emit('reinitShow', null)
    }
}
