







































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { isNull } from 'lodash'

@Component({
    methods: { isNull },
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class PopupReglesOrganisation extends Vue {
    @Prop() showPopup: any

    regles_orga: any = null
    select_radio: boolean | null = null

    @Watch('showPopup')
    initRegles() {
        const session = this.$store.state.session.sessions.find(
            (s: any) => s.id === this.$store.getters['auth/user_session_id']
        )
        if (session) {
            this.regles_orga = session.regles
            this.select_radio = isNull(session.use_real_data_at) ? false : session.use_real_data_at
        }
    }

    // Ferme la popup des règles d'organisation
    cancel_edition_regles() {
        this.$emit('close')
    }

    // Sauvegarder le paramétrage des règles d'organisation
    save_edition_regles() {
        const payload = {
            regles: this.regles_orga,
            use_real_data: this.select_radio ?? false
        }
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        this.$store.dispatch('session/saveRegles', payload)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                this.$emit('save_params')
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}
