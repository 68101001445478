































































import { mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { PosteContext, PosteInterface } from '@/types/Poste'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import store from '@/store/index'
import { formatDate } from '@/utils/helpers'
import { Ability } from '@/types/Ability'

@Component({
    computed: {
        ...mapGetters('centre', ['error', 'countError', 'error_conflict', 'loading', 'loading_search']),
        ...mapState('centre', ['centre_users_select', 'totalRows', 'users_count', 'error']),
        ...mapState('poste', ['postes']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    methods: {
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})

export default class PopupValidationIntervenants extends Vue {
    @Prop() centre_select: any
    @Prop() centreId?: number

    Ability = Ability

    @Watch('centre_select')
    init () {
        if (this.centre_select) {
            this.centreSelect = this.centre_select
        }
    }

    beforeMount () {
        if (this.centre_select) {
            this.centreSelect = this.centre_select
        }
    }

    centreSelect: Array<any> = []
    postes_compteurs: Array<any> = []
    decision_select = 2
    commentaire_select = ''
    showError = false
    messageErreurSimple = ''
    showModalPopupValidation = false

    // Formatte les dates
    formatteDate (date: Date) {
        return formatDate(date)
    }

    // Ouvre le popup de confirmation de la validation d'une liste d'intervenants
    openPopupValidation () {
        this.postes_compteurs = this.$store.state.poste.postes.filter((p: PosteInterface) => p.context !== PosteContext.CONTEXT_RESPONSABLE_CENTRE && p.context !== PosteContext.CONTEXT_CHEF_CENTRE && p.context !== PosteContext.CONTEXT_CORRESPONDANT_ORAL)
        this.showModalPopupValidation = true
    }

    // Annule la soumission de la liste
    cancelValidation () {
        this.showModalPopupValidation = false
    }

    // SubmitDecisionValidation (e: Event) {
    SubmitDecisionValidation () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours...', infosToaster)
        // e.preventDefault()
        let data: any = {}
        let msgToast = ''

        if (this.centre_select.intervenants_submitted_at) {
            msgToast = 'Votre décision a été enregistrée avec succès !'
            switch (this.decision_select.toString()) {
                case '1':
                    data = {
                        centre_id: this.centre_select.id,
                        intervenants_validate: this.decision_select
                    }
                    break
                case '0':
                    data = {
                        centre_id: this.centre_select.id,
                        intervenants_validate: this.decision_select,
                        commentaire_rejet: this.commentaire_select
                    }
                    break
                default:
                    break
            }
        } else {
            // cas d'une validation forcée par le Resp SOL sans que la liste ait encore été soumise
            msgToast = 'Validation enregistrée avec succès !'
            data = {
                centre_id: this.centre_select.id,
                intervenants_validate: 1
            }
        }

        this.$store.dispatch('centre/validateIntervenantFromCentre', data)
            .then(() => {
                this.messageErreurSimple = ''
                this.showError = false
                this.showModalPopupValidation = false
                // Fermeture de la popup aprés validation
                const closeValidationIntervenants: HTMLElement = document.getElementsByClassName('closeValidationIntervenants')[0] as HTMLElement
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(msgToast, succesToaster)
                store.dispatch('centre/getCentre', { centre_id: this.centre_select.id }).then(() => {
                    //
                })
                closeValidationIntervenants.click()
            })
            .catch((error) => {
                console.log('ko:' + error)
                this.messageErreurSimple = 'Une erreur est survenue :'
                this.showError = true
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    closeHandler () {
        this.decision_select = 2
        this.commentaire_select = ''
        this.messageErreurSimple = ''
        this.showError = false
    }
}
